<template>
    <div
        class="row"
    >
        <div
            class="col-sm-12 col-md-4 q-pa-sm"
        >
            <QCard
                v-if="transitions && !isLoadingTransitions"
                flat
                bordered
            >
                <QCardSection>
                    <div class="text-h6">
                        {{ $t('visualization.conformance.issuesView.transitionsTitle') }}
                    </div>
                    <div class="text-subtitle text-italic">
                        {{ `${App.numberLocationFormat(transitions.unfulfillingCasesTotal)} ${occurenceLiteral(transitions.unfulfillingArcsTotal)}`+
                            ` ${$t('visualization.conformance.issuesView.outOf')} ${App.numberLocationFormat(start.total)}` }}
                    </div>
                </QCardSection>
                <QSeparator inset />
                <QCardSection class="IssueListing">
                    <QList v-if="transitions.unfulfillingCasesTotal > 0">
                        <QItem
                            v-for="(item,idx) in transitions.unfulfillingArcs"
                            :key="idx"
                        >
                            <QItemSection>
                                <QItemLabel>
                                    {{ item.source }} &rarr; {{ item.target }}
                                </QItemLabel>
                                <QLinearProgress
                                    :value="elementPercentage(item.total, transitions.unfulfillingCasesTotal)"
                                    size="20px"
                                    rounded
                                    color="secondary"
                                />
                                <QItemLabel class="text-caption text-italic">
                                    {{ `${App.numberLocationFormat(item.total)} ${occurenceLiteral(item.total)}` }}
                                </QItemLabel>
                            </QItemSection>
                        </QItem>
                    </QList>
                    <div
                        v-else
                        class="text-subtitle"
                    >
                        {{ $t('visualization.conformance.issuesView.noResultsTransitions') }}
                    </div>
                </QCardSection>
            </QCard>
            <div
                v-else-if="isLoadingTransitions"
                class="row"
            >
                <div class="col">
                    <QLinearProgress
                        indeterminate
                        color="secondary"
                    />
                </div>
            </div>
        </div>
        <div
            class="col-sm-12 col-md-4 q-pa-sm"
        >
            <QCard
                v-if="start && !isLoadingActivities['start']"
                flat
                bordered
            >
                <QCardSection>
                    <div class="text-h6">
                        {{ $t('visualization.conformance.issuesView.startTitle') }}
                    </div>
                    <div class="text-subtitle text-italic">
                        {{ `${App.numberLocationFormat(start.unfulfillingActivitiesTotal)} ${occurenceLiteral(start.unfulfillingActivitiesTotal)}`+
                            ` ${$t('visualization.conformance.issuesView.outOf')} ${App.numberLocationFormat(start.total)}` }}
                    </div>
                </QCardSection>
                <QSeparator inset />
                <QCardSection class="IssueListing">
                    <QList v-if="start.unfulfillingActivitiesTotal > 0">
                        <QItem
                            v-for="(item, idx) in start.unfulfillingActivities"
                            :key="idx"
                        >
                            <QItemSection>
                                <QItemLabel>
                                    {{ item.activityName }}
                                </QItemLabel>
                                <QLinearProgress
                                    :value="elementPercentage(item.total,start.unfulfillingActivitiesTotal)"
                                    size="20px"
                                    rounded
                                    color="secondary"
                                />
                                <QItemLabel class="text-caption text-italic">
                                    {{ `${App.numberLocationFormat(item.total)} ${occurenceLiteral(item.total)}` }}
                                </QItemLabel>
                            </QItemSection>
                        </QItem>
                    </QList>
                    <div
                        v-else
                        class="text-subtitle"
                    >
                        {{ $t('visualization.conformance.issuesView.noResultsStart') }}
                    </div>
                </QCardSection>
            </QCard>
            <div
                v-else-if="isLoadingActivities['start']"
                class="row"
            >
                <div class="col">
                    <QLinearProgress
                        indeterminate
                        color="secondary"
                    />
                </div>
            </div>
        </div>
        <div
            class="col-sm-12 col-md-4 q-pa-sm"
        >
            <QCard
                v-if="end && !isLoadingActivities['end']"
                flat
                bordered
            >
                <QCardSection>
                    <div class="text-h6">
                        {{ $t('visualization.conformance.issuesView.endTitle') }}
                    </div>
                    <div class="text-subtitle text-italic">
                        {{ `${App.numberLocationFormat(end.unfulfillingActivitiesTotal)} ${occurenceLiteral(end.unfulfillingActivitiesTotal)}`+
                            ` ${$t('visualization.conformance.issuesView.outOf')} ${App.numberLocationFormat(end.total)}` }}
                    </div>
                </QCardSection>
                <QSeparator inset />
                <QCardSection class="IssueListing">
                    <QList v-if="end.unfulfillingActivitiesTotal > 0">
                        <QItem
                            v-for="(item, idx) in end.unfulfillingActivities"
                            :key="idx"
                        >
                            <QItemSection>
                                <QItemLabel>
                                    {{ item.activityName }}
                                </QItemLabel>
                                <QLinearProgress
                                    size="20px"
                                    rounded
                                    :value="elementPercentage(item.total,end.unfulfillingActivitiesTotal)"
                                    color="secondary"
                                />
                                <QItemLabel class="text-caption text-italic">
                                    {{ `${App.numberLocationFormat(item.total)} ${occurenceLiteral(item.total)}` }}
                                </QItemLabel>
                            </QItemSection>
                        </QItem>
                    </QList>
                    <div
                        v-else
                        class="text-subtitle"
                    >
                        {{ $t('visualization.conformance.issuesView.noResultsEnd') }}
                    </div>
                </QCardSection>
            </QCard>
            <div
                v-else-if="isLoadingActivities['end']"
                class="row"
            >
                <div class="col">
                    <QLinearProgress
                        indeterminate
                        color="secondary"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest,
} from '@/api'
import { filtersStorageMixin, validatorsMixin } from '@/mixins'

const START_ACTIVITY = 'start'
const END_ACTIVITY = 'end'

export default {
    name: 'Issues',
    components: {
    },
    mixins: [filtersStorageMixin, validatorsMixin],
    inject: ['App'],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        modelId: VueTypes.string,
    },
    data () {
        return {
            transitions: null,
            start: null,
            end: null,
            isLoadingTransitions: false,
            isLoadingActivities: {
                start: false,
                end: false,
            },
        }
    },
    computed: {
    },
    watch: {
        modelId () {
            this.setIssuesInfo()
        },
    },
    mounted () {
        this.setIssuesInfo()
    },
    methods: {
        async fetchTransitionsInfo () {
            this.isLoadingTransitions = true
            const { processId } = this
            const { modelId } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            apiRequest(Api().conformance.transitions({
                datasetId: processId,
                bpmnId: modelId,
                params: {
                    filters,
                    filterSets: filterSetsUUIDs,
                    operator: generalOperator,
                },
            }))
                .then((data) => {
                    this.transitions = data
                })
                .finally(() => {
                    this.isLoadingTransitions = false
                })
        },
        async fetchActivitiesInfo (activityType) {
            this.isLoadingActivities[activityType] = true
            const { processId } = this
            const { modelId } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            apiRequest(Api().conformance.activities({
                datasetId: processId,
                bpmnId: modelId,
                params: {
                    filters,
                    filterSets: filterSetsUUIDs,
                    operator: generalOperator,
                    activityType,
                },
            }))
                .then((data) => {
                    this[activityType] = data
                })
                .finally(() => {
                    this.isLoadingActivities[activityType] = false
                })
        },
        setIssuesInfo () {
            this.fetchTransitionsInfo()
            this.fetchActivitiesInfo(START_ACTIVITY)
            this.fetchActivitiesInfo(END_ACTIVITY)
        },
        elementPercentage (occurrences, total) {
            return occurrences / total
        },
        occurenceLiteral (value) {
            return value !== 1 ? this.$t('visualization.conformance.issuesView.cases') : this.$t('visualization.conformance.issuesView.case')
        },
    },
}
</script>
<style scoped lang="scss">
.IssueListing {
    overflow: scroll;
    height: 300px;
}
</style>
