export default {
    upload: 'Upload dataset to assistant',
    reuploadWarning: 'This will reupload the dataset to the assistant and will restart the conversation. Continue?',
    restartConversation: 'Restart conversation',
    reuploadDataset: 'Reupload dataset',
    downloadConversation: 'Download conversation',
    assistantPresentation: 'Hello, I am your assistant, ask me your dataset related questions',
    writeMessagePlaceholder: 'Write a message',
    send: 'Send',
    yes: 'Yes',
    no: 'No',
    thinking: 'Thinking',
    labsDisclaimer: 'This assistant employs third-party LLM technology and answers may not be precise in some situations.' +
    'Under any circumstance, the user is responsible for any further use of the information this assistant provides.',
    labs: 'LABS',
    errorSendMessage: 'Error sending the message, reload the page or try again later.',
    emptyMessage: '(Empty response)',
}
