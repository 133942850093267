export default {
    sections: {
        personal: 'Definições pessoais',
        security: 'Segurança',
        apis: 'Gerir tokens',
        token: 'Token disponível',
    },

    personal: {
        text1: `Editar as suas informações pessoais e definições de visibilidade através do seu perfil `,
        text2: `{name} `,
        text3: `perfil.`,
    },

    security: {
        text: `Enviaremos uma mensagem com instruções para repor a sua palavra-passe para o seu endereço de correio eletrónico`,
        btn: 'Repor a palavra-passe',
    },

    apis: {
        text: 'Aqui pode gerir facilmente os seus tokens',
        generate: 'Gerar token',
        cancel: 'Revogar token',
    },

    token: {
        tooltip: 'Copiar para a área de transferência',
        success: 'Token copiado com sucesso',
        cancelled: 'Token revogado com sucesso',
        modal: {
            title: 'Informações do utilizador',
            password: 'Palavra-passe',
            btn: 'Enviar',
        },
    },

    notifications: {
        personal: {
            text: 'Informações pessoais guardadas corretamente',
        },
        resetPassword: {
            text: 'Será enviada uma mensagem com uma nova palavra-passe para o seu endereço de correio eletrónico',
        },
    },

    modals: {
        recovery: {
            title: 'Repor a palavra-passe',
            text: 'Tem a certeza de que pretende repor a sua palavra-passe?',
        },
    },
}
