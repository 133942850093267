<template>
    <div
        ref="draggable"
        class="Draggable"
        :style="{ position: isDragged ? 'absolute' : 'relative', top: isDragged ? `${top}px` : 'initial', left: isDragged ? `${left}px` : 'initial', zIndex: zIndex }"
        @mousedown="onMouseDown"
    >
        <slot />
    </div>
</template>

<script>

export default {
    name: 'Draggable',
    props: {
        zIndex: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            isDragging: false,
            isDragged: false,
            dragStartX: 0,
            dragStartY: 0,
            initialLeft: 0,
            initialTop: 0,
            left: 0,
            top: 0,
            containerHeight: 0,
        }
    },
    mounted () {
        this.updateContainerHeight()
        window.addEventListener('resize', this.updateContainerHeight)
        this.observeContainerHeight()
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.updateContainerHeight)
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
    },
    methods: {
        updateContainerHeight () {
            const container = document.querySelector('.ChartContainer')
            if (container) {
                this.containerHeight = container.offsetHeight
                this.adjustPositionWithinBounds()
            }
        },
        onMouseDown (event) {
            this.left = this.$refs.draggable.offsetLeft
            this.top = this.$refs.draggable.offsetTop
            this.initialLeft = this.left
            this.initialTop = this.top
            this.isDragging = true
            this.dragStartX = event.clientX
            this.dragStartY = event.clientY

            document.addEventListener('mousemove', this.onMouseMove)
            document.addEventListener('mouseup', this.onMouseUp)
        },
        onMouseMove (event) {
            if (!this.isDragging) return

            const deltaX = event.clientX - this.dragStartX
            const deltaY = event.clientY - this.dragStartY

            const threshold = 10
            if (deltaX > threshold || deltaY > threshold) {
                this.isDragged = true
            }

            let newLeft = this.initialLeft + deltaX
            let newTop = this.initialTop + deltaY

            const draggableRect = this.$refs.draggable.getBoundingClientRect()
            const container = document.querySelector('.ChartContainer')
            if (container) {
                const containerRect = container.getBoundingClientRect()

                // Limit the draggable within the container
                newLeft = Math.max(0, Math.min(newLeft, containerRect.width - draggableRect.width - 20))
                newTop = Math.max(0, Math.min(newTop, containerRect.height - draggableRect.height - 20))
            }

            this.left = newLeft
            this.top = newTop
        },
        onMouseUp () {
            this.isDragging = false
            document.removeEventListener('mousemove', this.onMouseMove)
            document.removeEventListener('mouseup', this.onMouseUp)
        },
        resetPosition () {
            this.isDragged = false
            this.isDragging = false
            this.left = this.initialLeft
            this.top = this.initialTop
        },
        adjustPositionWithinBounds () {
            if (this.isDragged) {
                const container = document.querySelector('.ChartContainer')
                const { draggable } = this.$refs
                if (draggable && container) {
                    const draggableRect = draggable.getBoundingClientRect()
                    const containerRect = container.getBoundingClientRect()
                    if (draggableRect.bottom > containerRect.bottom) {
                        this.top = containerRect.height - draggableRect.height - 20
                    }
                }
            }
        },
        observeContainerHeight () {
            const container = document.querySelector('.ChartContainer')
            if (container) {
                this.resizeObserver = new ResizeObserver(() => {
                    this.updateContainerHeight()
                })
                this.resizeObserver.observe(container)
            }
        },
    },
}
</script>
<style lang="scss">
.Draggable {
    display: flex;
    width: fit-content;
    // justify-content: center;
    align-items: center;
    position: absolute;
    cursor: move;
    user-select: none;
    transition: none;
 }
</style>
