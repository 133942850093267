<template>
    <div
        :id="id"
        class="Arc"
        :style="styles.container"
    >
        <div
            v-if="!simple && statistics && value"
            class="node-value"
        >
            <span
                :class="[ 'span-' + modelType ]"
                :style="styles.content"
                v-html="value"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import Utils from '../utils'

const styles = {
    container: {
        display: 'inline-block',
        'white-space': 'nowrap',
    },
    content: {
        display: 'inline-block',
        padding: '2px 6px',
    },
}

export default {
    name: 'Arc',
    props: {
        id: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        name: VueTypes.string,
        statistics: VueTypes.any,
        model: VueTypes.shape({
            values: VueTypes.any,
        }).loose,
        simple: VueTypes.bool.def(false),
    },
    computed: {
        modelType () {
            return (this.model || {}).values || Utils.graphValues.NONE
        },
        value () {
            return Utils.getInfoModel(this.statistics, this.model)
        },
    },
    beforeMount () {
        this.styles = styles
    },
}
</script>
