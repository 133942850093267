<template>
    <div class="Conformance q-mt-md">
        <div v-if="models && models.length > 0">
            <div class="flex justify-center items-center q-ma-md">
                <WText
                    :size="18"
                    class="q-mr-md"
                >
                    {{ $t('visualization.conformance.chooseBpmn') }}:
                </WText>
                <QSelect
                    v-model="chosenModel"
                    :options="models"
                    stackLabel
                    style="min-width: 250px;"
                    @update:modelValue="setConformanceModel"
                />
            </div>
            <Transition
                name="fade"
                mode="out-in"
            >
                <div v-if="chosenModel && xmlData && !isLoading">
                    <div
                        class="row"
                    >
                        <div
                            class="col-sm-12 q-pa-sm"
                        >
                            <BpmnViewer
                                :data="xmlData"
                                :centerButton="true"
                            />
                        </div>
                    </div>
                    <div class="flex flex-center q-ma-sm">
                        <QBtnToggle
                            v-model="currentTab"
                            toggleColor="primary"
                            noCaps
                            unelevated
                            :options="tabs"
                            class="q-mt-sm border-toggle"
                        />
                    </div>
                    <QTabPanels
                        v-model="currentTab"
                        animated
                        transitionNext="fade"
                        transitionPrev="fade"
                    >
                        <QTabPanel name="OVERVIEW">
                            <Overview
                                :processId="processId"
                                :modelId="chosenModel.value"
                            />
                        </QTabPanel>
                        <QTabPanel name="ISSUES">
                            <Issues
                                :processId="processId"
                                :modelId="chosenModel.value"
                            />
                        </QTabPanel>
                        <QTabPanel name="ROOT_CAUSE">
                            <RootCause
                                :processId="processId"
                                :modelId="chosenModel.value"
                                :attribs="attributes"
                            />
                        </QTabPanel>
                    </QTabPanels>
                </div>
                <div
                    v-else
                    class="flex justify-center items-center"
                    style="height:400px;"
                >
                    <QCircularProgress
                        indeterminate
                        rounded
                        size="50px"
                        color="primary"
                        class="q-ma-md"
                    />
                </div>
            </Transition>
        </div>
        <div v-else-if="!isLoading">
            <div class="flex flex-col justify-center items-center q-ma-md">
                <div>
                    <WText
                        :size="18"
                    >
                        {{ $t('visualization.conformance.noModels') }}
                    </WText>
                </div>
                <div>
                    <WText
                        :size="15"
                    >
                        {{ $t('visualization.conformance.noModelsUpload') }}
                    </WText>
                </div>
                <div class="mt-2">
                    <QBtn
                        color="primary"
                        noCaps
                        @click="handleUploadItem"
                    >
                        <QIcon
                            name="cloud_upload"
                            class="material-symbols-outlined"
                            left
                        />
                        {{ $t('projects.models.upload') }}
                    </QBtn>
                </div>
                <UploadDialog
                    ref="uploadDialog"
                    @import="uploadModel"
                    @rejected-file="notifyRejected"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess, notify,
} from '@/api'
import { filtersStorageMixin, validatorsMixin } from '@/mixins'
import BpmnViewer from '@/components/BpmnViewer/BpmnViewer.vue'
import { Overview, Issues, RootCause } from '.'
import { UploadDialog } from '../../../../Models/sections'

const DEFAULT_TAB = 'OVERVIEW'

export default {
    name: 'Conformance',
    components: {
        BpmnViewer,
        Overview,
        Issues,
        RootCause,
        UploadDialog,
    },
    mixins: [filtersStorageMixin, validatorsMixin],
    props: {
        projectId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.array,
    },
    data () {
        return {
            xmlData: null,
            models: null,
            chosenModel: null,
            total: undefined,
            isLoading: false,
            currentTab: DEFAULT_TAB,
        }
    },
    computed: {
        tabs () {
            return [{
                value: 'OVERVIEW',
                label: this.$t('visualization.conformance.overview'),
            },
            {
                value: 'ISSUES',
                label: this.$t('visualization.conformance.issues'),
            },
            {
                value: 'ROOT_CAUSE',
                label: this.$t('visualization.conformance.root'),
            }]
        },
    },
    mounted () {
        this.fetchModels()
    },
    methods: {
        async fetchModels () {
            this.isLoading = true
            const params = {
                projectId: this.projectId,
            }
            apiRequest(Api().models.list(params))
                .then(({ data, total }) => {
                    this.models = data.map(x => ({ label: x.modelName, value: x.id }))
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                    if (this.models.length > 0) {
                        [this.chosenModel] = this.models
                        this.setConformanceModel(this.chosenModel)
                    }
                })
        },
        async fetchModel (modelId) {
            const params = {
                modelId,
            }
            apiRequest(Api().models.get(params))
                .then(({ bpmn }) => {
                    this.xmlData = bpmn
                })
                .catch(notifyError)
        },
        setConformanceModel (model) {
            this.fetchModel(model.value)
        },
        handleUploadItem () {
            this.$refs.uploadDialog.open()
        },
        async uploadModel ({ modelName, file }) {
            this.isLoading = true
            const form = new FormData()
            form.append('file', file)
            form.append('name', modelName)
            form.append('projectUid', this.projectId)
            apiRequest(Api().models.create({
                params: form,
            }))
                .then(() => {
                    notifySuccess(this.$t('projects.models.notifications.created'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.fetchModels()
                })
        },
        notifyRejected () {
            notify({
                message: this.$t('projects.models.modals.upload.rejected'),
            })
        },
    },
}
</script>
<style scoped lang="scss">
.Conformance {
    width: 85%
}
.chart {
    min-width: 350px;
    height: 400px;
    width: 100%;
}
.modelSelector {
    min-width: 100px;
}
.border-toggle {
        border: 1px solid $primary;
    }
</style>
