<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ edit ? $t('visualization.settings.costs.ActivityCostModal.editActivityCost') : $t('visualization.settings.costs.ActivityCostModal.addActivityCost') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit.prevent="handleSubmit"
            >
                <div class="row mt-1">
                    <QSelect
                        v-model="selectedActivities"
                        class="Field"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.costs.ActivityCostModal.selectActivities')"
                        :options="activityOptions"
                        :disable="edit"
                        multiple
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt.label" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        :disable="scope.opt.disable"
                                        @update:modelValue="handleToggleOption(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                </div>
                <div class="chip-container">
                    <div
                        v-for="(selected, index) in selectedActivities"
                        :key="index"
                    >
                        <QChip
                            :removable="!edit"
                            color="primary"
                            textColor="white"
                            @remove="removeSelectedActivity(index)"
                        >
                            {{ selected.label }}
                        </QChip>
                    </div>
                </div>
                <div class="OptionsGroup row">
                    <q-input
                        v-model.number="costValue"
                        type="number"
                        class="col"
                        color="primary"
                        :label="$t('visualization.settings.costs.ActivityCostModal.costValue')"
                    />
                </div>
                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        unelevated
                        color="primary"
                        type="submit"
                    >
                        {{ edit ? $t('visualization.settings.costs.ActivityCostModal.save') : $t('visualization.settings.costs.ActivityCostModal.add') }}
                    </QBtn>
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal } from '@/components'
import {
    Api, apiRequest, notifyError, notify,
} from '@/api'

export default {
    name: 'ActivityCostModal',
    components: {
        Modal,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
    },
    emits: ['save'],
    data () {
        return {
            opened: false,
            selectedActivities: [],
            activityOptions: [],
            costValue: 0,
            edit: false,
            originalActivityIds: null,
        }
    },
    watch: {
        selectedActivities: {
            handler (newSelection) {
                this.activityOptions = this.activityOptions.map(option => ({
                    ...option,
                    disable: this.isActivitySelected(option.label) && !newSelection.some(selection => selection.value === option.value),
                }))
            },
            deep: true,
        },
    },
    mounted () {
        this.loadActivityOptions()
    },
    methods: {
        loadActivityOptions () {
            const { processId } = this.$route.params
            const params = {}

            apiRequest(Api().visualizations.activities({ processId, params }))
                .then((response) => {
                    this.activityOptions = response.map(activity => ({
                        label: activity.name,
                        value: activity.id,
                        disable: this.isActivitySelected(activity.name),
                    }))
                })
                .catch((error) => {
                    notifyError(error)
                })
        },
        isActivitySelected (activityName) {
            const result = this.values?.some(cost => cost.activities.split(', ').includes(activityName))
            return result
        },
        reset () {
            this.selectedActivities = []
            this.costValue = 0
            this.originalActivityIds = null
        },

        open (editCost = undefined) {
            if (editCost) {
                this.edit = true
                this.setValues(editCost)
            } else {
                this.edit = false
                this.reset()
            }
            this.opened = true
        },
        close () {
            this.opened = false
        },
        setValues (editCost) {
            this.selectedActivities = editCost.activityIds.split(', ').map((id) => {
                const option = this.activityOptions.find(opt => opt.value === id)
                return option ? { label: option.label, value: id } : { label: id, value: id }
            })
            this.costValue = editCost.avgCostHour
            this.originalActivityIds = editCost.activityIds
        },
        handleToggleOption (opt) {
            const index = this.selectedActivities.findIndex(activity => activity.value === opt.value)
            if (index !== -1) {
                this.selectedActivities.splice(index, 1)
            } else {
                this.selectedActivities.push(opt)
            }
        },
        handleSubmit () {
            if (this.validateForm()) {
                const costData = {
                    activities: this.selectedActivities.map((activity) => {
                        const option = this.activityOptions.find(opt => opt.value === activity.value)
                        return option ? option.label : activity.label
                    }).join(', '),
                    activityIds: this.selectedActivities.map(activity => activity.value).join(', '),
                    avgCostHour: this.costValue,
                }
                this.$emit('save', costData, this.edit, this.originalActivityIds)
                this.close()
            }
        },
        validateForm () {
            if (this.selectedActivities.length === 0 || this.selectedActivities[0] === '') {
                notify({ message: this.$t('visualization.settings.costs.ActivityCostModal.selectAtLeastOneActivity') })
                return false
            }
            if (this.costValue <= 0 || Number.isNaN(this.costValue)) {
                notify({ message: this.$t('visualization.settings.costs.ActivityCostModal.positiveNumberError') })
                return false
            }
            if (this.$parent.activityCosts.some(
                cost => this.selectedActivities.includes(cost.activities) &&
                  (!this.edit || cost.activities !== this.selectedActivities.join(', ')),
            )) {
                notify({ message: this.$t('visualization.settings.costs.duplicateError') })
                return false
            }
            return true
        },
        removeSelectedActivity (index) {
            this.selectedActivities.splice(index, 1)
        },
    },
}
</script>

<style scoped lang="scss">
.Modal {
  &:deep(.Dialog) {
      width: 100%;
  }

  .OptionsGroup {
      justify-content: space-between;
      gap: 10px;
  }

  .q-btn {
      padding-left: 25px;
      padding-right: 25px;
  }

  .Field {
      height: auto;
      width: 100%;
      &:deep(.q-field__control) {
              height: 50px;
              .q-field__control-container .q-field__native span {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      line-height: 34px;
              }
              .q-field__append {
                  height: 50px;
              }
      }
  }
  .chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
}
</style>
