import reports from './reports'
import queries from './queries'
import organizations from './organizations'
import projects from './projects'
import datasets from './datasets'
import visualizations from './visualizations'
import notifications from './notifications'
import eventConsumption from './eventConsumption'
import userManagement from './userManagement'
import users from './users'
import enviroments from './enviroments'
import dataNavigator from './dataNavigator'

const mocks = [
    reports,
    queries,
    organizations,
    projects,
    datasets,
    visualizations,
    notifications,
    userManagement,
    eventConsumption,
    users,
    enviroments,
    dataNavigator,
]

const endpoints = [
    // Reports
    // 'reports.get',

    // Queries
    // 'queries.list',
    // 'queries.create',
    // 'queries.get',
    // 'queries.update',
    // 'queries.delete',
    // 'queries.cancel',
    // 'queries.download',
    // 'queries.modes',
    // 'queries.modeReportTypes',

    // Organizations
    // 'organizations.list',
    // 'organizations.get',
    // 'organizations.create',
    // 'organizations.edit',
    // 'organizations.delete',

    // Projects
    // 'projects.list',
    // 'projects.get',
    // 'projects.create',

    // Datasets
    // 'datasets.list',
    // 'datasets.validateJdbc',
    // 'datasets.workflows',
    // 'datasets.activities',
    // 'datasets.getConfigurations',
    // 'datasets.finishUpload',

    // visualizations
    // 'visualizations.model',
    // 'visualizations.modelInfo',
    // 'visualizations.loops',
    // 'visualizations.dashboard',
    // 'visualizations.filters',

    // notifications
    // 'notifications.list',
    // 'notifications.create',

    // Events consumition
    // 'eventConsumption.get',
    // 'eventConsumption.detail',

    // User management
    // 'userManagement.list',
    // 'userManagement.create',
    // 'userManagement.cancel',
    // 'userManagement.revoke',

    // Users
    // 'users.me',
    // 'users.list',
    // 'users.update',
    // 'users.resetPassword',

    // Payments
    // 'payments.portalSession',
    // 'payments.activateSubscription',
    // 'payments.cancelSubscription',

    // Enviroments
    // 'enviroments.getInfo',

    // dataNavigator
    // 'dataNavigator.activitiesSorted',
    // 'dataNavigator.transitionsSorted',
    // 'dataNavigator.variantsOver',
    // 'dataNavigator.variantsSorted',
]

export default {
    endpoints,
    mocks,
}
