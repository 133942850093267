<template>
    <div>
        <svg
            id="graph"
            ref="svg"
            height="1px"
            class="Graph"
        />
        <q-inner-loading :showing="shouldShowLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    curveBasis, select, zoom, zoomIdentity, zoomTransform,
} from 'd3'
import dagreD3 from 'dagre-d3'
import { mediaQueryMixin } from '@/mixins'
import mount from '@/utils/mount'
import { Activity, Arc } from './components'
import { Svg, nameFormatter } from './utils'

export default {
    name: 'Graph',
    mixins: [mediaQueryMixin],
    inject: ['App'],
    props: {
        data: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        model: VueTypes.shape({
            values: VueTypes.string,
            statsValue: VueTypes.string,
            heatMapValue: VueTypes.string,
            directionValue: VueTypes.oneOf(['LR', 'TB']).def('TB'),
        }).loose,
        color: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        parent: VueTypes.string,
        simple: VueTypes.bool.def(false),
        variantsLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            svg: null,
            render: null,
            inner: null,
            g: null,
            zoom: null,
        }
    },
    computed: {
        shouldShowLoading () {
            return !this.data || Object.keys(this.data).length === 0 || this.variantsLoading
        },
    },
    mounted () {
        // DOC: Multiple watchers. More info in https://vuejs.org/v2/api/#vm-watch
        this.$watch(vm => JSON.stringify([vm.data, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]), this.fetchGraph, { immediate: true })
        this.$watch('$mq', this.centerGraph, { deep: true })
        this.$watch(
            vm => JSON.stringify([vm.color, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]),
            this.colorLoops,
            { immediate: true },
        )
    },
    methods: {
        fetchGraph () {
            if (!this.data || !this.data.activities || this.data.activities.length === 0) {
                console.warn('No data or activities are empty')
                return
            }
            const { svg } = this.$refs
            Svg.clean(svg)
            this.draw()
            Svg.listenEdgeHover()
            if (this.model && this.data && this.data.activities) {
                Svg.enableLoopsAndOverlaps(this.data, this.model)
            } else {
                console.warn('Model or data is undefined')
            }
        },
        draw () {
            const { svg } = this.$refs
            Svg.initalizeZoom(svg)
            this.g = new dagreD3.graphlib.Graph().setGraph({ ...Svg.directions[this.model.directionValue], rankdir: this.model.directionValue })
            this.render = new dagreD3.render() // eslint-disable-line new-cap

            if (this.data) {
                this.renderActivities()
                this.renderArcs()
            }

            this.svg = select(svg)
            this.inner = this.svg.append('g')
            this.zoom = zoom().on('zoom', () => {
                const transform = zoomTransform(this.svg.node())
                if (this.inner) {
                    this.inner.attr('transform', transform)
                }
            })
            this.svg.call(this.zoom)

            this.g.graph().transition = selection => selection.transition().duration(500)
            this.$nextTick(() => {
                this.render(this.inner, this.g)

                Svg.resetZoom(svg)
                this.centerGraph()
            })
        },
        renderActivities () {
            const { activities = [], frequency } = this.data
            activities.forEach((item) => {
                const labelId = `activity-node-${nameFormatter(item.name)}-label`
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    ...(item.attribute ? { attribute: item.attribute } : {}),
                    heatMap: {
                        duration: 0.098,
                        frequency: item.frequency / frequency,
                    },
                }
                const propsData = {
                    id: labelId,
                    name: item.name === 'START' || item.name === 'END' ? this.$t(`visualization.graph.${item.name}`) : item.name,
                    statistics,
                    model: this.model,
                    simple: this.simple,
                }
                const { vNode } = mount(Activity, { props: propsData })
                this.g.setNode(item.name, {
                    ...Svg.activities,
                    id: `activity-node-${item.name}`,
                    label: Svg.createElement(vNode),
                    style: this.data.activitiesExecuted && this.data.activitiesExecuted[item.id] === true ? `stroke: rgb(32, 20, 87)` : undefined,
                })
            })
        },
        renderArcs () {
            const { arcs = [], frequency } = this.data
            const maxFreq = Math.max(...arcs.map(item => item.frequency))

            arcs.forEach((item) => {
                const labelId = `arc-node-${nameFormatter(item.name)}-label`
                const strokeSize = item.frequency * Svg.stroke.max / maxFreq
                const stroke = strokeSize < Svg.stroke.min ? Svg.stroke.min : strokeSize
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    overlap: true,
                    heatMap: {
                        duration: item.duration.avg / item.duration.max,
                        frequency: item.frequency / frequency,
                    },
                }
                const propsData = {
                    id: labelId,
                    name: item.name,
                    statistics,
                    model: this.model,
                    simple: this.simple,
                }
                const { vNode } = mount(Arc, { props: propsData })
                this.g.setEdge(item.source, item.target, {
                    ...Svg.arcs,
                    id: `arc-node-${nameFormatter(item.name)}`,
                    label: Svg.createElement(vNode),
                    style: `stroke-width: ${stroke};`,
                    curve: curveBasis,
                    labelId,
                })
            })
        },
        centerGraph () {
            const { svg } = this.$refs
            if (this.svg && svg) {
                if (this.parent) this.svg.attr('height', document.getElementById(`${this.parent}`).clientHeight)
                else this.svg.attr('height', this.g.graph().height * Svg.scale)
                const { width: containerWidth, height: containerHeight } = svg.getBoundingClientRect()
                const width = this.g.graph().width * Svg.scale
                const height = this.g.graph().height * Svg.scale
                const translateX = (containerWidth - width) / 2
                const translateY = (containerHeight - height) / 2
                if (!Number.isFinite(translateX) && !Number.isFinite(translateY)) return
                this.svg.call(this.zoom.transform, zoomIdentity.translate(translateX, translateY).scale(Svg.scale))
            }
        },
        colorLoops () {
            if (this.color !== undefined) {
                Svg.enableLoopsAndOverlaps(this.color, this.model, true)
            }
        },
    },
}// eslint-disable-next-line
</script>

<style scoped lang="scss">
.Graph {
    display: block;
    margin: 0 auto;
    width: 100%;

     &:deep() {
        .node {
            .Activity.featured {
                color: $white;
            }

            .node-title {
                font-weight: bold;
            }

            rect {
                fill: $white;
                stroke: $border-color;
                stroke-width: 2px;
            }
            &.highlight {
                color: $white;
                rect {
                    fill: $graph-highlight;
                    stroke: $graph-highlight;
                }
            }
        }

        .edgeLabel {
            span {
                border-radius: $border-radius;
                color: $black;
                background-color: $graph-bg-value;
                font-weight: bold;
                cursor: default;
            }
            .highlight {
                span {
                    background: $graph-highlight;
                    color: $white;
                }
            }
        }

        .edgePath {
            & > path {
                stroke: $graph-bg;
                fill: none;
                stroke-width: 1.5px;
            }

            & .arrow-head {
                fill: $graph-bg;
            }

            &.highlight {
                & > path {
                    stroke: $graph-highlight !important;
                }
                & .arrow-head {
                    stroke: $graph-highlight !important;
                    fill: $graph-highlight !important;
                }
            }
        }

        &.heatmap {
            .edgeLabel {
                span {
                    background-color: #e9e9ef;
                    color: $black;
                }

                &.highlight span {
                    background-color: transparent;
                    color: $black;
                }
            }
        }
    }
}
</style>
