<template>
    <q-card>
        <q-card-section class="text-subtitle1 text-weight-bold text-primary">
            {{ $t('visualization.settings.caseCompletion.name') }}
        </q-card-section>
        <q-card-section class="column q-gutter-md">
            <q-card
                bordered
                flat
                class="col-auto border border-grey2 rounded"
            >
                <q-card-section>
                    <div class="text-primary text-weight-bold q-my-sm">
                        {{ $t('visualization.settings.startingActivities') }}
                    </div>
                    <q-select
                        v-model="startingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        labelColor="black"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="startingActivityOptions"
                        :loading="startingActivityOptions.length === 0"
                        @update:modelValue="updateCaseCompletion"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleStartingActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </q-select>
                    <div class="q-my-sm">
                        <q-chip
                            v-for="(selected, index) in startingA"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteIndexValue(index, 'startingA' )"
                        >
                            {{ selected }}
                        </q-chip>
                    </div>
                </q-card-section>
            </q-card>
            <q-card
                flat
                bordered
                class="col-auto border border-grey2 rounded"
            >
                <q-card-section>
                    <div class="text-primary text-weight-bold q-my-sm">
                        {{ $t('visualization.settings.occurringActivities') }}
                    </div>
                    <q-select
                        v-model="occurringA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        labelColor="black"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="activities"
                        :loading="activities.length === 0"
                        @update:modelValue="updateCaseCompletion"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleOccurringActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </q-select>
                    <div class="q-my-sm">
                        <q-chip
                            v-for="(selected, index) in occurringA"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteIndexValue(index,'occurringA')"
                        >
                            {{ selected }}
                        </q-chip>
                    </div>
                </q-card-section>
            </q-card>
            <q-card
                flat
                bordered
                class="col-auto border border-grey2 rounded"
            >
                <q-card-section>
                    <div class="text-primary text-weight-bold q-my-sm">
                        {{ $t('visualization.settings.endingActivities') }}
                    </div>
                    <q-select
                        v-model="endingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        labelColor="black"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="endingActivityOptions"
                        :loading="endingActivityOptions.length === 0"
                        @update:modelValue="updateCaseCompletion"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleEndingActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </q-select>
                    <div class="q-my-sm">
                        <q-chip
                            v-for="(selected, index) in endingA"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteIndexValue(index,'endingA')"
                        >
                            {{ selected }}
                        </q-chip>
                    </div>
                </q-card-section>
            </q-card>
        </q-card-section>
    </q-card>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'CaseCompletionConditions',
    components: {},
    mixins: [],
    props: {
        attributes: VueTypes.array,
        values: VueTypes.object,
    },
    emits: ['CaseCompletionConditionsConfig'],
    data () {
        return {
            startingA: [],
            occurringA: [],
            endingA: [],
            title: "$t('visualization.settings.cost.name')",
            startingActivityOptions: [],
            endingActivityOptions: [],
            activities: [],
        }
    },
    mounted () {
        this.getSplitFilteredActivities()
        this.checkIfValues()
    },
    methods: {
        checkIfValues () {
            if (this.values) {
                this.endingA = [...this.values.endingActivities]
                this.occurringA = [...this.values.occurringActivities]
                this.startingA = [...this.values.startingActivities]
            }
        },
        updateCaseCompletion () {
            const caseCompletion = {
                endingActivities: this.endingA ? [...this.endingA] : [],
                occurringActivities: this.occurringA ? [...this.occurringA] : [],
                startingActivities: this.startingA ? [...this.startingA] : [],
            }
            this.$emit('CaseCompletionConditionsConfig', { data: caseCompletion, type: 'caseCompletion' })
        },
        getSplitFilteredActivities () {
            const { processId } = this.$route.params
            const params = { filters: [] }
            apiRequest(Api().visualizations.splitFilteredActivities({ processId, params }))
                .then((data) => {
                    this.startingActivityOptions = data.firstActivities
                    this.endingActivityOptions = data.lastActivities
                    this.activities = data.allActivities
                })
                .catch(notifyError)
        },
        deleteIndexValue (index, name) {
            if (name === 'startingA') {
                this.startingA.splice(index, 1)
            } else if (name === 'occurringA') {
                this.occurringA.splice(index, 1)
            } else {
                this.endingA.splice(index, 1)
            }
            this.updateCaseCompletion()
        },
        handleStartingActivitiesInput (selected) {
            if (!this.startingA.includes(selected)) {
                this.startingA.push(selected)
            } else {
                const index = this.startingA.indexOf(selected)
                if (index !== -1) {
                    this.startingA.splice(index, 1)
                }
            }
            this.updateCaseCompletion()
        },
        handleOccurringActivitiesInput (selected) {
            if (!this.occurringA.includes(selected)) {
                this.occurringA.push(selected)
            } else {
                const index = this.occurringA.indexOf(selected)
                if (index !== -1) {
                    this.occurringA.splice(index, 1)
                }
            }
            this.updateCaseCompletion()
        },
        handleEndingActivitiesInput (selected) {
            if (!this.endingA.includes(selected)) {
                this.endingA.push(selected)
            } else {
                const index = this.endingA.indexOf(selected)
                if (index !== -1) {
                    this.endingA.splice(index, 1)
                }
            }
            this.updateCaseCompletion()
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
