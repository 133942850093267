<template>
    <QDialog
        v-model="dialog"
        persistent
        transitionShow="slide-up"
        transitionHide="slide-down"
    >
        <QCard class="ModelPreview">
            <QBar class="bg-primary text-white">
                <div>{{ name }}</div>
                <QSpace />
                <QBtn
                    dense
                    flat
                    icon="close"
                    @click="$emit('closePreview')"
                />
            </QBar>
            <QCardSection>
                <div class="ButtonRow">
                    <QBtn
                        id="reset-zoom-button"
                        ref="resetZoomButton"
                        icon="adjust"
                        unelevated
                        color="white"
                        class="CenterBtn"
                        textColor="primary"
                        size="md"
                        @click="centerGraph"
                    >
                        <QTooltip>
                            {{ $t('visualization.centerChart') }}
                        </QTooltip>
                    </QBtn>
                </div>
                <div class="BpmnRow">
                    <BpmnViewer
                        ref="bpmnViewer"
                        :data="xmlData"
                        :centerButton="false"
                    />
                </div>
            </QCardSection>
        </QCard>
    </QDialog>
</template>
<script>
import VueTypes from 'vue-types'
import BpmnViewer from '@/components/BpmnViewer/BpmnViewer.vue'

export default {
    name: 'ModelPreview',
    components: {
        BpmnViewer,
    },
    props: {
        show: VueTypes.bool,
        bpmnData: VueTypes.any,
    },
    emits: ['closePreview'],
    data () {
        return {
            dialog: false,
            name: undefined,
            xmlData: undefined,
        }
    },
    watch: {
        show () {
            this.dialog = this.show
        },
        bpmnData () {
            if (this.bpmnData !== undefined) {
                this.name = this.bpmnData.modelName
                this.xmlData = this.bpmnData.bpmn
            }
        },
    },
    methods: {
        centerGraph () {
            if (this.$refs.bpmnViewer) {
                this.$refs.bpmnViewer.viewer.get('canvas').zoom('fit-viewport')
            }
        },
    },
}
</script>
<style scoped lang="scss">
    .ModelPreview {
        width: 800px;
        max-width: 1024px;
    }
    .ButtonRow {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
    }
</style>
