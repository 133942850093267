<template>
    <div
        style="white-space: pre-wrap;"
        :class="['message', { dark }]"
    >
        <p class="time">
            {{ App.dateLocationFormat(time, 'long') }}
        </p>
        {{ text }}
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Message',
    inject: ['App'],
    props: {
        time: VueTypes.Number, // Time of the message
        text: VueTypes.string, // Content of the message
        author: VueTypes.string, // Author of the message
        dark: VueTypes.Boolean, // Background variant of the box
    },
}
</script>

<style scoped lang="scss">
.message {
    background: #e7e7e7;
    border-radius: 10px;
    padding: 1rem;
    width: fit-content;
    word-wrap: break-word;
    max-width: 80%;
}

.message.dark {
    background: #e9eaf6;
}

h5 {
    margin: 0 0 .5rem 0;
}

.time {
    color: $primary;
}

</style>
