import { createRouter, createWebHistory } from 'vue-router'
import Config from '@/config'
import { APIError } from '@/screens'
import MainLayout from '@/layouts/Main.vue'

import dashboardRoutes from './routes/dashboard'
import organizationsRoutes from './routes/organizations'

export default function usePlugin (app) {
    // Aquí es donde usarías app.use() para instalar tu plugin
    app.use(router)
}

export const router = createRouter({
    history: createWebHistory(),
    base: Config.vueRouter.base,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: MainLayout,
            redirect: { name: 'Dashboard' },
            children: [
                ...dashboardRoutes(),
                ...organizationsRoutes(),
                {
                    path: 'error',
                    name: 'Error',
                    component: APIError,
                },
            ],
        },
        {
            path: '/:catchAll(.*)',
            redirect: { name: 'Dashboard' },
        },
    ],
})
