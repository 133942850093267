<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ edit ? $t('visualization.settings.sla.transitionSlaModal.editTitle') : $t('visualization.settings.sla.transitionSlaModal.addTitle') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit="handleAddTransitionSla"
            >
                <div class="row mt-1">
                    <QSelect
                        v-model="transition"
                        class="Field"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :disable="edit ? true : false"
                        :label="$t('visualization.settings.sla.transitionSlaModal.selectTransition')"
                        :options="transitionOptions"
                    />
                </div>
                <div class="OptionsGroup row">
                    <InputNumeric
                        v-model="value"
                        :decimalNum="0"
                        :decimalStrictLimit="true"
                        :label="$t('visualization.settings.sla.number')"
                        :min="1"
                        standout
                        outlined
                        type="number"
                        class="col"
                    />
                    <QSelect
                        v-model="unit"
                        outlined
                        class="col"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.sla.unit')"
                        :options="slaUnits"
                    />
                </div>
                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        unelevated
                        color="primary"
                        type="submit"
                    >
                        {{ edit ? $t('visualization.settings.sla.edit') : $t('visualization.settings.sla.add') }}
                    </QBtn>
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal, InputNumeric } from '@/components'
import { Api, apiRequest, notifyError } from '@/api'

export default {
    name: 'TransitionSlaModal',
    components: {
        Modal,
        InputNumeric,
    },
    inject: ['App'],
    emits: [
        'addTransitionSla',
    ],
    data () {
        return {
            opened: false,
            transition: '',
            transitionOptions: [],
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            value: 0,
            edit: false,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
        this.getTransitions()
    },
    methods: {
        open (editCustomSla = undefined) {
            if (editCustomSla !== undefined) {
                this.edit = true
                this.setValues(editCustomSla)
            } else {
                this.edit = false
                this.reset()
            }
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            [this.transition] = this.transitionOptions
            this.unit = { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') }
            this.value = 0
        },
        setValues (editCustomSla) {
            [this.transition] = editCustomSla.elements
            this.unit = { value: editCustomSla.unit, label: this.$t(`visualization.settings.sla.units.${editCustomSla.unit}`) }
            this.value = editCustomSla.value
        },
        getTransitions () {
            const { processId } = this.$route.params
            const params = {}
            apiRequest(Api().visualizations.arcs({ processId, params }))
                .then((res) => {
                    this.transitionOptions = res.sort()
                    const [firstTransition] = this.transitionOptions
                    this.transition = firstTransition
                })
                .catch(notifyError)
        },
        handleAddTransitionSla () {
            this.$emit('addTransitionSla', {
                type: 'transition',
                elements: [this.transition],
                value: this.value,
                unit: this.unit.value,
                loopMode: '',
                sourceOccurrence: '',
                targetOccurrence: '',
            }, this.edit)
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 600px;
    }

    .OptionsGroup {
        justify-content: space-between;
        gap: 10px;
    }

    .q.btn {
        padding-left: 25px;
        padding-right: 25px;
    }

    .Field {
        width: 100%;
        height: auto;
        &:deep(.q-field__control) {
                height: 50px;
                .q-field__control-container .q-field__native span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 34px;
                }
                .q-field__append {
                    height: 50px;
                }
        }
    }
}
</style>
