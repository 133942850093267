import moment from 'moment'
import mtimezone from 'moment-timezone'

const humanize = value => (value > 0 ? moment.duration(value).locale('precise-en').humanize() : 0)
const percentage = value => (value * 100).toFixed(1)
const date = (value, formatDate = 'DD/MM/YY HH:mm', tzone) => mtimezone.tz(value, tzone).format(formatDate)
const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export default {
    methods: {
        // Date
        date (value, formatDate = 'DD/MM/YY HH:mm') {
            const userTimezone = getUserTimezone()
            return date(value, formatDate, userTimezone)
        },
        humanize,
        // Numbers
        decimal (value, num = 2) {
            return typeof value === 'number' ? value.toFixed(num) : value
        },
        percentage,
    },
}
