<template>
    <div class="Projects">
        <div class="Actions flex row-left-center mb-1">
            <QBtn
                color="primary"
                noCaps
                icon="add_circle_outline"
                :label="$t('projects.new')"
                @click="handleNewItem"
            />
        </div>

        <div class="Container">
            <ProjectsList
                ref="table"
                :rows="projects"
                :total="total"
                :search="header.search"
                :loading="isFetching"
                @on-new-item="handleNewItem"
                @on-delete="handleDeleteItem"
                @on-update="handleUpdateItem"
                @on-click="handleClickItem"
                @on-fetch-data="fetchData"
            />
        </div>

        <ModalRequest
            ref="delPrDialog"
            :title="$t('projects.delete')"
            :text="$t('projects.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @on-accept="archiveProject"
        />
        <ModalNameDesc
            ref="editPrDialog"
            :title="$t('projects.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            @on-submit="updateProject"
        />
        <ModalNameDesc
            ref="newPrDialog"
            :title="$t('projects.new')"
            :action="$t('actions.create')"
            :loading="isLoading"
            @on-submit="createProject"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { dialogMixin } from '@/mixins'
import { ModalRequest, ModalNameDesc } from '@/components'
import { ProjectsList } from './sections'

export default {
    name: 'Projects',
    components: {
        ModalRequest,
        ModalNameDesc,
        ProjectsList,
    },
    mixins: [dialogMixin],
    props: {
        headers: VueTypes.shape({
            search: VueTypes.string.def(''),
            archived: VueTypes.bool.def(false),
        }),
    },
    data () {
        return {
            projects: undefined,
            total: undefined,
            isFetching: false,
            isLoading: false,

            header: {
                search: '',
                archived: false,
            },
        }
    },
    computed: {
        params () {
            const pagination = this.$refs.table.getPagination()
            return {
                ...this.headers,
                ...pagination,
            }
        },
    },
    watch: {
        headers (filters) {
            if (filters) this.fetchData()
        },
    },
    beforeMount () {
        const { organizationId } = this.$route.params || {}
        this.organizationId = organizationId
    },
    mounted () {
        const { q } = this.$route.query || {}
        this.search = q || ''
        this.fetchData()
    },
    methods: {
        handleSearch ({ search }) {
            if (!search) this.$router.replace(this.$router).catch(() => {})
            this.fetchData()
        },
        handleClickItem (projectId) {
            this.$router.push({ name: 'Project', params: { organizationId: this.organizationId, projectId } })
        },
        async fetchData () {
            this.isFetching = true
            const {
                archived, search, page, rowsPerPage,
            } = this.params
            const params = {
                organizationUid: this.organizationId,
                includeArchived: !!archived,
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || null,
            }
            apiRequest(Api().projects.list(params))
                .then(({ data, total }) => {
                    this.projects = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        async createProject ({ name, description }) {
            this.isLoading = true
            apiRequest(Api().projects.create({ organizationUid: this.organizationId, name, description }))
                .then(() => {
                    notifySuccess(this.$t('projects.notifications.created.text'))
                    this.fetchData()
                    this.$refs.newPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async updateProject ({ uuid, name, description }) {
            this.isLoading = true
            apiRequest(Api().projects.update({ projectId: uuid, params: { name, description } }))
                .then(() => {
                    notifySuccess(this.$t('projects.notifications.updated.text'))
                    this.fetchData()
                    this.$refs.editPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async archiveProject () {
            const { uuid } = this.selected
            this.isLoading = true
            apiRequest(Api().projects.delete({ projectId: uuid }))
                .then(() => {
                    notifySuccess(this.$t('projects.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.delPrDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
