<template>
    <div>
        <slot :loading="isLoading" />
    </div>
</template>
<script>
import {
    Api, Headers, apiRequest, notifyError,
} from '@/api'
import AuthService from '@/services/auth'
import { changeLocale } from '@/lang/i18n'

const normalizedLocale = {
    es: 'es-ES',
    en: 'en-US',
    pt: 'pt-PT',
}

export default {
    name: 'AppProvider',
    provide () {
        return {
            App: this,
        }
    },
    emits: ['llmEnabledChanged', 'mlEnabledChanged'],
    data () {
        return {
            user: undefined,
            enviroment: undefined,
            isLoading: false,
        }
    },
    beforeMount () {
        this.fetchUser()
        this.getInfo()
    },
    created () {
        this.mlEnabledSend()
        this.llmEnabledSend()
    },
    methods: {
        async fetchUser () {
            this.isLoading = true
            apiRequest(Api().users.me())
                .then(this.setUser)
                .catch(notifyError)
                // .finally(() => (this.isLoading = false))
            this.isLoading = false
        },
        async setUser (user) {
            const { locale } = user
            this.user = { ...this.user, ...user }
            this.setLocale(locale)
            this.$eventBus.emit('llmEnabledChanged', this.user.llmEnabled)
            this.$eventBus.emit('mlEnabledChanged', this.user.mlEnabled)
        },
        async getInfo () {
            this.isLoading = true
            apiRequest(Api().enviroments.getInfo())
                .then(data => (this.enviroment = data))
                .catch(notifyError)
                // .finally(() => (this.isLoading = false))
            this.isLoading = false
        },
        async setLocale (locale) {
            // Change vue-i18n
            changeLocale(locale)
            // Change Accept-Language HTTP Header
            if (normalizedLocale[locale]) {
                Headers.set({ 'Accept-Language': normalizedLocale[locale] })
            }
            // Change quasar components
            try {
                let formattedLocale = locale
                if (formattedLocale.includes('en')) formattedLocale = formattedLocale.replace('en', 'en-US')
                await import(`quasar/lang/${formattedLocale}`).then(lang => (this.$q?.lang.set(lang.default)))
            } catch (err) {
                console.warn('Requested Quasar Language Pack does not exist', err)
            }
        },
        signout () {
            AuthService.signout({ storage: this.$storage })
        },
        dateLocationFormat (date, type) {
            if (date === undefined) return ''
            const locationFormat = () => {
                if (this.user) {
                    return this.user.locationFormat === 'eu' ? 'es-ES' : this.user.locationFormat
                }
                return 'en'
            }
            return this.$d(new Date(date), type, locationFormat())
        },
        getDecimalSeparator () {
            if (this.user) {
                return this.user.locationFormat === 'eu' ? ',' : '.'
            }
            return '.'
        },
        removeCommas (value) {
            const decimalSeparator = this.getDecimalSeparator()
            const regexNonNumber = new RegExp(`[^\\d${decimalSeparator}]`, 'g')
            let cleanedValue = value.replace(regexNonNumber, '') // remove non numbers and decimal separator
            const firstDecimalIndex = cleanedValue.indexOf(decimalSeparator) // check if there is more than one decimal separator
            if (firstDecimalIndex !== -1) { // if there is more than one decimal separator, after the first one, remove all of them
                cleanedValue = cleanedValue.slice(0, firstDecimalIndex + 1) +
                            cleanedValue.slice(firstDecimalIndex + 1).replace(new RegExp(`\\${decimalSeparator}`, 'g'), '')
            }

            return cleanedValue
        },
        removeIncorrectDecimalFormat (number) { // removes the . or , from the number if it is not a decimal separator in the selected location format
            if (this.user) {
                if (this.user.locationFormat === 'eu' ? 'de-DE' : this.user.locationFormat) {
                    if (number.includes('.')) {
                        return false
                    }
                    return true
                }
                if (number.includes(',')) {
                    return false
                }
                return true
            }
            if (number.includes(',')) {
                return false
            }
            return true
        },
        numberLocationFormat (number, fullNum = false, insideText = false) { // to format the numbers to the selected location format (european or american generally)
            let last = '' // used to add the K or M back to the number after formatting
            if (number === undefined) return ''
            const locationFormat = () => { // to select the location format of the user (european or american)
                if (this.user) {
                    return this.user.locationFormat === 'eu' ? 'de-DE' : this.user.locationFormat
                }
                return 'en'
            }
            const validNumberPattern = /^[\d.,]+$/
            if (fullNum) { // in case is desired to maintain all the decimals of the number
                if (!validNumberPattern.test(number)) {
                    return number
                }
                if ((typeof number !== typeof 0) && (number.endsWith('K') || number.endsWith('M') || number.endsWith('k') || number.endsWith('m'))) {
                    last = number.slice(-1)
                }
                const num = parseFloat(number)
                if ((!Number.isNaN(num)) && (typeof num === typeof 0)) {
                    return `${this.$n(num, 'fulldecimal', locationFormat())}${last}`
                }
            }
            if (insideText) { // in case the number (or numbers) is inside a text
                /* regex to match numbers in a string but assuring that they are separated from the rest of the text with space
                   and that they may have a comma or a dot as decimal separator (or as thousand separator)
                   It checks for a string with:
                     - first the start of the string, a space, > or / before the number
                     - followed by a number which can contain a comma or a dot as decimal separator
                     - followed by a space, $, < or the end of the string after the number
                */
                if (typeof number === 'number') {
                    // return number
                    return this.$n(number, 'decimal', locationFormat())
                }
                const numberRegex = /(?<=\s|^|>|\/)(\d+([.,]\d+)?|\s\d+([.,]\d+)?(?=\s|$|<))/g
                return number.replace(numberRegex, (match) => {
                    const parsedNumber = parseFloat(match)
                    if (!Number.isNaN(parsedNumber)) {
                        return this.$n(parsedNumber, 'decimal', locationFormat())
                    }
                    return match
                })
            }
            if (typeof number === typeof 0) {
                return this.$n(number, 'decimal', locationFormat())
            }
            if (number.includes('K') || number.includes('M')) {
                return number
            }
            if (!validNumberPattern.test(number)) { // Because of parseFloat returns a number if a string starts with a number, we check first if the value has more characters
                return number
            }
            if (Number.isNaN(parseFloat(number))) { // for avoiding NaN in the number when the passed value is not a number
                return number
            }
            return this.$n(parseFloat(number), 'decimal', locationFormat())
        },
        mlEnabledSend () {
            this.$eventBus.on('mlEnabledGet', () => {
                if (this.user !== undefined) {
                    this.$eventBus.emit('mlEnabledChanged', this.user.mlEnabled)
                }
            })
        },
        llmEnabledSend () {
            this.$eventBus.on('llmEnabledGet', () => {
                if (this.user !== undefined) {
                    this.$eventBus.emit('llmEnabledChanged', this.user.llmEnabled)
                }
            })
        },
        getNumberFormatCharacters (numberFormat) {
            let decimalCharacter = '.'
            let thousandCharacter = ','
            if (numberFormat === 'eu') {
                decimalCharacter = ','
                thousandCharacter = '.'
            }
            return { decimalCharacter, thousandCharacter }
        },
    },
}// eslint-disable-next-line
</script>
