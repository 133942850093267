export default function registerGlobalComponents (app) {
    const requireComponent = require.context(
        // The relative path of the components folder
        '../components',
        // Whether or not to look in subfolders
        true,
        // Only include "W" prefixed .vue files
        /W[\w-]+\.vue$/,
    )

    const register = context => (fileName) => {
        // Get the component config
        const componentConfig = context(fileName)
        // Get the PascalCase version of the component name
        const componentName = componentConfig.default ? componentConfig.default.name : componentConfig.name
        // Globally register the component
        if (componentName) app.component(componentName, componentConfig.default || componentConfig)
    }

    // For each matching file name...
    requireComponent.keys().forEach(register(requireComponent))
}
