<template>
    <div
        :id="id"
        class="Activity"
        :class="{ featured: isFeatured }"
        :style="styles.container"
    >
        <div class="flex no-wrap row-left-center">
            <div v-if="!simple">
                <div
                    class="Dot mr-0-5"
                    :style="dotStyle"
                />
            </div>
            <div class="Wrapper">
                <div
                    class="node-title"
                    :class="{ 'mr-0-5': !simple }"
                >
                    {{ name }}
                </div>
                <div
                    v-if="!simple && statistics && value"
                    class="node-value"
                    :class="['node-' + modelType]"
                >
                    <span
                        :class="[ 'span-' + modelType ]"
                        v-html="value"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import Utils from '../utils'

const styles = {
    container: {
        display: 'inline-block',
        'white-space': 'nowrap',
    },
    dot: {
        width: '22px',
        height: '22px',
        border: '2px solid #FFF',
        borderRadius: '50%',
    },
}

export default {
    name: 'Activity',
    props: {
        id: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        name: VueTypes.string,
        statistics: VueTypes.any,
        model: VueTypes.shape({
            values: VueTypes.any,
        }).loose,
        simple: VueTypes.bool.def(false),
    },
    computed: {
        modelType () {
            return (this.model || {}).values || Utils.graphValues.BOTH
        },
        heatmapType () {
            return (this.model || {}).heatMapValue || Utils.heatmapValues.NONE
        },
        isFeatured () {
            return this.heatmapType === Utils.heatmapValues.FREQ
        },
        value () {
            return Utils.getInfoModel(this.statistics, this.model)
        },
        colors () {
            return Utils.getHeatMapColors(this.statistics?.heatMap)
        },
        color () {
            return this.colors[this.modelType !== Utils.graphValues.BOTH ? this.modelType : Utils.graphValues.FREQ]
        },
        dotStyle () {
            return {
                ...this.styles.dot,
                background: this.color,
            }
        },
    },
    beforeMount () {
        this.styles = styles
    },
}
</script>
