<template>
    <div class="Step">
        <div class="Wrapper">
            <NameDescForm
                ref="form"
                v-model="localValue.settings"
                :loading="isLoading"
                :dense="false"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { NameDescForm } from '@/forms'

export default {
    name: 'StepSettings',
    components: {
        NameDescForm,
    },
    props: {
        modelValue: VueTypes.shape({
            settings: VueTypes.any.isRequired,
        }).loose,
        steps: VueTypes.any,
    },
    emits: ['input'],
    data () {
        return {
            localValue: this.modelValue,
            isLoading: false,
        }
    },
    watch: {
        modelValue (value) {
            this.localValue = value
        },
    },
    methods: {
        isValid () {
            const { form } = this.$refs
            return form ? form.isValid() : true
        },
        handleInput (settings) {
            this.$emit('input', {
                ...this.modelValue,
                settings,
            })
        },
    },
}
</script>
<style scoped lang="scss">
$grid-width: 575px;

.Wrapper {
    width: 100%;
    max-width: $grid-width;
    margin: 0 auto;
}
</style>
