<template>
    <div class="Overview flex row-stretch">
        <div class="Screen pos-r flex-1">
            <QTabs
                v-model="tab"
                align="left"
                dense
                noCaps
                inlineLabel
                activeColor="primary"
                indicatorColor="primary"
                class="Tabs text-grey-8"
                @update:modelValue="handleChangeTab"
            >
                <QTab
                    v-for="(crtab,idx) in getTabs"
                    :key="idx"
                    :name="crtab.id"
                    :label="crtab.label"
                    :icon="crtab.icon"
                >
                    <QTooltip
                        class="bg-grey-3 text-black"
                    >
                        {{ crtab.tooltip }}
                    </QTooltip>
                </QTab>
            </QTabs>
            <div
                v-if="!attributesLoading && !settingsLoading"
                class="MainContainer"
            >
                <div class="D2T">
                    <QBtn
                        v-if="tab === 'OVERVIEW'"
                        color="primary"
                        noCaps
                        :label="$t('booster.d2t.viewReport')"
                        :loading="analysisReportLoading"
                        :disabled="analysisReportDisabled"
                        @click="handleOpenAnalysisReportModal"
                    >
                        <QIcon
                            right
                            name="vital_signs"
                            class="material-symbols-outlined"
                        />
                    </QBtn>
                    <QBtn
                        v-if="tab === 'PREDICTION'"
                        color="primary"
                        noCaps
                        :label="$t('booster.d2t.viewReport')"
                        :loading="analysisReportLoading"
                        :disabled="analysisReportDisabled"
                        @click="handleOpenAnalysisReportModal"
                    >
                        <QIcon
                            right
                            name="vital_signs"
                            class="material-symbols-outlined"
                        />
                    </QBtn>
                    <QBtn
                        v-if="tab === 'IMPROVEMENT'"
                        color="primary"
                        noCaps
                        :label="$t('booster.d2t.viewReport')"
                        :loading="analysisReportLoading"
                        :disabled="analysisReportDisabled"
                        @click="handleOpenAnalysisReportModal"
                    >
                        <QIcon
                            right
                            name="vital_signs"
                            class="material-symbols-outlined"
                        />
                    </QBtn>
                    <QIcon
                        v-if="tab === 'PREDICTION' && highlightsData && !fullResult"
                        class="WarningIcon"
                        name="warning"
                        :style="{ color: '#ffbc04', paddingTop: '3px', marginLeft: '10px' }"
                        size="28px"
                    >
                        <QTooltip
                            class="bg-amber text-black shadow-4"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 10]"
                            :style="{ fontSize: '12px' }"
                        >
                            {{ $t('visualization.predictions.someNotAvailable') }}
                        </QTooltip>
                    </QIcon>
                </div>
                <div
                    v-if="tab === 'OVERVIEW'"
                    class="ContentContainer"
                >
                    <div
                        v-if="!attributesDefined || !slaDefined"
                        class="flex flex-col justify-center items-center q-ma-md"
                    >
                        <div>
                            <WText
                                :size="20"
                            >
                                {{ getStateErrorMessage() }}
                            </WText>
                        </div>
                        <div>
                            <WText
                                :size="15"
                            >
                                {{ $t('booster.overview.noConditionsText') }}
                            </WText>
                        </div>
                        <div class="mt-2">
                            <q-btn
                                icon="settings"
                                noCaps
                                color="primary"
                                :label="$t('visualization.tabs.settings')"
                                @click="handleGoToSettings"
                            />
                        </div>
                    </div>
                    <State
                        v-if="attributesDefined && slaDefined"
                        ref="State"
                        :processId="processId"
                        :attributes="attributes"
                        :attributesWithoutAll="attributesWithoutAll"
                        @on-analysis-report-loading="handleAnalysisReportLoading"
                        @on-analysis-report-finished="handleAnalysisReportFinished"
                    />
                </div>
                <div
                    v-if="tab === 'PREDICTION'"
                    class="ContentContainer"
                >
                    <div
                        v-if="!attributesDefined || !slaDefined || !caseCompletionDefined"
                        class="flex flex-col justify-center items-center q-ma-md"
                    >
                        <div>
                            <WText
                                :size="20"
                            >
                                {{ getPredictionErrorMessage() }}
                            </WText>
                        </div>
                        <div>
                            <WText
                                :size="15"
                            >
                                {{ $t('booster.overview.noConditionsText') }}
                            </WText>
                        </div>
                        <div class="mt-2">
                            <q-btn
                                icon="settings"
                                noCaps
                                color="primary"
                                :label="$t('visualization.tabs.settings')"
                                @click="handleGoToSettings"
                            />
                        </div>
                    </div>
                    <Prediction
                        v-if="attributesDefined && slaDefined && caseCompletionDefined"
                        ref="Prediction"
                        :processId="processId"
                        :attributes="attributes"
                        :sla="slaValue"
                        @on-analysis-report-loading="handleAnalysisReportLoading"
                        @on-analysis-report-finished="handleAnalysisReportFinished"
                        @on-check-highlights="handleHighlightsWarning"
                    />
                </div>
                <div
                    v-if="tab === 'IMPROVEMENT'"
                    class="ContentContainer"
                >
                    <div
                        v-if="!attributesDefined || !costDefined || !slaDefined"
                        class="flex flex-col justify-center items-center q-ma-md"
                    >
                        <div>
                            <WText
                                :size="20"
                            >
                                {{ getImprovementErrorMessage() }}
                            </WText>
                        </div>
                        <div>
                            <WText
                                :size="15"
                            >
                                {{ $t('booster.overview.noConditionsText') }}
                            </WText>
                        </div>
                        <div class="mt-2">
                            <q-btn
                                icon="settings"
                                noCaps
                                color="primary"
                                :label="$t('visualization.tabs.settings')"
                                @click="handleGoToSettings"
                            />
                        </div>
                    </div>
                    <Improvement
                        v-if="attributesDefined && costDefined && slaDefined"
                        ref="Improvement"
                        :processId="processId"
                        :attributes="attributesWithoutAll"
                        :cost="cost"
                        @on-analysis-report-loading="handleAnalysisReportLoading"
                        @on-analysis-report-finished="handleAnalysisReportFinished"
                    />
                </div>
                <div
                    v-if="tab === 'ALERTS'"
                    class="ContentContainer"
                >
                    <Alerts
                        :processId="processId"
                        class="Wrapper"
                    />
                </div>
            </div>
            <q-inner-loading :showing="attributesLoading || settingsLoading" />
        </div>
        <AnalysisReportModal
            ref="analysisReportModal"
        />
    </div>
</template>
<script>

import {
    Api, apiRequest, notifyError,
} from '@/api'
import Alerts from './sections/Alerts/Alerts.vue'
import Prediction from './sections/Prediction/Prediction.vue'
import State from './sections/State/State.vue'
import Improvement from './sections/Improvement/Improvement.vue'
import AnalysisReportModal from './components/AnalysisReport.vue'

const DEFAULT_TAB = 'OVERVIEW'

export default {
    name: 'Overview',
    components: {
        Alerts,
        Prediction,
        Improvement,
        State,
        AnalysisReportModal,
    },
    data () {
        return {
            tab: this.$route.params.subview || DEFAULT_TAB,
            processId: this.$route.params.processId,
            viewMode: 'booster',
            tabTitle: this.$t('booster.overview.tab'),
            description: this.$t('booster.overview.description'),
            attributes: undefined,
            attributesWithoutAll: undefined,
            attributesDefined: false,
            cost: undefined,
            costDefined: false,
            slaValue: undefined,
            slaDefined: false,
            caseCompletionDefined: false,
            attributesLoading: false,
            settingsLoading: false,
            analysisReport: '',
            analysisReportLoading: false,
            analysisReportDisabled: true,
            highlightsData: undefined,
            fullResult: false,
        }
    },
    computed: {
        getTabs () {
            const tabs = [
                {
                    id: 'OVERVIEW',
                    label: this.$t('booster.overview.tab'),
                    icon: 'travel_explore',
                    tooltip: this.$t('booster.overview.description'),
                },
                {
                    id: 'PREDICTION',
                    label: this.$t('booster.prediction.tab'),
                    icon: 'batch_prediction',
                    tooltip: this.$t('booster.prediction.description'),
                },
                {
                    id: 'ALERTS',
                    label: this.$t('booster.alerts.tab'),
                    icon: 'notifications',
                    tooltip: this.$t('booster.alerts.description'),
                },
                {
                    id: 'IMPROVEMENT',
                    label: this.$t('booster.improvement.tab'),
                    icon: 'query_stats',
                    tooltip: this.$t('booster.improvement.description'),
                },
            ]
            return tabs
        },
    },
    watch: {
        // eslint-disable-next-line func-names
        '$route.params.subview': function (newVal) {
            this.tab = newVal || DEFAULT_TAB
        },
    },
    mounted () {
        this.getAttributes()
        this.getSettings()
    },
    methods: {
        handleChangeTab (subview) {
            const params = { subview, ...this.$route.params.otherParams }
            if (subview) this.$router.replace({ ...this.$route, params }).catch(() => {})
            this.analysisReportDisabled = true
        },
        handleGoToSettings () {
            sessionStorage.setItem('settingsFromTab', this.tab)
            this.$router.push({ name: 'ProcessSettings', params: this.$route.params })
        },
        getAttributes () {
            // eslint-disable-next-line prefer-destructuring
            const datasetId = this.processId
            this.attributesLoading = true
            apiRequest(Api().datasets.getConfigurations({ datasetId }))
                .then(({ data }) => {
                    const configuration = data[0]
                    if (configuration.relevantAttributes !== null && configuration.relevantAttributes.length > 0) {
                        this.attributesDefined = true
                        this.attributes = configuration.relevantAttributes
                        this.attributesWithoutAll = JSON.parse(JSON.stringify(configuration.relevantAttributes))
                        if (this.attributes[0].name !== this.$t('booster.state.all')) {
                            this.attributes.unshift({ name: this.$t('booster.state.all') })
                        }
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.attributesLoading = false
                })
        },
        getSettings () {
            this.settingsLoading = true
            apiRequest(Api().datasets.get({ datasetId: this.processId }))
                .then(({ data }) => {
                    const settings = data[0]
                    if (settings.slaValue !== null) {
                        this.slaDefined = true
                        this.slaValue = settings.slaValue
                    }
                    if (settings.caseCompletion !== null && settings.caseCompletion.endingActivities.length > 0) {
                        this.caseCompletionDefined = true
                    }
                    if (settings.cost !== null) {
                        this.costDefined = true
                        this.cost = settings.cost
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.settingsLoading = false
                })
        },
        getStateErrorMessage () {
            let errorMessage = ''
            if (!this.attributesDefined && !this.slaDefined) {
                errorMessage = this.$t('booster.overview.relevantAndSLANotDefined')
            } else if (this.attributesDefined && !this.slaDefined) {
                errorMessage = this.$t('booster.overview.slaNotDefined')
            } else if (!this.attributesDefined && this.slaDefined) {
                errorMessage = this.$t('booster.overview.relevantNotDefined')
            }
            return errorMessage
        },
        getPredictionErrorMessage () {
            let errorMessage = ''
            if (!this.attributesDefined && !this.slaDefined && !this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.relevantAndSLAAndCaseNotDefined')
            } else if (!this.attributesDefined && !this.slaDefined && this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.relevantAndSLANotDefined')
            } else if (!this.attributesDefined && this.slaDefined && !this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.relevantAndCaseNotDefined')
            } else if (this.attributesDefined && !this.slaDefined && !this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.slaAndCaseNotDefined')
            } else if (!this.attributesDefined && this.slaDefined && this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.relevantNotDefined')
            } else if (this.attributesDefined && !this.slaDefined && this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.slaNotDefined')
            } else if (this.attributesDefined && this.slaDefined && !this.caseCompletionDefined) {
                errorMessage = this.$t('booster.overview.caseCompletionNotDefined')
            }
            return errorMessage
        },
        getImprovementErrorMessage () {
            let errorMessage = ''
            if (!this.attributesDefined && !this.slaDefined && !this.costDefined) {
                errorMessage = this.$t('booster.overview.relevantAndSLAAndCostNotDefined')
            } else if (!this.attributesDefined && !this.slaDefined && this.costDefined) {
                errorMessage = this.$t('booster.overview.relevantAndSLANotDefined')
            } else if (!this.attributesDefined && this.slaDefined && !this.costDefined) {
                errorMessage = this.$t('booster.overview.relevantAndCostNotDefined')
            } else if (this.attributesDefined && !this.slaDefined && !this.costDefined) {
                errorMessage = this.$t('booster.overview.slaAndCostNotDefined')
            } else if (!this.attributesDefined && this.slaDefined && this.costDefined) {
                errorMessage = this.$t('booster.overview.relevantNotDefined')
            } else if (this.attributesDefined && !this.slaDefined && this.costDefined) {
                errorMessage = this.$t('booster.overview.slaNotDefined')
            } else if (this.attributesDefined && this.slaDefined && !this.costDefined) {
                errorMessage = this.$t('booster.overview.costNotDefined')
            }
            return errorMessage
        },
        handleAnalysisReportLoading () {
            this.analysisReportLoading = true
        },
        handleAnalysisReportFinished (analysisReport) {
            this.analysisReport = analysisReport
            if (this.analysisReport) {
                this.analysisReportDisabled = false
            }
            this.analysisReportLoading = false
        },
        handleOpenAnalysisReportModal () {
            this.$refs.analysisReportModal.open({ analysisReport: this.analysisReport })
        },
        handleHighlightsWarning (highlightContent) {
            this.highlightsData = highlightContent.highlightsData
            this.fullResult = highlightContent.fullResult
        },
    },
}
</script>
<style scoped lang="scss">
$visualization-sidebar-width: 60px;
.Overview {
    flex-wrap: nowrap;
    overflow: hidden;
}
.Screen {
    height: calc(100vh - $header-height);
    overflow: auto;
}
.MainContainer {
    padding: 20px;
}
.D2T {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.ContentContainer {
    height: 77vh;
}
.Tabs {
    margin: 0;
    border-bottom: 1px solid $gray-05;
    background-color: $white;
    z-index:1100;
    &:deep(.Container) {
        padding: 0;
        .Tabs {
            display: flex;
            .Tab {
                width: fit-content;
                min-height: inherit;
                padding: 15px;
            }
        }
    }
}
.Titles {
    height: 12%;
}
.Title {
    display: flex;
}
.TitleText {
    font-size: 3vh;
    font-weight: bolder;
}
.toggle {
    font-size:2vh;
    display:flex;
    justify-content: right;
}
.Description {
    color: $primary;
    width:fit-content;
    font-size: 1.7vh;
    font-weight: normal;
}
@media (max-width: 1150px) {
    .Overview {
            overflow: visible;
        }
}
.D2T {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}
.WarningIcon:hover .TooltipText {
    display: block;
}
.TooltipText {
    display: none;
    position: absolute;
    top: 70%;
    left: -15%;
    transform: translate(-100%, -50%);
    width: 35vw;
    background-color: #ffbc04;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    white-space: normal;
}
</style>
