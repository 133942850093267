export default {
    selectAll: 'Selecionar tudo',
    create: 'Criar',
    next: 'Próximo',
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Eliminar',
    discard: 'Rejeitar',
    close: 'Fechar',
    cancel: 'Cancelar',
    search: 'Pesquisar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    mock: 'Simular',
    remove: 'Remover',
    download: 'Descarregar como CSV',
    downloadBPMN: 'Descarregar BPMN',
    uploadFile: 'Selecionar um ficheiro ou arrastar para aqui',
    print: 'Imprimir',
    retry: 'Tentar de novo',
    info: 'Definições',
    move: 'Mover',
}
