<!-- eslint-disable prefer-destructuring -->
<template>
    <div class="row justify-center">
        <div class="col justify-center q-pa-xs">
            <div class="row justify-start">
                <q-toggle
                    v-model="enableGlobal"
                    checkedIcon="check"
                    :label="$t('visualization.settings.sla.globalSla.enableGlobal')"
                    color="green"
                    uncheckedIcon="clear"
                    class="text-primary text-subtitle1"
                    @update:model-value="updateSLA"
                />
            </div>
            <q-slide-transition>
                <div
                    v-if="enableGlobal"
                >
                    <div class="row">
                        <div
                            shadow
                            class="col q-pa-md rounded-borders"
                        >
                            <WText
                                class="mb-1"
                                color="dark"
                            >
                                {{ $t('visualization.settings.sla.globalSla.description') }}
                            </WText>
                            <div class="OptionsGroup row justify-around">
                                <q-input
                                    v-model.number="number"
                                    :label="$t('visualization.settings.sla.number')"
                                    :disable="!enableGlobal"
                                    type="number"
                                    color="primary"
                                    class="col-5"
                                    @update:model-value="updateSLA"
                                />
                                <QSelect
                                    v-model="unit"
                                    outlined
                                    class="col-5"
                                    labelColor="dark"
                                    color="dark"
                                    :label="$t('visualization.settings.sla.unit')"
                                    :options="slaUnits"
                                    @update:model-value="updateSLA"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="row items-center q-mt-md"
                    >
                        <q-icon
                            name="circle"
                            color="green-3"
                            size="25px"
                            class="q-mx-sm"
                        />
                        <WText>
                            {{ $t('visualization.settings.sla.globalSla.compliance') }}
                        </WText>
                        <q-icon
                            name="circle"
                            color="red-3"
                            size="25px"
                            class="q-mx-sm"
                        />
                        <WText>
                            {{ $t('visualization.settings.sla.globalSla.nonCompliance') }}
                        </WText>
                    </div>
                    <div class="q-my-md">
                        <q-linear-progress
                            rounded
                            size="25px"
                            :value="slaCompliancePct"
                            color="green-3"
                            trackColor="white"
                            class="q-mt-sm"
                        >
                            <div class="absolute-full flex flex-center">
                                <q-badge
                                    color="white"
                                    textColor="primary"
                                    :label="`${App.numberLocationFormat(slaComplianceAbs, true)} ${$t('visualization.settings.sla.globalSla.cases')}
                                    (${App.numberLocationFormat((slaCompliancePct*100).toFixed(0), true)}%)`"
                                />
                            </div>
                        </q-linear-progress>
                        <q-linear-progress
                            rounded
                            size="25px"
                            :value="nonSlaCompliancePct"
                            color="red-3"
                            trackColor="white"
                            class="q-mt-sm"
                        >
                            <div class="absolute-full flex flex-center">
                                <q-badge
                                    color="white"
                                    textColor="primary"
                                    :label="`${App.numberLocationFormat(nonSlaComplianceAbs, true)} ${$t('visualization.settings.sla.globalSla.cases')}
                                    (${App.numberLocationFormat((nonSlaCompliancePct*100).toFixed(0), true)}%)`"
                                />
                            </div>
                        </q-linear-progress>
                    </div>
                    <div class="row justify-start">
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $t('visualization.settings.sla.globalSla.minCase') }}:
                                </q-item-label>
                                <q-item-label caption>
                                    {{ min }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $t('visualization.settings.sla.globalSla.maxCase') }}:
                                </q-item-label>
                                <q-item-label caption>
                                    {{ max }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </div>
                </div>
            </q-slide-transition>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { debounce } from 'quasar'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'GlobalSLA',
    components: { },
    inject: ['App'],
    props: {
        valuesReceived: VueTypes.object.def(null),
        globalInfo: VueTypes.object.def(null),
    },
    emits: ['saveSLA'],
    data () {
        return {
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            tab: this.$route.params.tab,
            number: 0,
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            medUnit: undefined,
            med: 0,
            max: 0,
            min: 0,
            values: undefined,
            slaComplianceAbs: 0,
            slaCompliancePct: 0,
            nonSlaComplianceAbs: 0,
            nonSlaCompliancePct: 0,
            enableGlobal: false,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    created () {
        this.saveSLA = debounce(this.saveSLA, 400)
    },
    mounted () {
        this.getBaseInfo()
        if (this.valuesReceived.sla && this.valuesReceived.sla.value !== -1) {
            this.values = this.valuesReceived
            this.number = this.values.sla.value
            this.unit = this.values.sla.unit
            this.getSLAPercentages()
            this.enableGlobal = true
        } else {
            this.setStartingValues()
        }
    },
    methods: {
        getBaseInfo () {
            const [num, unit] = this.formatDuration(this.globalInfo.duration.med)
            this.med = num
            this.medUnit = unit
            const [maxNum, maxUnit] = this.formatDuration(this.globalInfo.duration.max)
            this.max = `${maxNum} ${this.$t(`visualization.settings.sla.units.${maxUnit}`)}`
            const [minNum, minUnit] = this.formatDuration(this.globalInfo.duration.min)
            this.min = `${minNum} ${this.$t(`visualization.settings.sla.units.${minUnit}`)}`
        },
        setStartingValues () {
            this.values = { sla: null }
            this.number = this.med
            this.unit.value = this.medUnit
            this.unit.label = this.$t(`visualization.settings.sla.units.${this.medUnit}`)
        },
        updateSLA () {
            if (this.number === null || this.number === '' || this.number <= 0) this.number = 1
            if (this.enableGlobal) {
                this.values.sla = {
                    unit: this.unit.value ? this.unit.value : this.unit,
                    value: this.number,
                }
                this.getSLAPercentages()
            } else {
                this.values.sla = {
                    unit: this.unit.value ? this.unit.value : this.unit,
                    value: -1,
                }
            }
            this.$emit('saveSLA', this.values)
        },
        getSLAPercentages () {
            const params = { value: this.values.sla.value, unit: this.values.sla.unit }
            apiRequest(Api().booster.slaInfo({ processId: this.processId, params }))
                .then((data) => {
                    this.slaComplianceAbs = data.meetSlaAbsolute
                    this.slaCompliancePct = data.meetSlaPercentage
                    this.nonSlaComplianceAbs = data.exceedSlaAbsolute
                    this.nonSlaCompliancePct = data.exceedSlaPercentage
                })
                .catch((notifyError))
        },
        formatDuration (duration) {
            if (duration < 3601) {
                return [Math.round(duration / 60), 'minutes']
            }
            if (duration < 180000) {
                return [Math.round(duration / 3600), 'hours']
            }
            if (duration < 604800) {
                return [Math.round(duration / 86400), 'days']
            }
            if (duration < 2419000) {
                return [Math.round(duration / 604800), 'weeks']
            }
            return [Math.round(duration / 2419000), 'months']
        },
    },
}
</script>
<style scoped lang="scss">
</style>
