import VueValuesStore from '../vue3-values/main'

// Declare here the default values for StoredValue components
const VUE_VALUES_DEFAULT_STATE = {
    visualizationFilters: [],
}
// Declare here the initial values for StoredValue components
const VUE_VALUES_INITIAL_STATE = {
    visualizationFilters: [],
}
// Declare here those stored values you want to persist in local storage
const VUE_VALUES_TO_PERSIST = {
    visualizationFilters: true,
}

// Gets the whole store from the local storage
function getWholeVueValuesStoreFromLocalStorage () {
    return JSON.parse(localStorage.getItem('VueValueStore') || '{}')
}
// Saves the whole store into the local storage
function saveWholeVueValuesStoreIntoLocalStorage (store) {
    localStorage.setItem('VueValueStore', JSON.stringify(store))
}
// Saves a single value into the local storage
function saveVueValueIntoLocalStorage (uid, value) {
    const store = getWholeVueValuesStoreFromLocalStorage()
    store[uid] = value
    saveWholeVueValuesStoreIntoLocalStorage(store)
}
// Deletes a single value from the local storage
function deleteVueValueFromLocalStorage (uid) {
    const store = getWholeVueValuesStoreFromLocalStorage()
    delete store[uid]
    saveWholeVueValuesStoreIntoLocalStorage(store)
}

// Initializes the default, initial and current store
VueValuesStore.setDefaultState(VUE_VALUES_DEFAULT_STATE)
VueValuesStore.setInitialState(VUE_VALUES_INITIAL_STATE)
VueValuesStore.setState(
    Object.assign(
        {},
        VUE_VALUES_DEFAULT_STATE,
        VUE_VALUES_INITIAL_STATE,
        getWholeVueValuesStoreFromLocalStorage(),
    ),
)

// Sets the updating handlers
VueValuesStore.setUpdatingHandlers({
    onSet: (uid, value) => {
        if (VUE_VALUES_TO_PERSIST[uid]) {
            saveVueValueIntoLocalStorage(uid, value)
        }
        return value
    },
    onDelete: (uid) => {
        if (VUE_VALUES_TO_PERSIST[uid]) {
            deleteVueValueFromLocalStorage(uid)
        }
    },
})
