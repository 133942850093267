import { createI18n } from 'vue-i18n'
import moment from 'moment'
import 'moment/locale/es'

import { LangSession } from '@/services/session.service'
import Config from '@/config'
import locales from './locales'

const sessionLocale = LangSession.get()
const defaultLocale = sessionLocale || Config.i18n.locale
moment.locale(Config.i18n.moment[defaultLocale])

function loadLocaleMessages () {
    const messages = {}
    Object.entries(locales).forEach(([key, value]) => {
        messages[key] = value
    })
    return messages
}
/*
export default createI18n({
    locale: "en",
    fallbackLocale: "en",
    legacy: false,
    globalInjection: true,
    messages: loadLocaleMessages(),
})
*/
const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: Config.i18n.fallbackLocale,
    legacy: false,
    globalInjection: true,
    messages: loadLocaleMessages(),
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
        'de-DE': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
        'es-ES': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
    },
    numberFormats: {
        en: {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
            fulldecimal: {
                style: 'decimal',
                maximumFractionDigits: 20, // the maximum in javascript is 17
            },
        },
        'es-ES': {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
            fulldecimal: {
                style: 'decimal',
                maximumFractionDigits: 20, // the maximum in javascript is 17
            },
        },
        'de-DE': {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
            fulldecimal: {
                style: 'decimal',
                maximumFractionDigits: 20, // the maximum in javascript is 17
            },
        },
    },
})

export const changeLocale = (locale) => {
    if (Config.i18n.locales.includes(locale)) {
        i18n.global.locale.value = locale
        moment.locale(Config.i18n.moment[locale])
        LangSession.set(locale)
    } else {
        // eslint-disable-next-line no-console
        console.warn(`'${locale}' locale is not available.`)
    }
}

export default i18n
// eslint-disable-next-line
