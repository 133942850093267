<template>
    <div>
        <div class="row justify-between items-center q-pr-md">
            <WText
                :size="20"
                class="mb-1"
            >
                SAP Extractor
            </WText>
            <QBtn
                square
                icon="arrow_back"
                color="primary"
                @click="backtoConnectors"
            >
                <QTooltip
                    anchor="center left"
                    self="center right"
                >
                    Back to connector list
                </QTooltip>
            </QBtn>
        </div>
        <div class="row items-center q-pr-md">
            <q-card
                bordered
                class="Personal mb-2"
            >
                <WText
                    :size="18"
                    class="mb-1"
                >
                    ABAP Scripts
                </WText>
                <p>
                    Given the actual variety in SAP implementations through SAP customers,
                    Inverbis offers two ABAP scripts that cover two standard processes,
                    order to cash and procure to pay. These can be used to extract relevant
                    process data in a format already prepared for analysis with Inverbis.
                </p>
            </q-card>
            <q-card
                bordered
                class="Personal mb-2"
            >
                <WText
                    :size="18"
                    class="mb-1"
                >
                    Choose your SAP process
                </WText>
                <div class="row">
                    <div
                        class="q-pa-xs col-xs-12 col-sm-6 col-md-6"
                    >
                        <QCard class="bg-grey-2">
                            <QCardSection class="text-h6 align-center">
                                <QRadio
                                    v-model="connector"
                                    val="p2p"
                                    label="Purchase to Pay"
                                    color="secondary"
                                />
                            </QCardSection>
                            <QCardSection
                                horizontal
                                class="text-primary"
                            >
                                <QCardSection>
                                    <QIcon
                                        name="payments"
                                        style="font-size: 3em;"
                                    />
                                </QCardSection>
                                <QCardSection>
                                    <p>
                                        Extract process data from your SAP purchases from purchase requisitions to the final payment.
                                    </p>
                                </QCardSection>
                            </QCardSection>
                        </QCard>
                    </div>
                    <div class="q-pa-xs col-xs-12 col-sm-6 col-md-6">
                        <QCard class="bg-grey-2">
                            <QCardSection class="text-h6 align-center">
                                <QRadio
                                    v-model="connector"
                                    val="o2c"
                                    label="Order to Cash"
                                    color="secondary"
                                />
                            </QCardSection>
                            <QCardSection
                                horizontal
                                class="text-primary"
                            >
                                <QCardSection>
                                    <QIcon
                                        name="sell"
                                        style="font-size: 3em;"
                                    />
                                </QCardSection>
                                <QCardSection>
                                    <p>
                                        Extract process data from your SAP sales from the order creation to the final billing.
                                    </p>
                                </QCardSection>
                            </QCardSection>
                        </QCard>
                    </div>
                </div>
                <div class="column items-center q-mt-md">
                    <QCheckbox
                        v-model="license"
                        label="I agree not to share or distribute Inverbis's SAP Extractor scripts partly or totally without written consent from Inverbis"
                        class="q-mt-md"
                    />
                    <QBtn
                        label="Download"
                        color="primary"
                        icon="download"
                        noCaps
                        :disable="disabledDownload"
                        class="q-mt-md"
                        @click="download"
                    />
                </div>
            </q-card>
        </div>
    </div>
</template>

<script>
import { exportFile } from 'quasar'
import { WIZARD } from '@/entities'
import o2c from '@/assets/sap/order_to_cash.txt'
import p2p from '@/assets/sap/purchase_to_pay.txt'

export default {
    name: 'StepSAP',
    components: {
    },
    props: {
    },
    emits: [],
    data () {
        return {
            connector: undefined,
            license: false,
        }
    },
    computed: {
        disabledDownload () {
            return this.license === false || this.connector === undefined
        },
    },
    methods: {
        backtoConnectors () {
            const { projectId } = this.$route.params
            this.$storage.remove(WIZARD.KEYS.IMPORT)
            this.$router.push({ name: 'WizardImport', params: { projectId } })
        },
        download () {
            if (this.connector === 'o2c') {
                exportFile(`${this.connector}.txt`, o2c)
            } else if (this.connector === 'p2p') {
                exportFile(`${this.connector}.txt`, p2p)
            }
        },
    },
}
</script>

<style scoped lang="scss">
</style>
