<template>
    <div
        class="SlaSettings"
    >
        <div class="text-subtitle1 text-weight-bold text-primary q-ml-lg q-mt-lg">
            {{ $t('visualization.settings.sla.name') }}
        </div>
        <q-tabs
            v-model="tab"
            noCaps
            dense
            class="text-primary"
        >
            <q-tab
                v-for="tab in tabs"
                :key="tab.name"
                :label="tab.label"
                :name="tab.name"
            />
        </q-tabs>
        <QTabPanels
            v-model="tab"
        >
            <QTabPanel
                class="SLATabs"
                name="caseSla"
            >
                <div class="row justify-between">
                    <QBtn
                        noCaps
                        color="primary"
                        icon="add"
                        menuAnchor="bottom end"
                        :menuOffset="[65,0]"
                        :label="$t('visualization.settings.sla.addSla')"
                        @click="onOpenVariantSlaModal"
                    />
                    <QBtn
                        icon="delete_outline"
                        color="primary"
                        noCaps
                        :label=" $t('visualization.settings.sla.removeSla')"
                        @click="handleDeleteVariantSla"
                    />
                </div>
                <div class="TableRow row">
                    <QTable
                        v-model:selected="selectedVariants"
                        class="Table"
                        flat
                        bordered
                        :rows="variantsSla"
                        :columns="variantsSlaColumns"
                        rowKey="name"
                        selection="multiple"
                        @selection="updateSelectedVariants"
                    >
                        <template #body-cell-name="props">
                            <QTd :props="props">
                                {{ props.row.name }}
                                <QTooltip
                                    v-if="checkQtooltipVisible('variantName', props.row.name)"
                                    anchor="top start"
                                    self="center start"
                                    :offset="[-10,0]"
                                >
                                    {{ props.row.name }}
                                </QTooltip>
                            </QTd>
                        </template>

                        <template #body-cell-edit="props">
                            <QTd :props="props">
                                <QBtn
                                    flat
                                    square
                                    icon="edit"
                                    size="15px"
                                    color="white"
                                    textColor="primary"
                                    class="q-mr-sm"
                                    padding="0px"
                                    @click="onOpenEditVariantSlaModal(props.row)"
                                />
                            </QTd>
                        </template>

                        <template #no-data="{ message }">
                            <div class="full-width row flex-center text-accent q-gutter-sm">
                                <span>
                                    {{ message }}
                                </span>
                            </div>
                        </template>
                    </QTable>
                </div>
            </QTabPanel>
            <QTabPanel
                class="SLATabs"
                name="customSla"
            >
                <div class="row justify-between">
                    <QBtnDropdown
                        noCaps
                        autoClose
                        color="primary"
                        icon="add"
                        menuAnchor="bottom end"
                        :menuOffset="[65,0]"
                        :label="$t('visualization.settings.sla.addSla')"
                        @click.stop
                    >
                        <QList>
                            <QItem
                                clickable
                                @click="onOpenActivitySlaModal"
                            >
                                <QItemSection>
                                    <QItemLabel>{{ $t('visualization.settings.sla.activitySla') }}</QItemLabel>
                                </QItemSection>
                            </QItem>
                            <QItem
                                clickable
                                @click="onOpenTransitionSlaModal"
                            >
                                <QItemSection>
                                    <QItemLabel>{{ $t('visualization.settings.sla.transitionSla') }}</QItemLabel>
                                </QItemSection>
                            </QItem>
                            <QItem
                                clickable
                                @click="onOpenActivityGapSlaModal"
                            >
                                <QItemSection>
                                    <QItemLabel>{{ $t('visualization.settings.sla.activityGapSla') }}</QItemLabel>
                                </QItemSection>
                            </QItem>
                        </QList>
                    </QBtnDropdown>
                    <QBtn
                        icon="delete_outline"
                        color="primary"
                        noCaps
                        :label="$t('visualization.settings.sla.removeSla')"
                        @click="handleDeleteCustomSla"
                    />
                </div>
                <div class="TableRow row">
                    <QTable
                        v-model:selected="selectedCustomSla"
                        class="Table"
                        :rows="customSlaRows"
                        :columns="customSlaColumns"
                        flat
                        outlined
                        bordered
                        virtualScroll
                        style="max-height: 400px"
                        :rowsPerPageOptions="[0]"
                        rowKey="id"
                        selection="multiple"
                    >
                        <template #body-cell-type="props">
                            <QTd :props="props">
                                {{ props.row.type.charAt(0).toUpperCase() + props.row.type.slice(1) }}
                                <QTooltip
                                    v-if="checkQtooltipVisible('type', props.row.type.charAt(0).toUpperCase() + props.row.type.slice(1))"
                                    anchor="top start"
                                    self="center start"
                                    :offset="[-10,0]"
                                >
                                    {{ props.row.type.charAt(0).toUpperCase() + props.row.type.slice(1) }}
                                </QTooltip>
                            </QTd>
                        </template>

                        <template #body-cell-elements="props">
                            <QTd :props="props">
                                {{ props.row.elements.join(', ') }}
                                <QTooltip
                                    v-if="checkQtooltipVisible('elements', props.row.elements.join(', '))"
                                    anchor="top start"
                                    self="center start"
                                    :offset="[-10,0]"
                                >
                                    {{ props.row.elements.join(', ') }}
                                </QTooltip>
                            </QTd>
                        </template>

                        <template #body-cell-loopMode="props">
                            <QTd :props="props">
                                {{ props.row.loopMode.charAt(0).toUpperCase() + props.row.loopMode.slice(1) }}
                            </QTd>
                        </template>

                        <template #body-cell-sourceOccurrence="props">
                            <QTd :props="props">
                                {{ props.row.sourceOccurrence.charAt(0).toUpperCase() + props.row.sourceOccurrence.slice(1) }}
                            </QTd>
                        </template>

                        <template #body-cell-targetOccurrence="props">
                            <QTd :props="props">
                                {{ props.row.targetOccurrence.charAt(0).toUpperCase() + props.row.targetOccurrence.slice(1) }}
                            </QTd>
                        </template>

                        <template #body-cell-sla="props">
                            <QTd :props="props">
                                {{ props.row.sla }}
                                <QTooltip
                                    v-if="checkQtooltipVisible('sla', props.row.sla)"
                                    anchor="top start"
                                    self="center start"
                                    :offset="[-10,0]"
                                >
                                    {{ props.row.sla }}
                                </QTooltip>
                            </QTd>
                        </template>

                        <template #body-cell-edit="props">
                            <QTd
                                class="EditTD"
                                :props="props"
                            >
                                <QBtn
                                    flat
                                    square
                                    icon="edit"
                                    size="15px"
                                    color="white"
                                    textColor="primary"
                                    class="q-mr-sm"
                                    padding="0px"
                                    @click="onOpenEditSlaModal(props)"
                                />
                            </QTd>
                        </template>
                        <template #no-data="{ message }">
                            <div class="full-width row flex-center text-accent q-gutter-sm">
                                <span>
                                    {{ message }}
                                </span>
                            </div>
                        </template>
                    </QTable>
                </div>
            </QTabPanel>
            <QTabPanel
                class="SLATabs"
                name="globalSla"
            >
                <div>
                    <GlobalSLA
                        :valuesReceived="values"
                        :globalInfo="globalInfo"
                        @saveSLA="saveGlobalSLA"
                    />
                </div>
            </QTabPanel>
        </QTabPanels>
        <VariantSlaModal
            ref="variantSlaModal"
            :variants="variantsModal"
            @add-variant-sla="handleAddVariantSla"
            @edit-variant-sla="handleEditVariantSla"
        />
        <ActivitySlaModal
            ref="activitySlaModal"
            @add-activity-sla="handleAddCustomSlaRow"
        />
        <TransitionSlaModal
            ref="transitionSlaModal"
            @add-transition-sla="handleAddCustomSlaRow"
        />
        <ActivityGapSlaModal
            ref="activityGapSlaModal"
            @add-activity-gap-sla="handleAddCustomSlaRow"
        />
    </div>
</template>

<script>
import {
    QBtn,
    QBtnDropdown,
    QList,
    QItemSection,
    QItemLabel,
    QTooltip,
} from 'quasar'
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
    notify,
} from '@/api'
import i18n from '@/lang/i18n'
import GlobalSLA from './GlobalSLA.vue'
import VariantSlaModal from './VariantSLAModal.vue'
import ActivitySlaModal from './ActivitySLAModal.vue'
import TransitionSlaModal from './TransitionSLAModal.vue'
import ActivityGapSlaModal from './ActivityGapSLAModal.vue'

const TABS = {
    GLOBAL: 'globalSla',
    CASE: 'caseSla',
    CUSTOM: 'customSla',
}

export default {
    name: 'SlaSettings',
    components: {
        QBtn,
        QBtnDropdown,
        QList,
        QItemSection,
        QItemLabel,
        QTooltip,
        VariantSlaModal,
        ActivitySlaModal,
        TransitionSlaModal,
        ActivityGapSlaModal,
        GlobalSLA,
    },
    mixins: [],
    props: {
        values: VueTypes.object,
        globalInfo: VueTypes.object,
    },
    emits: ['SlaConfig', 'deleteSlaGlobal'],
    data () {
        return {
            unit: { value: 'minutes', label: i18n.global.t('visualization.settings.sla.units.minutes') },
            number: 0,
            variantsSla: [],
            variants: [],
            variantsModal: [],
            selectedVariants: [],
            variantsSlaColumns: [
                {
                    name: 'name', align: 'left', label: i18n.global.t('visualization.variant'), field: 'name', sortable: true,
                },
                {
                    name: 'unit',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.name'),
                    field: 'unitValue',
                    sortable: true,
                    sort: (a, b, _rowA, _rowB) => this.sortUnit(a, b, _rowA, _rowB),
                },
                {
                    name: 'edit', align: 'right', label: '',
                },
            ],
            timeout: null,
            tab: TABS.GLOBAL,
            customSlaRows: [],
            customSlaColumns: [
                {
                    name: 'type',
                    field: 'type',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.type'),
                    headerStyle: 'max-width: 20%',
                    sortable: true,
                },
                {
                    name: 'elements',
                    field: 'elements',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.elements'),
                    headerStyle: 'max-width: 25%',
                    sortable: true,
                },
                {
                    name: 'loopMode',
                    field: 'loopMode',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.loopMode'),
                    headerStyle: 'max-width: 10%',
                    sortable: true,
                },
                {
                    name: 'sourceOccurrence',
                    field: 'sourceOccurrence',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.sourceOccurrence'),
                    headerStyle: 'max-width: 10%',
                    sortable: true,
                },
                {
                    name: 'targetOccurrence',
                    field: 'targetOccurrence',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.targetOccurrence'),
                    headerStyle: 'max-width: 10%',
                    sortable: true,
                },
                {
                    name: 'sla',
                    field: 'sla',
                    align: 'left',
                    label: i18n.global.t('visualization.settings.sla.customSlaTable.sla'),
                    headerStyle: 'max-width: 15%',
                    sortable: true,
                    sort: (a, b, _rowA, _rowB) => this.sortUnit(a, b, _rowA, _rowB),
                },
                {
                    name: 'edit',
                    field: 'edit',
                    align: 'left',
                    label: '',
                    headerStyle: 'max-width: 10%',
                },
            ],
            selectedCustomSla: [],
            editRowIndex: undefined,
        }
    },
    computed: {
        tabs () {
            return [
                { name: TABS.GLOBAL, label: this.$t('visualization.settings.sla.globalSla.tab') },
                { name: TABS.CASE, label: this.$t('visualization.settings.sla.caseSla') },
                { name: TABS.CUSTOM, label: this.$t('visualization.settings.sla.customSla') },
            ]
        },
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    watch: {
        variantsSla: {
            handler (newValue, oldValue) {
                newValue.forEach((v) => {
                    this.$watch(() => v.value, () => {
                        this.updateUnitValue(v)
                    })
                    this.$watch(() => v.unit, () => {
                        this.updateUnitValue(v)
                    })
                })
            },
            deep: true,
        },
    },
    mounted () {
        this.getVariants()
        this.checkIfValues()
    },
    methods: {
        checkQtooltipVisible (column, qTooltipContent) {
            const span = document.createElement('span')
            span.innerText = qTooltipContent
            span.style.fontSize = '13px'
            span.style.visibility = 'hidden'
            span.style.position = 'absolute'
            span.style.top = '-9999px'
            document.body.appendChild(span)
            const width = span.offsetWidth
            document.body.removeChild(span)
            const maxContentWidth = this.getMaxContentWidth(column)
            return width > maxContentWidth
        },
        getMaxContentWidth (column) {
            let maxContentWidth = 0
            const pageWidth = window.innerWidth
            if (column === 'type') {
                if (pageWidth < 1600) {
                    maxContentWidth = 31
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.02
                } else {
                    maxContentWidth = 61
                }
            } else if (column === 'elements') {
                if (pageWidth < 1600) {
                    maxContentWidth = 75
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.05
                } else {
                    maxContentWidth = 126
                }
            } else if (column === 'sla') {
                if (pageWidth < 1600) {
                    maxContentWidth = 22
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.015
                } else {
                    maxContentWidth = 47
                }
            } else if (column === 'variantName') {
                if (pageWidth < 1450) {
                    maxContentWidth = 176
                } else if (pageWidth >= 1450 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * (pageWidth * 0.01)
                } else {
                    maxContentWidth = 356
                }
            }
            return maxContentWidth
        },
        checkIfValues () {
            if (this.values) {
                if (this.values.sla) {
                    this.unit.value = this.values.sla.unit
                    this.number = this.values.sla.value
                    this.unit.label = this.getLabelByValue(this.unit.value)
                }
                if (this.values.customSla && Array.isArray(this.values.customSla)) {
                    this.values.customSla.forEach((customSla) => {
                        const id = this.generateCustomSlaId(customSla)
                        const sla = `${customSla.value} ${this.$t(`visualization.settings.sla.units.${customSla.unit}`)}`
                        const customSlaRow = { ...customSla, id, sla }
                        this.customSlaRows.push(customSlaRow)
                    })
                }
            }
        },
        getVariants () {
            const { processId } = this.$route.params
            const params = {}
            apiRequest(Api().visualizations.variants({ processId, params }))
                .then(({ data }) => {
                    this.variants = data.map(i => ({ label: i.name, value: i.id }))

                    if (this.values.variantsSla) {
                        this.variantsSla = this.values.variantsSla.map((v) => {
                            const matched = this.variants.find(v2 => v2.value === v.variantId)
                            if (matched) {
                                return { ...v, name: matched.label, unitValue: `${v.value} ${this.getLabelByValue(v.unit)}` }
                            }
                            return { ...v, unitValue: `${v.value} ${this.getLabelByValue(v.unit)}` }
                        })
                    } else {
                        this.variantsSla = []
                    }
                    this.updateVariantsModal()
                })
                .catch(notifyError)
        },
        saveGlobalSLA (values) {
            this.$emit('SlaConfig', {
                data:
                    values.sla ? { unit: values.sla.unit, value: values.sla.value } : { unit: null, value: -1 },
                type: 'sla',
            })
        },
        getLabelByValue (value) {
            const option = this.slaUnits.find(opt => opt.value === value)
            return option ? option.label : ''
        },
        onOpenVariantSlaModal () {
            this.$refs.variantSlaModal.open()
        },
        onOpenActivitySlaModal () {
            this.$refs.activitySlaModal.open()
        },
        onOpenTransitionSlaModal () {
            this.$refs.transitionSlaModal.open()
        },
        onOpenActivityGapSlaModal () {
            this.$refs.activityGapSlaModal.open()
        },
        onOpenEditVariantSlaModal (row) {
            this.$refs.variantSlaModal.open(row)
        },
        onOpenEditSlaModal (customSlaRow) {
            const customSla = customSlaRow.row
            this.editRowIndex = customSlaRow.rowIndex
            if (customSla.type === 'activity') {
                this.$refs.activitySlaModal.open(customSla)
            } else if (customSla.type === 'transition') {
                this.$refs.transitionSlaModal.open(customSla)
            } else {
                this.$refs.activityGapSlaModal.open(customSla)
            }
        },
        updateVariantSLA () {
            const variantsSla = this.variantsSla
                .filter(v => 'variantId' in v)
                .map(({ name, unitValue, ...rest }) => rest)

            this.$emit('SlaConfig',
                {
                    data: variantsSla,
                    type: 'variantsSla',
                })
        },
        handleAddVariantSla ({ variant, number, unit }) {
            if (!variant || number < 0 || !unit) {
                notifyError()
                return
            }
            const unitLabel = this.getLabelByValue(unit.value)
            this.variantsSla = [...this.variantsSla, {
                name: variant.label,
                unit: unit.value,
                value: parseInt(number, 10),
                unitValue: `${parseInt(number, 10)} ${unitLabel}`,
                variantId: variant.value,
            }]
            this.updateVariantsModal()
            this.$refs.variantSlaModal.close()
            this.selectedVariants = []
            this.updateVariantSLA()
        },
        handleEditVariantSla ({ variant, number, unit }) {
            const variantSla = this.variantsSla.find(v => v.variantId === variant.value)
            Object.assign(variantSla, { unit: unit.value, value: parseInt(number, 10) })
            this.$refs.variantSlaModal.close()
            this.updateVariantSLA()
        },
        handleDeleteVariantSla () {
            const selectedVariantIds = this.selectedVariants.map(s => s.variantId)
            this.variantsSla = this.variantsSla.filter(v => !selectedVariantIds.includes(v.variantId))
            this.updateVariantsModal()
            this.selectedVariants = []
            this.updateVariantSLA()
        },
        updateSelectedVariants (selected) {
            this.selectedVariants = selected.rows
        },
        updateVariantsModal () {
            if (!this.variantsSla || this.variantsSla.length === 0) {
                this.variantsModal = this.variants
                    .map(v => ({ ...v, disable: false }))
            } else {
                this.variantsModal = this.variants
                    .map(v => ({ ...v, disable: this.variantsSla.some(v2 => v2.variantId === v.value) }))
            }
        },
        handleAddCustomSlaRow (newCustomSla, edit) {
            if (newCustomSla.type === 'activity') {
                this.$refs.activitySlaModal.close()
            } else if (newCustomSla.type === 'transition') {
                this.$refs.transitionSlaModal.close()
            } else {
                this.$refs.activityGapSlaModal.close()
            }
            if (edit) {
                this.editCustomSlaRow(newCustomSla)
            } else {
                this.addCustomSlaRow(newCustomSla)
            }
        },
        addCustomSlaRow (newCustomSla) {
            const validElement = this.verifyCustomSlaDuplicate(newCustomSla)
            if (validElement) {
                const id = this.generateCustomSlaId(newCustomSla)
                const sla = `${newCustomSla.value} ${this.$t(`visualization.settings.sla.units.${newCustomSla.unit}`)}`
                const customSlaRow = { ...newCustomSla, id, sla }
                this.customSlaRows.push(customSlaRow)
            } else {
                notify({
                    message: this.$t('visualization.settings.sla.customSlaTable.notifyDuplicated'),
                })
            }
            this.updateCustomSLA()
        },
        editCustomSlaRow (newCustomSla) {
            const id = this.generateCustomSlaId(newCustomSla)
            const sla = `${newCustomSla.value} ${this.$t(`visualization.settings.sla.units.${newCustomSla.unit}`)}`
            const customSlaRow = { ...newCustomSla, id, sla }
            this.customSlaRows.splice(this.editRowIndex, 1)
            this.customSlaRows.splice(this.editRowIndex, 0, customSlaRow)
            this.editRowIndex = undefined
            this.updateCustomSLA()
        },
        generateCustomSlaId (customSla) {
            return `${customSla.type}-${customSla.elements.join(', ')}-${customSla.loopMode}-${customSla.sourceOccurrence}-${customSla.targetOccurrence}`
        },
        verifyCustomSlaDuplicate (newCustomSla) {
            const isDuplicate = this.customSlaRows.some(item => item.type === newCustomSla.type &&
                JSON.stringify(item.elements) === JSON.stringify(newCustomSla.elements) &&
                item.loopMode === newCustomSla.loopMode &&
                item.sourceOccurrence === newCustomSla.sourceOccurrence &&
                item.targetOccurrence === newCustomSla.targetOccurrence)

            if (isDuplicate) {
                return false
            }
            return true
        },
        handleDeleteCustomSla () {
            this.customSlaRows = this.customSlaRows.filter(row => !this.selectedCustomSla.includes(row))
            this.selectedCustomSla = []
            this.updateCustomSLA()
        },
        updateCustomSLA () {
            const customSla = this.customSlaRows.map((customSlaRow) => {
                const newCustomSla = { ...customSlaRow }
                delete newCustomSla.id
                delete newCustomSla.sla
                return newCustomSla
            })
            this.$emit('SlaConfig', { data: customSla, type: 'customSla' })
        },
        updateUnitValue (variant) {
            const index = this.variantsSla.findIndex(v => v === variant)
            if (index !== -1) {
                const unitLabel = this.getLabelByValue(variant.unit.value ? variant.unit.value : variant.unit)
                const updatedVariant = {
                    ...variant,
                    unit: variant.unit.value ? variant.unit.value : variant.unit,
                    unitValue: `${variant.value} ${unitLabel}`,
                }
                this.variantsSla[index] = updatedVariant
            }
        },
        sortUnit (unitValueA, unitValueB, rowA, rowB) {
            const valueA = unitValueA.split(' ')[0]
            const unitI18nA = unitValueA.split(' ')[1]
            const unitA = this.slaUnits.find(s => s.label === unitI18nA).value
            const valueSecondsA = this.toSeconds(unitA, valueA)

            const valueB = unitValueB.split(' ')[0]
            const unitI18nB = unitValueB.split(' ')[1]
            const unitB = this.slaUnits.find(s => s.label === unitI18nB).value

            const valueSecondsB = this.toSeconds(unitB, valueB)
            return valueSecondsA - valueSecondsB
        },
        toSeconds (unit, value) {
            let conversionFactor = 1
            if (unit === 'minutes' || unit === 'hours' || unit === 'days' || unit === 'weeks' || unit === 'months') {
                conversionFactor *= 60
            }
            if (unit === 'hours' || unit === 'days' || unit === 'weeks' || unit === 'months') {
                conversionFactor *= 60
            }
            if (unit === 'days' || unit === 'weeks' || unit === 'months') {
                conversionFactor *= 24
            }
            if (unit === 'weeks') {
                conversionFactor *= 7
            }
            if (unit === 'months') {
                conversionFactor *= 30
            }
            return value * conversionFactor
        },
    },
}
</script>
<style lang="scss" scoped>
.SlaSettings {
    min-height: 300px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;

    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 20px;
    }
    .SaveButtons {
        width: 80px;
    }
    .Tabs {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -40px;
        white-space: nowrap;
        &:deep(.q-panel) {
            max-width: 95%;
        }
    }
    .SLATabs {
        margin: 15px;
        width: 96%;
    }
    .TableRow {
        width:100%;
        max-height: 90%;
        margin-top: 30px;
        margin-bottom: 10px;
        .Table {
            flex-grow: 1;
            max-width: 100%;
            :deep(.q-table th) {
                font-weight: normal;
                font-size: 12px;
                background-color: rgb(235, 235, 235);
                text-align: left;
                position: sticky;
                top: 0;
                z-index: 99;
            }
            :deep(.q-table td) {
                max-width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            :deep(.q-table td.EditTD) {
                text-overflow: unset;
            }
        }
    }
}
</style>
