<template>
    <div class="AccountScreen">
        <q-card
            class="Personal mb-2 q-pa-md"
            flat
            bordered
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('settings.sections.personal') }}
            </WText>

            <div class="Wrapper">
                <div
                    class="Personal pb-0-5 mb-1"
                >
                    <span class="PersonalText">
                        {{ $t('settings.personal.text1') }}
                    </span>
                    <span class="PersonalTextBold">
                        {{ $t('settings.personal.text2', { name: project }) }}
                    </span>
                    <span class="PersonalText ">
                        {{ $t('settings.personal.text3') }}
                    </span>
                </div>
                <UserForm
                    v-model:user="user"
                    :disabled="isLoading"
                    @on-submit="update"
                />
            </div>
        </q-card>
        <q-card
            class="q-pa-md Password mb-2"
            flat
            bordered
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('settings.sections.security') }}
            </WText>

            <div class="Wrapper">
                <WText class="pb-0-5 mb-1">
                    {{ $t('settings.security.text') }}
                </WText>
                <QBtn
                    :disable="isLoading"
                    :label="$t('settings.security.btn')"
                    class="buttonStyle"
                    color="primary"
                    noCaps
                    @click="handleClickRecoveryPassword"
                />
            </div>
        </q-card>
        <ModalRequest
            ref="recoveryDialog"
            :title="$t('settings.modals.recovery.title')"
            :text="$t('settings.modals.recovery.text')"
            @on-accept="recoveryPassword"
        />
    </div>
</template>
<script>
import Config from '@/config'
import {
    Api, apiRequest, notifySuccess, notifyError,
} from '@/api'
import { ModalRequest } from '@/components'
import { UserForm } from '@/forms'

export default {
    name: 'AccountScreen',
    components: {
        UserForm,
        ModalRequest,
    },
    inject: ['App'],
    data () {
        const { user, enviroment } = this.App
        return {
            user: { ...user },
            enviroment: { ...enviroment },
            isLoading: false,
        }
    },
    watch: {
        'App.user': {
            handler (value) {
                if (value) this.user = value
            },
            immediate: true,
        },
    },
    beforeCreate () {
        const { name } = Config.project || {}
        this.project = name
    },
    methods: {
        handleClickRecoveryPassword () {
            this.$refs.recoveryDialog.open()
        },
        async update () {
            this.isLoading = true
            apiRequest(Api().users.update(this.user))
                .then(() => {
                    this.App.setUser(this.user)
                    notifySuccess(this.$t('settings.notifications.personal.text'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        async recoveryPassword () {
            this.isLoading = true
            apiRequest(Api().users.resetPassword())
                .then(() => {
                    this.$refs.recoveryDialog.close()
                    notifySuccess(this.$t('settings.notifications.resetPassword.text'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Personal {
    line-height: 1.4;
}

.PersonalText {
    font-size: 14px;
    color: #444;
    font-weight:400;
    font-family: 'Roboto', 'sans-serif';
    text-align: left;
}

.PersonalTextBold {
    font-size: 14px;
    color: #444;
    font-weight:700;
    font-family: 'Roboto', 'sans-serif';
    text-align: left;
}
</style>
