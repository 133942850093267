<template>
    <div
        v-if="chartInfo"
        class="LoopMetrics"
    >
        <div class="row justify-between q-ma-xs">
            <div class="row items-center">
                <QIcon
                    class="IconTitle"
                    name="rotate_left"
                    color="white"
                    size="25px"
                />
                <div class="Title">
                    {{ $t(`visualization.loopMetrics.metricsTitle`) }}
                </div>
            </div>
            <div class="row justify-end">
                <QBtn
                    round
                    color="primary"
                    flat
                    icon="close_fullscreen"
                    size="12px"
                    textColor="white"
                    @click="visible=!visible"
                />
            </div>
        </div>
        <div v-if="visible">
            <div class="row q-col-gutter-sm">
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t('visualization.loopMetrics.avgTime') }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ averageLoopTimeFormatted }}
                        </div>
                    </QCard>
                </div>
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t('visualization.loopMetrics.avgCost') }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold text-secondary">
                            {{ averageCostFormatted }}
                        </div>
                    </QCard>
                </div>
                <div class="col-sm-12">
                    <span class="ContextualElementName">{{ $t('visualization.loopMetrics.percentTime') }}</span>
                    <QLinearProgress
                        size="25px"
                        :value="averagePercentage"
                        color="orange-7"
                        trackColor="grey-2"
                        rounded
                    >
                        <div class="absolute-full flex flex-center">
                            <QBadge
                                color="white"
                                textColor="primary"
                                :label="`${(averagePercentage*100).toFixed(0)}%`"
                            />
                        </div>
                    </QLinearProgress>
                </div>
                <div class="col-sm-12">
                    <span class="ContextualElementName">{{ $t('visualization.loopMetrics.estTotalEffort') }}</span>
                    <div class="row q-col-gutter-sm">
                        <div class="col-sm-6">
                            <QCard
                                flat
                                class="bg-grey-2"
                            >
                                <div class="q-pa-sm text-center text-weight-bold">
                                    {{ estimatedTotalEffortTimeFormatted }}
                                </div>
                            </QCard>
                        </div>
                        <div class="col-sm-6">
                            <QCard
                                flat
                                class="bg-grey-2"
                            >
                                <div class="q-pa-sm text-center text-weight-bold text-secondary">
                                    {{ `${estimatedTotalEffortCostFormatted} ${curOptions[currency.value]}` }}
                                </div>
                            </QCard>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <span class="ContextualElementName">{{ $t('visualization.loopMetrics.afterRemoval') }}</span>
                    <QCard
                        flat
                        class="col bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            <span>{{ estimatedRemovedEffortFormatted }}</span>
                            <QIcon name="arrow_right" />
                            <span class="text-secondary">{{ `${estimatedRemovedCost} ${curOptions[currency.value]}` }}</span>
                        </div>
                    </QCard>
                </div>
            </div>
        </div>
        <q-inner-loading :showing="Object.keys(chartInfo).length == 0 || variantsLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersMixin } from '@/mixins'

export default {
    name: 'LoopMetricsPanel',
    components: {
    },
    mixins: [filtersMixin],
    inject: ['App'],
    props: {
        displayedInfo: VueTypes.object,
        chartInfo: VueTypes.any,
        currency: VueTypes.object,
        costPerHour: VueTypes.number,
        variantsLoading: VueTypes.bool,
    },
    data () {
        return {
            loopArcs: this.chartInfo?.loop_arcs || [],
            loopActivities: this.chartInfo?.loops_activities || [],
            caseAvgTime: this.displayedInfo.duration?.avg || 0,
            frequency: this.chartInfo?.frequency || 0,
            loopStats: this.chartInfo?.stats || {},
            visible: true,
        }
    },
    computed: {
        curOptions () {
            return {
                euro: '€',
                dollar: '$',
                pound: '£',
            }
        },
        averageLoopTime () {
            return this.loopStats.avgDuration || 0
        },
        averageLoopTimeFormatted () {
            return moment.duration(this.averageLoopTime, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
        },
        averageCost () {
            return this.loopStats.avgCost || 0
        },
        averageCostFormatted () {
            const formattedCost = (this.averageCost).toFixed(0)
            return `${formattedCost} ${this.curOptions[this.currency.value]}`
        },
        averagePercentage () {
            return this.averageLoopTime > 0 && this.caseAvgTime > 0 ? ((this.averageLoopTime / this.caseAvgTime)) : 0
        },
        estimatedTotalEffortTimeFormatted () {
            return moment.duration(this.averageLoopTime * this.frequency, 'seconds').format('d[d] h[h]:m[m]:s[s]', {
                largest: 2, trim: false, groupingSeparator: '', useToLocaleString: false,
            })
        },
        estimatedTotalEffortCostFormatted () {
            const totalCost = (this.loopStats.totalCost || 0).toFixed(0)
            return this.App.numberLocationFormat(totalCost)
        },
        estimatedRemovedEffortFormatted () {
            return moment.duration(this.loopStats.estimatedRemovedEffortTime, 'seconds').format('d[d] h[h]:m[m]:s[s]', {
                largest: 2, trim: false, groupingSeparator: '', useToLocaleString: false,
            })
        },
        estimatedRemovedCost () {
            const removedCost = (this.loopStats.estimatedRemovedCost || 0).toFixed(0)
            return this.App.numberLocationFormat(removedCost)
        },
        chartAndDisplay () {
            return {
                chart: this.chartInfo,
                display: this.displayedInfo,
            }
        },
    },
    watch: {
        chartAndDisplay: {
            deep: true,
            handler (newValue, oldValue) {
                if (newValue.chart && newValue.display && oldValue.display !== newValue.display) {
                    this.loopArcs = this.chartInfo?.loop_arcs
                    this.loopActivities = this.chartInfo?.loops_activities
                    this.frequency = this.chartInfo?.frequency || 0
                    this.caseAvgTime = this.displayedInfo.duration?.avg || 0
                    this.loopStats = this.chartInfo?.stats || {}
                }
            },
        },
    },
    mounted () {
    },
    methods: {
        setDurationOption (option) {
            this.durMode = option
        },
        formatDate (value, format = 'DD/MM/YY') {
            const isValid = value ?? undefined
            return isValid ? this.date(value, format) : this.$t('visualization.filters.date.noData')
        },
        formatPercentage (value, total) {
            return value ? ((value / total)).toFixed(2) : 0
        },
        numFormatter (num) {
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num > 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style lang="scss" scoped>
.LoopMetrics {
  position: relative;
  z-index: 99;
  width: 300px;
  max-width: 300px;
}
.ContextualTitle {
    font-size: small;
    color: white;
    font-weight: bold;
}
.ContextualElementName {
    font-size: small;
    color: white;
}
.Title {
    color:$white;
    margin-left: 1.7em;
}
</style>
