<template>
    <div class="UserForm">
        <div class="Row">
            <div class="Field">
                <QInput
                    ref="name"
                    v-model="user.name"
                    lazyRules
                    dense
                    outlined
                    maxlength="100"
                    :label="$t('forms.user.name.label')"
                    :placeholder="$t('forms.user.name.placeholder')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.name.label') })]"
                />
            </div>
            <div class="Field">
                <QInput
                    ref="surname"
                    v-model="user.surname"
                    lazyRules
                    dense
                    outlined
                    maxlength="100"
                    :label="$t('forms.user.surname.label')"
                    :placeholder="$t('forms.user.surname.placeholder')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.surname.label') })]"
                />
            </div>
        </div>
        <div class="Row">
            <div class="Field">
                <QInput
                    ref="email"
                    v-model="user.email"
                    lazyRules
                    dense
                    outlined
                    type="email"
                    :label="$t('forms.user.email.label')"
                    :placeholder="$t('forms.user.email.placeholder')"
                    :rules="[
                        value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.email.label') }),
                        value => validators.validEmail(value) || $t('validation.email', { attribute: $t('forms.user.email.label') }),
                    ]"
                />
            </div>
            <div class="Field">
                <QSelect
                    ref="locale"
                    v-model="user.locale"
                    lazyRules
                    dense
                    outlined
                    behavior="menu"
                    class="Locales"
                    :label="$t('forms.user.locale.label')"
                    :placeholder="$t('forms.user.locale.placeholder')"
                    :options="locales"
                    :optionValue="opt => Object(opt) === opt && 'value' in opt ? opt.value : null"
                    :optionLabel="opt => Object(opt) === opt && 'label' in opt ? opt.label : '- Null -'"
                    mapOptions
                    emitValue
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.locale.label') })]"
                />
            </div>
        </div>
        <div class="Row">
            <div class="Field">
                <QSelect
                    ref="locationFormat"
                    v-model="user.locationFormat"
                    lazyRules
                    dense
                    outlined
                    behavior="menu"
                    class="LocationFormats"
                    :label="$t('forms.user.locationFormat.label')"
                    :placeholder="$t('forms.user.locationFormat.placeholder')"
                    :options="locationFormats"
                    :optionValue="opt => Object(opt) === opt && 'value' in opt ? opt.value : null"
                    :optionLabel="opt => Object(opt) === opt && 'label' in opt ? opt.label : '- Null -'"
                    mapOptions
                    emitValue
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.locationFormat.label') })]"
                >
                    <template #option="scope">
                        <QItem
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                        >
                            <QItemSection>
                                <QItemLabel>{{ scope.opt.label }}</QItemLabel>
                                <QItemLabel caption>
                                    {{ scope.opt.description }}
                                </QItemLabel>
                            </QItemSection>
                        </QItem>
                    </template>
                </QSelect>
            </div>
            <div class="flex row-right-center">
                <QBtn
                    id="submit"
                    color="primary"
                    noCaps
                    :disabled="disabled"
                    :label="$t('forms.user.submit')"
                    @click="handleSubmit"
                />
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import Config from '@/config'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'UserForm',
    mixins: [validatorsMixin],
    props: {
        user: {
            type: Object,
            default (_rawProps) {
                return {
                    name: "",
                    surname: "",
                    email: "",
                    locale: "",
                    locationFormat: "",
                    timezone: "",
                    subscription: {
                        active: false,
                    },
                    theme: "",
                    llmEnabled: true,
                    mlEnabled: true,
                }
            },
        },
        disabled: VueTypes.bool.def(false),
    },
    emits: ['onSubmit'],
    data () {
        return {
            locales: Config.i18n.locales.map(val => ({ label: this.$t(`forms.user.locales.${val}`), value: val })),
            locationFormats: Config.i18n.locationFormats.map(val => ({
                label: this.$t(`forms.user.locationFormats.${val}`),
                value: val,
                description: `${this.$d(new Date(), 'long', val === 'eu' ? 'es-ES' : val)}   ${this.$n(123456.05, 'decimal', val === 'eu' ? 'de-DE' : val)}`,
            })),
        }
    },
    computed: {
        locale () {
            return this.$t(`forms.user.locales.${this.user.locale}`)
        },
        locationFormat () {
            const { locationFormat } = this.user || {}
            return this.locationFormats ? this.locationFormats.find(value => value === locationFormat) : locationFormat
        },
    },
    watch: {
        '$i18n.locale': function () {
            this.updateOptions()
        },
    },
    methods: {
        updateOptions () {
            this.locales = Config.i18n.locales.map(val => ({
                label: this.$t(`forms.user.locales.${val}`),
                value: val,
            }))
            this.locationFormats = Config.i18n.locationFormats.map(val => ({
                label: this.$t(`forms.user.locationFormats.${val}`),
                value: val,
                description: `${this.$d(new Date(), 'long', val === 'eu' ? 'es-ES' : val)}   ${this.$n(123456.05, 'decimal', val === 'eu' ? 'de-DE' : val)}`,
            }))
        },
        handleSubmit () {
            if (this.validate()) {
                this.$emit('onSubmit')
                this.locales = Config.i18n.locales.map(value => ({ label: this.$t(`forms.user.locales.${value}`, this.locale), value }))
                this.locationFormats = Config.i18n.locationFormats.map(val => ({
                    label: this.$t(`forms.user.locationFormats.${val}`, this.user.locale),
                    value: val,
                    description: `${this.$d(new Date(), 'long', val === 'eu' ? 'es-ES' : val)}   ${this.$n(123456, 'decimal', val === 'eu' ? 'de-DE' : val)}`,
                }))
            }
        },
        validate () {
            const fields = ['name', 'surname', 'email', 'locale', 'locationFormat']
            return !fields.filter(field => !this.$refs[field].validate()).length
        },
    },
}
</script>
<style scoped lang="scss">
.UserForm {
    .Row {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );

        & + & {
            margin-top: 15px;
        }

        .Field {
            margin-bottom: 10px;
        }
    }
}
</style>
