export default api => ({
    definitionList: ({ datasetId, params }) => api.get(`/datasets/${datasetId}/alerts`, { params }),
    definitionCreate: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts`, params),
    definitionGetAll: ({ datasetId, alertId }) => api.get(`/datasets/${datasetId}/alerts/${alertId}`),
    definitionDelete: ({ datasetId, alertId }) => api.delete(`/datasets/${datasetId}/alerts/${alertId}`),
    definitionDeleteAll: ({ datasetId }) => api.put(`/datasets/${datasetId}/alerts`),
    historyList: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts/evaluations`, params),
    historyDismiss: ({ datasetId, alertId }) => api.put(`/datasets/${datasetId}/alerts/${alertId}/dismiss`),
    activities: ({ datasetId }) => api.post(`/datasets/${datasetId}/activities?alertInfo=true`, { filters: [] }),
})
