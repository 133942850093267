export default {
    visualizationToBooster: 'Impulsionador de processos',
    boosterDisabledTooltip: 'É necessário configurar as condições necessárias para aceder ao Booster',
    customAnalysis: 'Análise personalizada',
    performanceBoosterTitle: 'Booster de desempenho',
    overview: {
        tab: 'Síntese',
        description: 'Compreender o desempenho atual do seu processo',
        relevantNotDefined: 'Configure os atributos relevantes para exibir os dados',
        slaNotDefined: 'Configurar as condições de SLA para exibir os dados.',
        relevantAndSLANotDefined: 'Configure os atributos relevantes e as condições de SLA para exibir os dados.',
        caseCompletionNotDefined: 'Configurar as condições de conclusão do caso para exibir os dados.',
        relevantAndCaseNotDefined: 'Configure os atributos relevantes e as condições de conclusão do caso para exibir os dados.',
        slaAndCaseNotDefined: 'Configurar as condições de SLA e as condições de conclusão do caso para exibir os dados.',
        relevantAndSLAAndCaseNotDefined: 'Configure os atributos relevantes, as condições de SLA e as condições de conclusão do caso para exibir os dados.',
        costNotDefined: 'Configurar as condições de custo para exibir os dados.',
        relevantAndCostNotDefined: 'Configure os atributos relevantes e as condições de custo para exibir os dados.',
        slaAndCostNotDefined: 'Configurar as condições de SLA e as condições de custo para exibir os dados.',
        relevantAndSLAAndCostNotDefined: 'Configure os atributos relevantes, as condições de SLA e as condições de custo para exibir os dados.',
        noConditionsText: 'É possível definir as condições necessárias nas configurações do conjunto de dados.',
    },
    state: {
        performanceBy: 'Performance by',
        rightClick: 'Clique com o botão direito do rato numa barra para ver o gráfico',
        leadTimesBy: 'Lead times by',
        timeSpentPerCase: 'Tempo médio gasto por caso',
        performanceComparison: 'comparação de desempenho',
        leadTimes: 'lead times',
        cases: 'casos',
        selectFilteringElements: 'Elementos atualmente seleccionados para filtragem de recursos',
        evolutionOverTime: 'Evolução do desempenho ao longo do tempo',
        overallPerformance: 'Desempenho global por:',
        activity: 'Atividade',
        transition: 'Transição',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Abaixo do SLA',
        intermediate: 'Intermediário',
        above: 'Acima do SLA',
        slaCompliance: 'Cumprimento do SLA',
        noTraces: 'Não foram encontrados vestígios para os filtros actuais',
        performanceByClick: 'Clique numa barra do gráfico à esquerda para selecionar',
    },
    prediction: {
        tab: 'Previsão',
        description: 'Descubra o desempenho do seu processo',
        predictionHighlights: {
            name: 'A previsão destaca',
            cases: 'casos',
            ongoingCases: 'ongoing cases',
            meetingSLA: 'in condition of',
            meetingSLAColor: 'cumprindo o SLA',
            missingSLAColor: 'under risk',
            missingSLA: 'de não cumprir o SLA',
            exceeding: 'already',
            exceedingColor: 'excedendo o SLA',
        },
        predictedPerformance: {
            name: 'Predicted performance by',
            risk: 'Estimativa de risco das previsões em curso',
            riskLabel: 'Risco',
            duration: 'Duração prevista versus duração passada',
            durationLabel: 'Dur. SLA',
            distribution: 'Distribuição do desempenho do SLA',
            distributionSla: 'Distr. SLA',
            distributionLabel: 'Distr. SLA',
            past: 'Passado',
            predicted: 'Previsto',
            finishedAbove: 'Terminou acima do Sla',
            finishedUnder: 'Terminou abaixo do Sla',
            finished: 'Concluído',
            ongoingAbove: 'Em curso acima de Sla',
            ongoingUnder: 'Em curso abaixo de Sla',
            finishedOngoing: 'Terminado - Em curso',
            pastPredicted: 'Passado - Previsto',
            ongoing: 'Em curso',
            pastMonths: 'Passado',
            months: 'meses',
        },
        ongoingCases: {
            showOnly: 'Show only for',
            ongoingCaseList: 'Lista de casos em curso',
            state: 'Estado',
            caseId: 'ID do caso',
            currentDuration: 'Duração atual',
            estimatedDuration: 'Duração estimada',
            Good: 'Bom',
            Risk: 'Risco',
            above: 'Acima do SLA',
            select: 'Por favor, seleccione um valor',

        },
        timeToMeet: {
            timeToMeet: 'Time to meet',
            improvementRate: 'Minimum monthly improvement rate for meeting',
            improvementRateSla: 'Minimum monthly improvement rate for',
            improvementRateSecondPart: 'in',
            past: 'Passado',
            months: 'meses',
            bestPerforming: 'current best performing',
        },
    },
    improvement: {
        tab: 'Simulação',
        description: 'Descubra como melhorar o desempenho do seu processo',
        activitiesImprove: 'Atividades do processo para melhorar',
        transitionsImprove: 'Transições do processo para melhorar',
        improvementResults: 'Resultados de melhoria em ',
        improvementTables: {
            rule1: 'Somente números',
            rule2: '0-100',
            name: 'Nome',
            currentImpact: 'Impacto Atual',
            improvement: 'Melhoria',
            estimatedImpact: 'Impacto Estimado',
        },
        improvementFigures: {
            title: 'Figuras de melhoria',
            cases: ' casos',
            red: 'vermelho ',
            green: ' verde',
            become: 'que se tornam',
            decrease: 'redução',
            overallDuration: ' na duração total',
            overallCosts: ' nos custos totais',
            days: ' dias',
            hours: 'horas',
            seconds: 'seg',
            currentPerformance: 'Desempenho atual',
            improvedPerformance: 'Desempenho melhorado',
            slaCompliance: 'Conformidade SLA',
            slaNonCompliance: 'Não conformidade SLA',
        },
        improvementScope: {
            title: 'Escopo de melhoria',
            add: 'Selecione e adicione pares atributo-valor para restringir o escopo da melhoria',
            attributes: 'Atributos',
            values: 'Valores',
        },
    },
    tabs: {
        overview: 'Visão geral',
        prediction: 'Previsão',
        improvement: 'Melhoria',
    },
    d2t: {
        viewReport: 'Ver relatório',
        analysisReport: 'Relatório de análise',
        copyToClipboard: 'Copiar para a área de transferência',
        copied: 'Copiado!',
    },
    alerts: {
        tab: 'Alertas',
        description: 'Configurar e verificar alertas em tempo real',
        filterBy: 'Filtrar por',
        columns: {
            name: 'Nome do atributo',
            severity: 'Gravidade',
            filterSet: 'Conjunto de filtros',
            type: 'Tipo de alerta',
            notification: 'Notificação',
        },
        tabs: {
            active: 'Ativo',
            history: 'Histórico',
            definitions: 'Definições',
        },
        detected: {
            noAlerts: 'Não há alertas para mostrar',
            affected: 'Casos afectados',
            lastDetected: 'Último caso detectado',
            severity: 'Nível de gravidade',
            filterSet: 'Conjunto de filtros associados',
            filterCases: 'Casos de filtro',
            dismiss: 'Descartar',
            severityRiskLabels: {
                LOW: 'Baixo',
                MEDIUM: 'Média',
                HIGH: 'Alto',
            },
            predictionRiskLabels: {
                LOW: 'SLA em risco',
                MEDIUM: 'SLA em risco',
                HIGH: 'SLA excedido',
            },
        },
        definition: {
            create: 'Configurar novo alerta',
            remove: 'Remover selecionado',
            name: 'Nome do atributo',
            severity: 'Gravidade',
            type: 'Tipo de alerta',
            types: {
                ALL: 'Todos',
                STANDARD: 'Padrão',
                PREDICTION: 'Previsão',
            },
            risk: 'Risco',
            filterSet: 'Conjunto de filtros',
            notification: 'Notificação',
            record: 'registo',
            selected: 'selecionado de',
            createScreen: {
                newAlert: 'Nova definição de alerta',
                sendEmail: 'Enviar notificação por correio eletrónico para',
                user: 'E-mail do utilizador',
                other: 'correio eletrónico diferente',
                notSend: 'não enviar',
                notifyTo: 'Endereço de correio eletrónico de destino',
                cancel: 'Cancelar',
                addAlert: 'Adicionar alerta',
            },
            tableColumn: {
                name: 'Nome do atributo',
                severity: 'Gravidade',
                filterset: 'Conjunto de filtros',
                type: 'Tipo de alerta',
                notification: 'Notificação',
            },
        },
        notifications: {
            created: 'Alerta criado com sucesso',
            validateError: 'Preencha corretamente todos os campos obrigatórios',
        },
    },
}
