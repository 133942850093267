export default {
    upload: 'Carregar o conjunto de dados para o assistente',
    reuploadWarning: 'Esta ação irá carregar novamente o conjunto de dados para o assistente e reiniciará a conversa. Continuar?',
    restartConversation: 'Reiniciar a conversação',
    reuploadDataset: 'Voltar a carregar o conjunto de dados',
    downloadConversation: 'Descarregar conversa',
    assistantPresentation: 'Olá, sou o seu assistente, faça-me as suas perguntas relacionadas com o conjunto de dados',
    writeMessagePlaceholder: 'Escrever uma mensagem',
    send: 'Send',
    yes: 'Sim',
    no: 'Não',
    thinking: 'Pensamento',
    labsDisclaimer: 'Este assistente utiliza tecnologia LLM de terceiros e as respostas podem não ser exactas em algumas situações.' +
    'Em qualquer circunstância, o utilizador é responsável por qualquer utilização posterior das informações fornecidas por este assistente.',
    labs: 'LÁBIOS',
    errorSendMessage: 'Erro ao enviar a mensagem, recarregar a página ou tentar novamente mais tarde.',
    emptyMessage: '(Resposta vazia)',
}
