<template>
    <div class="Container">
        <q-inner-loading :showing="highlightsLoading" />
        <div
            v-if="!highlightsLoading"
            class="Card"
        >
            <QCard
                class="MeetingBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ App.numberLocationFormat(meetingSLA, true) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="MeetingPercent">{{ App.numberLocationFormat(meetingSLAPercent, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                {{ $t('booster.prediction.predictionHighlights.meetingSLA') }}
                <span class="MeetingColor">
                    {{ $t('booster.prediction.predictionHighlights.meetingSLAColor') }}
                </span>
            </span>
        </div>
        <div
            v-if="!highlightsLoading"
            class="Card"
        >
            <QCard
                class="UnderRiskBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ App.numberLocationFormat(underRisk, true) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="UnderRiskPercent">{{ App.numberLocationFormat(underRiskPercent, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection text-h6">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                <span class="UnderRiskColor">
                    {{ $t('booster.prediction.predictionHighlights.missingSLAColor') }}
                </span>
                {{ $t('booster.prediction.predictionHighlights.missingSLA') }}
            </span>
        </div>
        <div
            v-if="!highlightsLoading"
            class="Card"
        >
            <QCard
                class="ExceedingBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ App.numberLocationFormat(exceeding, true) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="ExceedingPercent">{{ App.numberLocationFormat(exceedingPercent, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection text-h6">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                {{ $t('booster.prediction.predictionHighlights.exceeding') }}
                <span class="ExceedingColor">
                    {{ $t('booster.prediction.predictionHighlights.exceedingColor') }}
                </span>
            </span>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'PredictionBoosterMetricsPanel',
    inject: ['App'],
    props: {
        predictionHighlights: VueTypes.object,
        highlightsLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            meetingSLA: 0,
            meetingSLAPercent: 0,
            underRisk: 0,
            underRiskPercent: 0,
            exceeding: 0,
            exceedingPercent: 0,
        }
    },
    watch: {
        predictionHighlights: {
            handler: 'setData',
            immediate: true,
        },
    },
    created () {
        this.setData()
    },
    methods: {
        setData () {
            this.meetingSLA = this.numFormatter(this.predictionHighlights.meetSlaAbsolute)
            this.meetingSLAPercent = parseFloat((this.predictionHighlights.meetSlaPercentage * 100).toFixed(2))
            this.underRisk = this.numFormatter(this.predictionHighlights.riskSlaAbsolute)
            this.underRiskPercent = parseFloat((this.predictionHighlights.riskSlaPercentage * 100).toFixed(2))
            this.exceeding = this.numFormatter(this.predictionHighlights.exceedSlaAbsolute)
            this.exceedingPercent = parseFloat((this.predictionHighlights.exceedSlaPercentage * 100).toFixed(2))
        },
        numFormatter (num) {
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style scoped lang="scss">
    .Container {
        position:relative;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .Section {
        width: 50%;
    }
    .TextSection {
        padding-left: 1vw;
        font-size: 2.6vh;
    }
    .Card {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .CustomCard {
        font-size: 2.6vh;
    }
    .q-card__section--vert {
        height: 90%;
    }
    .MeetingBox {
        border: 3px solid #00C383;
    }
    .UnderRiskBox {
        border: 3px solid #fc8a28;
        height: fit-content;
    }
    .ExceedingBox {
        border: 3px solid #ee394a;
    }
    .MeetingPercent {
        color: #00C383;
        padding-left: 1vw;
    }
    .UnderRiskPercent {
        color: #fc8a28;
        padding-left: 1vw;
    }
    .ExceedingPercent {
        color: #ee394a;
        padding-left: 1vw;
    }
    .MeetingColor {
        color: #00C383;
    }
    .UnderRiskColor {
        color: #fc8a28;
    }
    .ExceedingColor {
        color: #ee394a;
    }
    .highlightsLoading {
        display: flex;
        top: 30%;
        left: 25%;
        max-width: 50px;
        max-height: 50px;
    }
</style>
