export default {
    required: '{attribute} é obrigatório.',
    integer: '{attribute} tem de ser um número inteiro.',
    numeric: '{attribute} tem de ser um número.',
    decimal: '{attribute} tem de ser um número decimal.',
    minValue: '{attribute} tem de ser superior a {min}.',
    maxValue: '{attribute} tem de ser inferior a {max}.',
    minLength: '{attribute} tem de ser inferior a {min}.',
    maxLength: '{attribute} tem de ser superior a {max}.',
    rangeLength: 'Tem de ter entre {min} e {max} caracteres.',
    between: 'Tem de estar entre {min} e {max}.',
    email: '{attribute} é obrigatório.',
    sameAs: '{attribute} tem de ser igual a {eq}.',
    requiredUppercase: '{attribute} tem de incluir letras maiúsculas.',
    regex: '{attribute} não é válido.',
    allowedChars: '{attribute} só pode incluir números, letras, espaços, travessão e sublinhado.',
}
