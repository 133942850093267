<template>
    <div
        v-if="attribsData && !isLoading"
        class="row"
    >
        <div
            v-for="idx in numberAttribs"
            :key="idx"
            class="col q-pa-sm"
        >
            <CategoricalCause
                :usableAttribs="usableAttribs"
                :defaultValue="attribsUsed[idx-1]"
                :totalEvents="attribsData.totalUnfullfilling"
                :attrData="getCurrAttribData(attribsUsed[idx-1])"
                :causeIndex="idx-1"
                @changed="refreshAttributeData"
            />
        </div>
    </div>
    <div
        v-else-if="!isLoading"
        class="flex justify-center items-cemter q-ma-md"
    >
        <WText
            :size="18"
        >
            {{ $t('visualization.conformance.rootCause.noAttributes') }}
        </WText>
    </div>
    <div
        v-else
        class="row"
    >
        <div class="col">
            <QLinearProgress
                indeterminate
                color="secondary"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest,
} from '@/api'
import { filtersStorageMixin, validatorsMixin } from '@/mixins'
import CategoricalCause from './CategoricalCause.vue'

const BANNED_ATTRIBS = ['ACTIVITY', 'TRACEID', 'VariantId']

export default {
    name: 'RootCause',
    components: {
        CategoricalCause,
    },
    mixins: [filtersStorageMixin, validatorsMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        modelId: VueTypes.string,
        attribs: VueTypes.array,
    },
    data () {
        return {
            isLoading: false,
            usableAttribs: null,
            attribsUsed: null,
            numberAttribs: 0,
            defaultAttribs: null,
            attribsData: null,
        }
    },
    computed: {
    },
    watch: {
        modelId () {
            this.setRootCauseInfo()
        },
    },
    mounted () {
        this.setUpAttributes()
        this.setRootCauseInfo()
    },
    methods: {
        async fetchRootCauseInfo () {
            this.isLoading = true
            const { processId } = this
            const { modelId } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const attributes = this.attribsUsed
            apiRequest(Api().conformance.rootCause({
                datasetId: processId,
                bpmnId: modelId,
                params: {
                    attributes,
                    filters,
                    filterSets: filterSetsUUIDs,
                    operator: generalOperator,
                },
            }))
                .then((data) => {
                    this.attribsData = data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        setUpAttributes () {
            this.usableAttribs = this.attribs.filter(x => x.type === 'STRING' && !BANNED_ATTRIBS.includes(x.name))
            const relevantAttribs = this.usableAttribs.filter(x => x.relevant).slice(0, 3)
            const nonRelevantAttribs = this.usableAttribs.filter(x => !x.relevant)
            this.attribsUsed = [...relevantAttribs, ...nonRelevantAttribs].slice(0, 4)
            this.defaultAttribs = this.attribsUsed.map(x => x.name)
            this.attribsUsed = [...this.defaultAttribs]
            this.numberAttribs = this.attribsUsed.length
        },
        setRootCauseInfo () {
            this.fetchRootCauseInfo()
        },
        refreshAttributeData ({ value, idx }) {
            this.attribsUsed[idx] = value.name
            this.fetchRootCauseInfo()
        },
        getCurrAttribData (attrName) {
            const [attrData] = this.attribsData.attributesCategBool.filter(x => x.attributeName === attrName)
            return attrData
        },
    },
}
</script>
<style scoped lang="scss">
.chart {
    min-width: 300px;
    height: 400px;
    width: 100%;
}
</style>
