<!-- eslint-disable prefer-destructuring -->
<template>
    <div>
        <div
            v-if="!selectedOption"
            class="q-mx-md"
        >
            <div class="text-h4 align-center q-mb-lg">
                {{ $t('wizard.boosterWizard.chooseApproach.title') }}
            </div>
            <div class="row q-mt-xl q-gutter-xl items-stretch">
                <div class="col">
                    <q-card
                        bordered
                        class="OptionCard"
                    >
                        <q-item class="bg-primary">
                            <q-item-section>
                                <q-item-label class="text-h5 text-white align-center">
                                    {{ $t('wizard.boosterWizard.chooseApproach.automaticInsights') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-card-section
                            horizontal
                            class="items-center"
                        >
                            <q-card-section class="col-5">
                                <q-img
                                    :src="require(`@/assets/images/autoinsights.png`)"
                                    class="Image rounded-borders"
                                />
                            </q-card-section>
                            <q-card-section class="col-7">
                                <q-card-section
                                    horizontal
                                    class="q-my-md text-body1"
                                >
                                    {{ $t('wizard.boosterWizard.chooseApproach.performanceBoosterDescription') }}
                                </q-card-section>
                                <q-card-section
                                    horizontal
                                    class="text-h6 text-primary"
                                >
                                    {{ $t('wizard.boosterWizard.chooseApproach.mainFeatures') }}
                                </q-card-section>
                                <q-card-section horizontal>
                                    <q-list>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="primary"
                                                    textColor="white"
                                                    icon="travel_explore"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.procOverview') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.procOverviewDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="primary"
                                                    textColor="white"
                                                    icon="batch_prediction"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.prediction') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.predictionDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="primary"
                                                    textColor="white"
                                                    icon="query_stats"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.simulation') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.simulationDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="primary"
                                                    textColor="white"
                                                    icon="edit_note"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.d2t') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.d2tDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-card-section>
                            </q-card-section>
                        </q-card-section>
                    </q-card>
                    <div class="flex justify-center q-mt-md q-gutter-sm">
                        <q-btn
                            noCaps
                            textColor="white"
                            color="primary"
                            class="col-4 text-weight-bold"
                            @click="handleGoToInsights"
                        >
                            {{ $t('wizard.boosterWizard.chooseApproach.configureInsights') }}
                        </q-btn>
                    </div>
                </div>
                <div class="col">
                    <q-card
                        bordered
                        class="OptionCard"
                    >
                        <q-item class="bg-secondary">
                            <q-item-section>
                                <q-item-label class="text-h5 text-white align-center">
                                    {{ $t('wizard.boosterWizard.chooseApproach.customAnalysis') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-card-section
                            horizontal
                            class="items-center"
                        >
                            <q-card-section class="col-7">
                                <q-card-section
                                    horizontal
                                    class="q-my-md text-body1"
                                >
                                    {{ $t('wizard.boosterWizard.chooseApproach.customAnalysisDescription') }}
                                </q-card-section>
                                <q-card-section horizontal>
                                    <div class="text-h6 text-secondary">
                                        {{ $t('wizard.boosterWizard.chooseApproach.mainFeatures') }}
                                    </div>
                                </q-card-section>
                                <q-card-section horizontal>
                                    <q-list>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="secondary"
                                                    textColor="white"
                                                    icon="account_tree"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.process') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.processDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="secondary"
                                                    textColor="white"
                                                    icon="360"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.rework') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.reworkDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="secondary"
                                                    textColor="white"
                                                    icon="fact_check"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.conformance') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.conformanceDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section
                                                top
                                                avatar
                                            >
                                                <q-avatar
                                                    rounded
                                                    color="secondary"
                                                    textColor="white"
                                                    icon="filter_alt"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ $t('wizard.boosterWizard.chooseApproach.filter') }}</q-item-label>
                                                <q-item-label caption>
                                                    {{ $t('wizard.boosterWizard.chooseApproach.filterDesc') }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-card-section>
                            </q-card-section>
                            <q-card-section class="col-5">
                                <q-img
                                    :src="require(`@/assets/images/manual_analysis.png`)"
                                    class="Image rounded-borders"
                                />
                            </q-card-section>
                        </q-card-section>
                    </q-card>
                    <div class="flex justify-center q-mt-md q-gutter-sm">
                        <q-btn
                            noCaps
                            textColor="white"
                            color="secondary"
                            class="col-4 text-weight-bold"
                            @click="goToCustom"
                        >
                            {{ $t('wizard.boosterWizard.chooseApproach.skipToManual') }}
                        </q-btn>
                    </div>
                </div>
            </div>
        </div>
        <WizardSettings
            v-else
            :datasetId="processId"
            :projectId="projectId"
            :datasetName="name"
            :type="selectedOption"
            @back="returnToApproach"
            @proceed="goToInsights"
        />
    </div>
</template>
<script>

import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'
import WizardSettings from './WizardSettings.vue'

const DEFAULT_VISUALIZATION = 'MODEL'
const DEFAULT_INSIGHTS = 'OVERVIEW'

export default {
    name: 'WizardChooseApproach',
    components: { WizardSettings },
    data () {
        return {
            projectId: this.$route.query.projectId,
            processId: this.$route.query.processId,
            tab: this.$route.query.tab,
            name: this.$route.query.datasetName,
            selectedOption: null,
        }
    },
    mounted () {
    },
    methods: {
        handleGoToInsights () {
            this.selectedOption = 'insights'
        },
        goToCustom () {
            this.setOpened()
            this.$router.push({ name: 'ProcessVisualization', params: { subview: DEFAULT_VISUALIZATION } })
        },
        returnToApproach () {
            this.selectedOption = null
        },
        setOpened () {
            apiRequest(Api().datasets.setOpened({
                datasetId: this.processId,
            }))
                .catch(notifyError)
        },
        goToInsights () {
            this.setOpened()
            this.$router.push({ name: 'ProcessBooster', params: { subview: DEFAULT_INSIGHTS } })
        },
    },
}
</script>
<style scoped lang="scss">
    .Image {
        border: solid;
        border-color: lightgray;
    }
    .ImageSource {
        min-width: 250px;
    }
    .OptionCard {
        height: 100%;
    }
</style>
