<template>
    <div class="Loops pr-1 pl-1 pt-1">
        <div class="flex row-stretch-center flex-nowrap">
            <WText class="pr-0-5">
                {{ $t('visualization.nLoops') }}
            </WText>
            <QInput
                v-model="search"
                dense
                outlined
                type="search"
                :label="$t('actions.search')"
                :disable="disableElements"
                class="Search search-input float-left"
                @keyup.enter="handleSearchLoops(search)"
            >
                <template #append>
                    <q-icon
                        v-if="search"
                        id="clean"
                        name="close"
                        size="20px"
                        clickable
                        style="margin-right: 0.5rem;cursor: pointer;"
                        @click="handleResetSearch"
                    />
                    <q-icon
                        id="search"
                        name="search1"
                        size="20px"
                        clickable
                        style="cursor: pointer;"
                        @click="handleSearchLoops(search)"
                    />
                </template>
            </QInput>
        </div>
        <Tabs
            :value="tab"
            class="Tabs"
            :tabs="tabs"
            horizontal
            @onClick="handleChangeTab"
        />

        <QTabPanels v-model="tab">
            <QTabPanel
                class="model-tab-panel"
                :name="tab"
            >
                <div class="scroll">
                    <q-inner-loading :showing="isLoading" />
                    <div v-show="showLoops">
                        <a
                            v-for="(item) in loops"
                            :key="item.id"
                            href="#"
                            class="Bar mt-0-5"
                            :aria-disabled="disableElements ? 'true' : 'false'"
                            :class="{ selected: item.id === activeLoop, disabled: disableElements }"
                            @click.prevent="handleChangeLoop(item.id)"
                        >
                            <div class="flex row-stretch-center">
                                <WText
                                    weight="semi"
                                    :size="12"
                                >
                                    {{ item.name.replace('Loop', $t('visualization.loop')) }}
                                    <QBtn
                                        v-if="activeLoop === item.id"
                                        flat
                                        square
                                        icon="edit"
                                        size="10px"
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        padding="0px"
                                        :style="{ marginLeft: '5px'}"
                                        @click.stop="handleRenameLoop(item.id)"
                                    />
                                </WText>
                                <WText
                                    weight="semi"
                                    :size="12"
                                >
                                    {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                </WText>
                            </div>
                            <QLinearProgress
                                size="10px"
                                :value="getAbsFrequency(item.absFrequency)"
                                class="LinearProgress"
                            />
                            <QTooltip
                                class="Tooltip text-primary"
                                maxWidth="500px"
                                style="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                anchor="center right"
                                self="center left"
                            >
                                <WText
                                    color="primary"
                                >
                                    <strong>
                                        {{ item.name.replace('Loop', $t('visualization.loop')) }}
                                    </strong>: {{ $t('visualization.loopsFound') }} {{ item.absFrequency }} {{ $t('visualization.cases') }}
                                    ({{ percentage(getAbsFrequency(item.absFrequency)) }}%)
                                </WText>
                            </QTooltip>
                        </a>
                    </div>
                    <div
                        v-if="!showLoops"
                        class="flex col-center mt-2"
                    >
                        <Badge
                            icon="warning_amber"
                            :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.loops') })"
                            color="warning"
                            textColor="black"
                            class="row-center pt-0-5 pb-0-5 mb-0-5"
                        />
                    </div>
                </div>
                <div
                    v-if="showLoops"
                    class="flex row-center pt-1"
                >
                    <QPagination
                        v-model="pagination.start"
                        :max="pagination.max"
                        :directionLinks="true"
                        :maxPages="4"
                        :disable="disableElements"
                        size="11.5px"
                        :boundaryNumbers="true"
                        @update:modelValue="handleChangePagination"
                    />
                </div>
                <div class="mt-1">
                    <QBtn
                        outline
                        color="primary"
                        noCaps
                        dense
                        padding="xs"
                        class="w-full"
                        style="justify-content: center;"
                        :disable="isLoopFilterActive || disableElements || emptyLoops || !showLoops"
                        :class="{ 'active-filter': isLoopFilterActive }"
                        @click="toggleLoopFilter"
                    >
                        <QIcon
                            name="filter_alt"
                            left
                            size="15px"
                        />
                        {{ isLoopFilterActive ? $t('visualization.filterLoopApplied') : $t('visualization.filterLoop') }}
                    </QBtn>
                </div>
            </QTabPanel>
        </QTabPanels>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { Badge, Tabs } from '@/components'
import { filtersStorageMixin, filtersMixin } from '@/mixins'

const INITIAL_LOOPS_PAGINATION = { start: 0, limit: 8 }

const TABS = {
    LOOPS: 'loops',
    SELF_LOOPS: 'self-loops',
}

export default {
    name: 'Loops',
    components: {
        Badge,
        Tabs,
    },
    mixins: [filtersStorageMixin, filtersMixin],
    inject: ['App'],
    props: {
        value: VueTypes.shape({
            heatMapValue: VueTypes.string,
        }).loose,
        info: VueTypes.object,
        // filteredInfo: VueTypes.object,
        percentageMode: VueTypes.string,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        disableElements: VueTypes.bool.def(false),
    },
    emits: ['onOpenRenameLoopModal', 'checkForVariantFilter', 'filterByLoop', 'input', 'variantsLoading', 'onLoadChart'],
    data () {
        return {
            activeLoop: undefined,
            loops: undefined,
            isLoading: undefined,
            isFetching: undefined,
            search: '',
            pagination: {
                start: 1,
                max: INITIAL_LOOPS_PAGINATION.limit,
            },
            tab: TABS.LOOPS,
            loopvariants: undefined,
            isLoopFilterActive: false,
            emptyLoops: false,
        }
    },
    computed: {
        showLoops () {
            return !this.isLoading && (this.loops || []).length
        },
        tabs () {
            return [
                { id: TABS.LOOPS, label: this.$t('visualization.nLoops') },
                { id: TABS.SELF_LOOPS, label: this.$t('visualization.selfLoops') },
            ]
        },
    },
    watch: {
        isFetching: 'emitVariantsLoading',
        isLoading: 'emitVariantsLoading',
    },
    mounted () {
        this.initializeLoops()
    },
    methods: {
        initializeLoops () {
            this.checkForLoopFilter()
            if (this.processId) this.getLoops()
        },
        handleRenameLoop (loopId) {
            this.$emit('onOpenRenameLoopModal', { loopId })
        },
        checkForLoopFilter () {
            this.$eventBus.emit('checkForVariantFilter')
            this.$eventBus.on('hasLoopFilter', (variantFilter) => {
                this.isLoopFilterActive = variantFilter
            })
        },
        toggleLoopFilter () {
            if (!this.isLoopFilterActive) {
                this.filterByLoop()
            }
        },
        filterByLoop () {
            const formattedValue = (this.$t('visualization.filterLoopText') + this.activeLoop)
            this.$eventBus.emit('filterByLoop', { variant: this.loopvariants, loopName: formattedValue })
        },
        handleDirectionChange (value) {
            this.directionValue = value
            this.handleInput('directionValue', { value: value ? 'LR' : 'TB' })
        },
        handleInput (key, { value }) {
            this.$emit('input', { ...(this.value || {}), [key]: value })
        },
        handleChangeTab (tab) {
            this.pagination.start = 1
            this.tab = tab
            this.getLoops()
        },
        handleChangeLoop (id) {
            this.activeLoop = id
            this.getLoop(id)
        },
        normalizeLoops (loops) {
            return (loops || []).map(loop => ({ ...loop, value: loop.relativeFrequency }))
        },
        async renameLoop ({ newName, loopRenamedId }) {
            this.isVariantsLoading = true
            const { processId } = this
            const loopId = this.loops.find(v => v.id === loopRenamedId).id
            const params = { name: newName }
            apiRequest(Api().visualizations.renameLoop({ processId, loopId, params }))
                .then(() => {
                    this.handleResetSearch()
                    notifySuccess(this.$t('visualization.loopRenameOK'))
                })
                .catch((e) => {
                    notifyError(e)
                })
                .finally(() => (this.isVariantsLoading = false))
        },
        async getLoops (param) {
            const self = this.tab === TABS.SELF_LOOPS
            this.isLoading = true
            const params = this.normalizeParams(param, self)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(params.filters)
            params.filters = filters
            params.filterSets = filterSetsUUIDs
            params.operator = generalOperator
            apiRequest(Api().visualizations.loops({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    const [first] = data
                    this.loops = this.normalizeLoops(data)
                    if (data.length === 0) {
                        const emptyLoop = {
                            name: null,
                            activities: [],
                            arcs: [],
                            frequency: 0,
                            duration: {
                                avg: 0,
                                sd: 0,
                                max: 0,
                                min: 0,
                                med: 0,
                            },
                        }
                        this.$emit('onLoadChart', { ...emptyLoop, loopId: "" })
                    } else {
                        this.pagination.max = this.normalizePaginationMax(total)
                        if (first) this.handleChangeLoop(first.id)
                        // else if (data.length === 0) {
                        //     this.emptyLoops = true
                        //     this.$emit('onLoadChart', { variant: [], variantId: [this.processId] })
                        // } else this.$emit('onLoadChart')
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        normalizePaginationMax (value) {
            const max = Math.round(value / INITIAL_LOOPS_PAGINATION.limit)
            return max || 1
        },
        handleChangePagination (start) {
            this.getLoops({ start: start - 1, search: this.search || '' })
        },
        getAbsFrequency (absFrequency) {
            const { cases } = this.info
            return cases ? absFrequency / cases : 0
        },
        normalizeParams (param, self) {
            const filters = this.visualizationFilters
            return {
                filters,
                ...INITIAL_LOOPS_PAGINATION,
                start: this.pagination.start - 1,
                ...(self ? { self } : {}),
                ...(typeof param === 'string' ? {} : param),
            }
        },
        handleResetSearch () {
            this.search = ''
            this.pagination.start = 1
            this.getLoops({ search: this.search, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleSearchLoops (search) {
            this.pagination.start = 1
            this.getLoops({ search, start: INITIAL_LOOPS_PAGINATION.start })
        },
        emitVariantsLoading () {
            if (!this.isFetching && !this.isLoading) {
                this.$emit('variantsLoading', false)
            } else {
                this.$emit('variantsLoading', true)
            }
        },
        async getLoop (loopId) {
            console.log("getLoop")
            this.isFetching = true
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = { filters, filterSets: filterSetsUUIDs, operator: generalOperator }
            apiRequest(Api().visualizations.loop({ processId: this.processId, loopId, params }))
                .then(({ data }) => {
                    const [variant] = data || []
                    this.loopvariants = variant.compliantVariants
                    console.log("loopvariant", variant, "loopId", loopId)
                    this.$emit('onLoadChart', { ...variant, loopId })
                })
                .catch(notifyError)
                .finally(() => {
                    this.isFetching = false
                })
        },
    },
}
</script>
<style scoped lang="scss">
.scroll {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.Tabs {
    margin-top: 10px;
}

.Bar {
    display: block;

    &:hover, &.selected {
        .WText { color: $black; }
        .LinearProgress { color: $accent; }
    }

    .WText {
        color: $gray-02;
        margin-bottom: 5px;
    }

    .LinearProgress {
        color: $secondary;
        border-radius: $border-radius;
        transition: $transition;
    }
}

.Stats{
    flex-wrap: nowrap;
}
.Tabs {
    margin-bottom: 15px;
    &:deep() {
        .Container {
            padding: 0;
        }
        .Tab {
            min-height: auto;
        }
    }
}
</style>
