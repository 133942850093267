<template>
    <Transition
        name="fade"
        mode="out-in"
        appear
    >
        <div
            class="SettingsModal"
        >
            <div class="row justify-between items-center q-pr-md">
                <WText
                    :size="20"
                    class="mb-1"
                >
                    {{ $t('projects.datasets.settings.title') }}:
                    <span class="titleName">
                        {{ datasetInfo.name }}
                    </span>
                </WText>
                <QBtn
                    square
                    icon="arrow_back"
                    color="primary"
                    @click="closeSettings"
                >
                    <QTooltip
                        anchor="center left"
                        self="center right"
                    >
                        {{ $t('projects.datasets.settings.back') }}
                    </QTooltip>
                </QBtn>
            </div>
            <QTabs
                v-model="tab"
                dense
                align="left"
                class="text-primary"
            >
                <QTab
                    name="GENERAL"
                    noCaps
                    :label="$t('projects.datasets.settings.tabs.general')"
                />
                <QTab
                    name="RT_UPDATES"
                    noCaps
                    :label="$t('projects.datasets.settings.tabs.updates')"
                />
                <QTab
                    name="ERRORS"
                    noCaps
                    :label="errorsName"
                />
            </QTabs>
            <QTabPanels
                v-model="tab"
                animated
                transitionNext="fade"
                transitionPrev="fade"
            >
                <QTabPanel name="GENERAL">
                    <GeneralSettings
                        :datasetInfo="datasetInfo"
                        @UpdateDataset="handleUpdateDataset"
                    />
                </QTabPanel>
                <QTabPanel name="RT_UPDATES">
                    <Updates
                        :datasetInfo="datasetInfo"
                        @SetUpdates="handleSetRealTimeSettings"
                    />
                </QTabPanel>
                <QTabPanel name="ERRORS">
                    <Errors
                        :datasetInfo="datasetInfo"
                    />
                </QTabPanel>
            </QTabPanels>
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'
import { GeneralSettings, Updates, Errors } from './settings'

const DEFAULT_TAB = 'GENERAL'

export default {
    name: 'SettingsModal',
    components: {
        GeneralSettings,
        Updates,
        Errors,
    },
    props: {
        isOpen: VueTypes.bool.def(false),
        datasetInfo: VueTypes.any,
    },
    emits: ['CloseSettings', 'UpdateDataset', 'SetUpdates'],
    data () {
        return {
            tab: DEFAULT_TAB,
        }
    },
    computed: {
        errorsName () {
            if ((this.datasetInfo.state.identifier === 'ERROR') || (this.datasetInfo.state.identifier === 'SUBMITTED' && !this.datasetInfo.uploaded)) {
                let errorLength = 1
                if (this.datasetInfo.errors) errorLength = this.datasetInfo.errors.length
                return `${this.$t('projects.datasets.settings.tabs.errors')} (${errorLength})`
            }
            return this.$t('projects.datasets.settings.tabs.errors')
        },
    },
    methods: {
        handleUpdateDataset (datasetInfo) {
            this.$emit('UpdateDataset', datasetInfo)
        },
        handleSetRealTimeSettings (settings) {
            this.$emit('SetUpdates', settings)
        },
        closeSettings () {
            this.$emit('CloseSettings')
        },
    },
}
</script>

<style scoped lang="scss">
.titleName {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $primary;
  font-size: 18px;
  white-space: nowrap;
  font-weight: bold;
}
</style>
