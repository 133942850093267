export default {
    model: 'Modelo',
    modelLabel: 'Número de variantes',
    availableVariants: 'Variantes disponibles',
    variants: 'Variantes',
    editVariants: 'Introduce número de variantes a agregar',
    variant: 'Variante',
    min: 'min',
    max: 'max',
    patterns: 'Patrones',
    loops: 'Bucles',
    loop: 'Bucle',
    nLoops: 'Bucles',
    selfLoops: 'Repeticiones',
    cases: 'trazas',
    loopsFound: 'Aparece en',
    options: 'Opciones',
    percentageTotal: 'Mostrar % total',
    percentageFiltered: 'Mostrar % filtrado',
    changePercentage: 'Cambiar referencia de %',
    showLoopMetrics: 'Mostrar métricas de bucle',
    changeGraphDirection: 'Cambiar dirección de grafo',
    changeGraphMetric: 'Cambiar métrica de duración',
    centerChart: 'Centrar grafo',
    cancel: 'Cancelar',
    filterVariants: 'Filtrar variante mostrada',
    filterVariantsApplied: 'Filtro de variantes ya aplicado',
    filterLoop: 'Filtrar bucle mostrado',
    filterLoopApplied: 'Filtro de bucle ya aplicado',
    filterLoopText: 'Filtrar por el bucle ',
    resetMultipleVariants: 'Restaurar variantes seleccionadas',
    aggregate: 'Agregación',
    individual: 'Individual',
    exportImageFormat: 'Exportar a formato de imagen',
    exportLoadingDialog: 'Exportando datos, por favor espere. Formato : ',

    pending: 'Pendiente',
    soon: 'Próximamente',

    display: 'Indicador',
    stats: 'Estadísticas',
    kPIs: 'KPIs',
    heatMap: 'Mapa de calor',
    direction: 'Cambiar dirección de grafo',
    directionInfo: 'Cambia la disposición del grafo entre vertical u horizontal',

    frequencies: 'frecuencia',
    freq: 'frec',
    durations: 'duración',
    both: 'ambos',
    kPI: 'KPI',
    averages: 'media',
    variances: 'desviación',
    median: 'mediana',
    off: 'off',

    resetOptions: 'Restaurar opciones',
    centerView: 'Centrar vista',
    resetHidePanelLocation: 'Resetear o esconder paneles',
    modelMetricsToggle: 'Métricas de modelo',
    customPlotPanelsToggle: 'Información de atributos',
    resetPanelLocation: 'Restaurar ubicación',
    loopMetricsToggle: 'Métricas del bucle',
    zoomer: {
        fab: 'Editar actividades',
        edit: 'Editar actividades visualizadas',
        create: 'Crear conjunto de datos a partir de la configuración actual',
        reset: 'Restaurar actividades visualizadas',
        addGroup: 'Añadir agrupación',
        removeGroup: 'Eliminar',
        createGroup: 'Crear grupo',
        goBack: 'Volver',
        apply: 'Aplicar cambios',
        groupTab: 'Agrupar actividades',
        removeTab: 'Eliminar actividades',
        labelGroup: 'Actividad agrupada',
        labelActivities: 'Actividades incluidas',
        labelGroupName: 'Nombre de la actividad agrupada',
        selectActivities: 'Seleccionar actividades',
        groupName: 'Define un nombre para el grupo',
        datasetName: 'Define un nombre para el nuevo conjunto de datos',
        labelDatasetName: 'Nombre del nuevo conjunto de datos',
        datasetTitle: 'Nuevo conjunto de datos',
        savedDatasetNotification: 'Nuevo conjunto de datos creado',
        zoomerConfigCreatedNotification: 'Configuración de actividades actualizada',
        restoreConfigNotification: 'Configuración de actividades restauada',
        loopsNotAvailable: 'Bucles no disponibles con actividades agrupadas aplicadas. Puedes crear un nuevo conjunto de datos para verlos.',
        createDatasetError: 'Configuración de agrupamiento no establecida',
        info: 'Esta función permite la agregación visual o eliminación de actividades para que los usuarios puedan previsualizar versiones simplificadas del proceso ' +
        'y solo tiene efecto en la vista del Proceso. Otras vistas no se ven afectadas por esta configuración. Si se requiere que estos cambios persistan ' +
        'y transformen el proceso, utilice la opción "Crear conjunto de datos a partir de la edición actual" en el menú de edición de actividades.',
        labelInfo: 'Información sobre cómo funciona Zoomer',
        indicator: {
            activated: 'La vista de proceso está siendo afectada por la agregación/eliminación de actividades. Haz clic izquierdo para desactivar.',
            disabled: 'Haz clic izquierdo para reactivar la agregación/eliminación de actividades en la vista de proceso.',
        },

    },
    tabs: {
        process: 'Proceso',
        variants: 'Variantes',
        loops: 'Bucles',
        metrics: 'Métricas',
        traces: 'Trazas',
        conformance: 'Conformidad',
        predictions: 'Predicciones',
        contextComparison: 'Comparativas',
        settings: 'Configuración',
        assistant: 'Asistente IA',
        tooltip: {
            process: 'Descubre cómo se comporta tu proceso',
            variants: 'Explora los distintos caminos de tu proceso',
            loops: 'Inspecciona trabajo redundante potencial y su impacto',
            metrics: 'Visualiza y exporta métricas sobre el rendimiento del proceso',
            traces: 'Verifica tus análisis sobre los datos originales',
            conformance: 'Verifica el cumplimiento de modelos de proceso predefinidos',
            predictions: 'Descubre cómo se comportará tu proceso',
            contextComparison: 'Compara y exporta contextos de análisis',
            settings: 'Configura los parámetros del proceso',
            assistant: 'Un asistente de IA para ayudarte a entender tus datos',
        },
    },
    bpmnSave: 'Guardar como modelo BPMN',
    bpmnOK: 'El modelo ha sido generado correctamente',
    bpmnNotShowable: 'El modelo no contiene información de renderizado',
    bpmnModal: {
        title: 'Generar modelo BPMN',
        name: 'Nombre del modelo',
        submit: 'Guardar modelo',
    },
    help: `
        <strong>Prevenir la vista panorámica y el zoom del gráfico en el desplazamiento por la pantalla</strong><br><br>
        La acción de desplazamiento puede hacer que el gráfico se amplíe sin querer.<br><br>
        El sitio web a continuación permite que la página se desplace normalmente, sin afectar el gráfico.
        Los usuarios pueden ampliar el gráfico haciendo clic en los controles de zoom.<br><br>
        También pueden hacer zoom y desplazarse mediante movimientos de dos dedos en el gráfico para dispositivos con pantalla táctil.
    `,
    downloadPng: {
        label: 'Descargar grafo',
        button: `<strong>Descargar PNG</strong><br><br>
            Asegúrate de que el grafo esté centrado para poder descargar la imagen correctamente.
        `,
    },
    notifications: {
        notFound: {
            text: 'No se han encontrado {type}',
            btn: 'Reintentar',
        },
    },
    graph: {
        START: 'INICIO',
        END: 'FIN',
    },
    renameVariantModal: {
        title: 'Renombrar variante',
        name: 'Nuevo nombre de la variante',
        submit: 'Renombrar',
    },
    renameLoopModal: {
        title: 'Renombrar bucle',
        name: 'Nuevo nombre del bucle',
        submit: 'Renombrar',
    },
    variantRenameOK: 'La variante ha sido renombrada correctamente',
    variantRenameRequired: 'Nombre requerido',
    variantRenameLengthError: 'El nombre debe tener entre 1 y 100 caracteres',
    loopRenameOK: 'El bucle ha sido renombrado correctamente',
    dashboard: {
        variants: 'Variantes',
        variantsOver: 'Por frecuencia versus duración media',
        activities: 'Actividades',
        transitions: 'Transiciones',
        download: 'Exportar métricas',
        notifications: {
            download: {
                text: 'Métricas exportadas correctamente.',
                powerbi: 'Archivo PBIX obtenido correctamente.',
            },
        },
        sortBy: {
            FREQUENCY: 'Por frecuencia',
            DURATION_AVG: 'Por mayor duración media',
            DURATION_MAX: 'Por mayor duración máxima',
            DURATION_MIN: 'Por menor duración mínima',
            DURATION_STD: 'Por mayor desviación en duración',
        },
        heatmap: {
            source: 'Fuente',
            target: 'Destino',
            value: 'Valor',
        },
        axisLabels: {
            frequency: 'Frecuencia',
            duration: 'Duración',
            variantId: 'Variante',
            activityId: 'Actividad',
        },
        oneVariantFrequency: 'Mostrando la variante más frecuente',
        multipleVariantFrequency: 'Mostrando las {variants} variantes más frecuentes',
        oneVariantAggregated: 'Mostrando 1 variante agregada',
        multipleVariantAggregated: 'Mostrando {variants} variantes agregadas',
    },
    traces: {
        table: {
            total: '{rowsNumber} eventos de {filteredTraces} casos',
            totalDesc: 'encontrados en base a los filtros actuales',
            search: {
                placeholder: 'Filtrar por ID de caso',
            },
        },
        toPlot: 'Vista gráfica',
        toTable: 'Vista de tabla',
        toDataSet: 'Crear nuevo conjunto de datos',
        createDataSet: 'Crear un nuevo conjunto de datos a partir de los filtros',
        cancel: 'Cancelar',
        create: 'Crear',
        nameRequired: 'Debe introducirse un nombre para el nuevo conjunto de datos',
        nameLength: 'El nombre debe constar de 3 o más caracteres',
        creatingDataset: 'Creando conjunto de datos...',
        datasetCreated: 'Se ha creado un nuevo conjunto de datos a partir de los filtros actuales',
        downloadCSV: 'Exportar a CSV',
        downloadStarted: 'Descarga iniciada',
        downloadOK: 'Descarga finalizada',
        exportDialog: {
            title: 'Exportar datos de eventos a CSV',
            subtitle: 'Selecciona atributos incluidos',
            name: 'Nombre de atributo',
            type: 'Tipo de datos',
            attribute: 'atributo',
            selected: 'de',
            export: 'Generar archivo CSV',
        },
        plot: {
            eventsShown: 'Mostrando eventos',
            eventsTo: 'a',
            start: 'Desde:',
            end: 'Hasta:',
            noEvents: 'No hay eventos para mostrar',
        },
    },
    filters: {
        labels: {
            TRACE_STARTS_BEFORE: 'EMPIEZA ANTES DE',
            TRACE_ENDS_BEFORE: 'FINALIZA ANTES DE',
            TRACE_STARTS_AFTER: 'EMPIEZA TRAS',
            TRACE_ENDS_AFTER: 'FINALIZA TRAS',
            TRACE_STARTS_WITH_ACTIVITY: 'EMPIEZA CON',
            TRACE_ENDS_WITH_ACTIVITY: 'FINALIZA CON',
            TRACE_CONTAINS_ACTIVITIES: 'CONTIENE ACTIVIDADES',
            TRACE_NOT_CONTAINS_ACTIVITIES: 'NO CONTIENE ACTIVIDADES',
            TRACE_CONTAINS_ARCS: 'CONTIENE ARCOS',
            TRACE_NOT_CONTAINS_ARCS: 'NO CONTIENE ARCOS',
            TRACE_DURATION_GREATER_THAN: 'TRAZA DURA MÁS QUE',
            TRACE_DURATION_LESS_THAN: 'TRAZA DURA MENOS QUE',
            ARC_DURATION_GREATER_THAN: 'ARCO DURA MÁS QUE',
            ARC_DURATION_LESSER_THAN: 'ARCO DURA MENOS QUE',
            ACTIVITY_DURATION_GREATER_THAN: 'ACTIVIDAD DURA MÁS QUE',
            ACTIVITY_DURATION_LESS_THAN: 'ACTIVIDAD DURA MENOS QUE',
            ACTIVITIES_DURATION_GREATER_THAN: 'DURACIÓN ENTRE ACTIVIDADES DURA MÁS QUE',
            ACTIVITIES_DURATION_LESS_THAN: 'DURACIÓN ENTRE ACTIVIDADES DURA MENOS QUE',
            ACTIVITIES_DURATION_LOOP_GREATER_THAN: 'DURACIÓN ENTRE ACTIVIDADES CONSECUTIVAS DURA MÁS QUE',
            ACTIVITIES_DURATION_LOOP_LESS_THAN: 'DURACIÓN ENTRE ACTIVIDADES CONSECUTIVAS DURA MENOS QUE',
            TRACE_IS_IN_VARIANTS: 'ESTÁ EN LAS VARIANTES',
            TRACE_IS_IN_LOOP_VARIANTS: 'CONTIENE EL BUCLE',
            TRACE_IS_IN_N_VARIANTS: 'ESTÁ EN LAS N VARIANTES MÁS FRECUENTES',
        },
        analysisContext: 'Contexto analítico',
        modelMetrics: 'Métricas del modelo',
        contextual: 'Análisis',
        total: 'Filtros ({total})',
        title: 'Elija un filtro para agregar',
        titleOpenFilter: 'Configurar filtro seleccionado',
        manage: 'Añadir',
        sets: 'Sets',
        cancel: 'Cerrar',
        reset: 'Vaciar',
        empty: 'No se ha añadido ningún filtro',
        emptyModel: 'No hay trazas disponibles para los filtros seleccionados',
        sidebarTooltips: {
            durationMetric: "Elegir métrica de duración",
            addFilter: "Añadir filtro",
            resetActiveFilters: "Restablecer filtros activos",
            manageFilterSets: "Gestionar conjuntos de filtros",
            createFilterSet: "Crear conjunto de filtros",
            changeFilterAggregation: "Cambiar operador de agregación del filtro",
        },
        activities: {
            title: 'Actividades',
            label: 'Selecciona una actividad',
            activityOccurrence: 'Por orden de ocurrencia de actividad',
            activityOccurrenceDescription: `En caso de haber bucle en alguna traza, filtraría teniendo en cuenta la duración desde la primera/última ocurrencia
                de la primera actividad a la primera/última ocurrencia de la segunda actividad dentro de la traza.`,
            pairRepetition: 'Por repetición de pares',
            pairRepetitionDescription: `En caso de haber bucle en alguna traza, filtraría aquellas en las que la duración de todas las secuencias entre actividades
                cumplan el requisito.`,
        },
        arcs: {
            title: 'Arcos',
            label: 'Selecciona un arco',
        },
        constraints: {
            title: 'Restricción',
            label: 'Selecciona una restricción',
        },
        date: {
            title: 'Fecha y hora',
            label: 'Date',
            noData: 'Sin datos',
            max: {
                label: '{max}',
                tooltip: 'Fecha máxima de finalización',
            },
            min: {
                label: '{min}',
                tooltip: 'Fecha mínima de finalización',
            },
        },
        activitiesDuration: {
            occurrenceLabel: 'Selecciona ocurrencia',
        },
        duration: {
            title: 'Duración',
            label: 'Duración',
            avgTooltip: 'Avg: {d}d {h}h {m}m {s}s',
            avg: '{d}d {h}h {m}m {s}s',
            min: 'Min: {d}d {h}h {m}m {s}s',
            max: 'Max: {d}d {h}h {m}m {s}s',
            sd: 'Dev: {d}d {h}h {m}m {s}s',
            duration: '{d}d {h}h',
            secondDuration: '{h}h {m}m',
            minorDuration: '{m}m {s}s',
            filters: {
                trace: 'Duración de traza',
                activity: 'Duración de actividad',
                arc: 'Duración de arco',
                activities: 'Duración entre actividades',
            },
        },
        attributes: {
            title: 'Atributos',
            label: 'Atributos',
            value: {
                label: 'Valor',
            },
            categoryAny: 'Cualquiera',
            attrIs: 'ES',
            attrIsNot: 'NO ES',
            attrIsTrueLabel: 'ES VERDADERO',
            attrIsFalseLabel: 'ES FALSO',
            attrNumEqualsLabel: '=',
            attrNumNotEqualsLabel: '!=',
            attrNumGreaterThanLabel: 'MAYOR QUE',
            attrNumLesserThanLabel: 'MENOR QUE',
            attrNumBetweenLabel: 'ENTRE',
            attrBetweenLabel: 'ENTRE',
            attrDateAfterLabel: 'DESPUÉS DE',
            attrDateBeforeLabel: 'ANTES DE',
            attrDateBetweenLabel: 'ENTRE',
            attrGreaterLabel: 'MAYOR QUE',
            attrLesserLabel: 'MENOR QUE',
            attrFirstOccurrenceLabel: 'PRIMERA',
            attrLastOccurrenceLabel: 'ÚLTIMA',
            filters: {
                numericalField: 'Numérico',
                ctgField: 'Categórico / Textual',
                booleanField: 'Booleano',
                dateTimeField: 'Fecha / Hora',
            },
            table: {
                title: 'Atributos seleccionados',
                activity: 'Actividad',
                attribute: 'Atributo',
                constraints: 'Restricciones',
                value: 'Valor',
                delete: '',
            },
        },
        save: 'Guardar',
        add: 'Añadir',
        totalProgress: '{value} de {total}',
        progress: [
            { id: 'cases', label: 'Trazas' },
            { id: 'variants', label: 'Variantes' },
            { id: 'activities', label: 'Actividades' },
            { id: 'transitions', label: 'Transiciones' },
        ],
        contextualInfo: {
            total: 'Total',
            filtered: 'Filtrados',
            displayed: 'Visualizados',
            cases: 'Casos',
            variants: 'Variantes',
            duration: 'Duración',
            med: 'Mediana (med)',
            avg: 'Media (avg)',
            max: 'Máximo (max)',
            min: 'Mínimo (min)',
            sd: 'Desviación típica (sd)',
            maxEndDateTime: 'Fecha final',
            minStartDateTime: 'Fecha inicial',
            estimatedCost: 'Coste estimado',
            totalEstimatedCost: 'Coste total estimado',
            expandLess: 'Ocultar',
            expandMore: 'Mostrar',
        },
        filterSets: {
            title: 'Conjunto de filtros',
            name: 'Conjunto de filtros',
            createNewFilterSet: 'Crear conjunto de filtros',
            register: {
                success: 'Set de filtros registrado correctamente',
            },
            delete: {
                success: 'Set de filtros borrado correctamente',
                title: 'Borrar set de filtros',
                description: '¿Estás seguro de que quieres eliminar este set de filtros?',
            },
            search: {
                title: 'Añadir set de filtros',
                placeholder: 'Introduce nombre de nuevo set',
            },
            notifications: {
                noResult: {
                    title: 'No se ha encontrado ningún set de filtros',
                    noFilters: 'El set seleccionado no tiene filtros',
                },
            },
            config: {
                title: 'Configuración del sistema de filtros',
                aggregationOperators: {
                    title: 'Operador de agregación',
                    AND: 'AND',
                    OR: 'OR',
                },
                filterSetsTable: {
                    load: 'Cargar',
                    loadAsFilterSet: 'Como conjunto de filtros',
                    loadAsFilters: 'Como filtros individuales',
                    title: 'Filter sets',
                    new: 'Nuevo filter set',
                    addToActive: 'Agregar a los filtros activos',
                    replaceActive: 'Reemplazar filtros activos',
                    removeSelected: 'Eliminar seleccionado',
                    filterName: 'Nombre',
                    operator: 'Operador',
                    filtersIncluded: 'Filtros incluidos',
                    filters: 'filtros',
                    filter: 'filtro',
                },
            },
        },
    },
    loopMetrics: {
        metricsTitle: 'Métricas del bucle',
        avgTime: 'Duración media',
        avgCost: 'Coste medio',
        percentTime: '% medio sobre la duración',
        estTotalEffort: 'Esfuerzo total estimado',
        afterRemoval: 'Mejora sin bucle',
        avgCostConf: 'Coste medio/h',
        currency: 'Moneda',
    },
    customPlotPanels: {
        frequencyAndDuration: 'Frecuencia y duración',
        attributeInfo: 'Información de atributos',
        frequency: 'Frecuencia',
        duration: 'Duración',
        frequencyDuration: {
            selectDuration: 'Unidad de tiempo',
            selectRange: 'Rango',
        },
        attributesInfo: {
            selectAttribute: 'Seleccione un atributo',
            sortBy: 'Ordenar por:',
            start: 'inicio',
            end: 'fin',
            month: 'mes',
            week: 'semana',
            day: 'día',
            eventAttribute: 'Desglosar por actividad',
            selectActivity: 'Seleccione una actividad',
            activityRequired: 'Seleccione al menos una actividad',
            noData: 'No existen actividades que contengan este atributo',
            selectUnit: 'Seleccione una unidad',
        },
    },
    conformance: {
        chooseBpmn: 'Selecciona un modelo BPMN',
        overview: 'Cumplimiento',
        issues: 'Desglose de incumplimientos',
        root: 'Causa raíz',
        noModels: 'No se han encontrado modelos BPMN dentro del proyecto actual. ' +
            'Es necesario disponer de al menos un modelo BPMN para analizar la conformidad.',
        noModelsUpload: 'Puedes crear un modelo a partir de cualquier visualización del grafo en la pestaña de Proceso, o bien subir uno.',
        fulfillment: {
            fulfillment: 'Cumplimiento',
            percentageTitle: 'Proporción de cumplimiento de modelo',
            correct: 'Contemplado',
            incorrect: 'No contemplado',
            percentageTimeTitle: 'Proporción de cumplimiento a lo largo del tiempo',
            validPercentageTime: '% casos contemplados',
            invalidPercentageTime: '% casos no contemplados',
            days: 'días',
            weeks: 'semanas',
            months: 'meses',
            selectUnit: 'Selecciona unidad de tiempo',
        },
        issuesView: {
            transitionsTitle: 'Transiciones no contempladas',
            startTitle: 'Actividades de inicio no contempladas',
            endTitle: 'Actividades de fin no contempladas',
            case: 'caso',
            cases: 'casos',
            outOf: 'de',
            noResultsTransitions: 'No se encontraron transiciones no contempladas',
            noResultsStart: 'No se encontraron actividades de inicio no contempladas',
            noResultsEnd: 'No se encontraron actividades de fin no contempladas',
        },
        rootCause: {
            noAttributes: 'No hay atributos disponibles en este set de datos',
        },
    },
    settings: {
        name: 'Configuración',
        description: 'Configura los parámetros del proceso de tu conjunto de datos',
        pendingChanges: 'Hay cambios pendientes de guardar',
        type: {
            general: 'General',
            insights: 'Análisis',
        },
        number: 'Valor',
        unit: 'Unidad',
        caseCompletion: {
            name: 'Condiciones de finalización de casos',
            endingActivities: 'Actividades que finalizan',
            occurringActivities: 'Actividades que ocurren',
            startingActivities: 'Actividades que empiezan',
        },
        costs: {
            name: 'Costo y divisa',
            unit: 'Divisa',
            value: 'Valor',
            units: {
                euro: 'Euro (€)',
                dollar: 'Dólar ($)',
                pound: 'Libra (£)',
            },
            activities: 'Actividades',
            avgCostHour: 'Costo Promedio/Hora',
            general: 'General',
            activityCosts: 'Costos de Actividades',
            duplicateError: 'Una o más actividades seleccionadas ya tienen costos asignados.',
            addCost: 'Agregar Costo',
            removeCost: 'Eliminar Costo',
            noData: 'No hay datos disponibles',
            ActivityCostModal: {
                editActivityCost: 'Editar Costo de Actividad',
                addActivityCost: 'Agregar Costo de Actividad',
                selectActivities: 'Seleccionar Actividades',
                costValue: 'Valor del Costo',
                add: 'Agregar',
                save: 'Guardar',
                positiveNumberError: 'El valor del costo debe ser un número positivo',
                selectAtLeastOneActivity: 'Por favor, seleccione al menos una actividad.',
            },
        },
        sla: {
            name: 'Duración objetivo',
            number: 'Número',
            unit: 'Unidad de tiempo',
            units: {
                minutes: 'minutos',
                hours: 'horas',
                days: 'días',
                weeks: 'semanas',
                months: 'meses',
            },
            globalSla: {
                tab: 'Global',
                enableGlobal: 'Habilitar duración objetivo global',
                compliance: 'Cumple con la duración objetivo',
                nonCompliance: 'Excede la duración objetivo',
                cases: 'casos',
                name: 'Duración objetivo del proceso',
                description: 'Determina cuándo un caso debería ser considerado como aceptable según el valor de duración objetivo definido a continuación. ' +
                'Aquellos casos con menor duración que este umbral se considera que cumplen la duración objetivo del proceso:',
                backButton: 'Volver',
                minCase: 'Duración más corta',
                maxCase: 'Duración más larga',
            },
            caseSla: 'Duración objetivo de traza',
            customSla: 'Duraciones objetivo personalizadas',
            addSla: 'Añadir duración objetivo',
            activitySla: 'Duración objetivo de actividad',
            transitionSla: 'Duración objetivo de transición',
            activityGapSla: 'Duración objetivo de actividades no contiguas',
            removeSla: 'Eliminar',
            add: 'Añadir',
            edit: 'Editar',
            close: 'Cerrar',
            variantsSlaPresent: 'Duraciones objetivo de variante adicionales',
            customSlaPresent: 'Duraciones objetivo personalizadas adicionales',
            variantsAndCustomSlaPresent: 'Duraciones objetivo personalizadas y de variante adicionales',
            variantSlaModal: {
                title: 'Añadir duración objetivo de variante',
                selectVariant: 'Selecciona una variante',
                all: 'Todas',
            },
            activitySlaModal: {
                addTitle: 'Añadir duración objetivo de actividad',
                editTitle: 'Editar duración objetivo de actividad',
                selectActivity: 'Seleccione una actividad',
            },
            transitionSlaModal: {
                addTitle: 'Añadir duración objetivo de transición',
                editTitle: 'Editar duración objetivo de transición',
                selectTransition: 'Seleccione una transición',
            },
            activityGapSlaModal: {
                addTitle: 'Añadir duración objetivo entre actividades no adyacentes',
                editTitle: 'Editar duración objetivo entre actividades no adyacentes',
                selectFirstActivity: 'Seleccione la primera actividad',
                selectSecondActivity: 'Seleccione la segunda actividad',
                firstToLast: 'Primera a última',
                consecutiveLoop: 'Por ocurrencia',
                sourceOcurrence: 'Ocurrencia de la primera actividad',
                targetOccurrence: 'Ocurrencia de la segunda actividad',
                first: 'Primera',
                last: 'Última',
                firstToLastHelp: 'Primera a última: Comprueba que se cumpla la duración objtivo desde la primera/última ocurrencia de la primera actividad ' +
                'hasta la primera/última ocurrencia de la segunda actividad dentro de la traza.',
                consecutiveLoopHelp: 'Por ocurrencia: En caso de haber bucles en alguna traza, comprueba que se cumpla la duración objetivo en' +
                'todas las secuencias formadas por las actividades seleccionadas',
            },
            customSlaTable: {
                type: 'Tipo',
                elements: 'Elementos',
                sla: 'Duración objetivo',
                notifyDuplicated: 'No se ha podido añadir la nueva duración objetivo debido a que existe ya una con los mismo parámetros',
                loopMode: 'Modo ocurrencia',
                sourceOccurrence: 'Origen',
                targetOccurrence: 'Final',
            },
        },
        predictionAlgorithm: {
            name: 'Algoritmos de predicción',
            chooseAlgorithm: 'Elije el algoritmo de predicción',
            algorithms: {
                standard: {
                    name: 'Standard',
                    description: 'Método estadístico más rápido. Ofrece una buena precisión general, pero no está adaptado para cada conjunto de datos individual.',
                },
                machineLearning: {
                    name: 'Machine Learning',
                    description: 'El método más preciso.' +
                    ' Los modelos neuronales se entrenan en función del conjunto de datos actual, proporcionando los mejores resultados generales.' +
                    ' Dependiendo del tamaño del conjunto de datos, los modelos pueden tardar un tiempo en estar listos para su uso.',
                    caseDurationCheck: 'Duración del caso y cumplimiento de duración objetivo',
                    activitySequenceCheck: 'Pronóstico de secuencia de actividades',
                    trainingModel: 'Modelo de entrenamiento',
                    ready: 'Listo',
                    notReady: 'No listo',
                },
            },
            disabled: 'Es necesario definir tanto las condiciones de completitud de traza como la duración objetivo para poder configurar el algoritmo de predicción',
        },
        relevantAttributes: {
            name: 'Atributos Relevantes',
            attributes: 'Atributos',
            maxAttributes: '3',
            chooseRelevant: 'Elige atributos relevantes',
            noRelevant: 'Sin atributos relevantes configurados',
            noSelectableAttributes: 'Sin atributos categóricos de los que elegir',
            disabled: 'No existen atributos válidos que permitan configurar los atributos relevantes',
        },
        saveSettings: 'Guardar configuración',
        settingsSaved: 'Guardado correctamente',
        caseCompletionConditionsNoConf: 'Sin condiciones configuradas',
        caseCompletionConditionsConf: 'Condiciones configuradas',
        startingActivities: 'Actividades de inicio',
        occurringActivities: 'Actividades en curso',
        endingActivities: 'Actividades finales',
        conditionsSelected: 'Condiciones seleccionadas',
        activities: 'Actividades',
    },
    predictions: {
        downloadCSV: 'Exportar a CSV',
        caseId: 'ID del caso',
        caseMetrics: 'Métricas de caso',
        estimatedDuration: 'Duración estimada',
        lastActivity: 'Última actividad',
        estimatedVariant: 'Variante estimada',
        currentDuration: 'Duración actual',
        riskLevel: 'Nivel de riesgo',
        nextActivity: 'Siguiente actividad estimada',
        unifinishedCases: 'Casos sin finalizar',
        noRisk: 'Sin riesgo',
        risk: 'Con riesgo',
        days: 'días',
        highRiskSla: 'Casos con alto riesgo de incumplir la duración objetivo',
        table: {
            total: '{total} eventos sin finalizar',
            totalDesc: 'encontrados en base a los ajustes actuales',
            search: {
                placeholder: 'Filtra por ID de caso',
            },
            newVariant: 'Nueva variante',
        },
        newVariant: 'Nueva variante, predicción no disponible todavía.',
        loadingMessage: 'Cargando datos de predicción, espere por favor',
        slaNotDefined: 'La duración objetivo no ha sido definida para calcular la predicción.',
        caseCompletionNotDefined: 'Las condiciones de completitud de traza no han sido definidas para calcular la predicción.',
        slaAndCaseNotDefined: 'Las condiciones de completitud de traza y de duración objetivo no han sido definidas para calcular la predicción.',
        noPredictionsText: 'Puede definir las condiciones de predicción en la pestaña de Configuración, o bien pulsando el siguiente botón.',
        noData: 'No hay datos disponibles',
        notAvailable: 'La predicción no está disponible debido a la falta de datos históricos. ' +
            'Debería estar disponible una vez que se hayan subido y procesado más casos de procesos con el tiempo.',
        someNotAvailable: 'La predicción no está disponible para algunos casos debido a la falta de datos históricos. ' +
            'Los casos afectados se han omitido en la información proporcionada en esta vista.',
    },
    contextComparison: {
        addContext: 'Añadir Contexto',
        downloadCSV: 'Exportar a CSV',
        comparison: 'Comparación',
        cases: 'Casos',
        variants: 'Variantes',
        duration: 'Duración',
        dates: 'Fechas',
        start: 'Inicio',
        end: 'Fin',
        before: 'ANTES',
        after: 'DESPUES',
        noData: 'Sin contextos',
        noDataExplanation: 'Añade contextos a comparar',
        maxContext: 'Máximo ocho comparativas de contextos',
        datasets: 'Conjuntos de datos',
        filtersets: 'Conjuntos de filtros',
        noFiltersets: '*No hay conjuntos de filtros disponibles',
    },
    powerBIDialog: {
        powerBI: 'Power BI',
        title: 'Conecte los análisis de inteligencia de procesos de Inverbis a Microsoft Power BI',
        clipboard: 'Copiar al portapapeles',
        chooseConnection: 'Elija el método de conexión que mejor se adapte a sus necesidades' +
            'Tenga en cuenta que en ambos casos deberá proporcionar el identificador del conjunto de datos actual y los parámetros del token API válidos.',
        currentDataset: 'Identificador del conjunto de datos actual: ',
        tokenAvailable: 'El token API está disponible',
        noTokenAvailable: 'El token API no ha sido generado.',
        generateToken1: 'Puede ',
        generateToken2: 'generar su propio token',
        generateToken3: ' a través de la opción API en el menú de su perfil de usuario.',
        downloadButton1: 'Descargar .pbix para',
        downloadButton2: 'Power BI Desktop',
        downloadExplanation: 'Descargue una plantilla prediseñada en su aplicación de escritorio. Puede modificarla y adaptarla a sus necesidades.',
        installButton1: `Instale la aplicación de Inverbis en`,
        installButton2: 'su propia cuenta',
        installExplanation: 'Instale la aplicación de Inverbis en su propia suscripción a la nube de Power BI para mantener un acceso en línea a su panel de procesos.',
    },
    infoModal: {
        title: 'Cálculos en proceso.',
        info: 'Las duraciones mostradas podrían cambiar próximamente',
    },
    SLAInfo: {
        title: 'Info Duración objetivo',
        cases: 'Casos',
        noSLAInfo: 'Objetivo no aplicable',
    },
    inputNum: {
        IntNumError: 'El número admite hasta {maxIntNum} digitos enteros.',
        DecimalNumError: 'El número admite hasta {maxDecimalNum} digitos decimales.',
        DecimalNotAllowed: 'El número debe ser entero.',
    },
    frequencyIndicator: {
        percentOfActivities: '% de actividades mostradas.',
    },
    popup: {
        buttons: {
            start_with: 'EMPIEZA CON',
            end_with: 'TERMINA CON',
            contain: 'CONTENER',
            not_contain: 'NO CONTENER',
        },
        impact: {
            title: 'Impacto',
            current: 'Actual',
            total: 'Total',
            currentImpact: '% de impacto actual',
            totalImpact: '% de impacto sobre el total',
            level: {
                low: 'Bajo',
                medium: 'Medio',
                high: 'Alto',
            },
        },
        composed: 'Compuesta por:',
    },
}
