<template>
    <div class="Container">
        <div class="Header">
            <div
                v-if="this.case"
                class="Title"
            >
                {{ $t(`booster.improvement.${this.case}Improve`) }}
            </div>
            <QSpace />
            <QBtn
                flat
                icon="history"
                dense
                size="md"
                color="primary"
                @click="resetActivities"
            />
        </div>
        <q-inner-loading
            :showing="!rows || !rows.length"
            :style="{zIndex: 3000}"
        />
        <QTable
            v-if="rows"
            v-model:pagination="pagination"
            class="my-sticky-header-table"
            :rows="rows"
            :columns="columns"
            rowKey="name"
            flat
            outlined
            dense
            virtualScrol
            separator="vertical"
            bordered
            wrapCells
            :rowsPerPageOptions="rowsPerPageOptions"
        >
            <template #header="props">
                <QTr :props="props">
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        @click="sortColumn(col.name)"
                    >
                        {{ col.label }}
                        <QIcon
                            v-if="sortBy === col.name"
                            :name="descending ? 'arrow_downward' : 'arrow_upward'"
                        />
                    </QTh>
                </QTr>
            </template>
            <template #body="props">
                <QTr
                    :props="props"
                    class="cursor-pointer"
                >
                    <QTd
                        key="name"
                        class="Name"
                        autoWidth
                    >
                        {{ props.row.name }}
                        <QTooltip>
                            {{ props.row.name }}
                        </QTooltip>
                    </QTd>
                    <QTd
                        key="currentImpact"
                    >
                        {{ impactFormatted(props.row.currentImpact) }}
                    </QTd>
                    <QTd
                        key="improvement"
                        style="max-width:50px;"
                    >
                        <QInput
                            v-model="props.row.improvement"
                            dense
                            suffix="%"
                            maxlength="4"
                            :debounce="600"
                            :rules="improvementRules"
                            @update:model-value="improve(props.row)"
                            @focus="emptySpace(props.row)"
                        />
                    </QTd>
                    <QTd
                        key="estimatedImpact"
                    >
                        {{ impactFormatted(props.row.estimatedImpact) }}
                    </QTd>
                </QTr>
            </template>
            <template #no-data="{ message }">
                <div class="full-width row flex-center text-accent q-gutter-sm">
                    <span>
                        {{ message }}
                    </span>
                </div>
            </template>
        </QTable>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'

const ROWS_PER_PAGE_OPTIONS = [
    0, 5, 15, 30, 50, 75, 100,
]

export default {
    name: 'ImprovementTables',
    inject: ['App'],
    props: {
        rows: VueTypes.array,
        columns: VueTypes.array,
        cost: VueTypes.object,
        case: VueTypes.string,
    },
    emits: ['improve', 'reset'],
    data () {
        return {
            descending: false,
            sortBy: 'name',
            improvementRules: [
                val => val === undefined || /^\d+([.,]\d+)?$/.test(val) || this.$t('booster.improvement.improvementTables.rule1'),
                // eslint-disable-next-line radix
                val => val === undefined || parseInt(val) >= 0 || this.$t('booster.improvement.improvementTables.rule2'),
                // eslint-disable-next-line radix
                val => val === undefined || parseInt(val) <= 100 || this.$t('booster.improvement.improvementTables.rule2'),
            ],
            pagination: {
                page: 1,
                rowsPerPage: 0,
            },
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
        }
    },
    computed: {
        curOptions () {
            return {
                euro: '€',
                dollar: '$',
                pound: '£',
            }
        },
    },
    methods: {
        improve (props) {
            // eslint-disable-next-line no-restricted-globals
            this.$emit('improve', this.case, props)
        },
        resetActivities () {
            this.$emit('reset', this.case)
        },
        emptySpace (row) {
            if (row.improvement === (0).toFixed(1)) {
                // eslint-disable-next-line no-param-reassign
                row.improvement = undefined
            }
        },
        sortColumn (by) {
            if (this.sortBy === by) {
                this.descending = !this.descending
            } else {
                this.sortBy = by
                this.descending = false
            }
            this.sortData()
        },
        sortData () {
            this.rows.sort((a, b) => {
                // eslint-disable-next-line no-restricted-globals
                const isStringA = isNaN(a[this.sortBy])
                // eslint-disable-next-line no-restricted-globals
                const isStringB = isNaN(b[this.sortBy])
                if (isStringA && isStringB) {
                    return this.descending ? b[this.sortBy].localeCompare(a[this.sortBy]) : a[this.sortBy].localeCompare(b[this.sortBy])
                }
                return this.descending ? b[this.sortBy] - a[this.sortBy] : a[this.sortBy] - b[this.sortBy]
            })
        },
        impactFormatted (value) {
            const [time, cost] = value.split(' - ')

            const timeInSeconds = parseFloat(time)
            const costValue = parseFloat(cost)

            const formattedTime = moment.duration(timeInSeconds, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
            const formattedCost = `${this.App.numberLocationFormat(this.numFormatter(costValue.toFixed(2)), true)} ${this.curOptions[this.cost.unit]}`

            return `${formattedTime} - ${formattedCost}`
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
}
.Header {
    display: flex;
    flex-direction:row;
}
.Title {
    padding: 0.5vh 0px;
    height: fit-content;
    font-size: 15px;
    @media screen and (min-height: $md) {
        font-size: large;
    }
}
.Name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}
.my-sticky-header-table {
    max-height: 22vh;
    @media screen and (min-height: $md) {
        max-height: 25vh;
    }
    .q-field--with-bottom {
        padding-bottom: 0px;
    }
    &:deep(thead) {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}
</style>
