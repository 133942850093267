<template>
    <Transition
        name="fade"
        mode="out-in"
        appear
    >
        <div
            class="Alerts"
        >
            <div class="flex flex-center q-ma-sm">
                <QBtnToggle
                    v-model="tab"
                    toggleColor="primary"
                    noCaps
                    unelevated
                    :options="tabs"
                    class="q-mt-sm border-toggle"
                />
            </div>
            <QTabPanels
                v-model="tab"
                animated
                transitionNext="fade"
                transitionPrev="fade"
            >
                <QTabPanel name="ACTIVE">
                    <AlertList
                        :processId="processId"
                        :showDismissed="false"
                        class="q-mt-md"
                    />
                </QTabPanel>
                <QTabPanel name="HISTORY">
                    <AlertList
                        :processId="processId"
                        :showDismissed="true"
                        class="q-mt-md"
                    />
                </QTabPanel>
                <QTabPanel name="DEFINITIONS">
                    <AlertDefinition
                        :processId="processId"
                    />
                </QTabPanel>
            </QTabPanels>
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'
import { AlertList, AlertDefinition } from '.'

const DEFAULT_TAB = 'ACTIVE'
export default {
    name: 'Alerts',
    components: {
        AlertList,
        AlertDefinition,
    },
    props: {
        info: VueTypes.object,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    data () {
        return {
            tab: DEFAULT_TAB,
            alerts: undefined,
            total: undefined,
        }
    },
    computed: {
        activeName () {
            return this.$t('booster.alerts.tabs.active')
        },
        tabs () {
            return [{
                value: 'ACTIVE',
                label: this.$t('booster.alerts.tabs.active'),
            },
            {
                value: 'HISTORY',
                label: this.$t('booster.alerts.tabs.history'),
            },
            {
                value: 'DEFINITIONS',
                label: this.$t('booster.alerts.tabs.definitions'),
            }]
        },
    },
}
</script>
<style scoped lang="scss">
.Alerts {
    margin: 20px;
}

.border-toggle {
    border: 1px solid $primary;
}
</style>
