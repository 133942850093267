<template>
    <Modal
        :isOpen="opened"
        class="ZoomerModal"
        @close="close"
    >
        <template #header>
            <div
                class="Header"
            >
                <WText
                    class="mb-1"
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.zoomer.datasetTitle') }}
                </WText>
            </div>
        </template>
        <template #body>
            <div
                class="flex flex-row"
            >
                <WText
                    class="pl-1 mr-2"
                    tag="p"
                    weight="bold"
                >
                    {{ $t('visualization.zoomer.labelDatasetName') }}
                </WText>
                <QInput
                    v-model="datasetName"
                    :label="$t('visualization.zoomer.datasetName')"
                    maxlength="100"
                    stackLabel
                    outlined
                    :hint="`5 - 100 ${$t('general.characters')}`"
                    :rules="[
                        value => validators.betweenLength(value, 5, 100) || $t('validation.rangeLength', { attribute: $t('general.name'), min: 5, max: 100 }),
                        value => validators.allowedChars(value) || $t('validation.allowedChars', { attribute: $t('general.name') }),
                    ]"
                />
            </div>
            <div class="row justify-end mt-1">
                <QBtn
                    class="SaveButtons"
                    outline
                    type="submit"
                    color="primary"
                    :label="$t('visualization.filters.save')"
                    @click="createZoomer"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import Modal from '@/components/Modal/Modal.vue'
import {
    Api,
    apiRequest,
    notify,
    notifySuccess,
    notifyError,
} from '@/api'

export default {
    name: 'ZoomerCreateModal',
    components: {
        Modal,
    },
    mixins: [validatorsMixin],
    props: {
        settings: VueTypes.object,
    },
    emits: ['createZoomer'],
    data () {
        return {
            datasetName: '',
            opened: false,
        }
    },
    computed: {
    },
    watch: {
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {
        open () {
            this.opened = true
        },
        close () {
            this.opened = false
        },
        createZoomer () {
            if (!this.settings.zoomerConfig ||
              (this.settings.zoomerConfig.groupedActivities.length <= 0 &&
              this.settings.zoomerConfig.excludedActivities.length <= 0)) {
                notify({ message: this.$t('visualization.zoomer.createDatasetError') })
                return
            }
            if (this.datasetName.length < 5 || this.datasetName.length > 100) {
                return
            }
            apiRequest(Api().datasets.createZoomer({ datasetId: this.$route.params.processId, params: { name: this.datasetName, zoomerConfig: this.settings.zoomerConfig } }))
                .then(() => {
                    notifySuccess(this.$t('visualization.zoomer.savedDatasetNotification'))
                })
                .catch(notifyError)
            this.close()
        },
    },
}
</script>
<style lang="scss" scoped>
.ZoomerModal {
    &:deep(.Dialog) {
        min-width: 30%;
        display: flex;
        flex-direction: column;
        max-width: 60%;
        overflow: hidden;
    }
}
.SaveButtons {
    width: 80px;
}
.Header {
    margin: 20px;
}
.SaveButtons {
    width: 80px;
    margin-bottom: 20px;
}
</style>
