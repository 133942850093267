export default api => ({
    model: ({ processId, params }) => api.post(`/datasets/${processId}/models`, params),
    modelInfo: ({ processId, params }) => api.post(`/datasets/${processId}/models/info`, params),
    variants: ({ processId, params }) => api.post(`/datasets/${processId}/models/variants`, params),
    multipleVariants: ({ processId, params }) => api.post(`/datasets/${processId}/models/multiple-variants`, params),
    variant: ({ processId, variantId, params }) => api.post(`/datasets/${processId}/models/variants/${variantId}`, params),
    renameVariant: ({ processId, variantId, params }) => api.put(`/datasets/${processId}/models/variants/${variantId}`, params),
    renameLoop: ({ processId, loopId, params }) => api.put(`/datasets/${processId}/models/loops/${loopId}`, params),
    loops: ({ processId, params }) => api.post(`/datasets/${processId}/models/loops`, params),
    loop: ({ processId, loopId, params }) => api.post(`/datasets/${processId}/models/loops/${loopId}`, params),
    dashboard: ({ processId, params }) => api.post(`/datasets/${processId}/models/dashboards`, params),
    attributePanel: ({ processId, params }) => api.post(`datasets/${processId}/model/plot/attribute`, params),
    frequencyPanel: ({ processId, params }) => api.post(`datasets/${processId}/model/plot/frequency`, params),
    filters: () => api.get('/datasets/filters'),
    attributes: ({ processId, params }) => api.post(`/datasets/${processId}/attributes`, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    }),
    arcs: ({ processId, params }) => api.post(`/datasets/${processId}/arcs`, params),
    activities: ({ processId, params }) => api.post(`/datasets/${processId}/activities`, params),
    splitFilteredActivities: ({ processId, params }) => api.post(`/datasets/${processId}/splitFilteredActivities`, params),
    traces: ({ processId, params }) => api.post(`/datasets/${processId}/traces`, params),
    downloadTraces: ({ processId, params }) => api.post(`datasets/${processId}/traces/download`, params),
    predictions: ({ processId, params }) => api.post(`/datasets/${processId}/prediction`, params),
    predictionTrace: ({ processId, traceId, params }) => api.post(`/datasets/${processId}/prediction/${traceId}`, params),
    powerBILink: () => api.get('/export/powerbi/link'),
    powerBIDownload: () => api.get('/export/powerbi/download', { responseType: 'blob' }),
    activityImpactWallaceCof: ({ processId, activityName, params }) => api.post(`/datasets/${processId}/activities/${activityName}`, params),
    arcImpactWallaceCof: ({ processId, arcName, params }) => api.post(`/datasets/${processId}/arcs/${arcName}`, params),
})
