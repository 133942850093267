<template>
    <div class="Container">
        <div class="ImprovementScopeSubtitle">
            {{ $t('booster.improvement.improvementScope.add') }}
        </div>
        <q-inner-loading :showing="!attributes || !attributes.length" />
        <div class="SelectContainer">
            <QSelect
                v-model="attribute"
                :options="attributes.map(i=> i.name)"
                class="Select"
                dense
                outlined
                :label="$t('booster.improvement.improvementScope.attributes')"
                @update:model-value="getValues"
            />
            <QSelect
                v-model="selectedValue"
                :options="options"
                class="Select"
                dense
                outlined
                :label="$t('booster.improvement.improvementScope.values')"
            />
            <QBtn
                outline
                round
                dense
                color="primary"
                icon="add"
                class="AddButton"
                size="xs"
                @click="attributeValue"
            />
        </div>
        <div class="ChipContainer">
            <div
                v-for="(selected, index) in attrValPairs"
                :key="index"
            >
                <QChip
                    removable
                    color="primary"
                    textColor="white"
                    @remove="deleteIndexValue(index, 'attrValPairs' )"
                >
                    {{ selected.attribute }}: {{ selected.value }}
                </Qchip>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'ImprovementScope',
    props: {
        attributes: VueTypes.array,
        attrValPairs: VueTypes.array,
    },
    emits: ['attrValPairs'],
    data () {
        return {
            attribute: undefined,
            options: undefined,
            selectedValue: undefined,
        }
    },
    methods: {
        getValues () {
            // eslint-disable-next-line max-len
            this.options = this.attributes.find(item => item.name === this.attribute).values ? this.attributes.find(item => item.name === this.attribute).values : []
            this.selectedValue = undefined
            if (this.options.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                this.selectedValue = this.options[0]
            }
        },
        attributeValue () {
            if (this.attribute && (this.selectedValue || this.options.length === 0)) {
                if (!this.attrValPairs.some(item => item.attribute === this.attribute && item.value === this.selectedValue)) {
                    this.attrValPairs.push({ attribute: this.attribute, value: this.selectedValue ? this.selectedValue : '' })
                }
                this.attribute = undefined
                this.selectedValue = undefined
                this.options = []
                this.$emit('attrValPairs', this.attrValPairs)
            }
        },
        deleteIndexValue (index, nombre) {
            this.attrValPairs.splice(index, 1)
            this.$emit('attrValPairs', this.attrValPairs)
        },
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
}
.ImprovementScopeSubtitle {
    font-size:small;
    background-color: #fbfbfb;
    color: $primary;
    padding:1%;
    height: 10%;
}
.ImprovedScopeBody {
    display:flex;
    flex-direction: column;
    background-color: #fbfbfb;
}
.Select {
    width: 40%;
}
.SelectContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content:space-around;
    align-items:center;
    background-color:#fbfbfb;
    padding-bottom:1vh;
}
.ChipContainer {
    max-height: 10vh;
    @media screen and (min-height: $md) {
        max-height: 13vh;
    }
    overflow:auto;
    display: flex;
    flex-direction: column;
    align-items:center;
}
.AddButton {
    width: fit-content; height: fit-content;
}
</style>
