<template>
    <AppProvider v-slot="{ loading }">
        <main class="Container">
            <div
                v-if="!loading"
                class="Wrapper"
            >
                <RouterView v-slot="{ Component }">
                    <Transition
                        name="main-router"
                        mode="out-in"
                    >
                        <component :is="Component" />
                    </Transition>
                </RouterView>
            </div>
            <q-inner-loading :showing="loading" />
        </main>
    </AppProvider>
</template>
<script>
import { AppProvider } from '@/providers'

export default {
    name: 'MainLayout',
    components: {
        AppProvider,
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
    width: 100%;
    min-height: 100vh;
}
</style>
