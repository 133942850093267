<template>
    <div
        v-if="visible"
        :style="popupStyle"
        class="popup q-pa-sm"
    >
        <div
            v-if="!isGroupActivities"
        >
            <div
                class="flex justify-between"
            >
                <p style="font-size: large; font-weight: bold; padding: 10px;">
                    {{ data.id }}
                </p>
                <q-btn
                    icon="close"
                    flat
                    @click="handleClickClose"
                />
            </div>
            <q-inner-loading
                v-if="isLoading"
                :showing="isLoading"
                :style="{zIndex: 1000}"
                visible
            />
            <div
                v-else
            >
                <div class="row justify-center q-mb-sm">
                    <div class="row items-center q-pa-md">
                        <p style="font-size: medium; font-weight: bold">
                            {{ $t('visualization.popup.impact.title') }}
                        </p>
                    </div>
                    <div class="column items-center">
                        {{ $t('visualization.popup.impact.current') }}
                        <q-circular-progress
                            :value="currentWallaceCofPercent"
                            size="45px"
                            :thickness="0.2"
                            :color="severityColors[0]"
                            class="q-ma-md"
                            showValue
                        >
                            <div
                                class="q-mt-sm"
                                style="color: #000000; font-size: 14px;"
                            >
                                {{ currentWallaceCofPercent }}%
                            </div>
                            <q-tooltip>
                                {{ currentWallaceCofPercent }}{{ $t('visualization.popup.impact.currentImpact') }}
                            </q-tooltip>
                        </q-circular-progress>
                        {{ $t(`visualization.popup.impact.level.${severityLevels[0]}`) }}
                    </div>
                    <div class="column items-center">
                        {{ $t('visualization.popup.impact.total') }}
                        <q-circular-progress
                            :value="totalWallaceCofPercent"
                            size="45px"
                            :thickness="0.2"
                            :color="severityColors[1]"
                            class="q-ma-md"
                            showValue
                        >
                            <div
                                class="q-mt-sm"
                                style="color: #000000; font-size: 14px;"
                            >
                                {{ totalWallaceCofPercent }}%
                            </div>
                            <q-tooltip>
                                {{ totalWallaceCofPercent }}{{ $t('visualization.popup.impact.totalImpact') }}
                            </q-tooltip>
                        </q-circular-progress>
                        {{ $t(`visualization.popup.impact.level.${severityLevels[1]}`) }}
                    </div>
                </div>
                <div
                    class="buttons_container q-gutter-sm q-mx-sm"
                >
                    <div class="button_row">
                        <q-btn
                            :color="isFilterApplied[0] ? 'grey-7' : 'primary'"
                            icon="hourglass_top"
                            :label="$t('visualization.popup.buttons.start_with')"
                            class="q-mr-sm"
                            @click="handleClickFilter('TRACE_STARTS_WITH_ACTIVITY',0)"
                        />
                        <span style="margin-left: 10px">{{ casesValue.startsWith }} cases</span>
                    </div>
                    <div class="button_row">
                        <q-btn
                            :color="isFilterApplied[1] ? 'grey-7' : 'primary'"
                            icon="hourglass_bottom"
                            :label="$t('visualization.popup.buttons.end_with')"
                            class="q-mr-sm"
                            @click="handleClickFilter('TRACE_ENDS_WITH_ACTIVITY',1)"
                        />
                        <span style="margin-left: 10px">{{ casesValue.endsWith }} cases</span>
                    </div>
                    <div class="button_row">
                        <q-btn
                            :color="isFilterApplied[2] ? 'grey-7' : 'primary'"
                            icon="check_box"
                            :label="$t('visualization.popup.buttons.contain')"
                            class="q-mr-sm"
                            @click="handleClickFilter('TRACE_CONTAINS_ACTIVITIES',2)"
                        />
                        <span style="margin-left: 10px">{{ casesValue.contains }} cases</span>
                    </div>
                    <div class="button_row">
                        <q-btn
                            :color="isFilterApplied[3] ? 'grey-7' : 'primary'"
                            icon="disabled_by_default"
                            :label="$t('visualization.popup.buttons.not_contain')"
                            class="q-mr-sm"
                            @click="handleClickFilter('TRACE_NOT_CONTAINS_ACTIVITIES',3)"
                        />
                        <span style="margin-left: 10px">{{ casesValue.notContains }} cases</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
        >
            <div
                class="flex justify-between"
            >
                <div style="display: flex; align-items: center; margin-right: 10px;">
                    <q-icon
                        :name="getIconName(activityGroup)"
                        color="primary"
                        size="20px"
                        style="margin-right: 2px;"
                    />
                    <p style="font-size: large; font-weight: bold;  padding-left: 10px">
                        {{ groupName }}
                    </p>
                </div>
                <q-btn
                    icon="close"
                    flat
                    @click="handleClickClose"
                />
            </div>
            <p
                class="q-mt-md q-mb-xs"
                style="font-size: large; margin-bottom: 10px"
            >
                {{ $t('visualization.popup.composed') }}
            </p>

            <ul class="q-pl-lg">
                <li
                    v-for="(item, index) in activityGroup"
                    :key="index"
                    class="text-body1 q-my-xs"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { filtersStorageMixin } from '@/mixins'

export default {
    name: 'PopUp',
    mixins: [filtersStorageMixin],
    props: {
        data: VueTypes.object,
        visible: VueTypes.bool,
        isGroupActivities: VueTypes.bool,
        position: VueTypes.object,
        tab: VueTypes.string,
    },
    emits: ['close', 'add-filter', 'delete-filter'],
    data () {
        return {
            isLoading: false,
            currentWallaceCofPercent: 0,
            totalWallaceCofPercent: 0,
            severityLevels: ["low", "low"],
            severityColors: ["green", "green"],
            casesValue: {
                startsWith: 0,
                endsWith: 0,
                contains: 0,
                notContains: 0,
            },
            isFilterApplied: [false, false, false, false],
            filters: [],
            isBlocked: [false, false, false, false],
            grouName: '',
            activityGroup: [],
            groupName: '',
            iconName: 'filter_2',
        }
    },
    computed: {
        popupStyle () {
            return {
                left: `${this.position.x}px`,
                top: `${this.position.y}px`,
            }
        },
    },
    watch: {
        tab () {
            if (this.visible) {
                this.handleClickClose()
            }
        },
        visible (newVal) {
            if (newVal && !this.isGroupActivities) {
                this.applyFilters()
                this.getAppliedFilters()
                this.getImpactFromWallaceCof()
            } else {
                this.groupName = this.data.name
                this.activityGroup = this.data.activities
            }
        },
        data () {
            if (!this.isGroupActivities) {
                this.applyFilters()
                this.getAppliedFilters()
                this.getImpactFromWallaceCof()
            } else {
                this.groupName = this.data.name
                this.activityGroup = this.data.activities
            }
        },
        visualizationFilters: {
            handler () {
                this.getAppliedFilters()
            },
        },
        currentWallaceCofPercent () {
            this.calculateSeverityLevel()
        },
        totalWallaceCofPercent () {
            this.calculateSeverityLevel()
        },
        isGroupActivities: {
            handler () {
                if (this.isGroupActivities && this.data) {
                    this.groupName = this.data?.name
                    this.activityGroup = this.data?.activities
                }
            },
        },
    },
    methods: {
        getImpactFromWallaceCof () {
            this.isLoading = true
            const { processId } = this.$route.params
            const { variantCount: variants, variantUid: variantsId, loopUid: loopId } = this.data?.modelData || {}
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            this.currentFilters = filters
            const activityName = this.formatActivityName(this.data.id)
            const params = {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(variants && { variants }),
                ...(variantsId && { variantsId }),
                ...(loopId && { loopId }),
            }
            Api().visualizations.activityImpactWallaceCof({ processId, activityName, params })
                .then((data) => {
                    this.currentWallaceCofPercent = Math.round(data?.data.data[0].currentCW * 100)
                    this.totalWallaceCofPercent = Math.round(data?.data.data[0].totalCW * 100)
                }).catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },

        formatActivityName (name) {
            return encodeURIComponent(name)
        },

        getAppliedFilters () {
            this.isFilterApplied = [false, false, false, false]
            this.isBlocked = [false, false, false, false]

            this.visualizationFilters.forEach((filter) => {
                if (filter.id === 'TRACE_STARTS_WITH_ACTIVITY') {
                    this.isFilterApplied[0] = true
                    this.isBlocked[0] = true
                }
                if (filter.id === 'TRACE_STARTS_WITH_ACTIVITY' && (filter.key === this.data.id || filter.value === this.data.id)) {
                    this.isFilterApplied[0] = true
                    this.isBlocked[0] = false
                    if (!this.filters.some(f => f.id === filter.id && (filter.key === this.data.id || filter.value === this.data.id))) this.filters.push(filter)
                }
                if (filter.id === 'TRACE_ENDS_WITH_ACTIVITY') {
                    this.isFilterApplied[1] = true
                    this.isBlocked[1] = true
                }

                if (filter.id === 'TRACE_ENDS_WITH_ACTIVITY' && (filter.key === this.data.id || filter.value === this.data.id)) {
                    this.isFilterApplied[1] = true
                    this.isBlocked[1] = false
                    if (!this.filters.some(f => f.id === filter.id && (filter.key === this.data.id || filter.value === this.data.id))) this.filters.push(filter)
                }

                if (filter.id === 'TRACE_CONTAINS_ACTIVITIES' && (filter.key?.[0] === this.data.id || filter.value === this.data.id)) {
                    this.isFilterApplied[2] = true
                    console.log("antes", this.filters.includes(filter))
                    if (!this.filters.some(f => f.id === filter.id && (filter.key?.[0] === this.data.id || filter.value === this.data.id))) this.filters.push(filter)
                }

                if (filter.id === 'TRACE_NOT_CONTAINS_ACTIVITIES' && (filter.key?.[0] === this.data.id || filter.value === this.data.id)) {
                    this.isFilterApplied[3] = true
                    if (!this.filters.some(f => f.id === filter.id && (filter.key?.[0] === this.data.id || filter.value === this.data.id))) this.filters.push(filter)
                }
            })
        },

        async applyFilters () {
            try {
                const filters = [
                    'TRACE_STARTS_WITH_ACTIVITY',
                    'TRACE_ENDS_WITH_ACTIVITY',
                    'TRACE_CONTAINS_ACTIVITIES',
                    'TRACE_NOT_CONTAINS_ACTIVITIES',
                ]

                const promises = filters.map(filter => this.filterCases(filter))

                await Promise.all(promises)
                    .then((results) => {
                        this.casesValue = {
                            startsWith: results[0].cases,
                            endsWith: results[1].cases,
                            contains: results[2].cases,
                            notContains: results[3].cases,
                        }
                    })
            } catch (err) {
                notifyError(err)
            }
        },

        async filterCases (filter) {
            const { processId } = this.$route.params
            const { loopUid } = this.data?.modelData
            const { variantCount } = this.data?.modelData
            const { variantUid } = this.data?.modelData
            const params = {
                filters: [
                    {
                        id: filter,
                        values: [this.data.id],
                    },
                ],
                filterSet: [],
                operator: "AND",
                ...(loopUid !== undefined ? { loopUid } : {}),
                ...(variantCount ? { variantCount } : {}),
                ...(variantUid ? { variantUid } : {}),
            }
            return apiRequest(Api().visualizations.modelInfo({ processId, params }))
        },

        getSeverity (percent) {
            if (percent <= 30) {
                return { level: "low", color: "green" }
            } if (percent > 30 && percent <= 60) {
                return { level: "medium", color: "orange" }
            }
            return { level: "high", color: "red" }
        },

        calculateSeverityLevel () {
            const currentSeverity = this.getSeverity(this.currentWallaceCofPercent)
            const totalSeverity = this.getSeverity(this.totalWallaceCofPercent)
            this.severityLevels[0] = currentSeverity.level
            this.severityColors[0] = currentSeverity.color
            this.severityLevels[1] = totalSeverity.level
            this.severityColors[1] = totalSeverity.color
        },

        handleClickFilter (selectedFilter, i) {
            if (this.isFilterApplied[i]) {
                if (this.isBlocked[i]) return
                this.isFilterApplied[i] = false
                this.handleDeleteFilter(selectedFilter)
            } else {
                this.isFilterApplied[i] = true
                this.handleAddFilter(selectedFilter)
                if (i === 0 || i === 1) this.isBlocked[i] = false
            }
        },

        formatFilter (selectedFilter, del) {
            const filter = {
                id: selectedFilter,
                label: selectedFilter,
                value: this.data.id,
                type: {
                    category: [
                        this.data.id,
                    ],
                    date: "",
                    duration: "0000D 00h:00m:00s",
                    constraints: {
                        label: "MENOR QUE",
                        value: "LESSER_THAN",
                    },
                    arc: null,
                    variant: null,
                    loopName: null,
                    activitySource: null,
                    sourceOccurrence: null,
                    activityTarget: null,
                    activityOccurrence: null,
                    activitiesDurationAlgorithm: "ActivityOccurrence",
                },
                key: null,
                uuid: null,
                pending: null,
            }
            if (del) {
                this.filters.forEach((f) => {
                    if (f.id === 'TRACE_CONTAINS_ACTIVITIES' || f.id === 'TRACE_NOT_CONTAINS_ACTIVITIES') {
                        if (f.id === filter.id && f.key?.[0] === filter.value) {
                            filter.value = [f.key[0]]
                            filter.key = [f.key[0]]
                            filter.uuid = f.uuid
                            filter.pending = false
                        }
                    } else if (f.id === filter.id && (f.key === filter.value || f.value === filter.value)) {
                        // eslint-disable-next-line prefer-destructuring
                        filter.key = f.key
                        filter.value = f.key
                        filter.uuid = f.uuid
                        filter.pending = false
                    }
                })
            }
            return filter
        },

        checkFilterStartByEndBy (selectedFilter) {
            if (selectedFilter === 'TRACE_STARTS_WITH_ACTIVITY' || selectedFilter === 'TRACE_ENDS_WITH_ACTIVITY') {
                const { visualizationFilters } = this
                return visualizationFilters.some(f => f.id === selectedFilter)
            }
            return false
        },

        async handleAddFilter (selectedFilter) {
            if (this.checkFilterStartByEndBy(selectedFilter)) return
            await this.$emit('add-filter', this.formatFilter(selectedFilter, false))
            // It will add the filter to the filters array automatically by the getAppliedFilters function
        },

        handleDeleteFilter (selectedFilter) {
            const filter = this.formatFilter(selectedFilter, true)
            this.$emit('delete-filter', filter)
            this.filters = this.filters.filter(fil => fil.uuid !== filter.uuid)
            console.log(this.filters)
        },

        handleClickClose () {
            this.$emit('close')
        },

        getIconName (activityGroup) {
            const len = activityGroup.length
            if (len > 9) return `filter_9_plus`
            return activityGroup && (len >= 2) ? `filter_${len}` : 'filter_none'
        },
    },
}
</script>
<style lang="scss">
    .popup {
        border: 1px solid black;
        border-radius: 5px;
        background: white;
        position: absolute;
        padding: '10px';
        overflow: hidden;
        min-width: max-content;
    }

    .buttons_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
    }

    .button_row {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .button_row .q-btn {
        width: 170px;
    }

    .no-hover:hover {
        box-shadow: none;
        background-color: transparent;
    }

</style>
