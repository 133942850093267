<template>
    <div class="MainContainer">
        <div
            class="Container"
        >
            <div
                class="TopContainer"
            >
                <div class="ChartTitle">
                    <strong class="PrimaryColor"> {{ $t('booster.state.performanceBy') }} {{ $t(`booster.state.${selected}`) }}</strong>
                    <QIcon
                        v-if="selected === 'variant'"
                        color="primary"
                        size="xs"
                        class="GraphIcon"
                        name="account_tree"
                    >
                        <QTooltip> {{ $t('booster.state.rightClick') }} </QTooltip>
                    </QIcon>
                </div>
                <QSpace />
                <QBtnToggle
                    v-model="sortDirection.value"
                    toggleColor="primary"
                    noCaps
                    dense
                    style="height: fit-content;"
                    size="0.8em"
                    padding="2px 2px"
                    :options="sort"
                    @update:model-value="asignData(performanceByResult)"
                />
                <QBtnToggle
                    v-model="optionSel"
                    toggleColor="primary"
                    noCaps
                    dense
                    style="height: fit-content; margin-left: 5px;"
                    size="0.8em"
                    padding="2px 5px"
                    :options="options"
                    @update:model-value="asignData(performanceByResult)"
                />
            </div>
            <q-inner-loading :showing="isLoadingPerfBy" />
            <div
                v-if="!isLoadingPerfBy"
                class="ChartContainer"
                oncontextmenu="return false;"
            >
                <v-chart
                    id="performanceBy"
                    ref="performanceBy"
                    :option="performanceByOptions"
                    class="Chart"
                    @click="emitValue"
                    @context-menu="handleChartMouseMove"
                />
                <QDialog v-model="showTooltip">
                    <QCard class="Card">
                        <div class="GraphHeader">
                            <h6> {{ currentElement }}</h6>
                            <QSpace />
                            <QBtn
                                icon="close"
                                flat
                                round
                                dense
                                @click="showTooltip=false"
                            />
                        </div>
                        <q-inner-loading :showing="!ready" />
                        <div
                            id="view-wrapper"
                            class="GraphHolder flex"
                        >
                            <div style="position: absolute; right:0; margin:5px;">
                                <QBtn
                                    icon="adjust"
                                    unelevated
                                    color="white"
                                    textColor="primary"
                                    class="q-mr-sm"
                                    @click="handleCenterChart"
                                />
                                <QTooltip
                                    class="bg-grey-3 text-black"
                                >
                                    {{ $t('visualization.centerChart') }}
                                </QTooltip>
                            </div>
                            <div style="flex-grow: 1;">
                                <Graph
                                    v-if="ready && chart && (chart.activities || []).length && (Object.keys(chart) || []).length"
                                    ref="chart"
                                    :data="chart"
                                    :model="model"
                                    parent="view-wrapper"
                                />
                            </div>
                        </div>
                    </QCard>
                </QDialog>
            </div>
        </div>
        <div class="Container">
            <div
                class="TopContainer"
            >
                <div class="ChartTitle">
                    <strong
                        v-if="selected === 'activity'"
                        class="PrimaryColor"
                    >
                        {{ $t('booster.state.timeSpentPerCase') }}
                    </strong>
                    <strong
                        v-if="selected === 'transition'"
                        class="PrimaryColor"
                    >
                        {{ $t('booster.state.timeSpentPerCase') }}
                    </strong>
                    <strong
                        v-if="selected === 'variant'"
                        class="PrimaryColor"
                    > {{ $t('booster.state.leadTimesBy') }} {{ $t(`booster.state.${selected}`) }}</strong>
                </div>
                <QSpace />
                <QBtnToggle
                    v-model="sortDirection2.value"
                    toggleColor="primary"
                    style="height: fit-content;"
                    noCaps
                    dense
                    size="0.8em"
                    padding="2px 2px"
                    :options="sort"
                    @update:model-value="ascDesc"
                />
            </div>
            <q-inner-loading :showing="isLoadingLeadTimes" />
            <div class="ChartContainer">
                <v-chart
                    v-if="!isLoadingLeadTimes"
                    ref="leadTimes"
                    :option="leadTimesOptions"
                    class="Chart"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { QSpace, QTooltip } from 'quasar'
import Graph from '@/components/Graph/Graph.vue'
import {
    Api, apiRequest, notifyError,
} from '@/api'

const INITIAL_MODEL = {
    variant: 1,
    variants: 1,
    heatMapValue: 'none',
    directionValue: 'TB',
    statsValue: 'averages',
    kpi: undefined,
    values: 'freq',
}

export default {
    name: 'PerformanceBy',
    components: {
        Graph,
        QSpace,
        QTooltip,
    },
    props: {
        selected: VueTypes.string,
        slaCompliance: VueTypes.bool,
    },
    emits: ['selectedOptions'],
    data () {
        return {
            chart: {},
            ready: false,
            widthSpace: undefined,
            model: INITIAL_MODEL,
            tooltipData: { name: '', value: '' },
            currentElement: undefined,
            showTooltip: false,
            isLoadingLeadTimes: false,
            isLoadingPerfBy: false,
            left: 12,
            sortDirection: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sortDirection2: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sort: [{
                value: 'ASC',
                icon: 'arrow_upward',
            },
            {
                value: 'DESC',
                icon: 'arrow_downward',
            },
            ],
            optionSel: 'SLACOMPLIANCEFREQ',
            options: [
                {
                    value: 'SLACOMPLIANCEFREQ',
                    label: 'abs',
                },
                {
                    value: 'SLACOMPLIANCEPERCENTAGE',
                    label: '%',
                },
            ],
            newPerformanceBy: undefined,
            leftPerformanceBy: undefined,
            leftLeadTimesOpt: undefined,
            performanceByResult: [],
            leadTimesByResult: [],
            variants: [],
            leadTimesOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    // position (point, params, dom, rect, size) {
                    //     return [point[0], point[1] - size.contentSize[1]]
                    // },
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 40)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${seriesName}: ${moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    left: 100,
                    right: 25,
                    bottom: 20,
                    top: 15,
                },
                maxVisibleValues: 2,
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        filterMode: 'none',
                        showDetail: false,
                        showDataShadow: false,
                        start: 100,
                        maxSpan: 4,
                        maxValueSpan: 4,
                        minValueSpan: 4,
                        width: 7,
                        moveHandleSize: 0,
                        moveHandleStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                        },
                        brushStyle: {
                            color: 'rgba(200, 200, 200, 0.3)',
                            borderColor: 'transparent',
                            borderWidth: 0,
                        },
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },
                ],
                series: [
                    {
                        name: this.$t('booster.state.above'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        silent: true,
                    },
                    {
                        name: this.$t('booster.state.below'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#40E196',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        silent: true,
                    },
                ],
            },
            performanceByOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 40)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            this.optionSel === 'SLACOMPLIANCEFREQ'
                                ? tooltipContent += `${seriesName}: ${value} ${this.$t('booster.state.cases')}`
                                : tooltipContent += `${seriesName}: ${value} %`
                            tooltipContent += '<br></div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    left: 100,
                    right: 25,
                    bottom: 20,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return `${value }`
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        filterMode: 'none',
                        showDetail: false,
                        showDataShadow: false,
                        start: 100,
                        maxSpan: 5,
                        maxValueSpan: 5,
                        minValueSpan: 5,
                        width: 7,
                        moveHandleSize: 0,
                        moveHandleStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                        },
                        brushStyle: {
                            color: 'rgba(200, 200, 200, 0.3)',
                            borderColor: 'transparent',
                            borderWidth: 0,
                        },
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },

                ],
                series: [
                    {
                        name: this.$t('booster.state.above'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 15,
                    },
                    {
                        name: this.$t('booster.state.below'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                            formatter: {},
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#40E196',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 15,
                    },
                ],
            },
        }
    },
    mounted () {
        this.performanceBy()
        this.leadTimesBy()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (this.$refs.leadTimes) {
                setTimeout(() => {
                    this.$refs.leadTimes.resize()
                }, 500)
            }
            if (this.$refs.performanceBy) {
                setTimeout(() => {
                    this.$refs.performanceBy.resize()
                }, 500)
            }
        },
        createSubstring (text, large) {
            const substrings = []
            let start = 0
            while (start < text.length) {
                let end = start + large
                if (end >= text.length) {
                    end = text.length
                } else {
                    const space = text.indexOf(' ', start + large)
                    const dash = text.indexOf('-', start + large)
                    const underscore = text.indexOf('-', start + large)
                    if (space !== -1 && space < end) {
                        end = space
                    } else if (dash !== -1 && dash < end) {
                        end = dash
                    } else if (underscore !== -1 && underscore < end) {
                        end = underscore
                    } else {
                        end = start + large
                    }
                }
                substrings.push(text.substring(start, end))
                start = end
            }
            return substrings
        },
        handleCenterChart () {
            const { chart } = this.$refs
            if (chart) chart.centerGraph()
        },
        async getModelInfoDisplayed (variantInfo) {
            const { processId } = this.$route.params
            this.ready = false
            const params = { filters: [], start: 0, limit: 8 }
            const variantId = variantInfo[0].contentId
            apiRequest(Api().visualizations.variant({ processId, variantId, params }))
                .then(({ data }) => {
                    const [destrData] = data
                    const formattedData = this.formatChartData(destrData)
                    this.chart = data ? { ...formattedData } : undefined
                })
                .catch(() => {
                    notifyError()
                })
                .finally(() => (this.ready = true))
        },
        formatChartData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },
        handleChartMouseMove (e) {
            if (this.selected === 'variant') {
                this.currentElement = e.name
                const currentElementInfo = this.performanceByResult.rows.filter(item => (item.content === this.currentElement))
                this.showTooltip = true
                this.getModelInfoDisplayed(currentElementInfo)
            }
        },
        performanceBy () {
            const params = {
                content: this.selected.toUpperCase(),
                sortBy: this.optionSel,
                sortDirection: this.sortDirection.value,
            }
            this.isLoadingPerfBy = true
            const { processId } = this.$route.params
            apiRequest(Api().booster.performanceByContent({ processId, params }))
                .then((data) => {
                    this.performanceByResult = data
                    this.asignData(data)
                    const maxLetters = this.performanceByOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                    this.leftPerformanceBy = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                    this.performanceByOptions.grid.left = this.leftPerformanceBy + 16
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoadingPerfBy = false
                })
        },
        asignData (data) {
            if (this.optionSel === 'SLACOMPLIANCEFREQ') {
                if (this.sortDirection.value === 'ASC') {
                    this.newPerformanceBy = data.rows.slice().sort((a, b) => a.slaNonComplianceAbsolute - b.slaNonComplianceAbsolute)
                } else {
                    this.newPerformanceBy = data.rows.slice().sort((a, b) => b.slaNonComplianceAbsolute - a.slaNonComplianceAbsolute)
                }
                this.performanceByOptions.yAxis.data = this.newPerformanceBy.map(item => item.content)
                this.performanceByOptions.series[1].data = this.newPerformanceBy.map(item => item.slaComplianceAbsolute)
                this.performanceByOptions.series[0].data = this.newPerformanceBy.map(item => item.slaNonComplianceAbsolute)
                this.performanceByOptions.xAxis.max = undefined
            } else {
                // case percentage
                if (this.sortDirection.value === 'ASC') {
                    this.newPerformanceBy = data.rows.slice().sort((a, b) => a.slaNonCompliancePercentage - b.slaNonCompliancePercentage)
                } else {
                    this.newPerformanceBy = data.rows.slice().sort((a, b) => b.slaNonCompliancePercentage - a.slaNonCompliancePercentage)
                }
                this.performanceByOptions.yAxis.data = this.newPerformanceBy.map(item => item.content)
                this.performanceByOptions.series[1].data = this.newPerformanceBy.map(item => item.slaCompliancePercentage)
                this.performanceByOptions.series[0].data = this.newPerformanceBy.map(item => item.slaNonCompliancePercentage)
                this.performanceByOptions.xAxis.max = 100
            }
        },
        ascDesc (direction) {
            let prueba
            if (direction === 'ASC') {
                prueba = this.leadTimesByResult.rows.slice().sort((a, b) => a.slaNonComplianceAvg - b.slaNonComplianceAvg)
            } else {
                prueba = this.leadTimesByResult.rows.slice().sort((a, b) => b.slaNonComplianceAvg - a.slaNonComplianceAvg)
            }
            this.leadTimesOptions.yAxis.data = prueba.map(item => item.content)
            this.leadTimesOptions.series[1].data = prueba.map(item => item.slaComplianceAvg)
            this.leadTimesOptions.series[0].data = prueba.map(item => item.slaNonComplianceAvg)
            this.leadTimesOptions.xAxis.max = undefined
        },
        leadTimesBy () {
            const params = {
                content: this.selected.toUpperCase(),
                sortBy: 'SLANONCOMPLIANCEAVG',
                sortDirection: this.sortDirection2.value,
            }
            const { processId } = this.$route.params
            this.chartHeight2 = undefined
            this.isLoadingLeadTimes = true
            apiRequest(Api().booster.leadTimes({ processId, params }))
                .then((data) => {
                    this.leadTimesByResult = data
                    this.leadTimesOptions.yAxis.data = data.rows.map(item => item.content)
                    const maxLetters = this.leadTimesOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                    this.leftLeadTimesOpt = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                    this.leadTimesOptions.grid.left = this.leftLeadTimesOpt + 16
                    this.leadTimesOptions.series[1].data = data.rows.map(item => item.slaComplianceAvg)
                    this.leadTimesOptions.series[0].data = data.rows.map(item => item.slaNonComplianceAvg)
                    this.leadTimesOptions.xAxis.max = undefined
                    const dataLength = this.leadTimesOptions.series[0].data.length
                    const calculatedHeight = `${dataLength * 20 }px`
                    this.chartHeight2 = calculatedHeight
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoadingLeadTimes = false
                })
        },
        emitValue (value) {
            if (value.componentType === 'series') {
                const etiquetaEjeY = this.performanceByOptions.yAxis.data[value.dataIndex]
                this.$emit('selectedOptions', this.selected, etiquetaEjeY)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.GraphHolder{
    border: 1px solid #e6e6f2;
    height: 95%;
}
.GraphHeader {
    display:flex;
    height: 3%;
    margin: 2%
}
.GraphIcon {
    border:1px solid #33699a;
    border-radius:25px;
    padding:2px;
    margin-left:0.6vw;
}

.Graph {
    height: 100%;
}
.GraphLoading {
    min-height: 100%;
    min-width:100%;
}
.PrimaryColor {
    color:#33699a;
}
.MainContainer {
    height:auto;
    width:100%;
    display: flex;
    margin:0 auto;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
}
.Container {
    position:relative;
    width: 45%;
    margin: 0 auto;
}
.TopContainer {
    display: flex;
    flex-direction: row;
    height: auto;
    background-color: #fbfbfb;
    padding: 1vh;
}
.Card {
    height:80%;
    width: 100%;
    overflow:hidden;
}
.ChartContainer {
    width: 100%;
    height: 31vh;
    margin-bottom: 1%;
    white-space: nowrap;
    background-color: #fbfbfb;
    @media screen and (max-width: $lg) {
        height: 25vh;
   }
   @media screen and (max-width: $md) {
    height: 22vh;
}
    .Chart {
        max-height: 31vh;
        & + .chart {
          max-height: 31vh;
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
}
.ChartTitle {
    display:flex;
    justify-content: center;
    align-items: center;
}
 ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #DDDDDD;
    border-radius: 10px;
  }
</style>
