const DELAY = 5000

export default {
    data () {
        return {
            polling: null,
        }
    },
    methods: {
        pollData () {
            this.polling = setInterval(() => {
                if (this.fetchData) this.fetchData({ polling: true })
            }, DELAY)
        },
    },
    beforeUnmount () {
        clearInterval(this.polling)
    },
    mounted () {
        this.pollData()
    },
}
