import Notify from 'quasar/src/plugins/notify/Notify.js';
import Config from '@/config'

if (!Config.isTest) {
    Notify.setDefaults({
        position: 'bottom-right',
        timeout: 2500,
        textColor: 'white',
        actions: [{ icon: 'close', color: 'white' }],
    })
}

export default Notify
