import Transforms from './transforms'

const endpoints = {
    get: {
        path: '/event-consumption',
        transform: Transforms.get,
    },
    detail: {
        path: '/event-consumption/detailed',
        transform: Transforms.detail,
    },
}

export default {
    name: 'eventConsumption',
    endpoints,
}
