<template>
    <QDialog
        :modelValue="visible"
        persistent
    >
        <QCard>
            <QCardSection class="row items-center">
                <div class="spinner-cube">
                    <div class="cube1" />
                    <div class="cube2" />
                </div>
                <span class="ml-2">{{ $t('visualization.exportLoadingDialog') + ' ' + format }}</span>
            </QCardSection>
        </QCard>
    </QDialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        format: {
            type: String,
            required: true,
        },
    },
}
</script>

  <style scoped>
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner-cube {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.cube1, .cube2 {
  background-color: #279096;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  animation: cubeMove 1.8s infinite ease-in-out;
}

.cube2 {
  animation-delay: -0.9s;
}

@keyframes cubeMove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
}
  </style>
