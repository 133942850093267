<template>
    <div
        v-if="showBanner"
        style="z-index: 5001; width: fit-content;"
        class="col bg-transparent fixed-bottom-right"
    >
        <div
            class="row q-ma-xs reverse"
        >
            <QBanner
                class="bg-yellow-4 reverse"
                rounded
                style="cursor: pointer;"
                @click="expandBanner = !expandBanner"
            >
                <template #avatar>
                    <QCircularProgress
                        indeterminate
                        size="43px"
                        :thickness="0.3"
                        color="gray"
                        class="q-ma-xs q-pr-md"
                    />
                    <QTooltip
                        v-if="!expandBanner"
                    >
                        {{ $t('visualization.infoModal.info') }}
                    </QTooltip>
                </template>
                <div
                    v-if="expandBanner"
                    class="text-center q-px-md"
                >
                    {{ $t('visualization.infoModal.title') }}
                    <br>
                    {{ $t('visualization.infoModal.info') }}
                </div>
            </QBanner>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest,
} from '@/api'

export default {
    name: 'InfoModal',
    props: {
        processId: VueTypes.string,
    },
    emits: ['refreshInfo'],
    data () {
        return {
            text: '',
            showBanner: false,
            expandBanner: false,
            interval: null,
        }
    },
    watch: {
        $route (to, from) {
            this.getSettings()
        },
    },
    methods: {
        getSettings () {
            apiRequest(Api().datasets.get({ datasetId: this.$route.params.processId }))
                .then(() => {
                    if (this.interval === null && this.showBanner) {
                        this.interval = setInterval(this.getSettings, 5000)
                    } else if (this.interval && !this.showBanner) {
                        clearInterval(this.interval)
                        this.$eventBus.emit('refreshInfo', 'InfoModal')
                    }
                })
        },
    },
}
</script>
