import i18n from '@/lang/i18n'

export const WIZARD_INITAL_STATE = {
    settings: {
        name: '',
        description: '',
    },
    config: {
        name: 'default',
        activity: null,
        end: null,
        endFormat: '',
        start: null,
        startFormat: '',
        traceId: null,
        uid: null,
    },
    file: {
        name: '',
        size: 0,
        type: '',
    },
}

export const WIZAR_MOCKS = {
    settings: {
        name: `Default Dataset`,
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
    },
    file: {
        name: 'Foo.csv',
        size: 10024,
        type: 'csv',
    },
}

export const config = options => ({
    title: i18n.global.t('wizard.configs.dataset.title'),
    step: 'settings',
    steps: [
        {
            name: 'settings',
            component: 'StepSettings',
            title: i18n.global.t('wizard.configs.dataset.steps.0'),
            icon: 'settings',
            mock: 'settings',
            next: 'submitStep0',
        },
        {
            name: 'upload',
            component: 'StepUpload',
            title: i18n.global.t('wizard.configs.dataset.steps.1'),
            icon: 'cloud_upload',
            next: 'submitStep1',
        },
        {
            name: 'keys',
            component: 'StepKeyFields',
            title: i18n.global.t('wizard.configs.dataset.steps.2'),
            icon: 'vpn_key',
            mock: 'config',
            next: 'submitStep2',
        },
        {
            name: 'relevant',
            component: 'StepRelevantFields',
            title: i18n.global.t('wizard.configs.dataset.steps.3'),
            icon: 'vpn_key',
            next: 'submitStep3',
            end: true,
        },
    ],
})

const Setup = options => ({
    config: config(options),
    initialState: WIZARD_INITAL_STATE,
    mocks: WIZAR_MOCKS,
})

export default Setup
