<template>
    <QInput
        ref="input"
        v-model="text"
        standout
        outlined
        labelColor="primary"
        color="primary"
        :label="label"
        :min="min"
        :rules="combinedRules()"
        :placeholder="placeholder"
        :lazyRules="lazyRules"
        :dense="dense"
        @update:modelValue="inputFormat"
    />
</template>

<script>

import VueTypes from 'vue-types'

export default {
    name: 'InputNumeric',
    inject: ['App'],
    props: {
        modelValue: VueTypes.oneOfType([
            VueTypes.string,
            VueTypes.number,
        ]).def(0),
        decimalNum: VueTypes.number.def(-1),
        intNum: VueTypes.number,
        decimalStrictLimit: VueTypes.bool.def(false), // if true number of decimals will be limited to decimalNum prop if false it will show a warning
        intStrictLimit: VueTypes.bool.def(false), // if true number of ints will be limited to intNum prop if false it will show a warning
        input: VueTypes.func,
        rules: VueTypes.array.def([]),
        label: VueTypes.string,
        placeholder: VueTypes.string,
        lazyRules: VueTypes.bool.def(false),
        dense: VueTypes.bool.def(false),
        min: VueTypes.any.def(false),
    },
    data () {
        return {
            text: '',
            number: 0,
            hasError: false,
        }
    },
    watch: {
        modelValue (val) {
            if (val !== this.number) {
                this.number = val
                this.text = this.App.numberLocationFormat(val, true)
            }
        },
    },
    mounted () {
        this.text = this.App.numberLocationFormat(this.modelValue, true)
        this.formatNumber()
    },
    methods: {
        validate () {
            const bool = this.$refs.input.validate()
            this.hasError = this.$refs.input.hasError
            return bool
        },
        combinedRules () {
            return [
                val => this.checkIfAdmitDecimal(this.decimalNum) || this.$t('visualization.inputNum.DecimalNotAllowed'),
                val => this.checkDecimals(this.decimalNum) || this.$t('visualization.inputNum.DecimalNumError', { maxDecimalNum: this.decimalNum }),
                val => this.checkInts(this.intNum) || this.$t('visualization.inputNum.IntNumError', { maxIntNum: this.intNum }),
                ...this.rules, // extra rules
            ]
        },
        inputFormat () {
            this.formatNumber()
            if (this.input) {
                this.input()
            }
        },
        formatNumber () {
            this.removeCommas()
            this.filterNum()
            this.number = this.text.replace(',', '.')
            this.$emit('update:modelValue', Number(this.number))
        },
        filterDecimalQuantity () {
            if (this.decimalStrictLimit) {
                const pattern = new RegExp(`^-?\\d+(\\.|\\,\\d{1,${this.decimalNum}})?$`)
                // print 2º group if it exists
                this.filteredText = this.number.replace(pattern, '')
            }
        },
        filterIntQuantity () {
            if (this.intStrictLimit) {
                const pattern = new RegExp(`^(\\d{0,${this.intNum}})\\d*(\\.?\\d*)$`)
                this.text = this.text.replace(pattern, '$1$2')
                // Limitar la parte entera
                const integerPart = this.text.split('.')[0].slice(0, this.intNum)
                // Limitar la parte decimal
                const decimalPart = this.text.split('.')[1] ? this.text.split('.')[1].slice(0, this.decimalNum) : ''
                // Combinar las partes
                this.text = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
            }
        },

        filterNum () {
            // use , or . to split depending on which one contains the text
            const splitText = this.text.includes(',') ? this.text.split(',') : this.text.split('.')
            if (this.intStrictLimit) {
                // split the integer part to intNum digits
                splitText[0] = splitText[0].slice(0, this.intNum)
            }
            if (this.decimalStrictLimit) {
                // split the decimal part to decimalNum digits
                if (splitText[1] !== undefined) {
                    splitText[1] = splitText[1].slice(0, this.decimalNum)
                }
            }
            // join the parts with a dot
            this.text = this.text.includes(',') ? splitText.join(',') : splitText.join('.')
        },
        removeCommas () {
            this.text = this.App.removeCommas(this.text)
        },
        checkIfAdmitDecimal (decimalNum) {
            if (decimalNum !== -1) {
                if (decimalNum === 0) {
                    return !(this.App.removeCommas(this.text).includes('.') || this.App.removeCommas(this.text).includes(','))
                }
                return true
            }
            return true
        },
        checkDecimals (decimalNum) {
            if (decimalNum !== -1) {
                // check if the number of decimalNum is correct
                const regex = new RegExp(`^-?\\d+(\\.\\d{0,${decimalNum}})?$`)
                return regex.test(this.number)
            }
            return true
        },
        checkInts (intNum) {
            if (intNum) {
                // check if the number of ints is correct
                const regex = new RegExp(`^-?\\d{1,${intNum}}(\\.\\d*)?$`)
                return regex.test(this.number)
            }
            return true
        },
    },
}
</script>
