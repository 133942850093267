export default {
    process: {
        summary: 'Resumo geral',
        alternative: 'Alternativa principal',
        duration: 'Análise de duração',
        frequency: 'Análise de frequência',

        processAlternatives: 'Alternativas de processo',
        activities: 'Actividades',
        transitions: 'Transições',
        durationHours: 'Duração (horas)',
        numberOfCases: 'Number of cases',
        numberOfCasesDuration: 'Número de casos por duração',
        caseDuration: 'Histograma da duração dos casos',

        fequentActivities: 'Actividades mais frequentes',
        fequentAlternatives: 'Alternativas de processo mais frequentes',

        alternatives: 'alternativas',
        avg: 'Tempo médio do ciclo',

        averageDuration: 'Duração média das actividades',
        standardDeviation: 'Desvio padrão das actividades',

        averageDurationAlertnatives: 'Duração média das alternativas de processo',
        standardDeviationAlertnatives: 'Desvio padrão das alternativas de processo',

        averageTransition: 'Tempos médios de transição',
        transitionDeviations: 'Desvios do tempo de transição',
    },

    query: {
        conformance: 'Resumo da conformidade',
        duration: 'Informações sobre a duração',
        traces: 'traços',
        averageDuration: 'Avg duration',
        nonCompliantTracesPercentage: 'Non compliant',
        compliantTracesPercentage: 'Compliant',
        complianceIndicator: 'Compliance indicator: {complianceIndicator}',
        casesQt: 'Number of cases: {casesQt}',
        insightResults: 'Insight Results',
        insightModel: 'Insight model',
        insightConstraints: 'Insight constraints',
        casesAnalyzed: 'Cases analyzed',
        averageFitness: 'Average trace fitness value',
    },

    notifications: {
        download: {
            text: 'Report downloaded successfully.',
        },
    },
}
