export default {
    new: 'Nuevo proyecto',
    delete: 'Eliminar proyecto',
    edit: 'Editar proyecto',
    showActives: 'Mostrar proyectos activos',

    sections: {
        datasets: 'Datos',
        models: 'Modelos',
        discovery: 'Descubrimientos',
        queries: 'Insights',
    },

    notifications: {
        noResult: {
            title: 'No se han encontrado resultados',
            text: 'No se ha encontrado ningún proyecto para tu búsqueda.',
        },
        empty: {
            title: 'No se han encontrado proyectos',
            text: 'Puedes crear tu primer proyecto.',
        },
        created: {
            text: 'Proyecto creado correctamente.',
        },
        updated: {
            text: 'Proyecto actualizado correctamente.',
        },
        deleted: {
            text: 'Proyecto eliminado correctamente.',
        },
        archived: {
            text: 'Proyecto archivado correctamente.',
        },
    },

    modals: {
        delete: {
            text: '¿Seguro que quieres eliminar el proyecto "{name}"?',
        },
        upload: {
            title: 'Subir archivo de datos',
            text: 'Elige un archivo `.csv` para la subida',
        },
    },

    datasets: {
        new: 'Importar archivo',
        delete: 'Eliminar conjunto de datos',
        edit: 'Editar conjunto de datos',
        infoText: 'Información del conjunto de datos',
        errors: 'Errores',
        errorMessage: 'No existe información adicional disponible para este conjunto de datos actualmente.',
        generalError: 'Se detectó un error sin identificar al procesar el origen de datos. Contacta por favor con el administrador.',

        moveDataset: 'Mover Conjunto de Datos',
        createdByMe: 'Creadas por mí',
        sharedWithMe: 'Compartidas conmigo',
        move: 'Mover',
        missingData: 'Faltan datos necesarios para mover el conjunto de datos.',
        sameProjectError: 'No puedes mover el conjunto de datos al mismo proyecto.',
        moveSuccess: 'Conjunto de datos movido exitosamente.',

        import: 'Conectores',
        discovery: 'Descubrimiento',
        query: 'Insight',

        properties: {
            events: 'Eventos',
            activities: 'Actividades',
            arcs: 'Arcos',
            traces: 'Trazas',
            variants: 'Variantes',
            createdAt: 'Creado',
            sourceType: 'Tipo de fuente',
            source: 'Fuente',
        },
        notifications: {
            noResult: {
                title: 'No se han encontrado resultados',
                text: 'No hemos encontrado ningún resultado para la búsqueda.',
            },
            empty: {
                title: 'No se han encontrado conjuntos de datos',
                text: 'Puedes importar tu primer archivo.',
            },
            created: {
                text: 'Nombre y descripción recibidos correctamente.',
            },
            updated: {
                text: 'Datos actualizados correctamente.',
            },
            deleted: {
                text: 'Datos eliminados correctamente.',
            },
        },
        states: {
            running: 'PROCESANDO',
            submitted: 'ENVIANDO',
            finished: 'LISTO',
            error: 'ERROR',
            cancelled: 'CANCELADO',
            timeout: 'EXPIRADO',
        },

        settings: {
            title: 'Configuración del set de datos',
            back: 'Volver a la vista de sets de datos',
            tabs: {
                general: 'General',
                updates: 'Actualizaciones',
                errors: 'Errores',
            },
            general: {
                nameTitle: 'Nombre del set de datos',
                nameField: 'Nombre',
                descriptionField: 'Descripción',
                updateName: 'Actualizar',
                generalTitle: 'Information general',
                sourceType: 'Tipo de origen',
                sourceName: 'Nombre del origen',
                creation: 'Fecha de creación',
                lastUpdate: 'Última actualización',
                metricsTitle: 'Métricas de log y proceso',
                events: 'Eventos',
                activities: 'Actividades',
                cases: 'Casos',
                variants: 'Variantes',
                arcs: 'Arcos',
            },
            updates: {
                pushTitle: 'Actualizaciones push',
                pushEnabled: 'Actualizaciones push habilitadas',
                pushDisabled: 'Actualizaciones push deshabilitadas',
                pullTitle: 'Actualizaciones pull',
                pullToggle: 'Habilitar actualizaciones pull',
                frequency: 'Frecuencia de actualización (a partir de la última actualización)',
                value: 'Valor',
                unit: 'Unidad',
                evalValue: 'Sólo se admiten valores hasta 60',
                timeGranularity: {
                    minutes: 'minutos',
                    hours: 'horas',
                    days: 'días',
                    weeks: 'semanas',
                    months: 'meses',
                },
                saveChanges: 'Guardar cambios',
                notReady: 'El set de datos no está disponible para actualizaciones',
            },
            errors: {
                noErrors: 'No se encontraron errores en este set de datos',
            },
            okMessage: 'Configuración actualizada correctamente',
            failMessage: 'Comprueba los valores de configuración antes de guardar cambios',
        },

        modals: {
            delete: {
                text: '¿Seguro que quieres eliminar el conjunto de datos "{name}"?',
            },
            discovery: {
                text: 'Se lanzará un nuevo descubrimiento de proceso. ¿Continuar?',
            },
        },
    },

    models: {
        upload: 'Subir BPMN',
        empty: {
            title: 'No se encontraron modelos',
            text: 'Puedes importar tu primer modelo BPMN',
        },
        edit: 'Actualizar nombre de modelo',
        delete: 'Borrar modelo',
        preview: 'Previsualizar modelo',
        notifications: {
            noResult: {
                title: 'No se encontraron resultados',
                text: 'No hemos encontrado ningún modelo sobre tu búsqueda.',
            },
            empty: {
                title: 'No se encontraron modelos',
                text: 'Puedes importar tu primer modelo.',
            },
            created: {
                text: 'Modelo importado con éxito.',
            },
            updated: {
                text: 'Modelo actualizado con éxito.',
            },
            deleted: {
                text: 'Modelo eliminado con éxito.',
            },
        },
        source: {
            uploaded: 'IMPORTADO',
            dataset: 'DATA SET',
        },
        modals: {
            delete: {
                text: '¿Seguro que quieres eliminar el modelo?',
            },
            upload: {
                title: 'Subir modelo BPMN',
                name: 'Nombre del modelo',
                file: 'Elige un fichero BPMN',
                submit: 'Importar',
                rejected: 'Sólo se permiten ficheros BPMN de hasta 1MB.',
            },
        },
    },

    discoveries: {
        new: 'Nuevo descubrimiento',
        cancel: 'Cancelar descubrimiento',
        delete: 'Eliminar descubrimiento',
        edit: 'Editar descubrimiento',

        notifications: {
            noResult: {
                title: 'No se han encontrado resultados',
                text: 'No se ha encontrado ningún descubrimiento para tu búsqueda.',
            },
            empty: {
                title: 'No se han encontrado descubrimientos',
                text: 'Puedes realizar tu primer descubrimiento.',
            },
            created: {
                text: 'Descubrimiento creado correctamente.',
            },
            updated: {
                text: 'Descubrimiento actualizado correctamente.',
            },
            deleted: {
                text: 'Descubrimiento eliminado correctamente.',
            },
            archived: {
                text: 'Descubrimiento archivado correctamente.',
            },
        },

        modals: {
            cancel: {
                text: '¿Seguro que quieres cancelar este descubrimiento?',
            },
            delete: {
                text: '¿Seguro que quieres eliminar este descubrimiento?',
            },
        },
    },

    queries: {
        new: 'Nuevo insight',
        cancel: 'Cancelar insight',
        delete: 'Eliminar insight',
        edit: 'Editar datos de insight',

        notifications: {
            noResult: {
                title: 'No se han encontrado resultados',
                text: 'No se ha encontrado ningún insight para tu búsqueda.',
            },
            empty: {
                title: 'No se han encontrado insights',
                text: 'Puedes crear tu primer insight.',
            },
            created: {
                text: 'Insight creado correctamente.',
            },
            updated: {
                text: 'Insight actualizado correctamente.',
            },
            deleted: {
                text: 'Insight eliminado correctamente.',
            },
            cancel: {
                text: 'Insight cancelado correctamente.',
            },
            download: {
                text: 'Insight descargado correctamente.',
            },
        },
        states: {
            running: 'PROCESANDO',
            submitted: 'ENVIANDO',
            finished: 'LISTO',
            error: 'ERROR',
        },

        modals: {
            cancel: {
                text: '¿Seguro que quieres cancelar este insight?',
            },
            delete: {
                text: '¿Seguro que quieres eliminar este insight?',
            },
        },
    },
    variants: {
        changeOrderCriteria: 'Cambiar criterio de orden',
        order: {
            frequency: 'Frecuencia (freq)',
            mean: 'Duracion media (avg)',
            median: 'Duración mediana (med)',
            deviation: 'Desviación típica de duración (sd)',
        },
        orderAbbreviations: {
            frequency: 'frec',
            mean: 'media',
            median: 'mediana',
            deviation: 'desv',
        },
        changeOrder: 'Cambiar orden',
        orderOptions: {
            ascending: 'Ascendente (asc)',
            descending: 'Descendente (desc)',
        },
        orderOptionsAbbreviations: {
            ascending: 'asc',
            descending: 'desc',
        },
    },
}
