<template>
    <div class="MainContainer">
        <q-inner-loading :showing="!perfChartsValues || !Object.keys(perfChartsValues).length" />
        <div class="ChartContainer">
            <v-chart
                ref="currentPerf"
                :option="currentPerf"
                class="Chart"
            />
        </div>
        <div class="ChartContainer">
            <v-chart
                ref="improvedPerf"
                :option="improvedPerf"
                class="Chart"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'PerformanceCharts',
    inject: ['App'],
    props: {
        perfChartsValues: VueTypes.object,
    },
    data () {
        return {
            currentPerf: {
                title: {
                    text: this.$t('booster.improvement.improvementFigures.currentPerformance'),
                    left: 'center',
                    textStyle: {
                        color: '#33699a',
                        fontSize: 14,
                    },
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        // const { name } = params
                        const { value } = params
                        const { color } = params
                        const { label } = params.data
                        tooltipContent += `${label}<br>`
                        tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                            </div>`
                        tooltipContent += `${this.App.numberLocationFormat(this.numFormatter(value), true)} ${this.$t('booster.improvement.improvementFigures.cases')} <br>`
                        tooltipContent += `</div>`
                        return tooltipContent
                    },
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        label: {
                            position: 'inner',
                            color: 'white',
                            fontSize: 18,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },
            improvedPerf: {
                title: {
                    text: this.$t('booster.improvement.improvementFigures.improvedPerformance'),
                    left: 'center',
                    textStyle: {
                        color: '#33699a',
                        fontSize: 14,
                    },
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        // const { name } = params
                        const { value } = params
                        const { color } = params
                        const { label } = params.data
                        tooltipContent += `${label}<br>`
                        tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                            </div>`
                        tooltipContent += `${this.App.numberLocationFormat(this.numFormatter(value), true)} cases<br>`
                        tooltipContent += `</div>`
                        return tooltipContent
                    },
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        label: {
                            position: 'inner',
                            color: 'white',
                            fontSize: 18,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },

        }
    },
    computed: {
    },
    watch: {
        perfChartsValues: {
            handler (value) {
                if (value) {
                    this.refreshValues()
                }
            },
            immediate: true,
        },
    },
    mounted () {
        this.refreshValues()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount  () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (this.$refs.improvedPerf) {
                setTimeout(() => {
                    this.$refs.improvedPerf.resize()
                }, 500)
            }
            if (this.$refs.currentPerf) {
                setTimeout(() => {
                    this.$refs.currentPerf.resize()
                }, 500)
            }
        },
        refreshValues () {
            this.improvedPerf.series[0].data = [
                {
                    value: this.perfChartsValues.improvedSlaComplianceAbsolute,
                    name: `${this.App.numberLocationFormat(this.perfChartsValues.improvedSlaCompliancePercentage, true)}%`,
                    itemStyle: {
                        color: '#40E196',
                    },
                    label: this.$t('booster.improvement.improvementFigures.slaCompliance'),
                },
                {
                    value: this.perfChartsValues.improvedSlaNonComplianceAbsolute,
                    name: `${this.App.numberLocationFormat(this.perfChartsValues.improvedSlaNonCompliancePercentage, true)}%`,
                    itemStyle: {
                        color: '#fc7474',
                    },
                    label: this.$t('booster.improvement.improvementFigures.slaNonCompliance'),
                },
            ]
            this.currentPerf.series[0].data = [
                {
                    value: this.perfChartsValues.currentSlaComplianceAbsolute,
                    name: `${this.App.numberLocationFormat(this.perfChartsValues.currentSlaCompliancePercentage, true)}%`,
                    itemStyle: {
                        color: '#40E196',
                    },
                    label: this.$t('booster.improvement.improvementFigures.slaCompliance'),
                },
                {
                    value: this.perfChartsValues.currentSlaNonComplianceAbsolute,
                    name: `${this.App.numberLocationFormat(this.perfChartsValues.currentSlaNonCompliancePercentage, true)}%`,
                    itemStyle: {
                        color: '#fc7474',
                    },
                    label: this.$t('booster.improvement.improvementFigures.slaNonCompliance'),
                },
            ]
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style scoped lang="scss">
.MainContainer {
    position: relative;
    height:35vh;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
.ChartContainer {
    height:85%;
    width:48%;
    background-color: #fbfbfb;
}

</style>
