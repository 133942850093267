<template>
    <Modal
        :isOpen="opened"
        class="Upload"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('projects.models.modals.upload.title') }}
            </WText>
        </template>
        <template #body>
            <QForm class="align-center q-gutter-md q-ma-md">
                <QInput
                    v-model="modelName"
                    :label="$t('projects.models.modals.upload.name')"
                    outlined
                    stackLabel
                    dense
                    :rules="[val => !!val || 'Field is required']"
                />
                <QFile
                    v-model="file"
                    :label="$t('projects.models.modals.upload.file')"
                    dense
                    stackLabel
                    accept=".bpmn"
                    maxFileSize="1048576"
                    :rules="[val => !!val || 'Field is required']"
                    @rejected="onRejected"
                >
                    <template #prepend>
                        <QIcon name="attach_file" />
                    </template>
                </QFile>
                <QBtn
                    :label="$t('projects.models.modals.upload.submit')"
                    noCaps
                    color="primary"
                    unelevated
                    @click="handleUploadModel"
                />
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal } from '@/components'

export default {
    name: 'Upload',
    components: {
        Modal,
    },
    emits: [
        'import', 'rejectedFile',
    ],
    data () {
        return {
            opened: false,
            modelName: undefined,
            file: undefined,
        }
    },
    methods: {
        open () {
            this.reset()
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            this.modelName = undefined
            this.file = undefined
        },
        handleUploadModel () {
            this.$emit('import', {
                modelName: this.modelName,
                file: this.file,
            })
        },
        onRejected () {
            this.$emit('rejectedFile')
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 400px;
    }
}
</style>
