<template>
    <div style="display: flex; align-items: center;">
        <q-circular-progress
            :value="value"
            :innerMin="minValue"
            :max="100"
            size="65px"
            class="q-mr-md"
            :color="color"
            showValue
        >
            <div
                class="q-mt-sm"
                style="color: #000000; font-size: 18px;"
            >
                {{ value }}%
            </div>
            <q-tooltip>
                {{ value }}{{ $t('visualization.frequencyIndicator.percentOfActivities') }}
            </q-tooltip>
        </q-circular-progress>
        <div class="value-buttons">
            <div
                class="triangle-button up q-mb-sm"
                :class="value === 100 ? 'disabled' : ''"
                @click="increaseValue"
            />
            <div
                class="triangle-button down q-mt-sm"
                :class="value === minValue ? 'disabled' : ''"
                @click="decreaseValue"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'FrequencyIndicator',
    props: {
        validKnobValues: VueTypes.arrayOf(VueTypes.number).def([0, 100]),
    },
    emits: ['remove-activity', 'add-activity'],
    data () {
        return {
            color: 'primary',
            value: 100,
            minPath: [],
            minValue: 0,
            totalActivitiesNumber: 0,
        }
    },
    methods: {

        resetInitialValues () {
            this.color = 'primary'
            this.value = 100
            this.minValue = 0
        },
        updatePercent (value) {
            this.value = value
        },
        increaseValue () {
            if (this.value < 100) {
                this.$emit('add-activity')
            }
        },
        decreaseValue () {
            if (this.value > 0) {
                this.$emit('remove-activity')
            }
        },

        calcMinimumPercent (pathLength) {
            this.minValue = Math.round((pathLength / this.totalActivitiesNumber) * 100)
        },

        calcMinPath (cy, startId, endId) {
            const startNode = cy.getElementById(startId)
            this.totalActivitiesNumber = cy.elements().nodes().length - 2
            let path = []
            const visited = new Set()
            const stack = [startNode]

            while (stack.length > 0) {
                const currentNode = stack.pop()

                if (visited.has(currentNode.id())) {
                    // eslint-disable-next-line no-continue
                    continue
                }
                visited.add(currentNode.id())
                path.push(currentNode)

                if (currentNode.id() === endId) {
                    break
                }

                const neighbors = currentNode.neighborhood('node').filter(n => !visited.has(n.id()))

                if (neighbors.length === 0) {
                    path.pop()
                    if (path.length > 0) {
                        stack.push(path[path.length - 1])
                    }
                } else {
                    const nextNode = neighbors.reduce((maxNode, neighbor) => (neighbor.data('freq') > maxNode.data('freq')
                        ? neighbor
                        : maxNode), neighbors[0])
                    stack.push(nextNode)
                }
            }

            if (path.length === 0 || path[path.length - 1].id() !== endId) {
            // If algorithm can't find a path lets use A* to find one
                const astar = cy.elements().aStar({
                    root: startNode,
                    goal: cy.getElementById(endId),
                    weight: n => 1 / n.data('freq'),
                    directed: true,
                })

                path = astar.path.map(node => node)
            }
            this.calcMinimumPercent(path.filter(item => item.isNode()).length - 2)
            return path
        },
    },
}
</script>

<style lang="scss" scoped>
.value-buttons {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.triangle-button {
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
}
.triangle-button.up {
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent $primary transparent;
}

.triangle-button.down {
  border-width: 20px 10px 0 10px;
  border-color: $primary transparent transparent transparent;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
