import moment from 'moment'
import ColorMapService from '@/services/colormap'

const GRAPH_VALUES = {
    FREQ: 'freq',
    TIME: 'time',
    BOTH: 'both',
}

const HEAT_MAPS = {
    NONE: 'none',
    FREQ: 'freq',
    TIME: 'time',
}

const STATS_VALUES = {
    MEDIAN: 'median',
    AVERAGES: 'averages',
    VARIANCES: 'variances',
    MAX: 'max',
    MIN: 'min',
}

/**
 * Normalize date
 * @param {Number|String} ms
 */
const normalizeDuration = (seconds) => {
    if (seconds === undefined || seconds === '') return ''
    if (seconds === 0) return 0

    return `${seconds < 0 ? '-' : ''}${moment.duration(seconds, 'seconds').locale('precise-en').humanize()}`
}

/**
 * Normalize kpi value
 * @param {Number} value
 */
const normalizeBothMetrics = (duration, frequency) => {
    if (!duration || !frequency) {
        return `${frequency}\n${normalizeDuration(duration)}`
    }
    return `${frequency}\n|\n${normalizeDuration(duration)}`
}

/**
 * Get node data attrs with colors
 * @param {Number} heatMap.frequency
 * @param {Number} heatMap.duration
 * @param {Number} heatMap.kpi
 * @param {Boolean} heatMap.overlap
 */
const getHeatMapColors = ({
    frequency = '', duration, both = '',
} = {}) => ({
    ...(duration ? { time: ColorMapService.getDurColor(duration) } : {}),
    ...(both ? { both: ColorMapService.getDurColor(frequency) } : {}),
    ...(frequency ? { freq: ColorMapService.getDurColor(frequency) } : {}),
})

const getColor = overlap => (overlap ? { color: '#ef5350' } : { color: '#ef5053' })

/**
 * Get stat value of model
 * @param {Object} data
 * @param {String} statsModel must be equal to one of STATS_VALUES
 */
const getStatValue = (data, statsModel) => {
    if (!data) return ''
    const options = {
        [STATS_VALUES.MEDIAN]: data.med,
        [STATS_VALUES.AVERAGES]: data.avg,
        [STATS_VALUES.VARIANCES]: data.sd,
        [STATS_VALUES.MAX]: data.max,
        [STATS_VALUES.MIN]: data.min,
    }
    return options[statsModel] || ''
}

/**
 * Get node value
 * @param {Number} statistics.frequency
 * @param {Number} statistics.duration
 * @param {Number} statistics.kpi
 * @param {Object} model
 */
const getInfoModel = ({
    frequency = '', duration, attribute,
} = {}, model = {}) => {
    const { values: target, statsValue: statsModel } = model
    const options = {
        [GRAPH_VALUES.TIME]: normalizeDuration(getStatValue(duration, statsModel)),
        [GRAPH_VALUES.BOTH]: normalizeBothMetrics(getStatValue(duration, statsModel), frequency),
        [GRAPH_VALUES.FREQ]: frequency,
    }

    return options[target] !== undefined ? options[target] : options[GRAPH_VALUES.FREQ]
}

export default {
    getHeatMapColors,
    getColor,
    getInfoModel,
    getStatValue,
    graphValues: GRAPH_VALUES,
    heatmapValues: HEAT_MAPS,
    normalizeDuration,
}
