<template>
    <div v-if="datasetInfo.state.identifier === 'FINISHED'">
        <q-card
            flat
            bordered
            class="Personal mb-2 q-pa-md"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('projects.datasets.settings.updates.pushTitle') }}
            </WText>
            <QToggle
                v-model="pushValue"
                color="primary"
                :label="pushLabel"
                keepColor
                disable
            />
        </q-card>
        <q-card
            v-if="datasetInfo.source !== 'CSV'"
            flat
            bordered
            class="Personal mb-2 q-pa-md"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('projects.datasets.settings.updates.pullTitle') }}
            </WText>
            <QToggle
                v-model="pullValue"
                color="primary"
                :label="$t('projects.datasets.settings.updates.pullToggle')"
                keepColor
            />
            <Transition
                name="fade"
                mode="out-in"
                appear
            >
                <div
                    v-if="pullValue"
                    class="q-pa-md"
                >
                    <div
                        class="row q-gutter-md"
                    >
                        <InputNumeric
                            v-model="freqValue"
                            :lazyRules="true"
                            :decimalNum="0"
                            :decimalStrictLimit="true"
                            :intNum="2"
                            :intStrictLimit="true"
                            :label="$t('projects.datasets.settings.updates.frequency')"
                            :placeholder="$t('projects.datasets.settings.updates.value')"
                            :rules="[
                                value => validators.required(value.replace(',', '.')) || $t('validation.required', { attribute: $t('projects.datasets.settings.updates.value') }),
                                val => val.replace(',', '.') > 0 && val.replace(',', '.') <= 60 || $t('projects.datasets.settings.updates.evalValue') ]"
                            :input="removeCommas"
                            :dense="true"
                            class="col-3"
                        />
                        <QSelect
                            ref="timeSelect"
                            v-model="freqGranularity"
                            filled
                            :options="options"
                            :label="$t('projects.datasets.settings.updates.unit')"
                            stackLabel
                            dense
                            :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('projects.datasets.settings.updates.value') })]"
                            class="col-3"
                        />
                    </div>
                </div>
            </Transition>
        </q-card>
        <div
            v-if="datasetInfo.source !== 'CSV'"
            class="flex row-right-center"
        >
            <QBtn
                noCaps
                color="primary"
                :label="$t('projects.datasets.settings.updates.saveChanges')"
                @click="handleSubmit"
            />
        </div>
    </div>
    <div v-else>
        <QBanner class="bg-warning text-white">
            <template #avatar>
                <QIcon
                    name="warning"
                    color="white"
                />
            </template>
            {{ $t(`projects.datasets.settings.updates.notReady`) }}
        </QBanner>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import { notify } from '@/api'
import { InputNumeric } from '@/components'

export default {
    name: 'Updates',
    components: {
        InputNumeric,
    },
    mixins: [validatorsMixin],
    inject: ['App'],
    props: {
        datasetInfo: VueTypes.any,
    },
    emits: ['SetUpdates'],
    data () {
        return {
            pushValue: true,
            pullValue: false,
            freqValue: undefined,
            freqGranularity: undefined,
            options: [
                {
                    label: this.$t('projects.datasets.settings.updates.timeGranularity.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('projects.datasets.settings.updates.timeGranularity.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('projects.datasets.settings.updates.timeGranularity.days'),
                    value: 'days',
                },
                {
                    label: this.$t('projects.datasets.settings.updates.timeGranularity.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('projects.datasets.settings.updates.timeGranularity.months'),
                    value: 'months',
                },
            ],
        }
    },
    computed: {
        pushLabel () {
            return this.pushValue ? this.$t('projects.datasets.settings.updates.pushEnabled') : this.$t('projects.datasets.settings.updates.pushDisabled')
        },
    },
    mounted () {
        if (this.datasetInfo.updatesEnabled !== null) {
            this.pullValue = this.datasetInfo.updatesEnabled
        } else {
            this.pullValue = false
        }
        if (this.datasetInfo.frequency) {
            this.freqValue = this.App.numberLocationFormat(this.datasetInfo.frequency.value, true)
            this.freqGranularity = this.options.find(el => el.value === this.datasetInfo.frequency.unit)
        }
    },
    methods: {
        removeCommas () {
            this.freqValue = this.App.removeCommas(this.freqValue)
            this.freqValue = this.freqValue.replace(/^(\d{0,2})\d*(\.?\d*)$/, '$1$2')
        },
        handleSubmit () {
            if (this.pullValue) {
                if (this.$refs.freqField.validate() && this.$refs.timeSelect.validate()) {
                    const updateValues = {
                        updatesEnabled: this.pullValue,
                        frequency: {
                            value: this.freqValue.replace(',', '.'),
                            unit: this.freqGranularity.value,
                        },
                    }
                    this.$emit('SetUpdates', updateValues)
                } else {
                    notify({ message: this.$t('projects.datasets.settings.failMessage'), type: 'negative' })
                }
            } else {
                const updateValues = {
                    updatesEnabled: false,
                }
                this.$emit('SetUpdates', updateValues)
            }
        },
    },
}
</script>

<style scoped>
.FormClass{
    display: grid;
}
</style>
