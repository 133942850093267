export default {
    visualizationToBooster: 'Process Booster',
    boosterDisabledTooltip: 'You must configure the necessary conditions to access the Booster',
    customAnalysis: 'Custom Analysis',
    performanceBoosterTitle: 'Performance Booster',
    overview: {
        tab: 'Overview',
        description: 'Understand how your process currently performs',
        relevantNotDefined: 'Configure the relevant attributes to display the data',
        slaNotDefined: 'Configure the SLA conditions to display the data.',
        relevantAndSLANotDefined: 'Configure the relevant attributes and the SLA conditions to display the data.',
        caseCompletionNotDefined: 'Configure the case completion conditions to display the data.',
        relevantAndCaseNotDefined: 'Configure the relevant attributes and the case completion conditions to display the data.',
        slaAndCaseNotDefined: 'Configure the SLA conditions and the case completion conditions to display the data.',
        relevantAndSLAAndCaseNotDefined: 'Configure the relevant attributes, the SLA conditions and the case completion conditions to display the data.',
        costNotDefined: 'Configure the cost conditions to display the data.',
        relevantAndCostNotDefined: 'Configure the relevant attributes and the cost conditions to display the data.',
        slaAndCostNotDefined: 'Configure the SLA conditions and the cost conditions to display the data.',
        relevantAndSLAAndCostNotDefined: 'Configure the relevant attributes, the SLA conditions and the cost conditions to display the data.',
        noConditionsText: 'You can define the necessary conditions in the dataset settings.',
    },
    state: {
        performanceBy: 'Performance by ',
        rightClick: 'Right click on a bar to view graph',
        leadTimesBy: 'Lead times by ',
        timeSpentPerCase: 'Average time spent per case',
        performanceComparison: 'performance comparison',
        leadTimes: ' lead times',
        cases: ' cases',
        selectFilteringElements: 'Currently selected elements for resource filtering',
        evolutionOverTime: 'Performance evolution over time',
        overallPerformance: 'Overall performance by:',
        activity: 'Activity',
        transition: 'Transition',
        variant: 'Variant',
        months: 'months',
        weeks: 'weeks',
        all: 'All',
        below: 'Below SLA',
        intermediate: 'Intermediate',
        above: 'Above SLA',
        slaCompliance: 'SLA compliance',
        noTraces: 'No traces found for the current filters',
        performanceByClick: 'Click a left chart bar to select',
    },
    prediction: {
        tab: 'Prediction',
        description: 'Find out how your process will perform',
        predictionHighlights: {
            name: 'Prediction highlights',
            cases: 'cases',
            ongoingCases: 'ongoing cases ',
            meetingSLA: 'in condition of ',
            meetingSLAColor: 'meeting SLA',
            missingSLAColor: 'under risk ',
            missingSLA: 'of missing SLA',
            exceeding: 'already ',
            exceedingColor: 'exceeding SLA',
        },
        predictedPerformance: {
            name: 'Predicted performance by ',
            risk: 'Risk estimation of ongoing predictions',
            riskLabel: 'Risk',
            duration: 'Predicted duration versus past duration',
            durationLabel: 'Dur. SLA',
            distribution: 'SLA performance distribution',
            distributionLabel: 'Distr. SLA',
            past: 'Past',
            predicted: 'Predicted',
            finishedAbove: 'Finished above Sla',
            finishedUnder: 'Finished under Sla',
            finished: 'Finished',
            ongoingAbove: 'Ongoing above Sla',
            ongoingUnder: 'Ongoing under Sla',
            finishedOngoing: 'Finished - Ongoing',
            pastPredicted: 'Past - Predicted',
            ongoing: 'Ongoing',
            pastMonths: 'Past ',
            months: ' months',
        },
        ongoingCases: {
            showOnly: 'Show only for ',
            ongoingCaseList: 'Ongoing case list',
            state: 'State',
            caseId: 'Case ID',
            currentDuration: 'Current duration',
            estimatedDuration: 'Estimated duration',
            Good: 'Good',
            Risk: 'Risk',
            above: 'Above SLA',
            select: 'Please, select a value',

        },
        timeToMeet: {
            timeToMeet: 'Time to meet ',
            improvementRate: 'Minimum monthly improvement rate for meeting ',
            improvementRateSla: 'Minimum monthly improvement rate for ',
            improvementRateSecondPart: ' in ',
            past: 'Past ',
            months: ' months',
            bestPerforming: 'current best performing ',
        },
    },
    improvement: {
        tab: 'Simulation',
        description: 'Discover how to improve the performance of your process',
        activitiesImprove: 'Process activities to improve',
        transitionsImprove: 'Process transitions to improve',
        improvementResults: 'Improvement results on ',
        improvementTables: {
            rule1: 'Only numbers',
            rule2: '0-100',
            name: 'Name',
            currentImpact: 'Current Impact',
            improvement: 'Improvement',
            estimatedImpact: 'Estimated Impact',
        },
        improvementFigures: {
            title: 'Improvement figures',
            cases: ' cases',
            red: 'red ',
            green: ' green',
            become: 'that become',
            decrease: 'decrease',
            overallDuration: ' in overall duration',
            overallCosts: ' in overall costs',
            days: ' days',
            hours: 'hours',
            seconds: 'sec',
            currentPerformance: 'Current performance',
            improvedPerformance: 'Improved performance',
            slaCompliance: 'SLA compliance',
            slaNonCompliance: 'SLA non compliance',
        },
        improvementScope: {
            title: 'Improvement scope',
            add: 'Select and add attribute-value pairs to constrain the improvement scope',
            attributes: 'Attributes',
            values: 'Values',
        },
    },
    tabs: {
        overview: 'Overview',
        prediction: 'Prediction',
        improvement: 'Improvement',
    },
    d2t: {
        viewReport: 'View report',
        analysisReport: 'Analysis Report',
        copyToClipboard: 'Copy to clipboard',
        copied: 'Copied!',
    },
    alerts: {
        tab: 'Alerts',
        description: 'Set up and check alerts on real time',
        filterBy: 'Filter by',
        columns: {
            name: 'Name',
            severity: 'Severity',
            filterSet: 'Filter set',
            type: 'Type',
            notification: 'Notification',
        },
        tabs: {
            active: 'Active',
            history: 'History',
            definitions: 'Definitions',
        },
        detected: {
            noAlerts: 'No alerts to show',
            affected: 'Affected cases',
            lastDetected: 'Last detected',
            severity: 'Severity level',
            filterSet: 'Associated Filter Set',
            filterCases: 'Filter cases',
            dismiss: 'Dismiss',
            severityRiskLabels: {
                LOW: 'Low',
                MEDIUM: 'Medium',
                HIGH: 'High',
            },
            predictionRiskLabels: {
                LOW: 'SLA under risk',
                MEDIUM: 'SLA under risk',
                HIGH: 'SLA exceeded',
            },
        },
        definition: {
            create: 'Set up new alert',
            remove: 'Remove selected',
            name: 'Name',
            severity: 'Severity',
            type: 'Type',
            types: {
                ALL: 'All',
                STANDARD: 'Standard',
                PREDICTION: 'Prediction',
                TIME: 'Time',
            },
            risk: 'Risk',
            filterSet: 'Filter Set',
            notification: 'Notification',
            activity: 'Activity',
            period: 'Period',
            record: 'record',
            selected: 'selected of',
            createScreen: {
                newAlert: 'New alert definition',
                sendEmail: 'Send e-mail notification to',
                user: 'user\'s e-mail',
                other: 'different e-mail',
                notSend: 'do not send',
                notifyTo: 'Target e-mail address',
                cancel: 'Cancel',
                addAlert: 'Add alert',
            },
            tableColumn: {
                name: 'Name',
                severity: 'Severity',
                filterset: 'Filter Set',
                type: 'Type',
                notification: 'Notification',
            },
            activityPeriod: {
                hasStarted: 'Has started',
                hasEnded: 'Has ended',
            },
            timeUnit: {
                seconds: 'Seconds',
                minutes: 'Minutes',
                hours: 'Hours',
                days: 'Days',
                weeks: 'Weeks',
                months: 'Months',
            },
        },
        notifications: {
            created: 'Alert succesfully created',
            validateError: 'Please fill all the required fields properly',
        },
    },
}
