import moment from 'moment'
import {
    ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS, NVARIANTS_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'
import VueValuesStore from '../vue3-values/main'

export default {
    computed: {
        visualizationFilters () {
            return VueValuesStore.value.get('visualizationFilters')
        },
        generalOperator () {
            const operator = VueValuesStore.value.get('generalOperator')
            return operator || 'AND'
        },
    },
    methods: {
        setVisualizationFilters (selected) {
            VueValuesStore.value.set('visualizationFilters', selected)
        },
        setGeneralOperator (operator) {
            VueValuesStore.value.set('generalOperator', operator)
        },
        addVisualizationFilters (selected) {
            const { visualizationFilters } = this
            VueValuesStore.value.set('visualizationFilters', [...(visualizationFilters || []), selected])
        },
        addMultipleFilters (allSelected) {
            const { visualizationFilters } = this
            VueValuesStore.value.set('visualizationFilters', [...(visualizationFilters || []), ...allSelected])
        },
        updateFilters (filters) {
            const cached = this.visualizationFilters || []
            const formattedFilters = cached
                .map((f) => {
                    if (f.id !== 'FILTER_SET') {
                        const valuesComparison = f.values || f.value
                            ? this.valuesEqual(f.values || f.value, filters.values || filters.value)
                            : false
                        const keyComparison = f.key
                            ? this.valuesEqual(f.key, filters.values || filters.value)
                            : false
                        if (f.uuid === filters.uuid && (valuesComparison || keyComparison)) {
                            return { ...f, toDelete: true }
                        }
                        return f
                    }
                    if (f.uuid === filters.uuid) {
                        return { ...f, toDelete: true }
                    }
                    return f
                })
            this.setVisualizationFilters(formattedFilters)
        },
        getVisualizationFilters () {
            return VueValuesStore.value.get('filters')
        },
        resetStorage () {
            VueValuesStore.value.set('visualizationFilters', [])
        },
        formatFiltersParam (filters = []) { // TODO REFACTOR!!
            const sanitizedFilters = filters.filter(f => !f.pending)
            return sanitizedFilters.map(({
                id, key, kind, values, type = {}, activity, arc, attribute, constraints, nvariants, ...rest
            }) => {
                let filter = { id }
                if (ACTIVITY_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (ARC_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (DATE_FILTERS.includes(id)) {
                    const value = key || rest?.value
                    filter = { ...filter, values: [moment(value).format('YYYY-MM-DDTHH:mm:ss')] }
                }
                if (DURATION_FILTERS.includes(id)) {
                    let formattedValues = ''
                    if (!key) {
                        if (rest?.uuid.includes('ACTIVITIES_DURATION_GREATER_THAN') ||
                            rest?.uuid.includes('ACTIVITIES_DURATION_LESS_THAN') ||
                            rest?.uuid.includes('ACTIVITIES_DURATION_LOOP_GREATER_THAN') ||
                            rest?.uuid.includes('ACTIVITIES_DURATION_LOOP_LESS_THAN')
                        ) {
                            formattedValues = rest?.value.split(', ')
                        } else {
                            formattedValues = [rest?.value]
                        }
                    } else {
                        const [date, time] = key.split(' ')
                        const [days] = this.dateFormatter(date)
                        const [hours, minutes, seconds] = this.dateFormatter(time, ':')
                        const duration = {
                            days, hours, minutes, seconds,
                        }
                        const formattedKey = this.getDuration(duration)
                        if (id === 'ACTIVITIES_DURATION_GREATER_THAN' ||
                            id === 'ACTIVITIES_DURATION_LESS_THAN'
                        ) {
                            formattedValues = [type.activitySource, type.sourceOccurrence.value, type.activityTarget, type.targetOccurrence.value, formattedKey]
                        } else if (id === 'ACTIVITIES_DURATION_LOOP_GREATER_THAN' ||
                                   id === 'ACTIVITIES_DURATION_LOOP_LESS_THAN') {
                            formattedValues = [type.activitySource, type.activityTarget, formattedKey]
                        } else {
                            formattedValues = [formattedKey]
                        }
                    }

                    if (type.category || activity) filter = { ...filter, values: formattedValues, activity: type.category || activity }
                    else if (type.arc || arc) filter = { ...filter, values: formattedValues, arc: type.arc || arc }
                    else filter = { ...filter, values: formattedValues }
                }
                if (ATTRIBUTES_FILTERS.includes(kind) || ATTRIBUTES_FILTERS.includes(id)) {
                    let attrValues = []
                    if (constraints?.value === 'TRACE_ATTRIBUTES_CTG_IN' || constraints?.value === 'TRACE_ATTRIBUTES_CTG_NOT_IN') {
                        attrValues = rest?.value ? rest?.value : []
                    } else if (constraints?.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN') {
                        attrValues = rest?.value ? rest?.value : []
                    } else {
                        attrValues = rest?.value
                            ? [id === 'attrib_date'
                                ? moment(rest?.value).format('YYYY-MM-DDTHH:mm:ss')
                                : rest?.value,
                            ]
                            : []
                    }
                    filter = {
                        ...(activity?.value !== '*' ? { activity: activity?.value ? activity.value : activity } : {}),
                        attribute: attribute?.value ? attribute?.value : attribute,
                        id: constraints?.value ? constraints.value : id,
                        values: attrValues,
                    }
                }

                if (VARIANT_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (LOOP_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (NVARIANTS_FILTERS.includes(id)) {
                    const val = typeof key === 'number' ? key : key
                    filter = { ...filter, variantNumber: nvariants?.value || val, values: [''] }
                }
                return filter
            })
        },
        splitFilterAndFilterSets (totalFilters) {
            const onlyFilters = totalFilters.filter(filter => filter.id !== 'FILTER_SET')
            const filterSets = totalFilters.filter(filter => filter.id === 'FILTER_SET')
            const filterSetsUUIDs = filterSets.map(filterSet => filterSet.uuid)
            const filters = this.formatFiltersParam(onlyFilters)
            const { generalOperator } = this
            return { filters, filterSetsUUIDs, generalOperator }
        },

        getDuration (duration) {
            return moment.duration(duration).toISOString()
        },
        dateFormatter (dates, splitBy = '/') {
            return dates.split(splitBy).map(t => t.slice(0, -1))
        },
        arraysEqual (a, b) {
            if (a.length !== b.length) return false
            for (let i = 0; i < a.length; i += 1) {
                if (a[i] !== b[i]) return false
            }
            return true
        },
        valuesEqual (a, b) {
            if (Array.isArray(a) && Array.isArray(b)) {
                return this.arraysEqual(a, b)
            }
            if (typeof a === 'string' && typeof b === 'string') {
                return a === b
            }
            if (typeof a === 'number' && typeof b === 'number') {
                return a === b
            }
            return false
        },
    },
}
