<template>
    <div>
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-inner-loading :showing="!preview" />
        </Transition>
        <div
            v-if="preview"
            class="Step"
        >
            <Preview
                v-bind="preview"
                :modelValue="modelValue.config"
                :loading="isLoading"
                @update:modelValue="handleInput"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import Preview from './Preview/Preview.vue'

const PREVIEW_LENGTH = 10

const normalizeCols = (cols = []) => cols.map(col => ({
    align: 'left',
    field: col.name,
    label: col.name,
    name: col.name,
    selectableAsActivity: col.selectableAsActivity,
}))

const normalizeRows = (rows = []) => rows.map((data, index) => ({ ...data.row, index }))

export default {
    name: 'StepKeyFields',
    components: {
        Preview,
    },
    props: {
        datasetId: VueTypes.string,
        projectId: VueTypes.string,
        modelValue: VueTypes.shape({
            config: VueTypes.any.isRequired,
        }).loose,
    },
    emits: ['update:modelValue'],
    data () {
        return {
            preview: undefined,
            isLoading: false,
        }
    },
    mounted () {
        this.getPreview()
    },
    methods: {
        isValid () {
            const { traceId, activity, start } = this.modelValue?.config || {}
            return !!(traceId && activity && start)
        },
        handleInput (config) {
            this.$emit('update:modelValue', {
                ...this.modelValue,
                config,
            })
        },
        async getPreview () {
            const params = { length: PREVIEW_LENGTH }
            this.isLoading = true
            const { datasetId } = this
            if (datasetId) {
                apiRequest(Api().datasets.preview({ datasetId, params }))
                    .then((preview) => {
                        const { columns, rows } = preview || {}
                        this.preview = {
                            columns: normalizeCols(columns),
                            rows: normalizeRows(rows),
                        }
                        this.$emit('update:modelValue', { ...this.modelValue, preview })
                    })
                    .catch((error) => {
                        notifyError(error)
                    })
                    .finally(() => (this.isLoading = false))
            }
        },
    },
}
</script>
