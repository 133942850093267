<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ edit ? $t('visualization.settings.sla.activityGapSlaModal.editTitle') : $t('visualization.settings.sla.activityGapSlaModal.addTitle') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit="handleAddActivitySla"
            >
                <div class="RadioButtonsGroup row mt-1">
                    <QRadio
                        v-model="loopMode"
                        :disable="edit ? true : false"
                        :val="loopModeOptions.FIRSTTOLAST"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.firstToLast')"
                    />
                    <QRadio
                        v-model="loopMode"
                        :disable="edit ? true : false"
                        :val="loopModeOptions.CONSECUTIVELOOP"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.consecutiveLoop')"
                    />
                </div>
                <div class="OptionsGroup row">
                    <QSelect
                        v-model="firstActivity"
                        class="Field col"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :disable="edit ? true : false"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.selectFirstActivity')"
                        :options="activityOptions"
                    />
                    <QSelect
                        v-model="secondActivity"
                        class="Field col"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :disable="edit ? true : false"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.selectSecondActivity')"
                        :options="activityOptions"
                    />
                </div>
                <div
                    v-if="loopMode === 'firstToLast'"
                    class="OptionsGroup row"
                >
                    <QSelect
                        v-model="sourceOccurrence"
                        class="Field col"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :disable="edit ? true : false"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.sourceOcurrence')"
                        :options="ocurrenceOptions"
                    />
                    <QSelect
                        v-model="targetOccurrence"
                        class="Field col"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :disable="edit ? true : false"
                        :label="$t('visualization.settings.sla.activityGapSlaModal.targetOccurrence')"
                        :options="ocurrenceOptions"
                    />
                </div>
                <div class="OptionsGroup row">
                    <InputNumeric
                        v-model="value"
                        :decimalNum="0"
                        :decimalStrictLimit="true"
                        :label="$t('visualization.settings.sla.number')"
                        :min="1"
                        standout
                        outlined
                        type="number"
                        class="col"
                    />
                    <QSelect
                        v-model="unit"
                        outlined
                        class="col"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.sla.unit')"
                        :options="slaUnits"
                    />
                </div>
                <div
                    v-if="loopMode === 'firstToLast'"
                    class="row q-mx-md"
                >
                    <WText
                        tag="span"
                        :size="12"
                        weight="light"
                        class="mb-0"
                    >
                        {{ $t('visualization.settings.sla.activityGapSlaModal.firstToLastHelp') }}
                    </WText>
                </div>
                <div
                    v-if="loopMode === 'consecutiveLoop'"
                    class="row q-mx-md"
                >
                    <WText
                        tag="span"
                        :size="12"
                        weight="light"
                        class="mb-0"
                    >
                        {{ $t('visualization.settings.sla.activityGapSlaModal.consecutiveLoopHelp') }}
                    </WText>
                </div>
                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        unelevated
                        color="primary"
                        type="submit"
                    >
                        {{ $t('visualization.settings.sla.add') }}
                    </QBtn>
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal, InputNumeric } from '@/components'
import { Api, apiRequest, notifyError } from '@/api'

const LOOPMODEOPTIONS = {
    FIRSTTOLAST: 'firstToLast',
    CONSECUTIVELOOP: 'consecutiveLoop',
}

export default {
    name: 'ActivityGapSlaModal',
    components: {
        Modal,
        InputNumeric,
    },
    inject: ['App'],
    emits: [
        'addActivityGapSla',
    ],
    data () {
        return {
            opened: false,
            firstActivity: '',
            secondActivity: '',
            activityOptions: [],
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            value: 0,
            loopMode: LOOPMODEOPTIONS.FIRSTTOLAST,
            loopModeOptions: LOOPMODEOPTIONS,
            sourceOccurrence: { value: 'first', label: this.$t('visualization.settings.sla.activityGapSlaModal.first') },
            targetOccurrence: { value: 'last', label: this.$t('visualization.settings.sla.activityGapSlaModal.last') },
            edit: false,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
        ocurrenceOptions () {
            return [
                {
                    label: this.$t('visualization.settings.sla.activityGapSlaModal.first'),
                    value: 'first',
                },
                {
                    label: this.$t('visualization.settings.sla.activityGapSlaModal.last'),
                    value: 'last',
                },
            ]
        },
    },
    mounted () {
        this.getActivities()
    },
    methods: {
        open (editCustomSla = undefined) {
            if (editCustomSla !== undefined) {
                this.edit = true
                this.setValues(editCustomSla)
            } else {
                this.edit = false
                this.reset()
            }
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            [this.firstActivity, this.secondActivity] = this.activityOptions
            this.loopMode = LOOPMODEOPTIONS.FIRSTTOLAST
            this.sourceOccurrence = { value: 'first', label: this.$t('visualization.settings.sla.activityGapSlaModal.first') }
            this.targetOccurrence = { value: 'last', label: this.$t('visualization.settings.sla.activityGapSlaModal.last') }
            this.unit = { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') }
            this.value = 0
        },
        setValues (editCustomSla) {
            [this.firstActivity, this.secondActivity] = editCustomSla.elements
            this.loopMode = editCustomSla.loopMode
            this.sourceOccurrence = { value: editCustomSla.sourceOccurrence, label: this.$t(`visualization.settings.sla.activityGapSlaModal.${editCustomSla.sourceOccurrence}`) }
            this.targetOccurrence = { value: editCustomSla.targetOccurrence, label: this.$t(`visualization.settings.sla.activityGapSlaModal.${editCustomSla.targetOccurrence}`) }
            this.unit = { value: editCustomSla.unit, label: this.$t(`visualization.settings.sla.units.${editCustomSla.unit}`) }
            this.value = editCustomSla.value
        },
        getActivities () {
            const { processId } = this.$route.params
            const params = {}
            apiRequest(Api().visualizations.activities({ processId, params }))
                .then((res) => {
                    this.activityOptions = [...res.map(activity => activity.name).sort()]
                    /* eslint-disable prefer-destructuring */
                    this.firstActivity = this.activityOptions[0]
                    /* eslint-disable prefer-destructuring */
                    this.secondActivity = this.activityOptions[this.activityOptions.length - 1]
                })
                .catch(notifyError)
        },
        handleAddActivitySla () {
            this.$emit('addActivityGapSla', {
                type: 'activityGap',
                elements: [this.firstActivity, this.secondActivity],
                value: this.value,
                unit: this.unit.value,
                loopMode: this.loopMode,
                sourceOccurrence: this.loopMode === LOOPMODEOPTIONS.FIRSTTOLAST ? this.sourceOccurrence.value : '',
                targetOccurrence: this.loopMode === LOOPMODEOPTIONS.FIRSTTOLAST ? this.targetOccurrence.value : '',
            }, this.edit)
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 600px;
    }

    .RadioButtonsGroup {
        gap: 30px;
        align-items: center;
    }

    .HelpIcon {
        font-size: 1.5em;
    }

    .OptionsGroup {
        justify-content: space-between;
        gap: 10px;
    }

    .q-btn {
        padding-left: 25px;
        padding-right: 25px;
    }

    .Field {
        height: auto;
        &:deep(.q-field__control) {
                height: 50px;
                .q-field__control-container .q-field__native span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 34px;
                }
                .q-field__append {
                    height: 50px;
                }
        }
    }
}
</style>
