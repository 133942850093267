<template>
    <div class="MainContainer">
        <div
            class="PerformanceEvolutionContainer"
        >
            <q-inner-loading :showing="isLoadingPerfComp" />
            <div
                v-if="!isLoadingEvCont"
                class="PerformanceEvolutionTop"
            >
                <strong class="GraphTitle">{{ $t('booster.state.evolutionOverTime') }}</strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="selectedRange.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="range"
                        @update:model-value="performanceEvolution"
                    />
                </div>
            </div>
            <div class="PerformanceEvolutionChartContainer">
                <v-chart
                    v-if="!emptyEvolutionOT"
                    ref="performanceEvolution"
                    :option="performanceEvolutionOptions"
                    class="Chart"
                />
                <div
                    v-if="emptyEvolutionOT"
                    class="Empty"
                >
                    {{ $t('booster.state.noTraces') }}
                </div>
            </div>
        </div>
        <div class="GraphChartContainers">
            <q-inner-loading :showing="isLoadingPerfComp" />
            <div
                v-if="!isLoadingPerfComp"
                class="GraphChartContainersTop"
            >
                <strong class="GraphTitle"> {{ tab }} {{ $t('booster.state.performanceComparison') }} </strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="sortDirectionPerfComp.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="sort"
                        @update:model-value="asignData(perfCompResult)"
                    />
                </div>
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="optionSel"
                        toggleColor="primary"
                        dense
                        noCaps
                        size="0.8em"
                        padding="2px 5px"
                        :options="options"
                        @update:model-value="asignData(perfCompResult)"
                    />
                </div>
            </div>
            <div class="ChartContainer">
                <v-chart
                    v-if="!emptyPerfComparison"
                    ref="performanceCompOptions"
                    :option="performanceCompOptions"
                    class="Chart"
                />
                <div
                    v-if="emptyPerfComparison"
                    class="Empty"
                >
                    {{ $t('booster.state.noTraces') }}
                </div>
            </div>
        </div>
        <div class="GraphChartContainers BottomContainer">
            <q-inner-loading :showing="isLoadingLeadTimes" />
            <div
                v-if="!isLoadingLeadTimes"
                class="GraphChartContainersTop"
            >
                <strong class="GraphTitle"> {{ tab }} {{ $t('booster.state.leadTimes') }} </strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="sortDirectionLeadTimes.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="sort"
                        @update:model-value="ascDesc"
                    />
                </div>
            </div>
            <div class="ChartContainer">
                <v-chart
                    v-if="!emptyLeadTimes"
                    ref="leadTimesOptions"
                    :option="leadTimesOptions"
                    class="Chart"
                />
                <div
                    v-if="emptyLeadTimes"
                    class="Empty"
                >
                    {{ $t('booster.state.noTraces') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { Api, apiRequest, notifyError } from '@/api'

const bluePalette = ['#33699a', '#84b6f4', '#c4dafa', '#023047', '#a9d3ff']

export default {
    name: 'PerformanceByResource',
    components: {
    },
    inject: ['App'],
    inject: ['App'],
    props: {
        tab: VueTypes.string,
        selectedOptions: VueTypes.object,
    },
    data () {
        return {
            isLoadingEvCont: false,
            isLoadingPerfComp: false,
            isLoadingLeadTimes: false,
            maxDur: undefined,
            leftPerformanceCom: undefined,
            emptyPerfComparison: false,
            emptyLeadTimes: false,
            emptyEvolutionOT: false,
            perfCompResult: undefined,
            newPerformanceComparison: undefined,
            leadTimesByResult: [],
            sortDirectionPerfComp: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sortDirectionLeadTimes: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sort: [{
                value: 'ASC',
                icon: 'arrow_upward',
            },
            {
                value: 'DESC',
                icon: 'arrow_downward',
            },
            ],
            selectedRange: {
                value: 'MONTHS',
                label: this.$t('booster.state.months'),
            },
            range: [{
                value: 'MONTHS',
                label: this.$t('booster.state.months'),
            },
            {
                value: 'WEEKS',
                label: this.$t('booster.state.weeks'),
            },
            ],
            currentUnit: undefined,
            graphResponse: {},
            slaData: [],
            optionSel: 'SLACOMPLIANCEFREQ',
            options: [
                {
                    value: 'SLACOMPLIANCEFREQ',
                    label: 'abs',
                },
                {
                    value: 'SLACOMPLIANCEPERCENTAGE',
                    label: '%',
                },
            ],
            performanceEvolutionOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div style="max-width: 15em;">'
                        tooltipContent += `${this.lineBreak(params[0].name)}`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            if (value) {
                                let color
                                // eslint-disable-next-line prefer-destructuring
                                param.seriesName === 'SLA' ? color = 'red' : color = param.color
                                tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                    </div>`
                                tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]',
                                    { largest: 2, trim: false })} <br>`
                                tooltipContent += '</div>'
                            }
                        })
                        return tooltipContent
                    },
                },
                legend: {
                    formatter (name) {
                        if (name.length > 15) {
                            return `${name.substring(0, 15) }...`
                        }
                        return name
                    },
                },
                grid: {
                    left: 50,
                    right: 50,
                    bottom: 20,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: [],
            },
            leadTimesOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 60)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${seriesName}: ${moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    left: 95,
                    right: 25,
                    bottom: 20,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        filterMode: 'none',
                        showDetail: false,
                        showDataShadow: false,
                        start: 100,
                        maxSpan: 2,
                        maxValueSpan: 2,
                        minValueSpan: 2,
                        width: 7,
                        moveHandleSize: 0,
                        moveHandleStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                        },
                        brushStyle: {
                            color: 'rgba(200, 200, 200, 0.3)',
                            borderColor: 'transparent',
                            borderWidth: 0,
                        },
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },

                ],
                series: [
                    {
                        name: this.$t('booster.state.above'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        barCategoryGap: '50%',
                        silent: true,
                    },
                    {
                        name: this.$t('booster.state.below'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#40E196',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        silent: true,
                    },
                ],
            },
            performanceCompOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 60)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            this.optionSel === 'SLACOMPLIANCEFREQ'
                                ? tooltipContent += `${seriesName}: ${value} ${this.$t('booster.state.cases')}`
                                : tooltipContent += `${seriesName}: ${value} %`
                            tooltipContent += '<br></div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    right: 25,
                    bottom: 20,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        filterMode: 'none',
                        showDetail: false,
                        showDataShadow: false,
                        start: 100,
                        maxSpan: 3,
                        maxValueSpan: 3,
                        minValueSpan: 3,
                        width: 7,
                        moveHandleSize: 0,
                        moveHandleStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                        },
                        brushStyle: {
                            color: 'rgba(200, 200, 200, 0.3)',
                            borderColor: 'transparent',
                            borderWidth: 0,
                        },
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                        progressive: 200,
                        progressiveChunkMode: 'mod',
                    },

                ],
                series: [
                    {
                        name: this.$t('booster.state.above'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 15,
                        silent: true,
                    },
                    {
                        name: this.$t('booster.state.below'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                            formatter: {},
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#40E196',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 15,
                        silent: true,
                    },
                ],
            },
        }
    },
    watch: {
        // eslint-disable-next-line func-names
        selectedOptions: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
    mounted () {
        this.getData()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            const refsToResize = [
                this.$refs.performanceEvolution,
                this.$refs.leadTimesOptions,
                this.$refs.performanceCompOptions,
            ]
            setTimeout(() => {
                refsToResize.forEach((ref) => {
                    if (ref) {
                        ref.resize()
                    }
                })
            }, 500)
        },
        getData () {
            this.performanceEvolution()
            this.performanceComparison()
            this.leadTimes()
        },
        performanceEvolution () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                temporalUnit: this.selectedRange.value,
            }
            const { processId } = this.$route.params
            this.isLoadingEvCont = true
            apiRequest(Api().booster.evolutionOverTime({ processId, params }))
                .then((data) => {
                    let parsedData
                    try {
                        parsedData = JSON.parse(data)
                    } catch (e) {
                        parsedData = data
                    }
                    if (parsedData.rows.length > 0) {
                        this.emptyEvolutionOT = false
                        this.graphResponse = parsedData
                        const { rows } = parsedData

                        let allTimeStrings = new Set()
                        rows.forEach((row) => {
                            row.rows.forEach((item) => {
                                allTimeStrings.add(item.timeString)
                            })
                        })

                        allTimeStrings = Array.from(allTimeStrings).sort((a, b) => {
                            const dateA = this.getDateFromString(a)
                            const dateB = this.getDateFromString(b)
                            return dateA - dateB
                        })
                        const series = []
                        rows.forEach((row) => {
                            const seriesData = {
                                name: row.value,
                                type: 'line',
                                data: [],
                                lineStyle: {
                                    color: bluePalette[series.length % bluePalette.length],
                                },
                                symbolSize: 'auto',
                                itemStyle: {
                                    color: bluePalette[series.length % bluePalette.length],
                                },
                            }

                            const durationMap = new Map(row.rows.map(item => [item.timeString, item.duration]))
                            this.maxDur = this.rangeDuration(Math.max.apply(null, [row.rows.map(item => (item.duration))][0]))
                            allTimeStrings.forEach((timeString) => {
                                const duration = durationMap.has(timeString) ? durationMap.get(timeString) : undefined
                                seriesData.data.push(duration !== undefined && duration !== 0 ? this.formatSeconds(duration, this.maxDur) : undefined)
                            })

                            series.push(seriesData)
                        })
                        this.performanceEvolutionOptions.series = series
                        this.performanceEvolutionOptions.legend.type = series.length > 6 ? 'scroll' : 'plain'
                        this.performanceEvolutionOptions.grid.top = series.length > 6 || series.length < 4 ? 30 : 50
                        this.performanceEvolutionOptions.xAxis.data = allTimeStrings
                        this.performanceEvolutionOptions.series.push({
                            name: 'SLA',
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {
                                color: 'red',
                                type: 'dotted',
                            },
                            itemStyle: {
                                color: 'red',
                            },
                            data: Array.from(allTimeStrings, () => this.formatSeconds(parsedData.sla, this.maxDur)),
                        })
                    } else {
                        this.emptyEvolutionOT = true
                    }
                })
                .catch()
                .finally(() => (this.isLoadingEvCont = false))
        },
        getDateFromString (timeString) {
            if (timeString.startsWith('Week')) {
                const [, week, year] = timeString.split(' ')
                return new Date(`${year}-01-01`).setDate(7 * (parseInt(week, 10) - 1) + 4)
            }

            const monthYearFormat = timeString.split(' ')
            // eslint-disable-next-line no-restricted-globals
            if (monthYearFormat.length === 2 && !isNaN(new Date(`${monthYearFormat[0]} 1, ${monthYearFormat[1]}`))) {
                return new Date(`${timeString} 1`).getTime()
            }
            return 0
        },
        performanceComparison () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                sortBy: this.optionSel,
                sortDirection: this.sortDirectionPerfComp.value,
            }
            const { processId } = this.$route.params
            this.isLoadingPerfComp = true
            apiRequest(Api().booster.performanceComparison({ processId, params }))
                .then((data) => {
                    this.perfCompResult = data
                    if (data.rows && data.rows.length > 0) {
                        this.emptyPerfComparison = false
                        this.asignData(data)
                        const maxLetters = this.performanceCompOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                        this.leftPerformanceCom = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                        this.performanceCompOptions.grid.left = this.leftPerformanceCom + 16
                    } else {
                        this.emptyPerfComparison = true
                    }
                })
                .catch()
                .finally(() => (this.isLoadingPerfComp = false))
        },
        asignData (data) {
            if (this.optionSel === 'SLACOMPLIANCEFREQ') {
                if (this.sortDirectionPerfComp.value === 'ASC') {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => a.slaNonComplianceAbsolute - b.slaNonComplianceAbsolute)
                } else {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => b.slaNonComplianceAbsolute - a.slaNonComplianceAbsolute)
                }
                this.performanceCompOptions.yAxis.data = this.newPerformanceComparison.map(item => item.content)
                this.performanceCompOptions.series[1].data = this.newPerformanceComparison.map(item => item.slaComplianceAbsolute)
                this.performanceCompOptions.series[0].data = this.newPerformanceComparison.map(item => item.slaNonComplianceAbsolute)
                this.performanceCompOptions.xAxis.max = undefined
            } else {
                // case percentage
                if (this.sortDirectionPerfComp.value === 'ASC') {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => a.slaNonCompliancePercentage - b.slaNonCompliancePercentage)
                } else {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => b.slaNonCompliancePercentage - a.slaNonCompliancePercentage)
                }
                this.performanceCompOptions.yAxis.data = this.newPerformanceComparison.map(item => item.content)
                this.performanceCompOptions.series[1].data = this.newPerformanceComparison.map(item => item.slaCompliancePercentage)
                this.performanceCompOptions.series[0].data = this.newPerformanceComparison.map(item => item.slaNonCompliancePercentage)
                this.performanceCompOptions.xAxis.max = 100
            }
        },
        leadTimes () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                sortBy: 'SLANONCOMPLIANCEAVG',
                sortDirection: this.sortDirectionLeadTimes.value,
            }
            const { processId } = this.$route.params
            this.isLoadingLeadTimes = true
            apiRequest(Api().booster.attributeLeadTimes({ processId, params }))
                .then((data) => {
                    this.leadTimesByResult = data
                    if (data.rows.length > 0) {
                        this.emptyLeadTimes = false
                        this.leadTimesOptions.yAxis.data = data.rows.map(item => item.content)
                        const maxLetters = this.leadTimesOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                        this.leftPerformanceCom = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                        this.leadTimesOptions.grid.left = this.leftPerformanceCom + 16
                        this.leadTimesOptions.series[1].data = data.rows.map(item => item.slaComplianceAvg)
                        this.leadTimesOptions.series[0].data = data.rows.map(item => item.slaNonComplianceAvg)
                        this.leadTimesOptions.xAxis.max = undefined
                    } else {
                        this.emptyLeadTimes = true
                    }
                })
                .catch(notifyError)
                .finally(() => (this.isLoadingLeadTimes = false))
        },
        ascDesc (direction) {
            let prueba
            if (direction === 'ASC') {
                prueba = this.leadTimesByResult.rows.slice().sort((a, b) => a.slaNonComplianceAvg - b.slaNonComplianceAvg)
            } else {
                prueba = this.leadTimesByResult.rows.slice().sort((a, b) => b.slaNonComplianceAvg - a.slaNonComplianceAvg)
            }
            this.leadTimesOptions.yAxis.data = prueba.map(item => item.content)
            this.leadTimesOptions.series[1].data = prueba.map(item => item.slaComplianceAvg)
            this.leadTimesOptions.series[0].data = prueba.map(item => item.slaNonComplianceAvg)
            this.leadTimesOptions.xAxis.max = undefined
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.currentUnit = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.currentUnit = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.currentUnit = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.currentUnit = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        lineBreak (inputString) {
            let resultado = ''
            for (let i = 0; i < inputString.length; i += 22) {
                const linea = inputString.substr(i, 22)
                resultado += `${linea }<br>`
            }
            return resultado
        },
        createSubstring (text, large) {
            const substrings = []
            let start = 0
            while (start < text.length) {
                let end = start + large
                if (end >= text.length) {
                    end = text.length
                } else {
                    const space = text.indexOf(' ', start + large)
                    const dash = text.indexOf('-', start + large)
                    const underscore = text.indexOf('-', start + large)
                    if (space !== -1 && space < end) {
                        end = space
                    } else if (dash !== -1 && dash < end) {
                        end = dash
                    } else if (underscore !== -1 && underscore < end) {
                        end = underscore
                    } else {
                        end = start + large
                    }
                }
                substrings.push(text.substring(start, end))
                start = end
            }
            return substrings
        },
    },
}
</script>
<style scoped lang="scss">
.MainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.Select {
    width: fit-content;
}
.Empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ChartContainer {
    width: 100%;
    margin:0 auto;
    flex-grow: 1;
    white-space: nowrap;
    background-color: #fbfbfb;
    overflow-x: hidden;
    overflow-y: scroll;
    .Chart {
        width: 100%;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
}
.PerformanceEvolutionContainer {
    position:relative;
    flex-grow: 1;
    margin-top:2%;
    max-height: 33%;
    background-color: #fbfbfb;
}
.PerformanceEvolutionTop {
    display: flex;
    padding:1vh
}
.PerformanceEvolutionChartContainer {
    width: 100%;
    margin:0 auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    .Chart {
        width: 100%;
        height: 77%;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }

}
.BottomContainer {
    margin-bottom: 10px;
}
.GraphTitle {
    color:#33699a;
}
.GraphChartContainers {
    position:relative;
    height: 31%;
    margin-top:1.5%;
    display:flex;
    flex-direction:column;
}
.GraphChartContainersTop {
    display: flex;
    height: fit-content;
    background-color: #fbfbfb;
    padding: 1vh;
}
.FilterValuesContainer {
    display: flex;
    align-items:center;
    justify-content:center;
    margin-right: 10px;
}
::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #DDDDDD;
    border-radius: 10px;
  }
</style>
