<template>
    <div>
        <div style="display:flex">
            <QSelect
                v-model="duration"
                dense
                class="selectDuration"
                :options="durationOptions"
                :label="$t('visualization.customPlotPanels.frequencyDuration.selectDuration')"
                @update:modelValue="getData"
            />
            <QSelect
                v-model="range"
                dense
                class="selectRange"
                :options="rangeOptions"
                :label="$t('visualization.customPlotPanels.frequencyDuration.selectRange')"
                @update:modelValue="getData"
            />
            <div>
                <q-icon
                    key="dropdown"
                    name="more_vert"
                    size="30px"
                    class="openIcon"
                    color="primary"
                    clickable
                    @click="setSelected"
                />
                <Dropdown
                    v-if="selected"
                    v-model="title"
                    direction="bottomRight"
                    :opened="!!selected"
                    :options="metricOptions"
                    @on-click="handleClickOption"
                />
            </div>
        </div>
        <div class="chart-container">
            <v-chart
                :option="barChartOptions"
                class="chart"
                autoresize
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { filtersMixin, filtersStorageMixin } from '@/mixins'
import { Dropdown } from '@/components'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

const DEFAULT_TAB = 'FREQUENCY'
export default {
    name: 'FrequencyDurationPanel',
    components: { Dropdown },
    mixins: [filtersMixin, filtersStorageMixin],
    props: {
        currentTitle: VueTypes.object,
        filters: VueTypes.array,
        chart: VueTypes.object,
        processId: VueTypes.string,
    },
    emits: ['title', 'chartLoading'],
    data () {
        return {
            title: this.currentTitle,
            selected: false,
            rangeString: '',
            model: null,
            modifiedFilters: [],
            duration: {
                key: 'MONTH',
                label: this.$t('visualization.customPlotPanels.attributesInfo.month'),
            },
            durationOptions: [
                {
                    key: 'MONTH',
                    label: this.$t('visualization.customPlotPanels.attributesInfo.month'),
                },
                {
                    key: 'WEEK',
                    label: this.$t('visualization.customPlotPanels.attributesInfo.week'),
                },
                {
                    key: 'DAY',
                    label: this.$t('visualization.customPlotPanels.attributesInfo.day'),
                },
            ],
            rangeOptions: [
                {
                    key: 'START',
                    label: this.$t('visualization.customPlotPanels.attributesInfo.start'),
                },
                {
                    key: 'END',
                    label: this.$t('visualization.customPlotPanels.attributesInfo.end'),
                },
            ],
            range: {
                key: 'START',
                label: this.$t('visualization.customPlotPanels.attributesInfo.start'),
            },
            metricOptions: [{
                key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), disabled: true, icon: 'timelapse',
            },
            {
                key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), disabled: false, icon: 'query_stats',
            }],
            currentSel: DEFAULT_TAB,
            maxDur: '',
            barChartOptions: {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = ''
                        const { name } = params[0]
                        tooltipContent += `${name}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            if (seriesName === this.$t('visualization.customPlotPanels.duration')) {
                                tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]',
                                    { largest: 2, trim: false })} <br>`
                            } else {
                                tooltipContent += `${seriesName}: ${value} <br>`
                            }
                        })
                        return tooltipContent
                    },
                },
                dataZoom: [
                    {
                        type: 'inside',
                        show: true,
                        start: 0,
                        end: 100,
                        handleSize: 8,
                    },
                ],
                xAxis: {
                    type: 'category',
                    data: [],
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => this.formatNumber(value),
                        },
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => `${this.formatNumber(value)}${this.rangeString}`,
                        },
                    },
                ],
                grid: {
                    left: 45,
                    right: 45,
                    bottom: 23,
                    top: 20,
                },
                series: [
                    {
                        name: this.$t('visualization.customPlotPanels.duration'),
                        type: 'line',
                        data: [],
                        itemStyle: {
                            color: '#33699A',
                        },
                        yAxisIndex: 1,
                    },
                    {
                        name: this.$t('visualization.customPlotPanels.frequency'),
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: '#6C97BE',
                        },
                        yAxisIndex: 0,
                    },
                ],
            },
        }
    },
    watch: {
        filters (newValue, oldValue) {
            let equal = false
            if (newValue.length === oldValue.length) {
                if (newValue.length === 0) {
                    equal = true
                } else {
                // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < newValue.length; i++) {
                        if (newValue[i] !== oldValue[i]) {
                            equal = true
                        }
                    }
                }
            }
            if (equal === false) {
                this.getData()
            }
        },
        chart (newValue, oldValue) {
            this.getData()
        },
    },
    mounted () {
        this.getData()
    },
    methods: {
        setSelected () {
            this.selected = !this.selected
        },
        handleClickOption (sel) {
            this.$emit('title', sel)
            this.setSelected()
        },
        formatNumber (num) {
            switch (true) {
                case num >= 1000000:
                    return `${(num / 1000000).toFixed(1)}M`
                case num >= 100000:
                    return `${(num / 100000).toFixed(0)}k`
                case num >= 1000:
                    return `${(num / 1000).toFixed(1)}k`
                default:
                    return num.toString()
            }
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.rangeString = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.rangeString = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.rangeString = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.rangeString = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        getData () {
            this.$emit('chartLoading', true)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const data = {
                duration: this.duration.key,
                caseType: this.range.key,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                limit: 8,
                start: 0,
            }
            if (this.chart.variants) {
                data.variantCount = this.chart.variants
            }
            if (this.chart.variantId) {
                data.variantUid = this.chart.variantId
            }
            if (this.chart.loopId || this.chart.loopId === 0) {
                data.loopUid = this.chart.loopId
            }
            apiRequest(Api().visualizations.frequencyPanel({ processId: this.processId, params: data }))
                .then((res) => {
                    const returnData = res
                    this.maxDur = this.rangeDuration(Math.max.apply(null, [returnData.rows.map(item => (item.duration))][0]))
                    const durationArr = [returnData.rows.map(item => this.formatSeconds(item.duration, this.maxDur))][0]
                    const frequencyArr = [returnData.rows.map(item => item.frequency)][0]
                    const commonAttributes = [returnData.rows.map(item => item.timeString)][0]
                    this.barChartOptions.series[1].data = frequencyArr
                    this.barChartOptions.series[0].data = durationArr
                    this.barChartOptions.xAxis.data = commonAttributes
                })
                .catch(notifyError)
                .finally(() => {
                    this.$emit('chartLoading', false)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.selectRange {
    width: 6.5em;
    height: auto;
    margin: 1em;
    margin-top: 0em;
    margin-bottom: 0.5em;
  }
  .selectDuration {
    width: 8.5em;
    height: auto;
    margin: 1em;
    margin-top: 0em;
    margin-bottom: 0.5em;
  }
  .chart-container {
    margin: 0 auto;
    .chart {
        width: 285px;
        height: 180px;
        margin-bottom:5px;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
  .openIcon {
    height: fit-content;
    padding-bottom: 0px;
    margin-left: 0 auto;
  }
 }
</style>
