<template>
    <div>
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-inner-loading :showing="isLoading" />
        </Transition>
        <div
            v-if="!isLoading"
            class="Step"
        >
            <div class="Services flex row wrap">
                <div
                    v-for="({ key, label, disabled, description }) in services"
                    :key="key"
                    color="secondary"
                    class="myCard"
                >
                    <button
                        :key="key"
                        :value="((value || {}).service || {}).identifier"
                        :val="key"
                        :label="label"
                        :disabled="disabled"
                        class="buttonConnector"
                        @click="handleInput(key)"
                    >
                        <div class="buttonIconContainer">
                            <img
                                :src="require(`@/assets/images/connectors/${key}.svg`)"
                                :alt="label"
                                class="buttonIcon"
                            >
                        </div>
                        <div
                            class="containerName"
                        >
                            <div class="title">
                                <h3
                                    class="titleContainer"
                                >
                                    {{ label }}
                                </h3>
                            </div>
                            <div class="description">
                                <p class="descriptionContainer">
                                    {{ description }}
                                </p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'StepService',
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            service: VueTypes.shape({
                identifier: VueTypes.string,
                allowJdbc: VueTypes.bool,
                allowRest: VueTypes.bool,
            }).loose,
        }).loose,
    },
    emits: ['input'],
    data () {
        return {
            services: [],
            isLoading: false,
        }
    },
    mounted () {
        this.getImporters()
    },
    methods: {
        isValid () {
            return !!this.value?.service
        },
        isDisabled (service, disabled) {
            if (service === 'SAP') {
                return false
            }
            return disabled
        },
        handleInput (value) {
            const service = this.services.find(s => s.key === value)
            this.$emit('input', { ...(this.value || {}), service })
        },
        normalizeServices (services) {
            return (services || []).map(s => ({
                ...s,
                key: s.identifier,
                label: s.name,
                disabled: !s.enabled,
            })).sort((a, b) => Number(a.disabled) - Number(b.disabled))
        },
        async getImporters () {
            this.isLoading = true
            apiRequest(Api().datasets.importers())
                .then(data => (this.services = this.normalizeServices(data)))
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Step{
    display:flex;
}
.Services {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    justify-content:space-between;
}

.Radio{
  margin:0 auto;
}

.myCard{
  width: 50%;
  justify-content: space-around;
  padding:15px;
  border-radius: 10px;
  min-width: 500px;
  }
  .buttonConnector{
    width:100%;
    padding:0;
    height: 100%;
    display:grid;
    grid-template-columns: 17% 83%;
    border: 2px solid #ebebeb;
    background-color: #fafafa;
    border-radius:10px;
}
.buttonIconContainer{
    width:100%;
    height: 100%;
    display:flex;
    align-items: left;
    background-color:#ebebeb;
    border-radius: 10px 0px 0px 10px;
}
.buttonIcon {
    width:auto;
    height: auto;
    border-radius: 10px 0px 0px 10px;
}

.containerName{
    display:flex;
    flex-direction: column;
}
.title{
    width:100%;
    height: 65px;
}
.titleContainer{
    width:auto;
    height: 65px;
    margin-left: 5%;
    margin-top:3.5%;
    float:left;
    font-size: 1.4em;
    font-weight:bold;
}

.description{
    width:100%;
}
.descriptionContainer{
    width:auto;
    color:#33699a;
    margin-left: 5%;
    margin-bottom:3.5%;
    text-align:left;
    font-size: 1em;
    float: left;
    font-weight:bold;
}
.myCard:hover {
    transform: scale(1.05);
    transition-delay: 100ms;
  }
  .buttonConnector:hover{
    cursor: pointer;
  }
  .buttonConnector:focus {
  all:unset;
}
  .buttonConnector:focus{
    background-color:#ebebeb;
    border:4px solid #33699a !important;
    width:100%;
    padding:0;
    height: 100%;
    display:grid;
    grid-template-columns: 17% 83%;
    border-radius:10px;
    }
    [disabled]{
        pointer-events: none;
    }
    @media only screen and (max-width: 1067px) {
        .Services {
          justify-content: center;
        }
      }

</style>
