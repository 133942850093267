/* eslint-disable max-len */
export default {
    back: 'Volver',
    continue: 'Continuar',
    finish: 'Finalizar',
    cancel: 'Cancelar proceso',
    testing: 'Probando conexión',

    trace: 'ID de Traza',
    activity: 'Actividad',
    startTime: 'Fecha de inicio',
    endTime: 'Fecha de fin',
    relevant: 'Atributo relevante',

    activityWarning: 'El campo seleccionado contiene muchos valores diferentes. Por favor, asegúrate de que corresponde a la columna de Actividad real en el registro del proceso cargado.',

    name: 'Nombre',
    description: 'Descripción',
    file: 'Fichero',

    notifications: {
        keyFieldsSuccess: {
            text: 'La configuración de los campos claves se ha aplicado correctamente.',
        },
        keyFieldsError: {
            text: 'Revisa la configuración de los campos clave.',
        },
        keyRelevantFieldsLoading: {
            text: 'Cargando atributos relevantes, espere.',
        },
        keyRelevantFieldsCountError: {
            text: 'Debe seleccionar al menos 1 atributo relevante.',
        },
        keyRelevantFieldsError: {
            text: 'Los atributos relevantes deben ser de tipo STRING',
        },
        launched: {
            dataset: {
                text: 'Datos subidos correctamente.',
            },
            discovery: {
                text: 'Descubrimiento lanzado correctamente.',
            },
            import: {
                text: 'Importación lanzada correctamente.',
            },
            query: {
                text: 'Insight lanzada correctamente.',
            },
        },
        connection: {
            text: 'Conexión iniciada correctamente',
        },
    },

    modals: {
        cancel: {
            text: '¿Seguro que quieres abandonar este proceso?',
        },
    },

    configs: {
        dataset: {
            title: 'Importación de ficheros CSV',
            steps: {
                0: 'Configuración',
                1: 'Subida de fichero',
                2: 'Campos clave',
                3: 'Tipos de datos',
            },
        },
        query: {
            title: 'Asistente de insight',
            steps: {
                step0: 'Conjunto de datos',
                step1: 'Definición de insight',
            },
        },
        import: {
            title: 'Asistente de conexión',
            steps: {
                step1: 'Servicio',
                step2: 'Datos de conexión',
                step3: 'Definición',
                step4: 'Resumen',
            },
        },
    },

    preview: {
        step: 'Paso',
        select: 'Seleccionar columna',
        column: '',
        optional: 'Opcional',
        decimalCharacter: 'Separador decimal',
        thousandCharacter: 'Separador de miles',
        homogeinzeAttribute: 'Homogeneizar atributos categóricos',
        numberFormat: {
            label: 'Formato numérico',
            placeholder: '',
        },
        numbersFormats: {
            en: 'Americano',
            eu: 'Europeo',
        },
        warning: 'AVISO: ',
        mismatchedNumberFormat: 'Formato diferente al configurado en su cuenta. Asegúrese de qué formato numérico se está utilizando en los datos cargados.',
        dataTypesTitle: 'Ajusta los tipos de dato',
        relevantNoAttributesSelectedWarning: 'No se han elegido atributos relevantes. No disponer de atributos relevantes conllevará la no disponibilidad del performance booster.',
        relevantAttributesDescription: 'Modifica los tipos de los atributos',
        done: {
            title: 'Finalizado',
            text: 'Revisa tus selecciones y continúa.',
            noColumnsLeft: 'No quedan columnas para seleccionar atributos relevantes.',
        },

        tooltips: {
            ACTIVITY: `Este campo debe contener el nombre o código de la actividad que se asocia a cada evento registrado,
                (por ejemplo, “creación de pedido” o “empaque de producto”).`,
            START: 'Este campo debe contener la hora de inicio de un evento registrado.',
            END: `Este campo debe contener la hora de finalización de un evento registrado.`,
            TRACE: `Este campo debe identificar de forma única cada ejecución diferente del proceso.`,
            RELEVANT: `Este campo es considerado relevante para tu proceso`,
        },

        modal: {
            dateformat: 'Añadir formato de fecha',
            time: 'Formato de fecha',
            text: {
                one: 'El formato de fecha y hora debe cumplir el estándar ISO 8601',
                two: 'Puedes ver más información de formatos de fecha en este',
            },
            website: 'sitio web',
            example: 'Ejemplo',
        },
    },

    report: {
        name: 'Nombre del informe',
        type: 'Tipo de informe',
    },

    query: {
        mode: 'Modo de insight',
        query: 'Insight',
        model: 'Modelo',
        restrictions: 'Restricciones de insight',
        queries: {
            seq: 'SEQ',
            switch: 'SWITCH',
            fork: 'FORK',
            loop: 'LOOP',
            activities: 'Actividades',
        },
        insight: {
            model: {
                title: 'MODELO DE INSIGHT',
                description: `Un modelo de insight no es más que la estructura de flujo de trabajo que se va a usar para determinar qué
                trazas verifican el proceso completo o una parte del mismo tal y como ha sido concebido.
                El InVerbis Process Language (IPL) permite definir de forma sencilla modelos de proceso que contienen puertas y actividades de modo similar a una representación BPMN.
                Mediante 4 palabras clave, un usuario puede definir cualquier proceso que contenga secuencias de actividades,
                paralelos, selecciones de caminos y bucles anidando todos estos elementos.
                `,
                seq: {
                    title: 'SEQ("Nombre Actividad" | Estructura anidada, ...): ',
                    description: `Esta palabra clave define una secuencia de actividades o estructuras que debe ejecutarse siguiendo un orden de precedencia (de izquierda a derecha).
                    Dentro del mismo SEQ se pueden incluir tanto actividades como submodelos, siempre separados por comas simples. Ejemplos:`,
                    examples: [
                        'SEQ("Order created")',
                        'SEQ("Order created","Notify manager")',
                        'SEQ("Order created","Notify manager",SWITCH("Order accepted","Order rejected))',
                    ],
                },
                switch: {
                    title: 'SWITCH("Nombre Actividad" | Estructura anidada, ...):',
                    description: `Esta palabra clave define una elección de camino entre distintas actividades o submodelos entre los que,
                    para un caso dado, sólo uno de ellos se ejecuta finalmente. Ejemplos:`,
                    examples: [
                        'SWITCH("Card payment","Payment by PayPal")',
                        'SWITCH(SEQ("Send e-mail","Receive e-mail"),SEQ("Send SMS","Receive phone call"))',
                        'SEQ("Ticket opened",SWITCH("Escalate","Manage"))',
                    ],
                },
                fork: {
                    title: 'FORK("Nombre Actividad" | Estructura anidada, ...):',
                    description: `Esta palabra clave define un conjunto de actividades o submodelos de proceso que deben ser ejecutadas independientemente del orden en el que lo hagan.
                    Esto permite cubrir situaciones en las que la ejecución se da en paralelo o en las que el orden no es importante. Ejemplos:`,
                    examples: [
                        'FORK("Notify user","Notify manager")',
                        'SEQ("Triage",FORK("Blood analysis","CT Scan"),"Result analysis","Surgery")',
                    ],
                },
                loop: {
                    title: 'LOOP("Nombre Actividad" | Estructura anidada, ...):',
                    description: `Esta palabra clave define un conjunto de actividades o submodelos de proceso que pueden ejecutarse varias veces seguidas.
                    Actualmente, esta palabra clave representa una repetición con cardinalidad 0..*, y es probable que cambie en el futuro a un operador configurable n..*.
                    Esto significa que, por ejemplo, para definir un insight en el que una actividad se repita al menos una vez, actualmente debemos hacer explícita la primera repetición:`,
                    examples: [
                        'SEQ("Notify user",LOOP("Notify user"))',
                    ],
                },
                custom: {
                    description: `Del mismo modo, si necesitamos que una actividad o submodelo se ejecute al menos dos veces:`,
                    examples: [
                        'SEQ("Notify user","Notify user",LOOP("Notify user"))',
                    ],
                },
            },
            restrictions: {
                copy: 'Operador copiado al portapapeles',
                title: 'RESTRICCIONES DE INSIGHT',
                description: `Las restricciones permiten acotar adicionalmente el resultado de un insight mediante el filtrado de casos que,
                 además de verificar el modelo de insight, deben cumplir ciertas condiciones que se pueden definir respecto a los valores de atributos y duraciones.
                 Estas restricciones se evalúan como condiciones booleans, pero también se proporcionan varias operaciones lógicas, numéricas y de gestión de tiempos que permiten especificar condiciones complejas.
                 La lista de operaciones soportadas actualmente es:`,
                operations: [
                    'date(dd,MM,yyyy)',
                    'day(number)',
                    'week(number)',
                    'month(number)',
                    'before(datetime,datetime)',
                    'after(datetime,datetime)',
                    'eq(number,number)',
                    'gt(number,number)',
                    'gte(number,number)',
                    'lt(number,number)',
                    'lte(number,number)',
                    'or(boolean,boolean)',
                    'and(boolean,boolean)',
                    'sum(number,number)',
                    'minus(number,number)',
                    'prod(number,number)',
                    'div(number,number)',
                    'between(a, x, y)',
                    'traceDuration()',
                    'timestamp("Actividad")',
                    'duration("Actividad")',
                    'timeBetween("Actividad1","Actividad2")',
                    'attribute("Actividad","Nombre de Atributo","type")',
                ],
                examples: {
                    title: 'Ejemplos',
                    items: [
                        'and(between(timestamp("O_Created"), ',
                        'date(2,3,2002),',
                        'timestamp("O_Cancelled")),',
                        'gt(duration("O_Created"), month(2) + week(1)))',
                        'after(timestamp("O_Created"),',
                        ' timestamp("O_Cancelled") + month(2) + week(1))',
                        'gt(timeBetween("O_Created", "O_Cancelled"), ',
                        'month(2) + week(1))',
                        'gt(sum(attrib("O_Created", "MonthlyCost", float),',
                        'attrib("O_Cancelled", "MonthlyCost", float)), 1500)',
                    ],
                },
            },
        },
        options: {
            one: 'Definir un nuevo insight',
            two: 'Elegir un modelo de insight',
        },
    },

    connection: {
        standard: 'Estándar',
        service: 'Servicio',
        database: 'Base de datos',
        port: 'Puerto',
        user: 'Usuario',
        password: 'Contraseña',
        workflows: 'Flujo de trabajo',
        filterTraces: 'Filtro de traza',
        filterEvents: 'Filtro de eventos con campos `null`',
        enable: 'Habilitado',
        disable: 'Deshabilitado',

        notifications: {
            empty: {
                title: 'Sin vistas virtuales',
                text: 'No se han encontrado vistas virtuales en esta búsqueda',
            },
        },
    },

    boosterWizard: {
        chooseApproach: {
            head: 'Conjunto de datos recién hechos',
            title: 'Elige tu enfoque de análisis',
            performanceBooster: 'Performance booster',
            automaticInsights: 'Análisis automáticos',
            customAnalysis: 'Análisis personalizado',
            performanceBoosterDescription: 'Obtenga información inmediata sobre el estado de su proceso, posibles problemas y causas raíz, y encuentre soluciones para mejorar rápidamente su rendimiento.',
            customAnalysisDescription: 'Descubre cómo fluye tu proceso. Explora manualmente las variantes del proceso, los bucles, realiza análisis de conformidad y aplica filtros para lograr el mayor nivel de detalle.',
            mainFeatures: 'Características principales',
            procOverview: 'Visión general del proceso',
            procOverviewDesc: 'Aprende cómo y por qué tu proceso funciona respecto a una duración objetivo',
            prediction: 'Predicción',
            predictionDesc: 'Revisa cómo se espera que las ejecuciones en curso se comporten para tomar medidas con anticipación',
            simulation: 'Simulación',
            simulationDesc: 'Modifica la ocurrencia de actividades y los tiempos de espera y observa cómo mejora tu proceso',
            d2t: 'Informes de datos a texto',
            d2tDesc: 'Inverbis describirá textualmente para ti los aspectos más relevantes de tu análisis de proceso',
            configureInsights: 'Configurar insights automáticos',
            process: 'Descubrimiento de procesos',
            processDesc: 'Descubre los flujos de ejecución reales de tu proceso y los caminos más relevantes',
            rework: 'Análisis de retrabajo',
            reworkDesc: 'Explora posibles cuellos de botella y su impacto estimado en tu proceso',
            conformance: 'Verificación de conformidad',
            conformanceDesc: 'Realiza un análisis de conformidad frente a un flujo esperado por diseño',
            filter: 'Sistema de filtrado',
            filterDesc: 'Aplica potentes filtros para segmentar datos y personalizar tu análisis',
            configureManual: 'Preconfigurar análisis personalizado',
            skipToManual: 'Ir directamente al análisis personalizado',
        },
        setupOptions: {
            analysis: 'Configuración del análisis',
            insights: 'Configuración de análisis automáticos',
        },
        back: 'Volver',
        skip: 'Omitir configuración',
        save: 'Guardar y continuar',
        notConfigured: 'Para continuar se requiere configurar todos los parámetros necesarios.',
        mustConfigure: 'Esta función debe configurarse para poder continuar con los Insights Automáticos',
        performanceBooster: {
            title: 'Performance booster',
            description: 'Establezca cómo Inverbis agrupará los casos en grupos de rendimiento en función de los plazos de entrega.',
            costAndCaseCompletion: {
                description: 'Configure los siguientes elementos opcionales para ayudar a Inverbis a proporcionar información más detallada.',
                warning: 'No se definieron condiciones de casos válidas. Es posible que se incluyan casos incompletos con duraciones más cortas en el análisis. Esto es probable si la duración mínima (a la izquierda de la barra verde) es 0.',
                buttons: {
                    back: 'Anterior',
                    boost: 'Boost!',
                },
            },
        },
        sla: {
            name: 'Acuerdo de nivel de servicio (SLA)',
            description: 'Determina cuándo un caso debe considerarse de rendimiento aceptable si se supera la siguiente duración. Se considera que los casos inferiores a este umbral cumplen el acuerdo de nivel de servicio (SLA) del proceso.',
            backButton: 'Anterior',
            nextButton: 'Siguiente',
            compliance: 'Rendimiento de acuerdo al SLA',
            nonCompliance: 'Rendimiento por debajo del SLA',
            cases: 'casos',
        },
    },
}
