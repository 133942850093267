<template>
    <Modal
        :isOpen="opened"
        class="ModalNameDesc"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ title }}
            </WText>
        </template>
        <template #body>
            <NameDescForm
                v-model="form"
                :loading="loading"
                :action="action"
                :withoutDesc="withoutDesc"
                @on-submit="handleSubmit"
                @on-reset="close"
            />
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import Modal from '@/components/Modal/Modal.vue'
import { NameDescForm } from '@/forms'

const INITAL_STATE = {
    uuid: '',
    name: '',
    description: '',
}

export default {
    name: 'ModalNameDesc',
    components: {
        Modal,
        NameDescForm,
    },
    mixins: [validatorsMixin],
    props: {
        title: VueTypes.string,
        action: VueTypes.any,
        loading: VueTypes.bool.def(false),
        withoutDesc: VueTypes.bool.def(false),
    },
    emits: ['onSubmit'],
    data () {
        return {
            opened: false,
            form: { ...INITAL_STATE },
        }
    },
    methods: {
        open (form = {}) {
            this.opened = true
            if (form) this.form = { ...form }
        },
        close () {
            this.opened = false
        },
        handleSubmit (form) {
            this.opened = false
            this.$emit('onSubmit', this.form)
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 500px;
    }

    .NameDescForm {
        margin: 30px 0;
    }
}
</style>
