export default {
    new: 'Novo projeto',
    delete: 'Eliminar projeto',
    edit: 'Editar projeto',
    showActives: 'Mostrar projetos ativos',

    sections: {
        datasets: 'Conjuntos de dados',
        models: 'Modelos',
        discovery: 'Descoberta',
        queries: 'Conhecimentos',
    },

    notifications: {
        noResult: {
            title: 'Nenhum resultado encontrado',
            text: 'Não foram encontrados projectos para a sua pesquisa.',
        },
        empty: {
            title: 'Não foram encontrados projectos',
            text: 'Pode criar o seu primeiro projeto.',
        },
        created: {
            text: 'Projeto criado com sucesso.',
        },
        updated: {
            text: 'Projeto atualizado com êxito.',
        },
        deleted: {
            text: 'Projeto eliminado com êxito.',
        },
        archived: {
            text: 'Projeto arquivado com êxito.',
        },
    },

    modals: {
        delete: {
            text: 'Tem a certeza de que pretende eliminar o projeto "{nome}"?',
        },
        upload: {
            title: 'Carregar dados',
            text: 'Escolha um ficheiro .csv para carregar',
        },
    },

    datasets: {
        new: 'Novo conjunto de dados',
        delete: 'Eliminar conjunto de dados',
        edit: 'Editar conjunto de dados',
        infoText: 'Informações sobre o conjunto de dados',
        errors: 'Erros',
        errorMessage: 'Não estão atualmente disponíveis mais informações sobre este conjunto de dados.',
        generalError: 'Ocorreu um erro não identificado ao processar os dados de origem. Por favor, contacte o administrador do sistema.',

        moveDataset: 'Mover Conjunto de Dados',
        createdByMe: 'Criadas por mim',
        sharedWithMe: 'Compartilhadas comigo',
        move: 'Mover',
        missingData: 'Faltam dados necessários para mover o conjunto de dados.',
        sameProjectError: 'Você não pode mover o conjunto de dados para o mesmo projeto.',
        moveSuccess: 'Conjunto de dados movido com sucesso.',

        import: 'Conectores',
        discovery: 'Descoberta',
        query: 'Insight',

        properties: {
            events: 'Eventos',
            activities: 'Actividades',
            arcs: 'Arcos',
            traces: 'Traços',
            variants: 'Variantes',
            createdAt: 'Criado',
            sourceType: 'Tipo de fonte',
            source: 'Fonte',
        },
        notifications: {
            noResult: {
                title: 'Nenhum resultado encontrado',
                text: 'Não encontrámos nenhum conjunto de dados para a sua pesquisa.',
            },
            empty: {
                title: 'Nenhum conjunto de dados encontrado',
                text: 'Pode criar ou importar o seu primeiro conjunto de dados.',
            },
            created: {
                text: 'Informação do conjunto de dados processada com sucesso.',
            },
            updated: {
                text: 'Conjunto de dados atualizado com sucesso.',
            },
            deleted: {
                text: 'Conjunto de dados eliminado com sucesso.',
            },
        },
        states: {
            running: 'PROCESSAMENTO',
            submitted: 'A SUBMETER',
            finished: 'PRONTO',
            error: 'ERRO',
            cancelled: 'CANCELADO',
            timeout: 'TEMPO ESGOTADO',
        },

        settings: {
            title: 'Configuração do conjunto de dados',
            back: 'Voltar à vista dos conjuntos de dados',
            tabs: {
                general: 'Geral',
                updates: 'Actualizações em tempo real',
                errors: 'Erros',
            },
            general: {
                nameTitle: 'Nome do conjunto de dados',
                nameField: 'Nome do atributo',
                descriptionField: 'Descrição',
                updateName: 'Atualização',
                generalTitle: 'Informação geral',
                sourceType: 'Tipo de fonte',
                sourceName: 'Nome da fonte',
                creation: 'Data de criação',
                lastUpdate: 'Última atualização',
                metricsTitle: 'Métricas de registo e de processo',
                events: 'Eventos',
                activities: 'Actividades',
                cases: 'Casos',
                variants: 'Variantes',
                arcs: 'Arcos',
            },
            updates: {
                pushTitle: 'Actualizações push',
                pushEnabled: 'As actualizações de dados por push através da API estão activadas',
                pushDisabled: 'As actualizações de dados push através da API estão desactivadas',
                pullTitle: 'Actualizações pull',
                pullToggle: 'Ativar actualizações de dados pull',
                frequency: 'Frequência de atualização (aplica-se desde a última atualização)',
                value: 'Valor',
                unit: 'Unidade',
                evalValue: 'Só são permitidos valores numéricos até 60',
                timeGranularity: {
                    minutes: 'minutos',
                    hours: 'horas',
                    days: 'dias',
                    weeks: 'semanas',
                    months: 'meses',
                },
                saveChanges: 'Guardar alterações',
                notReady: 'Este conjunto de dados não está pronto e não pode ser atualizado',
            },
            errors: {
                noErrors: 'Não foram encontrados erros para este conjunto de dados',
            },
            okMessage: 'Configurações actualizadas corretamente',
            failMessage: 'Verificar os valores de configuração antes de guardar as alterações',
        },

        modals: {
            delete: {
                text: 'Tem a certeza de que pretende eliminar o conjunto de dados "{nome}"?',
            },
            discovery: {
                text: 'Tem a certeza de que pretende criar uma nova descoberta?',
            },
        },
    },

    models: {
        upload: 'Carregar BPMN',
        empty: {
            title: 'Nenhum modelo encontrado',
            text: 'Pode importar o seu primeiro modelo BPMN',
        },
        edit: 'Atualizar o nome do modelo',
        delete: 'Eliminar modelo',
        preview: 'Pré-visualizar modelo',
        notifications: {
            noResult: {
                title: 'Nenhum resultado encontrado',
                text: 'Não encontrámos nenhum modelo para a sua pesquisa.',
            },
            empty: {
                title: 'Nenhum modelo encontrado',
                text: 'Pode importar o seu primeiro modelo.',
            },
            created: {
                text: 'Modelo importado com sucesso.',
            },
            updated: {
                text: 'Modelo atualizado com sucesso.',
            },
            deleted: {
                text: 'Modelo eliminado com sucesso.',
            },
        },
        source: {
            uploaded: 'CARREGADO',
            dataset: 'CONJUNTO DE DADOS',
        },
        modals: {
            delete: {
                text: 'Tem a certeza de que pretende eliminar este modelo?',
            },
            upload: {
                title: 'Carregar modelo BPMN',
                name: 'Nome do modelo',
                file: 'Escolher um ficheiro BPMN',
                submit: 'Importar',
                rejected: 'Só são permitidos ficheiros BPMN até 1MB.',
            },
        },
    },

    discoveries: {
        new: 'Nova descoberta',
        cancel: 'Cancelar descoberta',
        delete: 'Eliminar descoberta',
        edit: 'Editar descoberta',

        notifications: {
            noResult: {
                title: 'Nenhum resultado encontrado',
                text: 'Não foram encontradas descobertas para a sua pesquisa.',
            },
            empty: {
                title: 'Não foram encontradas descobertas',
                text: 'Pode efetuar a sua primeira descoberta.',
            },
            created: {
                text: 'Descoberta criada com sucesso.',
            },
            updated: {
                text: 'Descoberta actualizada com êxito.',
            },
            deleted: {
                text: 'Descoberta eliminada com êxito.',
            },
            archived: {
                text: 'Descoberta arquivada com êxito.',
            },
        },

        modals: {
            cancel: {
                text: 'Tem a certeza de que pretende cancelar esta descoberta?',
            },
            delete: {
                text: 'Tem a certeza de que pretende apagar esta descoberta?',
            },
        },
    },

    queries: {
        new: 'Nova descoberta',
        cancel: 'Cancelar descoberta',
        delete: 'Eliminar descoberta',
        edit: 'Editar descoberta',

        notifications: {
            noResult: {
                title: 'Não foram encontrados resultados',
                text: 'Não foram encontradas ideias para a sua pesquisa.',
            },
            empty: {
                title: 'Não foram encontradas ideias',
                text: 'Pode criar a sua primeira ideia.',
            },
            created: {
                text: 'Ideias criadas com sucesso.',
            },
            updated: {
                text: 'Insight atualizado com sucesso.',
            },
            deleted: {
                text: 'Ideias eliminadas com êxito.',
            },
            cancel: {
                text: 'Idéia cancelada com sucesso.',
            },
            download: {
                text: 'Perceção descarregada com êxito.',
            },
        },
        states: {
            running: 'PROCESSAMENTO',
            submitted: 'A SUBMETER',
            finished: 'PRONTO',
            error: 'ERRO',
        },

        modals: {
            cancel: {
                text: 'Tem a certeza de que pretende cancelar esta ideia?',
            },
            delete: {
                text: 'Tem a certeza de que pretende eliminar esta ideia?',
            },
        },
    },
    variants: {
        changeOrderCriteria: 'Alterar critério de ordenação',
        order: {
            frequency: 'Frequência (freq)',
            mean: 'Duração média (média)',
            median: 'Duração mediana (med)',
            deviation: 'Desvio padrão da duração (dp)',
        },
        orderAbbreviations: {
            frequency: 'freq',
            mean: 'média',
            median: 'med',
            deviation: 'dp',
        },
        changeOrder: 'Alterar ordenação',
        orderOptions: {
            ascending: 'Crescente (cresc)',
            descending: 'Decrescente (desc)',
        },
        orderOptionsAbbreviations: {
            ascending: 'cresc',
            descending: 'desc',
        },
    },
}
