export default {
    selectAll: 'Select All',
    create: 'Create',
    next: 'Next',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    discard: 'Discard',
    close: 'Close',
    cancel: 'Cancel',
    search: 'Search',
    apply: 'Apply',
    confirm: 'Confirm',
    mock: 'Mock',
    remove: 'Remove',
    download: 'Download as CSV',
    downloadBPMN: 'Download BPMN',
    uploadFile: 'Select a file or drag here',
    print: 'Print',
    retry: 'Retry',
    info: 'Settings',
    move: 'Move',
}
