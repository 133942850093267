export default {
    visualizationToBooster: 'Process booster',
    boosterDisabledTooltip: 'Debe configurar las condiciones necesarias para acceder al Booster',
    customAnalysis: 'Análisis personalizado',
    performanceBoosterTitle: 'Performance booster',
    overview: {
        tab: 'Vista general',
        description: 'Descubre cómo se comporta tu proceso',
        relevantNotDefined: 'Configure los atributos relevantes para mostrar los datos',
        slaNotDefined: 'Configure las condiciones de SLA para visualizar los datos.',
        relevantAndSLANotDefined: 'Configure los atributos relevantes y las condiciones de SLA para mostrar los datos.',
        caseCompletionNotDefined: 'Configure las condiciones de completitud de caso para mostrar los datos.',
        relevantAndCaseNotDefined: 'Configure los atributos relevantes y las condiciones de completitud de caso para mostrar los datos.',
        slaAndCaseNotDefined: 'Configure las condiciones de SLA y las condiciones de completitud de caso para mostrar los datos.',
        relevantAndSLAAndCaseNotDefined: 'Configure los atributos relevantes, las condiciones de SLA y las condiciones de completitud de caso para mostrar los datos.',
        costNotDefined: 'Configure las condiciones de coste para visualizar los datos.',
        relevantAndCostNotDefined: 'Configure los atributos relevantes y las condiciones de coste para mostrar los datos.',
        slaAndCostNotDefined: 'Configure las condiciones de SLA y las condiciones de coste para mostrar los datos.',
        relevantAndSLAAndCostNotDefined: 'Configure los atributos relevantes, las condiciones de SLA y las condiciones de coste para mostrar los datos.',
        noConditionsText: 'Puede definir las condiciones necesarias en los ajustes del dataset.',
    },
    state: {
        performanceBy: 'Rendimiento por ',
        rightClick: 'Clic derecho en una barra para mostrar grafo',
        leadTimesBy: 'Duración media por ',
        timeSpentPerCase: 'Tiempo medio invertido por caso',
        performanceComparison: 'comparación de rendimiento',
        leadTimes: ' duración media',
        cases: ' casos',
        selectFilteringElements: 'Elementos seleccionados para filtrado de recursos',
        evolutionOverTime: 'Evolución del rendimiento',
        overallPerformance: 'Rendimiento general por:',
        activity: 'Actividad',
        transition: 'Transición',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Cumple SLA',
        intermediate: 'Intermedio',
        above: 'Incumple SLA',
        slaCompliance: 'Cumple SLA',
        noTraces: 'No hay  trazas para los filtros actuales',
        performanceByClick: 'Haz clic en una barra del gráfico de la izquierda para seleccionar',
    },
    prediction: {
        tab: 'Predicción',
        description: 'Descubre cómo se comportará tu proceso',
        predictionHighlights: {
            name: 'Aspectos destacados de la predicción',
            cases: 'casos',
            ongoingCases: 'casos en curso ',
            meetingSLA: 'en condiciones de ',
            meetingSLAColor: 'cumplir SLA',
            missingSLAColor: 'en riesgo ',
            missingSLA: 'de incumplir SLA',
            exceeding: '',
            exceedingColor: 'excediendo SLA',
        },
        predictedPerformance: {
            name: 'Rendimiento previsto por ',
            risk: 'Estimación del riesgo de predicciones en curso',
            riskLabel: 'Riesgo',
            duration: 'Duración predicha versus duración pasada',
            durationLabel: 'Dur. SLA',
            distribution: 'Distribución del rendimiento del SLA',
            distributionSla: 'Distr. SLA',
            distributionLabel: 'Distr. SLA',
            past: 'Pasado',
            predicted: 'Predicción',
            finishedAbove: 'Finalizadas que incumplieron el SLA',
            finishedUnder: 'Finalizadas que cumplieron el SLA',
            finished: 'Finalizadas',
            ongoingAbove: 'En curso que incumplen el SLA',
            ongoingUnder: 'En curso que cumplen el SLA',
            finishedOngoing: 'Finalizadas - En curso',
            pastPredicted: 'Pasado - Predicción',
            ongoing: 'En curso',
            pastMonths: 'Pasados ',
            months: ' meses',
        },
        ongoingCases: {
            showOnly: 'Filtrar por ',
            ongoingCaseList: 'Lista de casos en curso',
            state: 'Estado',
            caseId: 'ID de caso',
            currentDuration: 'Duración actual',
            estimatedDuration: 'Duración estimada',
            Good: 'Bueno',
            Risk: 'En riesgo',
            above: 'Por encima del SLA',
            select: 'Por favor, seleccione un valor',
        },
        timeToMeet: {
            timeToMeet: 'Tiempo de cumplimiento ',
            improvementRate: 'Tasa de mejora mensual para alcanzar el ',
            improvementRateSla: 'Tasa de mejora mensual para alcanzar el ',
            improvementRateSecondPart: ' en ',
            past: 'Pasados ',
            months: ' meses',
            bestPerforming: 'mejor rendimiento actual ',
        },
    },
    improvement: {
        tab: 'Simulación',
        description: 'Descubre cómo mejorar tu proceso',
        activitiesImprove: 'Mejora de actividades de proceso',
        transitionsImprove: 'Mejora de transiciones de proceso',
        improvementResults: 'Resultados de mejora en ',
        improvementTables: {
            rule1: 'Solo números',
            rule2: '0-100',
            name: 'Nombre',
            currentImpact: 'Impacto actual',
            improvement: 'Mejora',
            estimatedImpact: 'Impacto estimado',
        },
        improvementFigures: {
            title: 'Cifras de mejora',
            cases: ' casos',
            red: 'rojo',
            green: 'verde',
            become: ' que se convierte en ',
            decrease: 'disminuye',
            overallDuration: ' en duración total',
            overallCosts: ' en gastos generales',
            days: ' días',
            hours: 'horas',
            seconds: 'seg',
            currentPerformance: 'Performance actual',
            improvedPerformance: 'Performance mejorada',
            slaCompliance: 'Cumple SLA',
            slaNonCompliance: 'No cumple SLA',
        },
        improvementScope: {
            title: 'Ámbito de la mejora',
            add: 'Seleccione y agregue pares atributo-valor para limitar el ámbito de la mejora',
            attributes: 'Atributos',
            values: 'Valores',
        },
    },
    tabs: {
        overview: 'Vista general',
        prediction: 'Predicción',
        improvement: 'Mejora',
    },
    d2t: {
        viewReport: 'Ver informe',
        analysisReport: 'Informe de análisis',
        copyToClipboard: 'Copiar al portapapeles',
        copied: '¡Copiado!',
    },
    alerts: {
        tab: 'Alertas',
        description: 'Define y revisa alertas sobre el proceso',
        filterBy: 'Filtrar por',
        tabs: {
            active: 'Activas',
            history: 'Historial',
            definitions: 'Definiciones',
        },
        detected: {
            noAlerts: 'No existen alertas para mostrar',
            affected: 'Casos afectados',
            lastDetected: 'Última detección',
            severity: 'Nivel de severidad',
            filterSet: 'Filter Set asociado',
            filterCases: 'Filtrar casos',
            dismiss: 'Descartar',
            severityRiskLabels: {
                LOW: 'Bajo',
                MEDIUM: 'Medio',
                HIGH: 'Alto',
            },
            predictionRiskLabels: {
                LOW: 'SLA under risk',
                MEDIUM: 'SLA en riesgo',
                HIGH: 'SLA superado',
            },
        },
        definition: {
            create: 'Nueva definición',
            remove: 'Borrar seleccionadas',
            name: 'Nombre',
            severity: 'Severidad',
            filterSet: 'Filter Set',
            type: 'Tipo',
            types: {
                ALL: 'Todos',
                STANDARD: 'Standard',
                PREDICTION: 'Predicción',
                TIME: 'Temporal',
            },
            notification: 'Notificación',
            activity: 'Actividad',
            period: 'Periodo',
            record: 'registro',
            selected: 'de',
            createScreen: {
                newAlert: 'Nueva definición de alerta',
                sendEmail: 'Enviar notificación a',
                user: 'e-mail del usuario',
                other: 'otro e-mail',
                notSend: 'no enviar',
                notifyTo: 'E-mail de destino',
                cancel: 'Cancelar',
                addAlert: 'Añadir alerta',
            },
            tableColumn: {
                name: 'Nombre',
                severity: 'Severidad',
                filterset: 'Filter Set',
                type: 'Tipo',
                notification: 'Notificación',
            },
            activityPeriod: {
                hasStarted: 'Comienzo',
                hasEnded: 'Fin',
            },
            timeUnit: {
                seconds: 'Segundos',
                minutes: 'Minutos',
                hours: 'Horas',
                days: 'Días',
                weeks: 'Semanas',
                months: 'Meses',
            },
        },
        notifications: {
            created: 'Alerta creada correctamente',
            validateError: 'Por favor, rellena los campos adecuadamente',
        },
    },
}
