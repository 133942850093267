import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import * as echarts from 'echarts/charts'
import * as echartsComponents from 'echarts/components'
import VChart from 'vue-echarts'

const charts = Object.values(echarts)
const components = Object.values(echartsComponents)
use([
    CanvasRenderer,
    ...charts,
    ...components,
])

// register component to use
export default function useECharts (app) {
    app.component('VChart', VChart)
}
