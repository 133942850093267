<template>
    <div class="Invitations">
        <div class="Actions flex row-stretch-center mb-1">
            <QBtn
                color="primary"
                noCaps
                :label="$t('invitations.new')"
                icon="add_circle_outline"
                @click="handleNewItem"
            />
            <div
                v-if="availableInvitations"
                class="flex row-left-center"
            >
                <q-icon
                    name="info"
                    size="18px"
                    color="blue"
                    class="mr-0-5"
                />
                <WText
                    :size="14"
                    align="center"
                    tag="span"
                    weight="bold"
                >
                    {{ availableInvitations }}
                    <WText
                        :size="12"
                        tag="span"
                    >
                        {{ $t('invitations.available') }}
                    </WText>
                </WText>
            </div>
        </div>
        <div class="Container">
            <InvitationsList
                ref="table"
                :rows="invitations"
                :total="total"
                :loading="isFetching"
                @on-new-item="handleNewItem"
                @on-fetch-data="fetchData"
                @on-revoke-invitation="handleRevokeInvitation"
                @on-cancel-invitation="cancelInvitation"
            />
        </div>
        <ModalRequest
            ref="RevokeRequestModal"
            :title="$t('invitations.modal.title')"
            :text="$t('invitations.modal.text')"
            :loading="isLoading"
            @on-accept="revokeInvitation"
        />
        <InvitationDialog
            ref="newInvDialog"
            class="InvitationDialog"
            :title="$t('invitations.new')"
            :accept="$t('actions.create')"
            :loading="isLoading"
            @on-submit="createInvitation"
        />
    </div>
</template>

<script>
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { ModalRequest } from '@/components'
import { InvitationsList, InvitationDialog } from './sections'

export default {
    name: 'Invitations',
    components: {
        InvitationsList,
        InvitationDialog,
        ModalRequest,
    },

    data () {
        return {
            invitations: undefined,
            total: undefined,
            availableInvitations: undefined,
            isFetching: false,
            isLoading: false,
        }
    },
    computed: {
        params () {
            const pagination = this.$refs.table.getPagination()
            return {
                ...pagination,
            }
        },
    },
    beforeMount () {
        const { organizationId } = this.$route.params || {}
        this.organizationId = organizationId
    },
    mounted () {
        this.fetchData(this.params)
        this.getOrganization()
    },
    methods: {
        async fetchData (parameters) {
            this.isFetching = true
            const params = this.normalizeParams(parameters)
            apiRequest(Api().userManagement.list(params))
                .then(({ data, total }) => {
                    this.invitations = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        getOrganization () {
            apiRequest(Api().organizations.get({ organizationUid: this.organizationId }))
                .then(({ data }) => {
                    const [organization] = data
                    this.availableInvitations = organization.availableInvitations
                })
        },
        async cancelInvitation (uuid) {
            this.isFetching = true
            apiRequest(Api().userManagement.cancel({ userInvitationUid: uuid }))
                .then(() => {
                    notifySuccess(this.$t('invitations.notifications.canceled.text'))
                    this.fetchData(this.params)
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        handleRevokeInvitation (uuid) {
            this.$refs.RevokeRequestModal.open()
            this.uuid = uuid
        },
        async revokeInvitation () {
            this.isFetching = true
            apiRequest(Api().userManagement.revoke({ userInvitationUid: this.uuid }))
                .then(() => {
                    notifySuccess(this.$t('invitations.notifications.revoked.text'))
                    this.fetchData(this.params)
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        handleNewItem () {
            this.$refs.newInvDialog.open()
        },
        createInvitation (email) {
            const { organizationId: organizationUid } = this
            this.isLoading = true
            apiRequest(Api().userManagement.create({ organizationUid, email }))
                .then(() => {
                    notifySuccess(this.$t('invitations.notifications.registered.text'))
                    this.fetchData(this.params)
                    this.$refs.newInvDialog.close()
                    this.getOrganization()
                })
                .catch(({ result = {} }) => {
                    const { errorMessage = this.$t('error.general.text') } = (result.response || {}).data || {}
                    notifyError({
                        result: {
                            response: {
                                data: { errorMessage },
                                status: 400,
                            },
                        },
                    })
                })
                .finally(() => (this.isLoading = false))
        },

        normalizeParams (parameters) {
            const {
                page, rowsPerPage, endDate, startDate,
            } = parameters
            return {
                organizationUid: this.organizationId,
                ...(this.getStartParam(page, rowsPerPage)),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                ...(endDate ? { endDate } : {}),
                ...(startDate ? { startDate } : {}),
            }
        },
        getStartParam (page, rowsPerPage) {
            return rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}
        },
    },
}
</script>
<style lang="scss" scoped>
.InvitationDialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 9996;
    margin: auto;
    height: 232px;
    max-width: 500px;
}
</style>
