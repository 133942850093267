export default {
    events: 'Eventos',
    historic: 'Histórico',
    currentUse: 'Utilização atual',
    maxEvents: 'Máximo de eventos',
    lines: 'Linhas',

    emptyListTitle: 'Nenhum resultado encontrado',
    emptyListText: 'Não encontrámos nenhum evento.',
}
