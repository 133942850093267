<template>
    <div class="Process">
        <div class="Header flex row-stretch-center">
            <div class="HeaderLeft flex row-center">
                <q-icon
                    name="arrow_back_ios_new"
                    size="20px"
                    color="primary"
                    clickable
                    class="mr-1"
                    @click="handleBack"
                />
                <Breadcrumb />
            </div>
            <div class="HeaderRight">
                <div class="button-container flex-row-center">
                    <UserAvatar
                        direction="bottomLeft"
                    />
                </div>
            </div>
        </div>
        <div class="Container">
            <QLayout
                view="lHr lpR lFr"
            >
                <QDrawer
                    v-model="showDrawer"
                    showIfAbove
                    side="left"
                    behavior="desktop"
                    :width="drawerWidth"
                    :breakpoint="500"
                    :mini="!isFixed"
                    :style="{ background: '#f9f9f9', borderRight: '1px solid #e6e6f2' }"
                >
                    <QList class="ListContainer">
                        <div class="TopItems">
                            <QItem
                                v-ripple
                                class="ExpandSidebarItem"
                            >
                                <QItemSection class="TopItemSection">
                                    {{ $t('process.analysisTools') }}
                                </QItemSection>
                                <QItemSection avatar>
                                    <QIcon
                                        name="start"
                                        :style="{ cursor: 'pointer', transform: isFixed ? 'rotate(180deg)' : 'none' }"
                                        @click="toggleFixed()"
                                    >
                                        <QTooltip
                                            anchor="center right"
                                            self="center left"
                                            :offset="[10, 10]"
                                        >
                                            {{ isFixed ? $t('process.collapse') : $t('process.expand') }}
                                        </QTooltip>
                                    </QIcon>
                                </QItemSection>
                            </QItem>
                            <QSeparator class="Separator" />
                            <QItem
                                v-ripple
                                clickable
                                :active="isviewModeActive('visualization')"
                                @click="selectSection('visualization')"
                            >
                                <QItemSection avatar>
                                    <QIcon name="analytics">
                                        <QTooltip
                                            v-if="!isFixed"
                                            anchor="center right"
                                            self="center left"
                                            :offset="[10, 10]"
                                        >
                                            {{ $t('process.customAnalysis') }}
                                        </QTooltip>
                                    </QIcon>
                                </QItemSection>
                                <QItemSection class="ItemSection">
                                    {{ $t('process.customAnalysis') }}
                                </QItemSection>
                            </QItem>
                            <QItem
                                v-ripple
                                clickable
                                :active="isviewModeActive('booster')"
                                @click="selectSection('booster')"
                            >
                                <QItemSection avatar>
                                    <QIcon name="insights">
                                        <QTooltip
                                            v-if="!isFixed"
                                            anchor="center right"
                                            self="center left"
                                            :offset="[10, 10]"
                                        >
                                            {{ $t('process.automaticInsights') }}
                                        </QTooltip>
                                    </QIcon>
                                </QItemSection>
                                <QItemSection class="ItemSection">
                                    {{ $t('process.automaticInsights') }}
                                </QItemSection>
                            </QItem>
                        </div>
                        <div class="BottomItems">
                            <QItem
                                v-ripple
                                clickable
                                :active="isviewModeActive('settings')"
                                @click="selectSection('settings')"
                            >
                                <QItemSection avatar>
                                    <QIcon name="settings">
                                        <QTooltip
                                            v-if="!isFixed"
                                            anchor="center right"
                                            self="center left"
                                            :offset="[10, 10]"
                                        >
                                            {{ $t('process.settings') }}
                                        </QTooltip>
                                    </QIcon>
                                </QItemSection>
                                <QItemSection class="ItemSection">
                                    {{ $t('process.settings') }}
                                </QItemSection>
                            </QItem>
                        </div>
                    </QList>
                </QDrawer>
                <QPageContainer>
                    <component
                        :is="currentComponent"
                    />
                </QPageContainer>
            </QLayout>
        </div>
    </div>
</template>
<script>
import { Breadcrumb, UserAvatar } from '@/components'
import Visualization from './Visualization/Visualization.vue'
import Overview from './Booster/Overview.vue'
import SettingsModal from './Settings/SettingsModal.vue'

export default {
    name: 'Process',
    components: {
        Breadcrumb,
        UserAvatar,
        Visualization,
        Overview,
        SettingsModal,
    },
    data () {
        return {
            tabVisualization: 'MODEL',
            tabBooster: 'OVERVIEW',
            showDrawer: true,
            isFixed: false,
            viewMode: 'visualization',
            drawerWidth: 215,
        }
    },
    computed: {
        currentComponent () {
            switch (this.viewMode) {
                case 'visualization':
                    return 'Visualization'
                case 'booster':
                    return 'Overview'
                case 'settings':
                    return 'SettingsModal'
                default:
                    return 'Visualization'
            }
        },
    },
    watch: {
        '$route.fullPath': {
            handler: 'viewFromRoute',
            immediate: true,
        },
        '$i18n.locale': {
            handler: 'setDrawerWidth',
            immediate: true,
        },
    },
    created () {
        this.initializeFromRoute()
    },
    mounted () {
        this.setDrawerWidth()
    },
    methods: {
        handleBack () {
            this.$router.back()
        },
        setDrawerWidth () {
            const currentLocale = this.$i18n.locale
            if (currentLocale === 'en') {
                this.drawerWidth = 215
            } else if (currentLocale === 'es') {
                this.drawerWidth = 250
            } else if (currentLocale === 'pt') {
                this.drawerWidth = 250
            }
        },
        toggleFixed () {
            this.isFixed = !this.isFixed
        },
        initializeFromRoute () {
            const route = this.$route
            const segments = route.path.split('/')
            if (segments.includes('booster')) {
                this.viewMode = 'booster'
                this.tabBooster = this.$route.params.subview || 'OVERVIEW'
                this.tabVisualization = 'MODEL'
                this.$router.replace({ name: 'ProcessBooster', params: { subview: this.tabBooster } })
            } else if (segments.includes('visualization')) {
                this.viewMode = 'visualization'
                this.tabVisualization = this.$route.params.tab || 'MODEL'
                this.tabBooster = 'OVERVIEW'
                this.$router.replace({ name: 'ProcessVisualization', params: { tab: this.tabVisualization } })
            } else if (segments.includes('settings')) {
                this.viewMode = 'settings'
                this.tabVisualization = 'MODEL'
                this.tabBooster = 'OVERVIEW'
                this.$router.replace({ name: 'ProcessSettings' })
            }
        },
        viewFromRoute () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            if (segments.includes('visualization')) {
                this.viewMode = 'visualization'
            } else if (segments.includes('booster')) {
                this.viewMode = 'booster'
            } else if (segments.includes('settings')) {
                if (sessionStorage.getItem('settingsFromTab')) {
                    this.tabBooster = sessionStorage.getItem('settingsFromTab')
                    sessionStorage.removeItem('settingsFromTab')
                }
                this.viewMode = 'settings'
            }
        },
        toggleView () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            if (this.viewMode === 'visualization') {
                const index = segments.indexOf('visualization')
                if (index !== -1) {
                    segments.splice(index, 2, 'booster/OVERVIEW')
                    const newRoute = segments.join('/')
                    this.$router.push(newRoute)
                }
            } else {
                const index = segments.indexOf('booster')
                if (index !== -1) {
                    segments.splice(index, 2, `visualization/${ this.tabVisualization }`)
                    // const newRoute = segments.join('/')
                    this.$router.push({ name: 'ProcessVisualization', params: { tab: this.tabVisualization } })
                }
            }
        },
        selectSection (section) {
            if (section === 'visualization') {
                if (this.viewMode === 'booster') {
                    this.tabBooster = this.$route.params.subview
                }
                this.$router.replace({ name: 'ProcessVisualization', params: { tab: this.tabVisualization } })
            } else if (section === 'booster') {
                if (this.viewMode === 'visualization') {
                    this.tabVisualization = this.$route.params.tab
                }
                this.$router.replace({ name: 'ProcessBooster', params: { subview: this.tabBooster } })
            } else if (section === 'settings') {
                if (this.viewMode === 'visualization') {
                    this.tabVisualization = this.$route.params.tab
                } else if (this.viewMode === 'booster') {
                    this.tabBooster = this.$route.params.subview
                }
                this.$router.replace({ name: 'ProcessSettings' })
            }
        },
        isviewModeActive (section) {
            return section === this.viewMode
        },
    },
}
</script>
<style scoped lang="scss">
.Process {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .Header {
        height: $header-height;
        width: 100%;
        border-bottom: 1px solid $border-color;
        z-index: 5999;
        background: $white;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .Container {
        height: calc(100vh - $header-height);
        width: 100%;
    }
}
.HeaderRight {
    display: flex;
    align-items: center;
}
.ButtonContainer {
    display: flex;
    align-items: center;
}
.ButtonContainer .ml-1 {
    margin-left: 5px;
}
::v-deep .q-drawer {
    position: fixed !important;
    top: $header-height !important;
}
.ListContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height});
}
.TopItems {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.BottomItem {
    margin-top: auto;
}
.ExpandSidebarItem {
    min-height: 36px !important;
    height: 36px;
}
.TopItemSection {
    font-size: 14px;
    font-weight: bold;
    color: #616161;
}
.ItemSection {
    font-size: 15px;
    margin-left: -15px;
}
</style>
