import useQuasar from './quasar'
import useApexCharts from './vue-apexcharts'
import useWStorage from './vue-wstorage'
import useECharts from './vue-echarts'

export default function usePlugins (app) {
    useQuasar(app)
    useApexCharts(app)
    useWStorage(app)
    useECharts(app)
}
