<template>
    <div>
        <div>
            <QTable
                ref="myTable"
                v-model:pagination="pagination"
                class="my-sticky-header-table"
                :rows="rows"
                :columns="columns"
                flat
                :filter="filter"
                outlined
                separator="vertical"
                bordered
                virtualScroll
                :rowsPerPageOptions="rowsPerPageOptions"
                @request="emitRequest"
            >
                <template #header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            autoWidth
                            dense
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                        <q-th
                            autoWidth
                            color="primary"
                        />
                    </q-tr>
                </template>
                <template #body="props">
                    <QTr
                        :props="props"
                        class="cursor-pointer"
                        :class="{ 'selected-row': props.row.caseId == selectedRow.caseId }"
                        @click="selectRow(props.row)"
                    >
                        <QTd
                            key="caseId"
                            autoWidth
                            class="caseId"
                        >
                            {{ props.row.caseId }}
                            <QTooltip>
                                {{ props.row.caseId }}
                            </QTooltip>
                        </QTd>
                        <QTd
                            key="lastActivity"
                        >
                            {{ props.row.lastActivity }}
                        </QTd>
                        <QTd
                            key="riskLevel"
                            class="text-center"
                        >
                            {{ props.row.riskLevel }}
                            <QIcon
                                name="flag"
                                :color="flagColor(props.row.riskLevel)"
                                size="20px"
                            />
                        </QTd>
                        <QTd
                            key="estimatedVariant"
                        >
                            {{ props.row.estimatedVariant === 'New variant' ? $t('visualization.predictions.table.newVariant') : props.row.estimatedVariant }}
                        </QTd>
                        <QTd
                            key="currentDuration"
                        >
                            {{ props.row.currentDuration }}
                        </QTd>
                        <QTd
                            key="estimatedDuration"
                        >
                            {{ props.row.estimatedDuration }}
                        </QTd>
                        <QTd>
                            <QIcon
                                size="xs"
                                color="primary"
                                dense
                                name="search"
                                @click="openTraces(props.row.caseId)"
                            />
                        </QTd>
                    </QTr>
                </template>
                <template #bottom-left="{ paginationSlot }">
                    <QSelect
                        v-model="paginationSlot.rowsPerPage"
                        :options="rowsPerPageOptions"
                        outlined
                        dense
                    />
                </template>
                <template #bottom-right="{ paginationSlot }">
                    <QPagination
                        v-model="paginationSlot.page"
                        :min="1"
                        :max="total"
                        dense
                    />
                </template>
                <template #no-data="{ message }">
                    <div class="full-width row flex-center text-accent q-gutter-sm">
                        <span>
                            {{ message }}
                        </span>
                    </div>
                </template>
            </QTable>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'

export default {
    name: 'PredictionTable',
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        loading: VueTypes.bool.def(false),
    },
    emits: ['download', 'datasetFromFiltered', 'selectedId', 'update', 'ready'],
    data () {
        return {
            filter: '',
            newDatasetName: undefined,
            selectedRow: this.rows[0],
            selected: '',
            rowsPerPage: 30,
            page: 1,
            currentSort: 'caseId',
            descend: false,
            columnSelected: '',
            rowsPerPageOptions: [30, 50, 75, 100, 250, 500],
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.total,
                    sortBy: this.currentSort,
                    descending: this.descend,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
    },
    mounted () {
        this.$nextTick(() => {
            this.$emit('ready')
        })
    },
    methods: {
        openTraces (e) {
            const params = { tab: 'TRACES', selectedCaseId: e }
            this.$emit('viewTrace', params)
        },
        flagColor (risk) {
            if (risk === 0) {
                return 'positive'
            }
            if (risk === 1) {
                return 'warning'
            }
            if (risk === 2) {
                return 'negative'
            }
            return 'blue'
        },
        selectRow (row) {
            const ff = row
            this.$emit('selectedId', ff)
            this.selectedRow = row
        },
        emitRequest (p) {
            this.pagination.page = p.pagination.page
            this.pagination.rowsPerPage = p.pagination.rowsPerPage
            this.rowsPerPage = p.pagination.rowsPerPage
            this.page = p.pagination.page
            if (p.pagination.sortBy === null) {
                this.descend = false
            } else {
                this.currentSort = p.pagination.sortBy
                this.pagination.sortBy = p.pagination.sortBy
                this.descend = p.pagination.descending
            }
            this.$emit('update', this.pagination)
        },
        downloadData () {
            this.$emit('download')
        },
    },
}
</script>
<style lang="scss" scoped>
.my-sticky-header-table {
    max-height: calc(65vh - $sidebar-height);
    @media screen and (min-height: $md) {
        max-height:calc(69vh - $sidebar-height);
    }
    max-width: calc(100vw - 350px);
    &:deep(thead) {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        // background-color: #c1f4cd;
        background-color: #e0e0e0;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}

.selected-row > * {
    background-color: $gray-07;
  }

.caseId {
    max-width: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
