<template>
    <Modal
        :isOpen="isOpen"
        class="custom-modal"
        @close="close"
    >
        <template
            #header
            class="custom-modal-header"
        >
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
                style="width: 80vw; max-width: 800px;"
            >
                {{ $t('visualization.filters.filterSets.createNewFilterSet') }}
            </WText>
        </template>
        <template
            #body
            class="custom-modal-body"
        >
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit.prevent="handleSubmit"
            >
                <div class="row mt-1">
                    <QInput
                        v-model="filterSetName"
                        dense
                        outlined
                        style="width: 100%;"
                        :label="$t('visualization.filters.filterSets.search.placeholder')"
                    />
                </div>

                <div class="Section">
                    <WText
                        tag="h4"
                        :size="14"
                        align="left"
                        weight="bold"
                    >
                        {{ $t('visualization.filters.filterSets.config.aggregationOperators.title') }}
                    </WText>
                    <div class="q-gutter-sm">
                        <QRadio
                            v-model="operator"
                            val="AND"
                            :label="$t('visualization.filters.filterSets.config.aggregationOperators.AND')"
                        />
                        <QRadio
                            v-model="operator"
                            val="OR"
                            :label="$t('visualization.filters.filterSets.config.aggregationOperators.OR')"
                        />
                    </div>
                </div>

                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        color="primary"
                        type="submit"
                        :label="$t('actions.create')"
                    />
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersStorageMixin } from '@/mixins'
import { Modal } from '@/components'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'

export default {
    name: 'CreateFilterSetsModal',
    components: {
        Modal,
    },
    mixins: [filtersStorageMixin],
    props: {
        datasetId: VueTypes.string,
        activeFilters: VueTypes.array,
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            filterSetName: '',
            operator: 'AND',
        }
    },
    computed: {
        operators () {
            return [
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.AND'),
                    value: 'AND',
                },
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.OR'),
                    value: 'OR',
                },
            ]
        },
    },
    watch: {
        activeFilters: {
            handler () {
                this.updateOperator()
            },
            deep: true,
        },
        isOpen (open) {
            if (open) {
                this.filterSetName = ''
                this.updateOperator()
            }
        },
    },
    mounted () {
        this.updateOperator()
    },
    methods: {
        close () {
            this.$emit('close')
        },
        handleSubmit () {
            this.registerDatasetFilters()
            this.setGeneralOperator(this.operator)
            this.$emit('repeatCalls')
            this.close()
        },
        registerDatasetFilters () {
            const { datasetId } = this
            const name = this.filterSetName
            const { operator } = this
            const filters = this.formatFiltersParam(this.activeFilters)
            const params = { filters, name, operator }
            this.isLoading = true
            apiRequest(Api().datasets.registerDatasetFilters({ datasetId, params }))
                .then((res) => {
                    notifySuccess(this.$t('visualization.filters.filterSets.register.success'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        updateOperator () {
            const { generalOperator } = this.splitFilterAndFilterSets(this.activeFilters)
            this.operator = generalOperator || 'AND'
        },
    },
}
</script>

<style scoped lang="scss">
.Section {
    margin-bottom: 1rem;
}
.ApplyButton {
    margin-top: 1rem;
    width: 100%;
}
.Modal .custom-modal .Dialog[data-v-47db75aa] {
    width: 80vw !important;
    max-width: 800px !important;
    min-width: 600px
}
.custom-modal-header,
.custom-modal-body {
    width: 100%;
}
</style>
