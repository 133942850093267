export default {
    general: {
        text: 'Ocorreu um erro inesperado, contacte o administrador do sistema.',
    },
    unauthorized: {
        text: 'Erro de autorização. Por favor, inicie sessão novamente.',
        title: 'Oops...',
    },
    unknown: {
        text: 'Erro desconhecido. Por favor, tente novamente ou contacte-nos.',
        title: 'Oops...',
    },
    notfound: {
        text: 'Recurso não encontrado.',
        title: 'Oops...',
    },
    conflict: {
        text: 'Recurso inválido.',
        title: 'Oops...',
    },
    auth: {
        text: 'O nome de utilizador ou a palavra-passe estão incorrectos.',
        title: 'Erro',
    },
    404: {
        title: 'Página não encontrada',
    },
    select: {
        noOptions: 'Nenhum elemento encontrado.',
    },
}
