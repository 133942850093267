<template>
    <div>
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-inner-loading
                :showing="isLoading || isConnecting"
                label="isConnecting ? `${$t('wizard.testing')}...` : ''"
            />
        </Transition>
        <div
            v-if="!isLoading"
            class="Step"
        >
            <div class="row justify-center items-start">
                <div v-if="tabs.length > 1">
                    <QTabs
                        v-model="tab"
                        animated
                        noCaps
                        transitionNext="fade"
                        transitionPrev="fade"
                        class="text-grey"
                        activeColor="primary"
                        indicatorColor="primary"
                        @update:modelValue="handleChangeTab"
                    >
                        <QTab
                            v-for="(props, index) in tabs"
                            :key="index"
                            :name="props.id"
                            :label="props.label"
                        />
                    </QTabs>
                </div>
            </div>

            <QTabPanels
                v-model="tab"
                animated
                transitionNext="jump-up"
                transitionPrev="jump-up"
            >
                <QTabPanel
                    class="model-tab-panel"
                    :name="tabsTypes.STANDARD"
                >
                    <QForm ref="form">
                        <div class="row justify-center items-start mt-0-5">
                            <QSelect
                                v-model="localValue.connection.driver"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                lazyRules
                                outlined
                                behavior="menu"
                                label="Driver"
                                :options="types"
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: 'Driver' })]"
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.database"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.database')"
                                lazyRules
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.connection.database') })]"
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.host"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                label="Host"
                                lazyRules
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: 'Host' })]"
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.port"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.port')"
                                placeholder="3306"
                                lazyRules
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.connection.port') })]"
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.username"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.user')"
                                lazyRules
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.connection.user') })]"
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.password"
                                type="password"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.password')"
                                lazyRules
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.connection.password') })]"
                            />
                        </div>
                    </QForm>
                </QTabPanel>
                <QTabPanel
                    class="model-tab-panel"
                    :name="tabsTypes.JDBC"
                >
                    <QForm ref="form">
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.connectionChain"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                lazyRules
                                outlined
                                type="textarea"
                                label="JDBC"
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: 'JDBC' })]"
                            />
                        </div>
                    </QForm>
                </QTabPanel>
                <QTabPanel
                    class="model-tab-panel"
                    :name="tabsTypes.REST_SERVICE"
                >
                    <QForm ref="form">
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.restService"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                label="Host"
                                lazyRules
                                :rules="[
                                    value =>
                                        validators.required(value) ||
                                        $t('validation.required', { attribute: 'REST service' })
                                ]"
                            />
                        </div>
                        <div class="row justify-center items-start mb-1">
                            <QInput
                                v-model="localValue.connection.username"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.user')"
                                lazyRules
                            />
                        </div>
                        <div class="row justify-center items-start mt-0-5">
                            <QInput
                                v-model="localValue.connection.password"
                                type="password"
                                class="col-12 col-sm-6 col-md-4 col-lg-3"
                                clearable
                                outlined
                                :label="$t('wizard.connection.password')"
                                lazyRules
                            />
                        </div>
                    </QForm>
                </QTabPanel>
            </QTabPanels>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifySuccess, notifyError,
} from '@/api'
import { validatorsMixin } from '@/mixins'

const TABS_TYPES = {
    STANDARD: 'standard',
    JDBC: 'jdbc',
    REST_SERVICE: 'rest_service',
}

export default {
    name: 'StepConnection',
    components: {},
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            connection: VueTypes.any.isRequired,
            service: VueTypes.shape({
                identifier: VueTypes.string.isRequired,
                allowJdbc: VueTypes.bool,
                allowRest: VueTypes.bool,
            }).loose.isRequired,
        }).loose,
    },
    emits: ['input'],
    data () {
        return {
            tab: '',
            tabsTypes: TABS_TYPES,
            types: [],
            isLoading: false,
            isConnecting: false,
            restServiceTabId: TABS_TYPES.REST_SERVICE,
            localValue: this.value,
        }
    },
    computed: {
        tabs () {
            const { allowJdbc, allowRest } = this.value?.service || {}
            return [
                ...(allowJdbc ? [{ id: TABS_TYPES.STANDARD, label: 'JDBC' }, { id: TABS_TYPES.JDBC, label: 'JDBC (connection string)' }] : []),
                ...(allowRest ? [{ id: TABS_TYPES.REST_SERVICE, label: 'REST Service' }] : []),
            ]
        },
        connectionJDBC () {
            return this.value?.connection?.connectionChain
        },
    },
    watch: {
        value (value) {
            this.localValue = value
        },
    },
    mounted () {
        const { allowJdbc } = this.value?.service
        if (allowJdbc) this.getJdbc()
        this.tab = (this.tabs || [])[0].id
    },
    methods: {
        async isValid () {
            const { form } = this.$refs
            const valid = await form.validate()
            if (!valid) return false

            const { allowJdbc, allowRest } = this.value?.service
            if (allowJdbc && !allowRest) {
                const { ok } = await this.validateJdbc()
                return ok
            }

            return true
        },
        handleChangeTab () {
            this.$emit('input', { ...(this.value || {}), connection: {} })
        },
        normalizeTypes (types) {
            return (types || []).map(s => ({
                ...s,
                key: s.identifier,
                label: `${s.identifier} (${s.className})`,
            }))
        },
        async getJdbc () {
            this.isLoading = true
            apiRequest(Api().datasets.jdbc())
                .then(data => (this.types = this.normalizeTypes(data)))
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async validateJdbc () {
            this.isConnecting = true
            const {
                connectionChain, driver, host,
                database,
                password,
                port,
                username,
            } = this.value?.connection
            const params = {
                ...(connectionChain
                    ? {
                        connectionChain,
                    }
                    : {
                        driver: (driver || {}).key,
                        host,
                        database,
                        password,
                        port,
                        username,
                    }),
            }
            return apiRequest(Api().datasets.validateJdbc(params, { timeout: 0 }))
                .then(() => {
                    notifySuccess(this.$t('wizard.notifications.connection.text'))
                    return { ok: true }
                })
                .catch(notifyError)
                .finally(() => (this.isConnecting = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Tab {
    min-width: 120px;

    &:deep(.q-tab__content) {
        justify-content: center;
    }
}
.hideRest {
  display: none
}
</style>
