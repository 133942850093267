<template>
    <div
        v-if="!!(datasetInfo.errors || []).length"
        class="column"
    >
        <QBanner
            v-for="(error, idx) in datasetInfo.errors"
            :key="idx"
            class="col bg-negative text-white q-ma-sm"
        >
            <template #avatar>
                <QIcon
                    name="error"
                    color="white"
                />
            </template>
            {{ error }}
        </QBanner>
    </div>
    <div
        v-else-if="datasetInfo.state.identifier === 'ERROR' || datasetInfo.state.identifier === 'SUBMITTED' && !datasetInfo.uploaded"
    >
        <QBanner class="bg-negative text-white q-ma-sm">
            <template #avatar>
                <QIcon
                    name="error"
                    color="white"
                />
            </template>
            {{ $t(`projects.datasets.generalError`) }}
        </QBanner>
    </div>
    <div v-else>
        <QBanner class="bg-positive text-white q-ma-sm">
            <template #avatar>
                <QIcon
                    name="check_circle"
                    color="white"
                />
            </template>
            {{ $t(`projects.datasets.settings.errors.noErrors`) }}
        </QBanner>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Errors',
    props: {
        datasetInfo: VueTypes.any,
    },
}
</script>

<style scoped>

</style>
