<template>
    <ModalRequest
        ref="dialog"
        class="Dialog"
        :title="title"
        :accept="accept"
        :autoClose="autoClose"
        @on-cancel="close"
        @on-accept="handleSubmit"
    >
        <div class="Form">
            <QInput
                ref="email"
                v-model="email"
                lazyRules
                type="email"
                dense
                :hint="$t('invitations.hint')"
                label="Email"
                maxlength="100"
                outlined
                :rules="[value => validators.validEmail(value) || $t('invitations.valid')]"
            />
        </div>
    </ModalRequest>
</template>

<script>
import VueTypes from 'vue-types'
import { ModalRequest } from '@/components'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'InvitationDialog',
    components: {
        ModalRequest,
    },
    mixins: [validatorsMixin],
    props: {
        title: VueTypes.string,
        accept: VueTypes.string,
        loading: VueTypes.bool.def(false),
        autoClose: VueTypes.bool.def(false),
    },
    emits: ['onSubmit'],
    data () {
        return {
            email: '',
        }
    },
    methods: {
        open () {
            const { dialog } = this.$refs
            dialog.open()
        },
        close () {
            const { dialog } = this.$refs
            this.email = ''
            dialog.close()
        },
        handleSubmit () {
            const { email } = this.$refs
            email.validate()

            if (!email.hasError) {
                this.$emit('onSubmit', this.email)
            }
        },
    },
}
</script>
