<template>
    <QCard
        v-if="fetching"
        class="Card"
    >
        <QCardSection
            class="CardSection"
        >
            <QBtn
                icon="close"
                flat
                round
                dense
                class="CloseButton"
                @click="closeModal"
            />
            <div
                class="text-h6 TitleContainer"
            >
                {{ $t('visualization.powerBIDialog.title') }}
                <div class="HelpIconContainer">
                    <QIcon
                        name="help_outline"
                        color="primary"
                        size="1.5em"
                    />
                </div>
            </div>
        </QCardSection>
        <QCardSection>
            <div class="text-h6">
                {{ $t('visualization.powerBIDialog.chooseConnection') }}
            </div>
            <div class="Padding">
                <div
                    class="text-h6 ProcessIdContainer"
                >
                    <strong class="DatasetTitle">{{ $t('visualization.powerBIDialog.currentDataset') }}</strong> {{ processId }}
                    <div class="CopyClipboardContainer">
                        <q-icon
                            name="content_copy"
                            size="24px"
                            color="primary"
                            clickable
                            style="cursor: pointer;"
                            @click="handleCopyToClipboard(processId)"
                        />
                        <QTooltip>
                            {{ $t('visualization.powerBIDialog.clipboard') }}
                        </QTooltip>
                    </div>
                </div>
                <div
                    v-if="token"
                    class="text-h6 TokenContainer"
                >
                    <strong class="TokenAvailable">{{ $t('visualization.powerBIDialog.tokenAvailable') }}</strong>
                    <div class="CopyClipboardContainer">
                        <q-icon
                            name="content_copy"
                            size="24px"
                            color="primary"
                            clickable
                            style="cursor: pointer;"
                            @click="handleCopyToClipboard(token)"
                        />
                        <QTooltip>
                            {{ $t('visualization.powerBIDialog.clipboard') }}
                        </QTooltip>
                    </div>
                </div>
                <div
                    v-if="!token && fetching"
                    class="text-h6 Padding"
                >
                    <strong class="TokenNonAvailable">{{ $t('visualization.powerBIDialog.noTokenAvailable') }}</strong>
                    <span>{{ $t('visualization.powerBIDialog.generateToken1') }}</span>
                    <RouterLink
                        to="/doc"
                    >
                        <span>{{ $t('visualization.powerBIDialog.generateToken2') }}</span>
                    </RouterLink>
                    <span>{{ $t('visualization.powerBIDialog.generateToken3') }}</span>
                </div>
            </div>
            <div class="ButtonsContainer">
                <div class="ButtonContainer">
                    <QBtn
                        outline
                        class="ButtonDialog"
                        color="primary"
                        noCaps
                        @click="downloadTemplate"
                    >
                        <div class="ButtonContent">
                            <QIcon
                                name="save_alt"
                                size="2em"
                            />
                            <div class="ButtonTitle">
                                {{ $t('visualization.powerBIDialog.downloadButton1') }}<br>
                                {{ $t('visualization.powerBIDialog.downloadButton2') }}
                            </div>
                        </div>
                    </QBtn>
                    <div class="ExplanationContainer">
                        {{ $t('visualization.powerBIDialog.downloadExplanation') }}
                    </div>
                </div>
                <div class="ButtonContainer">
                    <QBtn
                        outline
                        class="ButtonDialog"
                        color="primary"
                        noCaps
                        disable="true"
                        @click="inverbisDashboard"
                    >
                        <div class="ButtonContent">
                            <QIcon
                                name="cached"
                                size="2em"
                            />
                            <div class="ButtonTitle">
                                {{ $t('visualization.powerBIDialog.installButton1') }} <br>
                                {{ $t('visualization.powerBIDialog.installButton2') }}
                            </div>
                        </div>
                    </QBtn>
                    <div class="ExplanationContainer">
                        {{ $t('visualization.powerBIDialog.installExplanation') }}
                    </div>
                </div>
            </div>
        </QCardSection>
    </QCard>
</template>

<script>
import VueTypes from 'vue-types'
import copy from 'copy-to-clipboard'
import { filtersMixin } from '@/mixins'
import { download } from '@/services/download'
import {
    Api, apiRequest, notifySuccess, notifyError,
} from '@/api'

export default {
    name: 'PowerBIConnector',
    mixins: [filtersMixin],
    props: {
        processId: VueTypes.string,
    },
    emits: ['closeModal'],
    data () {
        return {
            token: undefined,
            inverbisDashboardLink: undefined,
            fetching: false,
        }
    },
    computed: {
    },
    mounted () {
        this.getToken()
    },
    methods: {
        getToken () {
            apiRequest(Api().users.getApiToken())
                .then(({ data }) => {
                    if (data[0].valid) {
                        this.token = data[0].token
                    } else {
                        this.token = undefined
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.fetching = true
                })
        },
        closeModal () {
            this.$emit('closeModal')
        },
        handleCopyToClipboard (value) {
            copy(value)
            notifySuccess(this.$t('settings.token.success'))
        },
        inverbisDashboard () {
            apiRequest(Api().visualizations.powerBILink())
                .then((data) => {
                    this.inverbisDashboardLink = data.link
                    window.open(this.inverbisDashboardLink, '_blank')
                })
                .catch(notifyError)
        },
        downloadTemplate () {
            apiRequest(Api().visualizations.powerBIDownload())
                .then((data) => {
                    download({
                        name: 'InverbisAnalyticsDashboard', format: 'pbix', data,
                    })
                    notifySuccess(this.$t('visualization.dashboard.notifications.download.powerbi'))
                })
                .catch(notifyError)
        },
    },
}
</script>
<style lang="scss" scoped>
.ButtonDialog{
    min-width: 100px;
    font-size: 1.3em;
    margin: 0 auto;
    height: 87px;
}
.ButtonContainer{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.ButtonsContainer{
    display: flex;
    padding-top:3em;
    min-width: fit-content;
}
.ButtonContent{
    display: flex;
    align-items: center;
    padding:0.5em;
}
.ButtonTitle {
    margin-left: 1.5em;
}
.Card{
    width: 100%;
    min-width: fit-content;
    max-width: 80vw;
    max-height: 70vh;
    padding: 0 2em;
    border: 1px solid #33699A;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
}
.CardSection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CloseButton {
    cursor: pointer;
    align-self: flex-end;

}
.DatasetTitle {
    margin-right: 6px;
}
.ExplanationContainer{
    margin:0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 250px;
    text-align:center;
}
.HelpIconContainer, .CopyClipboardContainer {
    width: fit-content;
    margin-left: 4px;
}
.Padding {
    padding-top: 2em;
}
.ProcessIdContainer, .TitleContainer, .TokenContainer {
    display:flex;
    align-items: row;
}
.TitleContainer {
    color: #33699A;
}
.TokenContainer {
    padding-top:2.5%;
}
.TokenAvailable {
    color: #009C37;
}
.TokenNonAvailable {
    color: #C00000;
}
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
