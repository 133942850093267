<template>
    <div class="Tracking">
        <div v-if="!!step">
            <WText
                weight="semi"
                :size="18"
                class="mb-0-5"
            >
                {{ $t('wizard.preview.step') }} {{ step.num }}/{{ length }}: <WText
                    tag="span"
                    :size="18"
                >
                    {{ $t('wizard.preview.select') }} "{{ step.label }}" {{ $t('wizard.preview.column') }}
                </WText>
                <WText
                    v-if="step.optional"
                    tag="span"
                    color="tertiary"
                    :size="18"
                >
                    ({{ $t('wizard.preview.optional') }})
                </WText>
            </WText>
            <WText>
                {{ step.tooltip }}
            </WText>
        </div>
        <div v-else>
            <WText
                :size="18"
                weight="semi"
                color="primary"
                class="mb-0-5"
            >
                {{ $t('wizard.preview.done.title') }}
            </WText>
            <WText>
                {{ $t('wizard.preview.done.text') }}
            </WText>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'Tracking',
    props: {
        length: VueTypes.number,
        step: VueTypes.shape({
            num: VueTypes.number,
            label: VueTypes.string,
            tooltip: VueTypes.string,
            optional: VueTypes.bool.def(false),
        }).loose,
    },
}
</script>
