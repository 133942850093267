import '@/config/VueValuesStorage'
import { createApp /* ,  config,  prototype */ } from 'vue'
import usePlugins from '@/plugins'
import i18n from '@/lang/i18n'
import { router } from '@/router'
import routerBeforeEach from '@/router/beforeEach'
import { create as createApi } from '@/api'
import registerGlobalComponents from '@/components/registerAsGlobal'
import AuthService from '@/services/auth'
import App from './App.vue'
import moment from './utils/moment'
import eventBus from './utils/eventBus'

let app

// config.productionTip = false // removed in vue 3 https://v3-migration.vuejs.org/breaking-changes/global-api.html#config-productiontip-removed
console.log('mode', process.env.NODE_ENV)

routerBeforeEach({ router })

createApi()

moment()

AuthService.signin()
    .then(({ ok }) => {
        if (!ok) window.location.reload()
        else console.info('Authenticated Success')

        app = createApp(App)
        app = app.use(i18n)
        app = app.use(router)
        usePlugins(app)
        app.provide('$DEV', process.env.NODE_ENV === 'development')
        registerGlobalComponents(app)
        app.config.globalProperties.$eventBus = eventBus
        app.component('App', App)
        app.mount('#app')
    })
    .catch((error) => {
        console.info('Authenticated Failed', error)
    })
