<template>
    <div>
        <QCard class="Card">
            <QTabs
                v-model="tab"
                dense
                inlineLabel
                noCaps
                activeColor="primary"
                indicatorColor="primary"
                align="justify"
            >
                <QTab
                    name="activity"
                    :label="$t('booster.state.activity')"
                    icon="format_shapes"
                />
                <QTab
                    name="transition"
                    :label="$t('booster.state.transition')"
                    icon="arrow_forward"
                />
                <QTab
                    name="variant"
                    :label="$t('booster.state.variant')"
                    icon="device_hub"
                />
            </QTabs>
            <QSeparator />
            <QTabPanels
                v-model="tab"
                dense
                class="Panel"
                style="height:65%; margin-top: 1%"
            >
                <QTabPanel
                    v-for="(tabName, index) in attributes"
                    :key="index"
                    :name="tabName"
                    :label="tabName"
                >
                    <PerformanceBy
                        :selected="tabName"
                        @selected-options="selectedOptions"
                    />
                </QTabPanel>
            </QTabPanels>
            <div class="SelectFilteringTitle">
                <div style="margin: auto 10px;">
                    {{ $t('booster.state.selectFilteringElements') }}
                    <QIcon
                        color="primary"
                        size="1.2em"
                        name="help"
                    >
                        <QTooltip> {{ $t('booster.state.performanceByClick') }} </QTooltip>
                    </QIcon>
                </div>
                <QSpace />
                <div>
                    <QBtn
                        flat
                        round
                        size="ml"
                        color="primary"
                        icon="restart_alt"
                        @click="reset"
                    />
                </div>
            </div>
            <div class="ChipContainer">
                <div style="width:95%">
                    <div v-if="selectedOpt.activity.length > 0">
                        <QChip
                            v-for="(activity, index) in selectedOpt.activity"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteChip('activity',index)"
                        >
                            <QIcon
                                name="format_shapes"
                                style="margin-right:3px; size:1.4em;"
                                class="icon"
                            />
                            {{ activity }}
                        </QChip>
                    </div>
                    <div v-if="selectedOpt.transition.length > 0">
                        <QChip
                            v-for="(transition, index) in selectedOpt.transition"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteChip('transition',index)"
                        >
                            <QIcon
                                name="arrow_forward"
                                style="margin-right:3px; size:1.4em;"
                                class="icon"
                            />
                            {{ transition }}
                        </QChip>
                    </div>
                    <div v-if="selectedOpt.variant.length > 0">
                        <QChip
                            v-for="(variant, index) in selectedOpt.variant"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteChip('variant',index)"
                        >
                            <QIcon
                                name="device_hub"
                                style="margin-right:3px; size:1.4em;"
                                class="icon"
                            />
                            {{ variant }}
                        </QChip>
                    </div>
                </div>
            </div>
        </QCard>
    </div>
</template>

<script>
// import VueTypes from 'vue-types'
import PerformanceBy from './PerformanceBy.vue'

export default {
    name: 'ProcessElementsPanel',
    components: {
        PerformanceBy,
    },
    props: {
    },
    emits: ['selectedOpt'],
    data () {
        return {
            tab: 'activity',
            selected: [],
            selectedOpt: { activity: [], transition: [], variant: [] },
            attributes: ['activity', 'transition', 'variant'],
        }
    },
    methods: {
        selectedOptions (index, value) {
            if (this.selectedOpt[index].includes(value)) {
                const provisionalSelected = this.selectedOpt[index].filter(item => item !== value)
                this.selectedOpt[index] = provisionalSelected
            } else {
                this.selectedOpt[index].push(value)
            }
            this.$emit('selectedOpt', this.selectedOpt)
        },
        reset () {
            this.selectedOpt = {
                activity: [],
                transition: [],
                variant: [],
            }
            this.$emit('selectedOpt', this.selectedOpt)
        },
        // deleteChipsGroup (name) {
        //     this.selectedOpt[name] = []
        // },
        deleteChip (name, index) {
            this.selectedOpt[name].splice(index, 1)
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.SelectFilteringTitle {
    height: 7%;
    background-color: #fbfbfb;
    margin: 1% 2% 0% 2%;
    flex-direction: row;
    display:flex;
}
.ChipContainer {
    flex-grow: 1;
    background-color: #fbfbfb;
    margin: 0px 2% 10px 2%;
    flex-direction: row;
    display:flex;
    overflow-y: auto;
}
.Panel {
    &:deep(.q-panel) {
        overflow: visible !important;
    }
    &:deep(.q-tab-panel) {
        padding:0px;
    }
}
.QTabPanel {
    padding: 0px;
    overflow: 'hidden';
}
.TabPanel{
    height: 37vh;
    margin-bottom: 1vh;
    overflow:hidden;
}
::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #DDDDDD;
    border-radius: 10px;
  }
</style>
