<template>
    <div class="ProjectsList">
        <Table
            ref="table"
            :columns="cols"
            :data="normalizeRows"
            :total="total"
            :loading="loading"
            :hideHeader="hideHeader"
            rowKey="uuid"
            class="Table"
            @table-request="handleFetchData"
        >
            <template #content="{ row }">
                <QTr
                    class="cursor-pointer"
                >
                    <QTd
                        key="code"
                        class="td-code"
                        @click="handleGoToProject(row)"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.code }}
                        </WText>
                    </QTd>
                    <QTd
                        key="name"
                        class="td-name"
                        @click="handleGoToProject(row)"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.name }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            {{ $t('general.createdAt') }} {{ App.dateLocationFormat(row.createDate, 'long') }}
                        </WText>
                    </QTd>
                    <QTd
                        key="description"
                        class="td-desc"
                        @click="handleGoToProject(row)"
                    >
                        <span class="td-desc ellipsis">{{ row.description || '-' }}</span>
                        <QTooltip
                            v-if="row.description"
                            maxWidth="300px"
                        >
                            {{ row.description }}
                        </QTooltip>
                    </QTd>
                    <QTd
                        key="tst"
                        class="td-tst"
                        @click="handleGoToProject(row)"
                    >
                        <span>{{ row.tst !== '' ? App.dateLocationFormat(row.tst, 'long') : '-' }}</span>
                    </QTd>
                    <QTd
                        key="actions"
                        class="Actions align-right"
                    >
                        <q-icon
                            name="more_vert"
                            size="24px"
                            color="primary"
                            clickable
                            style="cursor: pointer;"
                            @click="setSelected(row, $event)"
                        />
                    </QTd>
                </QTr>
            </template>

            <template #empty>
                <EmptyList
                    v-bind="emptyMessage"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <QBtn
                            class="mt-1"
                            color="primary"
                            noCaps
                            :label="$t('projects.new')"
                            icon="add_circle_outline"
                            @click="$emit('onNewItem')"
                        />
                    </template>
                </EmptyList>
            </template>
        </Table>

        <Dropdown
            v-if="selected"
            :opened="!!selected"
            :options="selected.options"
            :coords="selected.coords"
            @on-close="cleanSelected"
            @on-click="handleClickOption"
        />
    </div>
</template>

<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { Table, EmptyList, Dropdown } from '@/components'
import { filtersMixin } from '@/mixins'

const ACTIONS = {
    DELETE: 'DELETE',
    EDIT: 'EDIT',
}

export default {
    components: {
        Table,
        EmptyList,
        Dropdown,
    },
    mixins: [filtersMixin],
    inject: ['App'],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
    },
    emits: ['onNewItem', 'onClick', 'onFetchData'],
    data () {
        return {
            selected: undefined,
        }
    },
    computed: {
        cols () {
            return [
                ...this.columns,
                {
                    name: 'code',
                    label: this.$t('general.code'),
                    align: 'left',
                    width: 100,
                },
                {
                    name: 'name',
                    label: this.$t('general.project'),
                    align: 'left',
                },
                {
                    name: 'description',
                    label: this.$t('general.description'),
                    align: 'left',
                },
                {
                    name: 'updateDate',
                    label: this.$t('general.updateDate'),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: '',
                },
            ]
        },
        hideHeader () {
            return !(this.rows || []).length
        },
        emptyMessage () {
            return {
                title: this.$t(`projects.notifications.${this.search ? 'noResult.title' : 'empty.title'}`),
                text: this.$t(`projects.notifications.${this.search ? 'noResult.text' : 'empty.text'}`),
            }
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    methods: {
        generateOptionsRow ({ deletedAt, parsed }) {
            const isArchived = !!deletedAt
            return [
                ...(!isArchived
                    ? [
                        {
                            key: ACTIONS.EDIT, icon: 'edit', iconColor: 'primary', label: this.$t('actions.edit'), event: 'onUpdate',
                        },
                        {
                            key: ACTIONS.DELETE, icon: 'delete', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                        },
                    ]
                    : []),
            ]
        },
        setSelected (row, event) {
            const { clientX: x, clientY: y } = event
            setTimeout(() => (this.selected = { coords: { x, y }, ...row }), 100)
        },
        cleanSelected () {
            this.selected = undefined
        },
        handleClickOption ({ event }) {
            if (event) this.$emit(event, this.selected)
            this.cleanSelected()
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        handleGoToProject ({ uuid }) {
            this.$emit('onClick', uuid)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
    },
}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 165px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
