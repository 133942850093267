export default {
    new: 'Novo grupo de trabalho',
    delete: 'Eliminar grupo de trabalho',
    edit: 'Editar grupo de trabalho',
    showActives: 'Mostrar grupos de trabalho activos',
    sharedWithMe: 'Partilhado comigo',
    createdByMe: 'Criado por mim',
    notifications: {
        noResult: {
            title: 'Nenhum resultado encontrado',
            text: 'Não foram encontrados grupos de trabalho para a sua pesquisa.',
        },
        empty: {
            title: 'Não foram encontrados grupos de trabalho',
            text: 'Não existe nenhuma notificação para mostrar atualmente.',
        },
        created: {
            text: 'Grupos de trabalho criados com sucesso.',
        },
        updated: {
            text: 'Grupos de trabalho actualizados com sucesso.',
        },
        deleted: {
            text: 'Grupos de trabalho eliminados com êxito.',
        },
    },

    modals: {
        delete: {
            text: 'Tem a certeza de que pretende eliminar o grupo de trabalho "{nome}"?',
        },
    },
}
