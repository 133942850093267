export default {
    user: {
        name: {
            label: 'Name',
            placeholder: '',
        },
        surname: {
            label: 'Last name',
            placeholder: '',
        },
        email: {
            label: 'E-mail address',
            placeholder: '',
        },
        locale: {
            label: 'Language',
            placeholder: '',
        },
        locationFormat: {
            label: 'Dates and numbers format',
            placeholder: '',
        },

        locales: {
            en: 'English',
            es: 'Spanish',
            pt: 'Portuguese',
        },
        locationFormats: {
            en: 'American',
            eu: 'European',
        },

        date: 'Date',
        number: 'Number',

        submit: 'Save',
    },
}
