<template>
    <div class="Models">
        <div class="Actions flex row-left-center mb-1">
            <QBtn
                outline
                noCaps
                color="primary"
                icon="cloud_upload"
                :label="$t('projects.models.upload')"
                @click="handleUploadItem"
            />
        </div>

        <div class="Container">
            <ModelListCards
                ref="table"
                :rows="models"
                :total="total"
                :search="(filters || {}).search"
                :archived="isProjectArchived"
                :loading="isFetching"
                @on-new-item="handleUploadItem"
                @on-delete="handleDeleteItem"
                @on-update="handleUpdateItem"
                @on-download="handleDownloadItem"
                @on-fetch-data="fetchData"
                @on-visualization="handleViewModel"
            />
        </div>

        <ModalRequest
            ref="deleteDialog"
            :title="$t('projects.models.delete')"
            :text="$t('projects.models.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @on-accept="archiveModel"
        />

        <ModalNameDesc
            ref="updateDialog"
            :title="$t('projects.models.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            withoutDesc
            @on-submit="updateModel"
        />

        <UploadDialog
            ref="uploadDialog"
            @import="uploadModel"
            @rejected-file="notifyRejected"
        />

        <ModelPreview
            :show="bpmnShown"
            :bpmnData="selected"
            @close-preview="handleCloseModelPreview"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess, notify,
} from '@/api'
import { download } from '@/services/download'
import { pollingMixin, filtersStorageMixin } from '@/mixins'
import { ModalRequest, ModalNameDesc } from '@/components'
import { ModelListCards, UploadDialog, ModelPreview } from './sections'

export default {
    name: 'Models',
    components: {
        ModalRequest,
        ModelListCards,
        ModalNameDesc,
        UploadDialog,
        ModelPreview,
    },
    mixins: [pollingMixin, filtersStorageMixin],
    props: {
        projectId: VueTypes.string,
        isProjectArchived: VueTypes.bool,
        filters: VueTypes.shape({
            search: VueTypes.string,
            archived: VueTypes.oneOfType([VueTypes.string, VueTypes.bool]),
        }),
    },
    data () {
        return {
            models: undefined,
            total: 0,
            isFetching: false,

            selected: undefined,
            isLoading: false,
            toUpload: undefined,
            bpmnShown: false,
        }
    },
    computed: {
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...(this.filters || {}),
                ...pagination,
            }
        },
    },
    watch: {
        filters: {
            handler (value) {
                if (value) this.fetchData()
            },
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        handleError (err) {
            console.error('failed to show diagram', err)
        },
        handleShown () {
            console.log('diagram shown')
        },
        handleDeleteItem (row) {
            this.selected = row
            this.$refs.deleteDialog.open()
        },
        handleUpdateItem (row) {
            this.selected = row
            this.$refs.updateDialog.open(row)
        },
        handleDownloadItem (row) {
            download({ name: row.modelName, format: 'bpmn', data: row.bpmn })
        },
        handleUploadItem () {
            this.$refs.uploadDialog.open()
        },
        async fetchData ({ polling = false } = {}) {
            const {
                search, page, rowsPerPage,
            } = this.params
            const params = {
                projectId: this.projectId,
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || null,
                polling,
            }

            if (!(this.models || []).length) this.isFetching = true
            apiRequest(Api().models.list(params))
                .then(({ data, total }) => {
                    this.models = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        async updateModel ({ name }) {
            const { id: modelId } = this.selected
            this.isLoading = true
            apiRequest(Api().models.update({
                modelId,
                params: {
                    name,
                },
            })).then(() => {
                notifySuccess(this.$t('projects.models.notifications.updated.text'))
                this.fetchData()
                this.$refs.updateDialog.close()
                this.selected = undefined
            })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async archiveModel () {
            const { id: modelId } = this.selected
            this.isLoading = true
            apiRequest(Api().models.delete({ modelId }))
                .then(() => {
                    notifySuccess(this.$t('projects.models.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.deleteDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async uploadModel ({ modelName, file }) {
            this.isLoading = true
            const form = new FormData()
            form.append('file', file)
            form.append('name', modelName)
            form.append('projectUid', this.projectId)
            apiRequest(Api().models.create({
                params: form,
            }))
                .then(() => {
                    notifySuccess(this.$t('projects.models.notifications.created'))
                    this.fetchData()
                    this.$refs.uploadDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleViewModel (model) {
            this.selected = model
            this.bpmnShown = true
        },
        handleCloseModelPreview () {
            this.bpmnShown = false
        },
        notifyRejected () {
            notify({
                message: this.$t('projects.models.modals.upload.rejected'),
            })
        },
    },
}
</script>
<style scoped lang="scss">
</style>
