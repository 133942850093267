<template>
    <div
        id="bpmn"
        ref="viewer"
    >
        <QBtn
            v-if="centerButton"
            id="reset-zoom-button"
            icon="adjust"
            unelevated
            color="white"
            :class="CenterBtn"
            textColor="primary"
            size="lg"
            padding="xs"
        >
            <QTooltip>
                {{ $t('visualization.centerChart') }}
            </QTooltip>
        </QBtn>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer'
import { notifyError } from '@/api'

export default {
    name: 'BpmnViewer',
    components: {
    },
    props: {
        data: VueTypes.string,
        centerButton: VueTypes.bool.def(true),
    },
    data () {
        return {
            viewer: undefined,
        }
    },
    computed: {
        CenterBtn () {
            return this.$parent.$options.name === 'Conformance' ? 'CenterBtnConformance' : 'CenterBtnModelPreview'
        },
    },
    watch: {
        data () {
            try {
                this.viewer.importXML(this.data).then(() => {
                    this.viewer.get('canvas').zoom('fit-viewport')
                    if (this.$parent.$options.name !== 'Conformance') {
                        this.viewer.get('canvas').scroll({ dx: 0, dy: 100 })
                    }
                })
            } catch (err) {
                this.handleError(err)
            }
        },
    },
    mounted () {
        this.viewer = new BpmnViewer({
            container: '#bpmn',
        })
        try {
            this.viewer.importXML(this.data).then(() => {
                this.viewer.get('canvas').zoom('fit-viewport')
                if (this.$parent.$options.name !== 'Conformance') {
                    this.viewer.get('canvas').scroll({ dx: 0, dy: 100 })
                }
            })
        } catch (err) {
            this.handleError(err)
        }
        document.getElementById('reset-zoom-button').addEventListener('click', () => {
            this.viewer.get('canvas').zoom('fit-viewport')
            if (this.$parent.$options.name !== 'Conformance') {
                this.viewer.get('canvas').scroll({ dx: 0, dy: 100 })
            }
        })
    },
    methods: {
        handleError (err) {
            notifyError(this.$t('visualization.bpmnNotShowable: ') + err)
        },
    },
}
</script>
<style scoped lang="scss">
    #bpmn {
        height: 350px;
        width: 100%;
    }
    .CenterBtnConformance {
        position: absolute;
        top: 150px;
        right: 15%;
    }
    .CenterBtnModelPreview {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
</style>
