<template>
    <form
        class="chat-box"
        @submit="onSubmit($event)"
    >
        <QInput
            v-model="text"
            :placeholder="$t('assistant.writeMessagePlaceholder')"
            type="text"
            class="EnterText"
        />
        <QBtn
            :disabled="text === ''"
            noCaps
            unelevated
            color="primary"
            @click="onSubmit($event)"
        >
            <div class="row items-center no-wrap">
                <div class="text-center">
                    {{ $t('assistant.send') }}
                </div>
                <QIcon
                    right
                    name="send"
                />
            </div>
        </QBtn>
    </form>
</template>

<script>
import { QInput } from 'quasar'

export default {
    name: 'ChatBox',
    components: { QInput },
    emits: ['submit'],
    data: () => ({
        // To keep the state of the input content
        text: '',
    }),
    methods: {
        // called when the form is submitted
        onSubmit (event) {
            this.$emit('submit', event, this.text)
            this.text = ''
        },
    },
}
</script>

<style scoped lang="scss">
.chat-box {
  width: 100%;
  display: flex;
}

.EnterText {
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 10px;
}

button:disabled {
  opacity: 0.5;
}

.chat-box {
  background-color: $gray-07;
  border: 1px solid $gray-03;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: $black;
  // height: 100px;
}

</style>
