const logo = require('../assets/logo-inverbis.svg')

const locales = ['en', 'es', 'pt']
const locationFormats = ['en', 'eu']

export default {
    project: {
        name: process.env.VUE_APP_PROJECT_NAME || '',
        description: process.env.VUE_APP_PROJECT_DESCRIPTION || '',
        logo: process.env.VUE_APP_PROJECT_LOGO || logo,
        website: process.env.VUE_APP_WEBSITE || '',
        powered: process.env.VUE_APP_POWERED || 'Inverbis',
        theme: process.env.VUE_APP_THEME || 'default',
    },
    isDev: process.env.NODE_ENV === 'development',
    isTest: process.env.NODE_ENV === 'test',
    useMock: process.env.VUE_APP_USE_MOCK === 'true',
    api: {
        baseURL: `${process.env.VUE_APP_ROOT_API}/${process.env.VUE_APP_ROOT_API_VERSION}/${process.env.VUE_APP_ROOT_API_REINO}`,
        timeout: 60000,
        headers: {
            'Accept-Language': 'en-US',
        },
    },
    apiLLm: {
        baseURL: `${process.env.VUE_APP_ROOT_API}/${process.env.VUE_APP_ROOT_API_VERSION}/${process.env.VUE_APP_ROOT_API_REINO}`,
        timeout: 90000,
        headers: {
            'Accept-Language': 'en-US',
        },
    },
    keycloak: {
        url: process.env.VUE_APP_KEYCLOAK_URL,
        clientId: `${process.env.VUE_APP_KEYCLOAK_CLIENTID}-frontend`,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
    },
    vueRouter: {
        base: process.env.VUE_APP_ROUTER_BASE_URL,
        // mode: 'history', //in router 4 it is not used
    },
    i18n: {
        locale: process.env.VUE_APP_I18N_LOCALE,
        fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
        locales,
        locationFormats,
        moment: {
            en: 'en',
            es: 'es',
        },
    },
}
