<template>
    <div class="pa-2">
        <WText
            class="pb-1"
            align="left"
            typo="h2"
            weight="bold"
        >
            {{ selectedFilter.label }}
        </WText>
        <form @submit.prevent.stop="handleAddAttribute">
            <div class="actions">
                <QSelect
                    ref="activty"
                    v-model="attrFilter.activity"
                    class="Field flex-1"
                    dense
                    outlined
                    behavior="menu"
                    :label="$t('visualization.filters.activities.label')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.activities.title') })]"
                    :options="categoriesOptions"
                />
                <QSelect
                    ref="attribute"
                    v-model="attrFilter.attribute"
                    dense
                    class="Field flex-1"
                    outlined
                    behavior="menu"
                    :label="$t('visualization.filters.attributes.label')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.attributes.label') })]"
                    :options="attributesOptions"
                    @update:modelValue="setAttributeOptionValues"
                />
                <QSelect
                    ref="constraints"
                    v-model="attrFilter.constraints"
                    dense
                    class="Field flex-1"
                    outlined
                    behavior="menu"
                    :label="$t('visualization.filters.constraints.label')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.constraints.title') })]"
                    :options="optionsForSelectedFilter"
                />
                <div v-if="selectedFilter.id !== FILTERS_TYPES.ATTRIB_BOOLEAN">
                    <QInput
                        v-if="selectedFilter.id === FILTERS_TYPES.ATTRIB_DATE"
                        ref="date"
                        v-model="attrFilter.value"
                        type="date"
                        class="Field"
                        :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.attributes.value.label') })]"
                        outlined
                    />
                    <div v-else-if="selectedFilter.id === FILTERS_TYPES.ATTRIB_NUMBERS">
                        <InputNumeric
                            ref="numberInput"
                            v-model="attrFilter.value"
                            :label="$t('visualization.filters.attributes.value.label')"
                            :rules="[
                                value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.attributes.value.label') })
                            ]"
                        />

                        <InputNumeric
                            v-if="attrFilter.constraints.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN'"
                            ref="numberInput2"
                            v-model="attrFilter.value2"
                            :label="$t('visualization.filters.attributes.value.label')"
                            :rules="[
                                value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.filters.attributes.value.label') })
                            ]"
                        />
                    </div>
                    <QSelect
                        v-else-if="selectedFilter.id === FILTERS_TYPES.ATTRIB_TEXT"
                        ref="textMultipleInput"
                        v-model="attrFilter.values"
                        class="Field"
                        :label="$t('visualization.filters.attributes.value.label')"
                        :rules="[value => validators.required(value.length > 0) || $t('validation.required', { attribute: $t('visualization.filters.attributes.value.label') })]"
                        :multiple="true"
                        outlined
                        dense
                        useInput
                        lazyRules
                        lazy
                        hideSelected
                        behavior="menu"
                        :options="attrValueOptionsFiltered"
                        @filter="attributeFilterFn"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleValuesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                    <div
                        v-if="attrFilter.values.length !== 0"
                        class="chip-container"
                    >
                        <div
                            v-for="(selected, index) in attrFilter.values"
                            :key="index"
                        >
                            <q-chip
                                :label="selected"
                                removable
                                color="primary"
                                textColor="white"
                                @remove="deleteIndexValue(index, attrFilter.values)"
                            >
                                {{ selected.label }}
                            </q-chip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ButtonContainer mt-1">
                <QBtn
                    outline
                    noCaps
                    type="submit"
                    color="primary"
                    :label="$t('visualization.filters.add')"
                />
            </div>
        </form>
        <div class="TableContainer mt-1">
            <QTable
                :title="$t('visualization.filters.attributes.table.title')"
                :rows="rows"
                :rowsPerPageOptions="[0]"
                :pagination="{ page:1, rowsPerPage: 0 }"
                :hideBottom="!!rows.length"
                :columns="columns"
                rowKey="name"
            >
                <template #no-data="{ message }">
                    <div class="full-width row flex-center text-accent q-gutter-sm">
                        <span>
                            {{ message }}
                        </span>
                    </div>
                </template>
                <template #body-cell-constraints="props">
                    <QTd :props="props">
                        {{ props.row.constraints.label }}
                    </QTd>
                </template>
                <template #body-cell-activity="props">
                    <QTd :props="props">
                        {{ props.row.activity.label }}
                    </QTd>
                </template>
                <template #body-cell-attribute="props">
                    <QTd :props="props">
                        {{ props.row.attribute.label }}
                    </QTd>
                </template>
                <template #body-cell-value="props">
                    <QTd :props="props">
                        {{ (showNumOrList(props.row.value)) }}
                    </QTd>
                </template>
                <template #body-cell-delete="props">
                    <QTd
                        key="delete"
                        class="td-center"
                    >
                        <div class="Delete">
                            <q-icon
                                name="delete"
                                size="24px"
                                color="secondary"
                                clickable
                                @click="handleDeleteAttribute(props)"
                            />
                        </div>
                    </QTd>
                </template>
            </QTable>
        </div>
        <div class="ButtonContainer mt-1">
            <QBtn
                color="primary"
                noCaps
                :disable="!(rows || []).length"
                :label="$t('visualization.filters.save')"
                @click="handleSaveFilter"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import { InputNumeric } from '@/components'
import { ATTRIBUTTE_TYPES, FILTERS_TYPES, CATEGOTY_ANY } from '../filtersEntity'

const ROWS_PER_PAGE_OPTIONS = [
    15, 30, 50, 75, 100,
]

export default {
    name: 'AttributesSection',
    components: { InputNumeric },
    mixins: [validatorsMixin],
    inject: ['App'],
    props: {
        attributes: VueTypes.array,
        categories: VueTypes.array,
        attributeValues: VueTypes.any,
        selectedFilter: VueTypes.object,
    },
    emits: ['onSaveAttributes'],
    data () {
        return {
            attrFilter: {
                activity: '',
                attribute: '',
                constraints: '',
                value: '',
                values: [],
            },
            attrValueOptions: [],
            selectedValues: [],
            attrValueOptionsFiltered: [],
            columns: [],
            rows: [],
            rowsPerPage: ROWS_PER_PAGE_OPTIONS,
        }
    },
    computed: {
        attributesOptions () {
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_NUMBERS) {
                return this.filterAttributesByType(this.formatAttributesOption(this.attributes), ['SHORT', 'DECIMAL'])
            }
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_BOOLEAN) {
                return this.filterAttributesByType(this.formatAttributesOption(this.attributes), 'BOOLEAN')
            }
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_TEXT) {
                return this.filterAttributesByType(this.formatAttributesOption(this.attributes), 'STRING')
            }
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_DATE) {
                return this.filterAttributesByType(this.formatAttributesOption(this.attributes), 'TIMESTAMP')
            }
            return this.formatAttributesOption(this.attributes)
        },
        // Reactive translations
        optionsForSelectedFilter () {
            const typeFunction = ATTRIBUTTE_TYPES[this.selectedFilter.id]
            return typeFunction ? typeFunction() : []
        },
        categoriesOptions () {
            return [
                { label: CATEGOTY_ANY(), value: '*' },
                ...this.categories.map(c => ({ label: c, value: c })),
            ]
        },
        hasError () {
            return this.$refs.activty.hasError || this.$refs.attribute.hasError || this.$refs.constraints.hasError
        },
    },
    beforeMount () {
        this.ATTRIBUTTE_TYPES = ATTRIBUTTE_TYPES
        this.FILTERS_TYPES = FILTERS_TYPES
    },
    created () {
        this.columns = [
            {
                name: 'activity', label: this.$t('visualization.filters.attributes.table.activity'), field: 'activity', align: 'center',
            },
            {
                name: 'attribute', align: 'center', label: this.$t('visualization.filters.attributes.table.attribute'), field: 'attribute',
            },
            {
                name: 'constraints', label: this.$t('visualization.filters.attributes.table.constraints'), field: 'constraints', align: 'center',
            },
            {
                name: 'value', field: 'value', align: 'center', label: this.$t('visualization.filters.attributes.table.value'),
            },
            {
                name: 'delete', field: 'delete', align: 'center', label: this.$t('visualization.filters.attributes.table.delete'),
            },
        ]
    },
    mounted () {},
    methods: {
        getOptions (filterId) {
            const typeFunction = ATTRIBUTTE_TYPES[filterId]
            return typeFunction ? typeFunction() : []
        },
        showNumOrList (value) { // in case the value is an array
            if (Array.isArray(value)) {
                const formattedValues = value.map(element => this.App.numberLocationFormat(element))
                return formattedValues.join(',   ')
            }
            return this.App.numberLocationFormat(value, true)
        },
        handleDeleteAttribute (props) {
            this.rows = this.rows.filter((_, index) => index !== props.rowIndex)
        },
        filterAttributesByType (attributes, type) {
            const key = Array.isArray(type) ? type : [type]
            return attributes.filter(f => key.includes(f.type))
        },
        formatAttributesOption (attributes) {
            return attributes.map(({ name, type }) => ({ label: name, value: name, type }))
        },
        handleAddAttribute () {
            const { selectedFilter } = this
            this.$refs.activty.validate()
            this.$refs.attribute.validate()
            this.$refs.constraints.validate()
            let valueInputError

            if (selectedFilter.id === FILTERS_TYPES.ATTRIB_DATE) {
                this.$refs.date.validate()
                valueInputError = this.$refs.date.hasError
            }
            if (selectedFilter.id === FILTERS_TYPES.ATTRIB_NUMBERS) {
                this.$refs.numberInput.validate()
                valueInputError = this.$refs.numberInput.hasError
                if (this.attrFilter.constraints.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN') {
                    this.$refs.numberInput2.validate()
                    valueInputError = (this.$refs.numberInput2.hasError || valueInputError)
                }
            }
            if (selectedFilter.id === FILTERS_TYPES.ATTRIB_TEXT) {
                this.$refs.textMultipleInput.validate()
                valueInputError = this.$refs.textMultipleInput.hasError
            }
            if (this.hasError || valueInputError) {
                return
            }
            let attrValue
            if (this.attrFilter.constraints.value === 'TRACE_ATTRIBUTES_CTG_IN' ||
                this.attrFilter.constraints.value === 'TRACE_ATTRIBUTES_CTG_NOT_IN') {
                attrValue = [...this.attrFilter.values]
            } else if (this.attrFilter.constraints.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN') {
                attrValue = [this.attrFilter.value, this.attrFilter.value2]
            } else {
                attrValue = this.attrFilter.value
            }
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_NUMBERS) {
                attrValue = Array.isArray(attrValue) ? attrValue.map(value => value.toString()) : attrValue.toString()
            }
            const attrFilter = {
                activity: this.attrFilter.activity,
                attribute: this.attrFilter.attribute,
                constraints: this.attrFilter.constraints,
                value: attrValue,
            }
            const newRow = { ...attrFilter }
            this.rows = [...this.rows, newRow]
        },
        handleValuesInput (selected) {
            if (!this.attrFilter.values.includes(selected)) {
                this.attrFilter.values.push(selected)
            } else {
                const index = this.attrFilter.values.indexOf(selected)
                if (index !== -1) {
                    this.attrFilter.values.splice(index, 1)
                }
            }
            this.selectedValues = this.attrFilter.values
        },
        setAttributeOptionValues (value) {
            if (this.selectedFilter.id === FILTERS_TYPES.ATTRIB_TEXT) {
                const [attrib] = this.attributeValues.filter(avs => avs.name === value.value)
                if (attrib) {
                    this.attrValueOptionsFiltered = attrib.values
                    this.attrValueOptions = attrib.values
                    this.attrFilter.values = []
                    this.selectedValues = this.attrFilter.values
                } else {
                    this.attrValueOptions = []
                    this.attrValueOptionsFiltered = []
                    this.attrFilter.value = ''
                    this.attrFilter.values = []
                    this.selectedValues = []
                }
            }
        },
        attributeFilterFn (val, update) {
            update(() => {
                const newValue = val.toLowerCase()
                this.attrValueOptionsFiltered = this.attrValueOptions.filter(v => v.toLowerCase().indexOf(newValue) > -1)
            })
        },
        handleSaveFilter () {
            this.$emit('onSaveAttributes', this.rows)
        },
        deleteIndexValue (index, filterValuesArray) {
            filterValuesArray.splice(index, 1)
        },
        formatAttributeValue (value) {
            if (Array.isArray(value)) {
                return value.join(', ')
            }
            return value
        },
    },
}
</script>
<style lang="scss" scoped>
    .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    .Field {
        &:deep(.q-field__control) {
            height: 50px;
            .q-field__append {
                height: 50px;
            }
        }
    }
    .ButtonContainer {
        text-align: end;
    }
    .Delete {
        text-align: center;
    }
    .chip-container {
        display: flex;
        flex-wrap: wrap;
    }
    .chip {
        margin-right: 5px;
        margin-bottom: 5px;
    }
</style>
