<template>
    <div
        class="Table"
        :class="{ bordered: !loading }"
    >
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-inner-loading :showing="loading" />
        </Transition>
        <QTable
            v-show="!loading"
            v-model:pagination="pagination"
            flat
            :columns="columns"
            :rows="data"
            :totalRows="total"
            :rowKey="rowKey"
            :rowsPerPageOptions="rowsPerPageOptions"
            :hideHeader="hideHeader"
            :hideBottom="hideBottom"
            :class="{ bordered: !length }"
            class="Wrapper"
            @request="emitRequest"
        >
            <template #body="props">
                <slot
                    name="content"
                    :row="props.row"
                />
            </template>

            <template #no-data="noData">
                <slot
                    name="empty"
                    :data="noData"
                />
            </template>
        </QTable>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

const ROWS_PER_PAGE_OPTIONS = [
    5, 10, 15, 25, 50, 100,
]

export default {
    name: 'Table',
    props: {
        columns: VueTypes.any,
        data: VueTypes.any,
        total: VueTypes.any,
        rowKey: VueTypes.string,
        loading: VueTypes.bool.def(false),
        hideHeader: VueTypes.bool.def(true),
        hideBottom: VueTypes.bool.def(false),
        rowsPerPageOptions: VueTypes.arrayOf([VueTypes.number]).def(ROWS_PER_PAGE_OPTIONS),
    },
    emits: ['table-request'],
    data () {
        return {
            page: 1,
            rowsPerPage: 15,
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.total,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
        length () {
            return (this.data || []).length
        },
    },
    methods: {
        emitRequest (props) {
            // check for limit = All, just changing the event data so the pagination remains as All
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            this.pagination = props.pagination
            this.$emit('table-request', { ...props.pagination, rowsPerPage })
        },
        getPagination () {
            return this.pagination
        },
    },
}
</script>

<style scoped lang="scss">
.Table {
    width: 100%;

    &.bordered {
        border: 1px solid $table-border-color;
        border-radius: $border-radius;
    }

    .Wrapper {
        &:deep(){
            flex: 1;
            color: $table-color !important;
            background: transparent !important;

            .q-table {
                thead th {
                    color: $table-head-color;
                    font-size: $table-head-size;
                    font-weight: bold;
                    text-transform: uppercase;
                    background: $table-head-bg;
                }

                tbody {
                    td {
                        padding: 15px;

                        &:before {
                            background: rgba($primary, 0.1)
                        }
                    }
                }
            }

            .q-table__bottom {
                background: $table-head-bg;
            }
        }

        &.bordered {
            &:deep(){
                .q-table__bottom {
                    border: 0;
                }
            }
        }
    }
}
</style>
