<template>
    <hr
        class="Divider"
        :class="color"
        :style="{
            height: `${height}px`,
        }"
    >
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Divider',
    props: {
        height: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def(1),
        color: VueTypes.oneOf([
            'light',
            'medium',
            'dark',
        ]).def('medium'),
    },
}
</script>
<style scoped lang="scss">
hr {
    display: block;
    width: 100%;
    margin: 0;
    border: 0;

    &.light {
        background: $gray-light;
    }
    &.medium {
        background: $gray-05;
    }
    &.dark {
        background: $gray-dark;
    }
}
</style>
