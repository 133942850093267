<template>
    <div
        class="Badge"
        :class="[color, { clickable }]"
        @click="clickable && $emit('onClick')"
    >
        <div class="flex row-left-center">
            <q-icon
                v-if="icon"
                :name="icon"
                :size="iconSize"
                :color="textColor"
            />
            <WText
                tag="span"
                typo="span"
                weight="semi"
                :size="size"
                :color="textColor"
            >
                {{ label }}
            </WText>
            <slot />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Badge',
    props: {
        label: VueTypes.string,
        textColor: VueTypes.oneOf(['primary', 'secondary', 'tertiary', 'white', 'black', 'yellow', 'red', 'gray01', 'gray02', 'gray03', 'gray04', 'accent', '']).def('white'),
        size: VueTypes.number.def(12),
        icon: VueTypes.string,
        iconSize: VueTypes.number.def(14),
        color: VueTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'info', '']).def(''),
        clickable: VueTypes.bool.def(false),
    },
    emits: ['onClick'],
}
</script>
<style scoped lang="scss">
.Badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: $border-radius;

    .q-icon {
        margin-right: 5px;
    }

    &.primary {
        background: $primary;
    }
    &.secondary {
        background: $secondary;
    }
    &.success {
        background: $success;
    }
    &.error {
        background: $danger;
    }
    &.warning {
        background: $warning;
    }
    &.info {
        background: $info;
    }
    &.clickable {
        cursor: pointer;
    }
}
</style>
