<template>
    <div>
        <div class="Title row">
            {{ $t(`wizard.boosterWizard.setupOptions.insights`) }}
        </div>
        <SettingsModal
            ref="settingsModal"
            class="SettingsModal"
            :wizardMode="true"
            :slaCheck="slaCheck"
            :caseCompletionCheck="caseCompletionCheck"
            :relevantAttributesCheck="relevantAttributesCheck"
            @settingsToWizard="checkSettings"
            @relevantToWizard="checkRelevant"
            @saved="handleProceedToVisualization"
        />
        <div class="row justify-between q-mx-xl">
            <q-btn
                textColor="white"
                color="primary"
                noCaps
                type="submit"
                @click="handleReturn"
            >
                {{ $t('wizard.boosterWizard.back') }}
            </q-btn>
            <q-btn
                textColor="white"
                color="primary"
                noCaps
                type="submit"
                class="q-ml-sm"
                @click="checkSaveAndProceed"
            >
                {{ $t('wizard.boosterWizard.save') }}
            </q-btn>
        </div>
    </div>
</template>
<script>

import VueTypes from 'vue-types'
import {
    notify,
} from '@/api'
import SettingsModal from '../../Project/Process/Settings/SettingsModal.vue'

export default {
    name: 'WizardSettings',
    components: { SettingsModal },
    props: {
        datasetId: VueTypes.string,
        projectId: VueTypes.string,
        datasetName: VueTypes.string,
        type: VueTypes.string,
    },
    emits: ['back', 'proceed'],
    data () {
        return {
            relevantAttributesSelected: [],
            slaCheck: false,
            caseCompletionCheck: false,
            relevantAttributesCheck: false,
            settings: {
                sla: undefined,
                caseCompletion: undefined,
            },
        }
    },
    mounted () {
    },
    methods: {
        checkSaveAndProceed () {
            if (this.caseCompletionCheck && this.slaCheck && this.relevantAttributesCheck) {
                this.$refs.settingsModal.saveInWizard()
            } else {
                notify(({
                    type: 'warning',
                    message: this.$t('wizard.boosterWizard.notConfigured'),
                }))
            }
        },
        handleProceedToVisualization () {
            this.$emit('proceed')
        },
        handleReturn () {
            this.$emit('back')
        },
        checkRelevant (relevant) {
            if (!relevant || relevant.length === 0) {
                this.relevantAttributesCheck = false
            } else {
                this.relevantAttributesCheck = true
            }
        },
        checkSettings (settings) {
            this.slaCheck = settings.sla && settings.sla.value !== -1
            let caseCompletionFilled = false
            const caseCompletionAvailable = settings.caseCompletion &&
                settings.caseCompletion.endingActivities &&
                settings.caseCompletion.occurringActivities &&
                settings.caseCompletion.startingActivities
            if (caseCompletionAvailable) {
                caseCompletionFilled = settings.caseCompletion.endingActivities.length > 0 ||
                    settings.caseCompletion.occurringActivities.length > 0 ||
                    settings.caseCompletion.startingActivities.length > 0
            }
            this.caseCompletionCheck = !!caseCompletionFilled
        },
    },
}
</script>
<style lang="scss" scoped>
.Title {
    display: flex;
    justify-content: center;
    font-size: 24px;
}
.SettingsModal {
    &:deep(.Screen) {
        height: 100%;
    }
}
</style>
