export default {
    organization: 'Grupo de trabalho',
    project: 'Projeto',
    dataset: 'Conjunto de dados',
    report: 'Relatório',
    visualization: 'Visualização',
    download: 'Descarregar zip',
    events: 'Eventos',
    processing: 'Processando...',

    date: 'Data e hora',
    createdAt: 'Criado em',
    updateDate: 'Última atualização',
    deleteDate: 'Apagar em',
    startAt: 'Início em',
    endAt: 'Fim em',
    seenAt: 'Visto em',
    content: 'Conteúdo',

    code: 'Código',
    title: 'Título',
    name: 'Nome do atributo',
    description: 'Descrição do ficheiro',
    status: 'Estado',
    model: 'Modelo',
    email: 'Correio eletrónico',
    amount: 'Quantidade',
    actions: 'Acções',
    type: 'Tipo de alerta',
    file: 'Ficheiro',
    characters: 'caracteres',

    readOnly: 'Só de leitura',
    archived: 'Arquivado',
    optional: 'Opcional',
    uploadData: 'Carregar dados',
    comingSoon: 'Em breve',

    account: 'Conta',
    apis: 'APIs',
    signout: 'Assinatura',
}
