<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <div class="Header row">
                <WText
                    tag="h3"
                    :size="16"
                    color="primary"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('booster.d2t.analysisReport') }}
                </WText>
                <QIcon
                    class="copy-button"
                    name="content_copy"
                    color="primary"
                    size="25px"
                    @click="copyToClipboard"
                    @mouseover="onMouseOver"
                    @mouseleave="onMouseLeave"
                />
                <QTooltip
                    anchor="center right"
                    self="center start"
                >
                    {{ tooltipMessage }}
                </QTooltip>
            </div>
        </template>
        <template #body>
            <div
                class="AnalysisReport"
            >
                <div v-html="analysisReport" />
            </div>
        </template>
    </Modal>
</template>

<script>
import { Modal } from '@/components'

export default {
    name: 'AnalysisReportModal',
    components: {
        Modal,
    },
    inject: ['App'],
    emits: ['save'],
    data () {
        return {
            opened: false,
            analysisReport: '',
            pureAnalysisReport: '',
            tooltipMessage: this.$t('booster.d2t.copyToClipboard'),
            isCopied: false,
        }
    },
    methods: {
        open (params) {
            this.reset()
            this.analysisReport = this.App.numberLocationFormat(params.analysisReport, false, true)
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            this.tooltipMessage = this.$t('booster.d2t.copyToClipboard')
            this.isCopied = false
        },
        copyToClipboard () {
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = this.analysisReport
            navigator.clipboard.writeText(tempDiv.innerText).then(() => {
                this.tooltipMessage = this.$t('booster.d2t.copied')
                this.isCopied = true
            })
        },
        onMouseOver () {
            if (!this.isCopied) {
                this.tooltipMessage = this.$t('booster.d2t.copyToClipboard')
            }
        },
        onMouseLeave () {
            setTimeout(() => {
                this.isCopied = false
                this.tooltipMessage = this.$t('booster.d2t.copyToClipboard')
            }, 200)
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 800px;
    }
}
.Header {
    align-items: center;
    gap: 10px,
}
.AnalysisReport {
    margin-top: 50px;
    margin-bottom: 20px;
}
.copy-button {
    color: $primary;
    cursor: pointer;
}
</style>
