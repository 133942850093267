<template>
    <WText
        :tag="tag"
        :typo="typo"
        :align="align"
        :color="color"
        :underline="underline"
        :italic="italic"
        :size="size"
        :weight="weight"
    >
        {{ formatValue(Number(animatedValue)) }}
    </WText>
</template>

<script>
import VueTypes from 'vue-types'
import anime from 'animejs'
import { formatNumber } from '@/utils/formatNumber'

export default {
    name: 'Counter',
    props: {
        value: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def('0').isRequired,
        formatValue: VueTypes.func.def(value => formatNumber(value, {
            decimals: 0, decimalSeparator: '.', compress: false, trimRight: true,
        })),
        easing: VueTypes.string.def('linear'),
        duration: VueTypes.number.def(1000),
        update: VueTypes.func,
        begin: VueTypes.func,
        complete: VueTypes.func,
        run: VueTypes.func.def(undefined),
        delay: VueTypes.number,
        round: VueTypes.any.def(null),
        number: VueTypes.number,
        tag: VueTypes.string.def('span'),
        typo: VueTypes.string.def('p'),
        align: VueTypes.oneOf(['left', 'center', 'right', '']).def('left'),
        color: VueTypes.oneOf([
            'primary',
            'secondary',
            'black',
            'white',
            'grey01',
            'grey02',
            'grey03',
            'grey04',
            '']).optional,
        size: VueTypes.oneOfType([String, Number]).optional,
        weight: VueTypes.oneOf(['light', 'medium', 'semi', 'bold', '']).def(''),
        underline: VueTypes.bool.def(false),
        italic: VueTypes.bool.def(false),
    },
    data () {
        return {
            animatedValue: 0,
        }
    },
    watch: {
        value (value) {
            this.animateValue(value)
        },
    },
    mounted () {
        this.animateValue(this.value)
    },
    methods: {
        animateValue (value) {
            const {
                begin,
                easing,
                duration,
                complete,
                update,
                run,
                delay,
                round,
            } = this
            anime({
                targets: this,
                animatedValue: value,
                duration,
                easing,
                update,
                begin,
                complete,
                run,
                delay,
                round,
            })
        },
    },
}
</script>
