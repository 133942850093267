<template>
    <apexChart
        type="radialBar"
        :options="chart.chartOptions"
        :series="chart.series"
    />
</template>

<script>
import VueTypes from 'vue-types'
import Colors from '@/styles/colors'

export default {
    name: 'RadialBar',
    props: {
        percentage: VueTypes.oneOfType([Number, String]),
        label: VueTypes.string,
    },
    computed: {
        chart () {
            const { percentage, label } = this
            return {
                series: [percentage],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'radialBar',
                    },
                    grid: {
                        padding: {
                            bottom: 10,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            hollow: {
                                size: '35%',
                            },
                            dataLabels: {
                                name: {
                                    fontSize: '12px',
                                    offsetY: 13,
                                },
                                value: {
                                    fontSize: '12px',
                                    offsetY: 11,
                                },
                            },
                        },
                    },
                    colors: [Colors.primary],
                    labels: [label],
                },
            }
        },
    },
}
</script>
