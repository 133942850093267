export default {
    user: {
        name: {
            label: 'Nome do atributo',
            placeholder: '',
        },
        surname: {
            label: 'Último nome',
            placeholder: '',
        },
        email: {
            label: 'Endereço eletrónico',
            placeholder: '',
        },
        locale: {
            label: 'Língua',
            placeholder: '',
        },
        locationFormat: {
            label: 'Formato das datas e dos números',
            placeholder: '',
        },

        locales: {
            en: 'Inglês',
            es: 'Espanhol',
            pt: 'Português',
        },
        locationFormats: {
            en: 'Americano',
            eu: 'Europeu',
        },

        date: 'Data e hora',
        number: 'Número de telefone',

        submit: 'Guardar',
    },
}
