<!-- eslint-disable prefer-destructuring -->
<template>
    <Transition
        name="fade"
        mode="out-in"
        appear
    >
        <div v-if="!showCreate">
            <div class="row justify-between q-mt-md">
                <div class="col">
                    <QBtn
                        color="primary"
                        :label="$t('booster.alerts.definition.create')"
                        class="q-ma-sm"
                        icon="add"
                        noCaps
                        @click="toggleCreationView"
                    />
                </div>
                <div class="col align-right">
                    <QBtn
                        color="primary"
                        :label="$t('booster.alerts.definition.remove')"
                        class="q-ma-sm"
                        icon="delete"
                        noCaps
                        :disable="cantRemove"
                        @click="deleteDefinitions"
                    />
                </div>
            </div>
            <div class="row q-mt-md q-justify-end">
                <div class="textColumn">
                    <p>
                        {{ $t('booster.alerts.filterBy') }}
                    </p>
                </div>
                <div>
                    <QSelect
                        v-model="filterByType"
                        :options="filterOptions"
                        outlined
                        dense
                        class="custom-select"
                        @update:modelValue="fetchData"
                    />
                </div>
            </div>
            <QTable
                ref="table"
                v-model:selected="selected"
                v-model:pagination="pagination"
                :rows="rows"
                :columns="columns"
                rowKey="name"
                flat
                outlined
                separator="none"
                bordered
                class="q-mt-sm"
                tableHeaderClass="bg-grey-2"
                selection="multiple"
                :selectedRowsLabel="getSelectedString"
                :loading="loading"
                :rowsPerPageOptions="rowsPerPageOptions"
                @request="handleTableRequest"
            >
                <template #body-cell-severity="cell">
                    <QTd :props="cell">
                        <QIcon
                            name="circle"
                            :color="`${getSeverityRiskColor(cell.row.severity || cell.row.riskSLA)}`"
                            size="20px"
                        />
                        <span v-if="cell.row.type === 'STANDARD' || cell.row.type === 'TIME'">
                            {{ $t(`booster.alerts.detected.severityRiskLabels.${cell.row.severity || cell.row.riskSLA}`) }}
                        </span>
                        <span v-else>
                            {{ $t(`booster.alerts.detected.predictionRiskLabels.${cell.row.severity || cell.row.riskSLA}`) }}
                        </span>
                    </QTd>
                </template>
                <template #body-cell-type="type">
                    <QTd :props="type">
                        <QIcon
                            name="circle"
                            :color="`${ getTypeColor(type.value) }`"
                            size="20px"
                        />
                        {{ $t(`booster.alerts.definition.types.${type.value}`) }}
                    </QTd>
                </template>
                <template #no-data="{ message }">
                    <div class="full-width row flex-center text-accent q-gutter-sm">
                        <span>
                            {{ message }}
                        </span>
                    </div>
                </template>
                <!-- <template v-slot:bottom-left="{ pagination }">
                    <QSelect
                        v-model="pagination.rowsPerPage"
                        :options="rowsPerPageOptions"
                        outlined
                        dense
                    />
                </template>
                <template v-slot:bottom-right="{ pagination }">
                    <QPagination
                        v-model="pagination.page"
                        :min="1"
                        :max="total"
                        dense
                    />
                </template> -->
            </QTable>
        </div>
        <NewAlert
            v-else
            class="q-ma-md"
            :processId="processId"
            :filterSets="filterSets"
            @cancel="toggleCreationView"
            @create="createDefinition"
        />
    </Transition>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { filtersMixin } from '@/mixins'
import NewAlert from './NewAlert.vue'

const ROWS_PER_PAGE_OPTIONS = [
    5, 15, 30, 50, 75, 100,
]

export default {
    name: 'AlertDefinition',
    components: {
        NewAlert,
    },
    mixins: [filtersMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    data () {
        return {
            loading: false,
            definitions: undefined,
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            total: undefined,
            page: 1,
            rowsPerPage: 15,
            selected: [],
            showCreate: false,
            filterSets: [],
            filterByType: '',
            columns: [{
                name: 'name', label: this.$t('booster.alerts.definition.tableColumn.name'), field: 'name', align: 'left',
            },
            {
                name: 'severity', label: this.$t('booster.alerts.definition.tableColumn.severity'), field: 'severity', align: 'center',
            },
            {
                name: 'filterset', label: this.$t('booster.alerts.definition.tableColumn.filterset'), field: 'filterset', align: 'center',
            },
            {
                name: 'type', label: this.$t('booster.alerts.definition.tableColumn.type'), field: 'type', align: 'center',
            },
            {
                name: 'notification', label: this.$t('booster.alerts.definition.tableColumn.notification'), field: 'notification', align: 'center',
            }],
            filterOptions: [
                { label: this.$t('booster.alerts.definition.types.ALL'), value: '' },
                { label: this.$t('booster.alerts.definition.types.STANDARD'), value: 'STANDARD' },
                { label: this.$t('booster.alerts.definition.types.PREDICTION'), value: 'PREDICTION' },
            ],
            rows: [],
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.total,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
        cantRemove () {
            return this.selected.length === 0
        },
    },
    mounted () {
        this.getDatasetFilters(this.processId)
        this.fetchData()
    },
    methods: {
        getSelectedString () {
            return this.selected.length === 0
                ? ''
                : `${this.selected.length} ${this.$t('booster.alerts.definition.record')}${this.selected.length > 1 ? 's' : ''}
            ${this.$t('booster.alerts.definition.selected')} ${this.rows.length}`
        },
        getSeverityRiskColor (severityRisk) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severityRisk]
        },
        getTypeColor (type) {
            const colors = {
                STANDARD: 'primary',
                PREDICTION: 'secondary',
            }
            return colors[type]
        },
        toggleCreationView () {
            this.showCreate = !this.showCreate
        },
        async createDefinition (params) {
            const datasetId = this.processId
            apiRequest(Api().alerts.definitionCreate({ datasetId, params }))
                .then(() => {
                    notifySuccess(this.$t('booster.alerts.notifications.created'))
                    this.fetchData()
                })
                .catch(notifyError)
                .finally(() => {
                    this.toggleCreationView()
                    this.isLoading = false
                })
        },
        handleTableRequest ({ pagination }) {
            if (pagination) {
                this.page = pagination.page
                this.rowsPerPage = pagination.rowsPerPage
            }
            this.fetchData()
        },
        async fetchData () {
            const datasetId = this.processId
            const filterType = this.filterByType ? this.filterByType.value : ''
            const { page, rowsPerPage } = this.pagination
            const params = {
                type: filterType,
                ...(rowsPerPage ? { start: (page - 1) } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
            }
            apiRequest(Api().alerts.definitionList({ datasetId, params }))
                .then(({ data, total }) => {
                    this.definitions = data
                    this.total = total
                    this.rows = (this.definitions || []).map(row => ({
                        ...row,
                        filterset: Object.values(row.filters)[0],
                        notification: row.mailToNotify,
                    }))
                })
                .catch(notifyError)
        },
        async deleteDefinitions () {
            const { selected } = this
            const datasetId = this.processId
            selected.forEach((item) => {
                apiRequest(Api().alerts.definitionDelete({ datasetId, alertId: item.id }))
                    .then(() => {
                        this.fetchData()
                    })
                    .catch(notifyError)
            })
            this.selected = []
        },
        getDatasetFilters (datasetId) {
            apiRequest(Api().datasets.getDatasetFilters({ datasetId }))
                .then(({ data }) => { this.filterSets = data })
                .catch(notifyError)
                .finally()
        },
    },
}
</script>
<style scoped lang="scss">
    filter {
        right: 0;
    }
    .row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .textColumn {
        margin-right: 16px; /* Espacio entre el texto y el select */
    }

    .custom-select {
        width: 200px; /* Ancho personalizado del QSelect */
    }
</style>
