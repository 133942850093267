export default {
    new: 'Convidar novo utilizador',
    available: 'Notificações disponíveis',
    revoke: 'Revogar notificação',
    cancel: 'Cancelar notificação',
    showActives: 'Mostrar notificações visualizadas',

    notifications: {
        noResult: {
            title: 'Nenhum resultado encontrado',
            text: 'Não foram encontradas notificações para a sua pesquisa.',
        },
        empty: {
            title: 'Não foram encontradas notificações',
            text: 'Todas as notificações geradas por esta plataforma serão listadas nesta vista.',
        },
        seen: {
            text: 'Notificação marcada com sucesso como vista.',
        },
    },
}
