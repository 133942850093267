<template>
    <div
        ref="userAvatar"
        class="UserAvatar"
        @click="handleOpenDropdown"
    >
        <div class="flex row-left-center flex-nowrap">
            <div class="User">
                {{ initialName }}
            </div>
            <WText
                v-if="!hideFullName"
                tag="span"
                weight="semi"
                ellipsis
                class="ml-1"
            >
                {{ fullName }}
            </WText>
        </div>
        <Dropdown
            v-if="isDropdownOpen"
            :opened="isDropdownOpen"
            :options="options"
            :direction="direction"
            :withCoordinates="true"
            :coords="coords"
            @on-close="handleCloseDropdown"
            @on-click="handleClickAction"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Dropdown } from '@/components'

const ACTIONS = {
    ACCOUNT: 'ACCOUNT',
    EVENTS: 'EVENTS',
    DOC: 'DOC',
    SIGNOUT: 'SIGNOUT',
}

export default {
    name: 'UserAvatar',
    components: {
        Dropdown,
    },
    inject: ['App'],
    props: {
        direction: VueTypes.string.def('topRight'),
        hideFullName: VueTypes.bool.def(false),
    },
    data () {
        return {
            isDropdownOpen: false,
            coords: { x: 0, y: 0 },
        }
    },
    computed: {
        initialName () {
            const [letter] = this.fullName || ''
            return letter || 'U'
        },
        fullName () {
            const { name } = this.App?.user || {}
            return name || ''
        },
        options () {
            return [
                {
                    id: 'account', icon: 'person', iconColor: 'primary', key: ACTIONS.ACCOUNT, label: this.$t('general.account'),
                },
                {
                    id: 'apis', icon: 'code', iconColor: 'primary', key: ACTIONS.DOC, label: this.$t('general.apis'),
                },
                {
                    id: 'signout', icon: 'logout', iconColor: 'primary', key: ACTIONS.SIGNOUT, label: this.$t('general.signout'),
                },
            ]
        },
    },
    methods: {
        handleClickAction ({ key }) {
            const actions = {
                [ACTIONS.ACCOUNT]: () => this.navigateAndCloseDropdown({ name: 'Account' }),
                [ACTIONS.DOC]: () => this.navigateAndCloseDropdown({ name: 'Doc' }),
                [ACTIONS.SIGNOUT]: this.signOutAndCloseDropdown,
            }

            if (actions[key]) actions[key]()
        },
        navigateAndCloseDropdown (route) {
            this.$router.push(route).then(() => {
                this.isDropdownOpen = false
            }).catch(() => {})
        },
        signOutAndCloseDropdown () {
            this.App.signout()
            this.isDropdownOpen = false
        },
        handleOpenDropdown () {
            const divRect = this.$refs.userAvatar.getBoundingClientRect()
            const centerX = divRect.left
            const centerY = divRect.top
            this.coords = { x: centerX, y: centerY }
            this.isDropdownOpen = true
        },
        handleCloseDropdown () {
            this.isDropdownOpen = false
        },
    },
}
</script>
<style scoped lang="scss">
.UserAvatar {
    cursor: pointer;
}

.User {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $tertiary;
    border-radius: $border-radius;
    font-size: 18px;
    font-weight: 600;
    color: $primary;
}
</style>
