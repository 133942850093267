<template>
    <div class="State">
        <div
            class="FirstMainContainer"
        >
            <OverallPerformance
                v-if="attributes"
                :attributes="attributes"
                class="OverallPerformance"
            />
            <div
                v-else
                class="OverallPerformance"
            />
            <ProcessElementsPanel
                class="PerformanceActivities"
                @selected-opt="selectedOpt"
            />
        </div>
        <PerformanceDashboardByResource
            :selectedOptions="selectedOptions"
            :attributes="attributesWithoutAll"
            class="SecondMainContainer"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'

import {
    Api, apiRequest, notifyError,
} from '@/api'
import OverallPerformance from './components/OverallPerformance.vue'
import ProcessElementsPanel from './components/ProcessElementsPanel.vue'
import PerformanceDashboardByResource from './components/PerformanceDashboardByResource.vue'

export default {
    name: 'State',
    components: {
        PerformanceDashboardByResource,
        ProcessElementsPanel,
        OverallPerformance,
    },
    props: {
        attributes: VueTypes.array,
        attributesWithoutAll: VueTypes.array,
        processId: VueTypes.string,
    },
    emits: ['onAnalysisReportLoading', 'onAnalysisReportFinished'],
    data () {
        return {
            selectedOptions: {},
        }
    },
    mounted () {
        this.getStateAnalysisReport()
    },
    methods: {
        selectedOpt (data) {
            this.selectedOptions = data
        },
        getStateAnalysisReport () {
            this.$emit('onAnalysisReportLoading')
            const params = { format: 'html' }
            apiRequest(Api().booster.stateD2T({ processId: this.processId, params }))
                .then((data) => {
                    const { analysisReport } = data
                    this.analysisReport = analysisReport
                })
                .catch(notifyError)
                .finally(() => {
                    this.$emit('onAnalysisReportFinished', this.analysisReport)
                })
        },
    },
}
</script>
<style scoped lang="scss">
.State {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}
.FirstMainContainer {
    width: 59%;
    max-height: 100%;
    min-height: 510px;
    display: flex;
    margin:0 auto;
    margin-right: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;
}
.OverallPerformance{
    width:100%;
    height: 35%;
    display: flex;
    margin:0 auto;
    flex-direction: row;
    flex-wrap: wrap;
}
.PerformanceActivities {
    width:100%;
    height: 65%;
    min-height: 320px;
}
.SecondMainContainer {
    width: 40%;
    max-height: 100%;
    min-height: 510px;
    padding-bottom: 10px;
}
</style>
