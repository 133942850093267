import Config from '@/config'

const { name, description } = Config.project

export default {
    general: {
        title: 'Bem-vindo',
        titleTemplate: `%s | ${name}`,
        description,
    },
    home: {
        title: 'Início',
    },
}
