<template>
    <div class="Container">
        <div class="FiltersContainer">
            <label class="FiltersContLabel">
                {{ $t('booster.prediction.predictedPerformance.name') }}
            </label>
            <QSelect
                v-if="predictedPerformanceBy"
                v-model="predictedPerformanceBy"
                style="padding: 5px;"
                dense
                outlined
                :options="attr.map(i => i.name)"
                @update:modelValue="asignAttribute"
            />
            <QSpace />
            <QBtnToggle
                v-model="versusVision.value"
                toggleColor="primary"
                style="height: fit-content; margin: auto 10px;"
                noCaps
                dense
                :options="groupVersusVisionOptions"
                @update:model-value="changeVersusVision"
            />
        </div>
        <div
            v-if="versusVision.value==='duration'"
            class="ChangingContainers"
        >
            <DurationSla
                v-if="versusVision.value==='duration'"
                :attribute="predictedPerformanceBy"
                :processId="processId"
            />
        </div>
        <div
            v-if="versusVision.value==='sla'"
            class="ChangingContainers"
        >
            <DistributionSla
                v-if="versusVision.value==='sla'"
                :attribute="predictedPerformanceBy"
                :processId="processId"
            />
        </div>
        <div
            v-if="versusVision.value==='risk'"
            class="ChangingContainers"
        >
            <RiskEstimation
                v-if="versusVision.value==='risk'"
                :attribute="predictedPerformanceBy"
                :processId="processId"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import DurationSla from './DurationSla.vue'
import DistributionSla from './DistributionSla.vue'
import RiskEstimation from './RiskEstimation.vue'

export default {
    name: 'PredictedPerformance',
    components: { DurationSla, DistributionSla, RiskEstimation },
    props: {
        attributes: VueTypes.array,
        processId: VueTypes.string,
    },
    emits: ['selectedAttr'],
    data () {
        return {
            predictedPerformanceBy: undefined,
            attr: undefined,
            versusVision: {
                label: this.$t('booster.prediction.predictedPerformance.riskLabel'),
                value: 'risk',
            },
            groupVersusVisionOptions: [
                {
                    label: this.$t('booster.prediction.predictedPerformance.riskLabel'),
                    value: 'risk',
                },
                {
                    label: this.$t('booster.prediction.predictedPerformance.durationLabel'),
                    value: 'duration',
                },
                {
                    label: this.$t('booster.prediction.predictedPerformance.distributionLabel'),
                    value: 'sla',
                },
            ],
        }
    },
    mounted () {
        // this.asignAttribute(this.attributes[0].name)
        this.sel()
    },
    methods: {
        changeVersusVision () {
        },
        sel () {
            this.attr = this.attributes.filter(i => i.name !== this.$t('booster.state.all'))
            // eslint-disable-next-line prefer-destructuring
            this.predictedPerformanceBy = this.attr[0].name
            this.asignAttribute(this.predictedPerformanceBy)
        },
        asignAttribute (attr) {
            this.$emit('selectedAttr', attr)
        },
    },
}
</script>
<style scoped lang="scss">
    .Container {
        display: flex;
        flex-direction:column;
        flex-wrap: wrap;
        height: fit-content;
    }
    .FiltersContainer {
        display: flex;
        justify-content: row;
        align-items: center;
        padding: 1vh;
        width: 100%;
        height: fit-content;
    }
    .ChangingContainers {
        width: 100%;
        flex-grow: 1;
        margin:0 auto;
        display:flex;
        flex-direction:column;
        flex-wrap: wrap;
    }
    .FiltersContLabel {
            margin-right: 1em;
            font-size: 15px;
            @media screen and (min-height: $md) {
                font-size: large;
            }
            height: fit-content;
    }
    </style>
