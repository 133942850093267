<template>
    <div class="Container">
        <q-inner-loading
            :showing="!improvementFigures || !Object.keys(improvementFigures).length"
            :style="{zIndex: 3000}"
        />
        <div class="Card">
            <QCard
                class="MeetingBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ App.numberLocationFormat(improvementFigures.redBecomeGreenAbsolute, true) }}
                    {{ $t('booster.improvement.improvementFigures.cases') }}
                    <span class="MeetingPercent">{{ App.numberLocationFormat(improvementFigures.redBecomeGreenPercentage, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="TextSection">
                <strong class="RedColor">
                    {{ $t('booster.improvement.improvementFigures.red') }}
                </strong>
                <strong>
                    {{ $t('booster.improvement.improvementFigures.become') }}
                </strong>
                <strong class="GreenColor">
                    {{ $t('booster.improvement.improvementFigures.green') }}
                </strong>
            </span>
        </div>
        <div class="Card">
            <QCard
                class="UnderRiskBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ improvementFigures.improvedDurationAbsolute }}
                    <span class="UnderRiskPercent">{{ App.numberLocationFormat(improvementFigures.decreaseDurationPercentage, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="TextSection text-h6">
                <strong class="GreenColor">
                    {{ $t('booster.improvement.improvementFigures.decrease') }}
                </strong>
                <strong>
                    {{ $t('booster.improvement.improvementFigures.overallDuration') }}
                </strong>
            </span>
        </div>
        <div class="Card">
            <QCard
                class="ExceedingBox Section"
                flat
            >
                <QCardSection class="text-weight-bold text-center CustomCard">
                    {{ App.numberLocationFormat(improvementFigures.improvedCost, false, true) }}
                    <span class="ExceedingPercent">{{ App.numberLocationFormat(improvementFigures.decreaseCostPercentage, true) }}%</span>
                </QCardSection>
            </QCard>
            <span class="TextSection text-h6">
                <strong class="GreenColor">
                    {{ $t('booster.improvement.improvementFigures.decrease') }}
                </strong>
                <strong>
                    {{ $t('booster.improvement.improvementFigures.overallCosts') }}
                </strong>
            </span>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'ImprovementFigures',
    inject: ['App'],
    props: {
        improvementFigures: VueTypes.object,
    },
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
        numFormatter (num) {
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style scoped lang="scss">
    .Container {
        position: relative;
        display: flex;
        height: 90%;
        width:90%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin:0 auto;
    }
    .Section {
        width: 50%;
    }
    .TextSection {
        width: 50%;
        padding-left: 1vw;
        font-size: 2.6vh;
    }
    .Card {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .CustomCard {
        font-size: 2.6vh;
    }
    .q-card__section--vert {
        height: 90%;
    }
    .MeetingBox, .UnderRiskBox, .ExceedingBox {
        border: 3px solid $primary;
    }
    .MeetingPercent, .UnderRiskPercent,  .ExceedingPercent {
        color: $primary;
        padding-left: 1vw;
    }
    .GreenColor {
        color: #00C383;
    }
    .RedColor {
        color: #fc7474;
    }
</style>
