export default {
    selectAll: 'Seleccionar todos',
    create: 'Crear',
    next: 'Siguiente',
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Eliminar',
    discard: 'Descartar',
    close: 'Cerrar',
    cancel: 'Cancelar',
    search: 'Buscar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    mock: 'Mock',
    remove: 'Eliminar',
    download: 'Descargar como CSV',
    downloadBPMN: 'Descargar BPMN',
    uploadFile: 'Selecciona un fichero o arrastra hasta aquí',
    print: 'Imprimir',
    retry: 'Reintentar',
    info: 'Configuración',
    move: 'Mover',
}
