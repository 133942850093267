<template>
    <div>
        <q-card
            flat
            bordered
            class="Password mb-2 q-pa-md"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('settings.sections.apis') }}
            </WText>

            <div class="Wrapper">
                <WText class="pb-0-5 mb-1">
                    {{ $t('settings.apis.text') }}
                </WText>
                <div>
                    <QBtn
                        color="primary"
                        noCaps
                        :label="$t('settings.apis.generate')"
                        @click="handleGenerate"
                    />
                    <QBtn
                        outline
                        class="ml-1 errorColor"
                        noCaps
                        :disable="!total"
                        :label="$t('settings.apis.cancel')"
                        @click="handleCancel"
                    />
                </div>
            </div>
        </q-card>
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-card
                v-if="total"
                flat
                bordered
                class="Personal mb-2 q-pa-md"
            >
                <div class="flex row-stretch-center mb-1">
                    <WText
                        :size="18"
                    >
                        {{ $t('settings.sections.token') }}
                    </WText>
                    <div>
                        <q-icon
                            name="content_copy"
                            size="24px"
                            color="secondary"
                            clickable
                            style="cursor: pointer;"
                            @click="handleCopyToClipboard"
                        />
                        <QTooltip>
                            {{ $t('settings.token.tooltip') }}
                        </QTooltip>
                    </div>
                </div>

                <div class="TokenWrapper">
                    <WText class="pb-0-5 mb-1">
                        {{ token }}
                    </WText>
                </div>
            </q-card>
        </Transition>
        <DocModal
            ref="DocModal"
            @close="isModalOpened = false"
            @on-submit="handleSubmit"
        />
    </div>
</template>
<script>
import qs from 'qs'
import axios from 'axios'
import copy from 'copy-to-clipboard'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import Config from '@/config'
import { DocModal } from './components'

export default {
    name: 'Doc',
    components: {
        DocModal,
    },
    data () {
        return {
            token: undefined,
            total: 0,
            isFetching: false,
        }
    },
    mounted () {
        this.fetchApiToken()
    },
    methods: {
        async fetchApiToken () {
            this.isFetching = true
            apiRequest(Api().users.getApiToken())
                .then(({ data, total }) => {
                    const [tokenInfo] = data
                    this.token = (tokenInfo || {}).token || ''
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        handleGenerate () {
            this.$refs.DocModal.open()
        },
        handleSubmit ({ name, password }) {
            const params = {
                client_id: Config.keycloak.clientId,
                username: name,
                password,
                grant_type: 'password',
                scope: 'offline_access',
            }
            return axios.post(
`${Config.keycloak.url}/realms/${Config.keycloak.realm}/protocol/openid-connect/token`,
qs.stringify(params),
{
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    },
},
            )
                .then(({ data }) => {
                    this.updateApiToken(data.refresh_token)
                    this.token = data.refresh_token
                    this.total = 1
                    this.isModalOpened = false
                })
                .catch(notifyError)
        },
        updateApiToken (token) {
            this.isFetching = true
            apiRequest(Api().users.updateApiToken({ token }))
                .then(() => (notifySuccess(this.$t('settings.token.success'))))
                .catch((err) => {
                    this.token = undefined
                    this.total = 0
                    notifyError(err)
                })
                .finally(() => (this.isFetching = false))
        },
        handleCopyToClipboard () {
            copy(this.token)
            notifySuccess(this.$t('settings.token.success'))
        },
        handleCancel () {
            this.isFetching = true
            apiRequest(Api().users.cancelApiToken())
                .then(() => {
                    this.token = undefined
                    this.total = 0
                    notifySuccess(this.$t('settings.token.cancelled'))
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
    },
}
</script>
<style lang="scss" scoped>
.TokenWrapper {
    overflow-wrap: anywhere;
}
</style>
