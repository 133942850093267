<template>
    <div>
        <div
            ref="visualization"
            class="Visualization flex row-stretch"
        >
            <div
                ref="Screen"
                class="Screen pos-r flex-1"
            >
                <QTabs
                    v-model="tab"
                    align="left"
                    dense
                    noCaps
                    inlineLabel
                    activeColor="primary"
                    indicatorColor="primary"
                    class="Tabs text-grey-8"
                    @update:modelValue="handleChangeTab"
                >
                    <QTab
                        v-for="(crtab,idx) in getTabs"
                        :key="idx"
                        :name="crtab.id"
                        :label="crtab.label"
                        :icon="crtab.icon"
                    >
                        <QTooltip>
                            {{ crtab.tooltip }}
                        </QTooltip>
                    </QTab>
                </QTabs>
                <DataNavigator
                    v-if="tab === 'DASHBOARD'"
                    class="DataNavigator"
                    :processId="processId"
                />
                <ContextComparison
                    v-else-if="tab === 'COMPARISON'"
                    :processId="processId"
                    :filteredInfo="infoFiltered"
                    :sections="sectionsContext"
                    class="Wrapper"
                    @section-list="updateSectionsList"
                />
                <div
                    v-else
                    id="view-wrapper"
                    class="Wrapper flex row-center-top"
                >
                    <TracesSection
                        v-if="tab === 'TRACES'"
                        class="TracesSection"
                        :processId="processId"
                        :filteredTraces="filteredTraces"
                        :attributes="attributes"
                    />
                    <Conformance
                        v-else-if="tab === 'CONFORMANCE'"
                        class="Conformance"
                        :processId="processId"
                        :projectId="$route.params.projectId"
                        :attributes="attributes"
                    />
                    <Assistant
                        v-else-if="tab === 'ASSISTANT' && llmEnabled"
                        :processId="processId"
                        class="Wrapper Assistant"
                    />
                    <div
                        v-else
                        ref="mainContainer"
                        class="flex w-full ChargingHeight"
                    >
                        <div class="ChartContainer flex-1">
                            <div
                                class="Actions flex row-stretch-center"
                                style="z-index: 2000;"
                            >
                                <div
                                    v-if="chart"
                                    class="flex row-stretch-center"
                                >
                                    <QBtn
                                        icon="edit"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="toggleZoomer"
                                    >
                                        <QTooltip>
                                            {{ $t('visualization.zoomer.fab') }}
                                        </QTooltip>
                                    </QBtn>
                                    <div
                                        v-if="showingZoomer"
                                        ref="zoomerModal"
                                        class="ShowingPanelsContainer flex q-pa-sm"
                                    >
                                        <div>
                                            <div
                                                class="flex align-left"
                                                style="width: max-content; flex-direction: column;"
                                            >
                                                <div>
                                                    <QSpace />
                                                    <QBtn
                                                        icon="edit"
                                                        :label="$t('visualization.zoomer.edit')"
                                                        unelevated
                                                        flat
                                                        dense
                                                        noCaps
                                                        color="white"
                                                        textColor="primary"
                                                        class="BtnZoomer q-mx-xs"
                                                        @click="handleOpenZoomerModal"
                                                    />
                                                </div>
                                                <div>
                                                    <QSpace />
                                                    <QBtn
                                                        :icon="'img:' + require('@/assets/images/dataset_linked.svg')"
                                                        :label="$t('visualization.zoomer.create')"
                                                        unelevated
                                                        flat
                                                        dense
                                                        noCaps
                                                        color="white"
                                                        textColor="primary"
                                                        class="BtnCreateZoomer BtnZoomer q-mx-xs"
                                                        @click="handleOpenCreateZoomerModal"
                                                    />
                                                </div>
                                                <div>
                                                    <QSpace />
                                                    <QBtn
                                                        icon="restart_alt"
                                                        :label="$t('visualization.zoomer.reset')"
                                                        unelevated
                                                        flat
                                                        dense
                                                        noCaps
                                                        color="white"
                                                        textColor="primary"
                                                        class="BtnZoomer q-mx-xs"
                                                        @click="restoreZoomerConfig"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <QBtn
                                        icon="space_dashboard"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="togglePanels"
                                    >
                                        <QTooltip>
                                            {{ $t('visualization.resetHidePanelLocation') }}
                                        </QTooltip>
                                    </QBtn>
                                    <div
                                        v-if="showingPanels"
                                        ref="modal"
                                        class="ShowingPanelsContainer flex q-pa-md"
                                    >
                                        <div>
                                            <div>
                                                <div class="Panel">
                                                    <div class="row items-center justify-between">
                                                        {{ $t('visualization.modelMetricsToggle') }}
                                                        <QToggle
                                                            v-model="modelMetricsPanel"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="Panel">
                                                    <div class="row items-center justify-between">
                                                        {{ $t('visualization.customPlotPanelsToggle') }}
                                                        <QToggle
                                                            v-model="customPlotPanels"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="tab==='LOOPS'"
                                                    class="Panel"
                                                >
                                                    <div class="row items-center justify-between">
                                                        {{ $t('visualization.loopMetricsToggle') }}
                                                        <QSpace />
                                                        <QToggle
                                                            v-model="loopMetricsPanel"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="Panel">
                                                    <div class="row items-center justify-between">
                                                        {{ $t('visualization.SLAInfo.title') }}
                                                        <QSpace />
                                                        <QToggle
                                                            v-model="slaInfoPanel"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="q-mt-md row justify-center">
                                                <QBtn
                                                    style="text-transform: initial; font-size: 16px; padding-left: 15px; padding-right: 15px;"
                                                    :label="$t('visualization.resetPanelLocation')"
                                                    color="primary"
                                                    icon="restart_alt"
                                                    @click="resetDraggablePanels"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <QFab
                                        ref="percentageFab"
                                        label="%"
                                        labelPosition="left"
                                        flat
                                        square
                                        :icon="percentageDisplay.icon"
                                        :activeIcon="percentageDisplay.icon"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        style="background-color: white;"
                                        @click="toggleFab('percentageFab')"
                                    >
                                        <template #tooltip>
                                            <QTooltip>
                                                {{ $t('visualization.changePercentage') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in percOptions"
                                            :key="idx"
                                            color="grey-2"
                                            textColor="primary"
                                            :icon="action.icon"
                                            :label="$t(action.label)"
                                            labelPosition="left"
                                            externalLabel
                                            @click="setPercentageMode(action)"
                                        />
                                    </QFab>
                                    <QFab
                                        ref="directionFab"
                                        flat
                                        square
                                        :icon="directionDisplay.icon"
                                        :activeIcon="directionDisplay.icon"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        style="background-color: white;"
                                        @click="toggleFab('directionFab')"
                                    >
                                        <template #tooltip>
                                            <QTooltip>
                                                {{ $t('visualization.changeGraphDirection') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in directionOptions"
                                            :key="idx"
                                            color="grey-2"
                                            textColor="primary"
                                            :icon="action.icon"
                                            @click="setDirectionMode(action)"
                                        />
                                    </QFab>
                                    <QFab
                                        ref="statsFab"
                                        labelPosition="left"
                                        flat
                                        square
                                        :label="statsDisplay.abbreviation"
                                        icon="schedule"
                                        activeIcon="schedule"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        style="background-color: white; z-index: 200;"
                                        @click="toggleFab('statsFab')"
                                    >
                                        <template #tooltip>
                                            <QTooltip>
                                                {{ $t('visualization.changeGraphMetric') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in statsOptions"
                                            ref="statsFabAction"
                                            :key="idx"
                                            labelPosition="left"
                                            :label="action.label"
                                            color="grey-2"
                                            textColor="primary"
                                            @click="setStatsMode(action)"
                                        />
                                    </QFab>
                                    <QBtn
                                        icon="adjust"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="handleCenterChart"
                                    >
                                        <QTooltip>
                                            {{ $t('visualization.centerChart') }}
                                        </QTooltip>
                                    </QBtn>
                                    <QBtn
                                        icon="schema"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="saveBpmnModel"
                                    >
                                        <QTooltip>
                                            {{ $t('visualization.bpmnSave') }}
                                        </QTooltip>
                                    </QBtn>
                                    <QFab
                                        ref="exportImageFab"
                                        icon="collections"
                                        activeIcon="collections"
                                        flat
                                        square
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        direction="down"
                                        padding="sm"
                                        style="background-color: white;"
                                        @click="toggleFab('exportImageFab')"
                                    >
                                        <template #tooltip>
                                            <QTooltip>
                                                {{ $t('visualization.exportImageFormat') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in exportOptions"
                                            :key="idx"
                                            :label="action.label"
                                            color="grey-2"
                                            textColor="primary"
                                            @click="handleExportImage(action)"
                                        />
                                    </QFab>
                                </div>
                            </div>
                            <div
                                ref="panelsContainer"
                                class="FixedPanels"
                            >
                                <Draggable
                                    v-if="modelMetricsPanel"
                                    ref="DraggableChartContextualInfo"
                                    :zIndex="1040"
                                >
                                    <ChartContextualInfo
                                        class="FixedBar col"
                                        :totalInfo="infoTotal"
                                        :filteredInfo="infoFiltered"
                                        :displayedInfo="displayedInfo"
                                        :percentageMode="percentageDisplay.value"
                                        :currency="currency"
                                        :costPerHour="costPerHour"
                                        :variantsLoading="variantsLoading"
                                    />
                                </Draggable>
                                <Draggable
                                    v-if="loopMetricsPanel && tab==='LOOPS'"
                                    ref="DraggableLoopMetricsPanel"
                                    :zIndex="1030"
                                >
                                    <LoopMetricsPanel
                                        class="FixedBar col"
                                        :displayedInfo="displayedInfo"
                                        :chartInfo="chart"
                                        :currency="currency"
                                        :costPerHour="costPerHour"
                                        :variantsLoading="variantsLoading"
                                    />
                                </Draggable>
                                <Draggable
                                    v-if="customPlotPanels"
                                    ref="DraggableCustomPlotPanels"
                                    :zIndex="1020"
                                >
                                    <CustomPlotPanels
                                        v-if="(attributesNotEmpty && chart)"
                                        :attributes="attributes"
                                        :processId="processId"
                                        :chart="chart"
                                        :title="attributePanelOption"
                                        :filters="formatFiltersParam(visualizationFilters)"
                                        :variantsLoading="variantsLoading"
                                        @titleSel="changeOptionPanel1"
                                    />
                                </Draggable>
                                <Draggable
                                    v-if="slaInfoPanel"
                                    ref="DraggableSlaInfoPanel"
                                    :zIndex="1010"
                                >
                                    <SLAInfo
                                        v-if="displayedInfo"
                                        :info="displayedInfo"
                                        :chart="chart"
                                        :variantsLoading="variantsLoading"
                                    />
                                </Draggable>
                            </div>
                            <div
                                v-if="(settedChart && !variantsLoading) && (!chart || !(chart.activities || []).length || !(Object.keys(chart) || []).length)"
                                class="EmptyModel"
                            >
                                {{ $t('visualization.filters.emptyModel') }}
                            </div>
                            <GraphCyTo
                                v-else
                                ref="chart"
                                class="Graph"
                                :data="chart"
                                :model="model"
                                :color="color"
                                :variantsLoading="variantsLoading"
                                :settedChart="settedChart"
                                :tab="tab"
                                :settings="settings"
                                parent="view-wrapper"
                                @add-filter="handleAddFilter"
                                @delete-filter="handleDeleteFilter"
                                @zoomerIsVisible="handleZoomerIsVisible"
                                @resetZoomerIndicator="handleResetZoomerIndicator"
                            />
                        </div>
                        <ZoomerIndicator
                            ref="zoomerIndicator"
                            :zoomerIsVisible="isZoomerVisible"
                            @toggleZoomerView="handlertoggleZoomerView"
                        />
                        <div
                            ref="options"
                            class="Options"
                            :class="{ visible: visibleOptions }"
                        >
                            <QTabPanels
                                v-if="processId"
                                v-model="tab"
                                animated
                                transitionNext="fade"
                                transitionPrev="fade"
                            >
                                <QTabPanel name="MODEL">
                                    <Variants
                                        ref="MODEL"
                                        v-model="model"
                                        class="Variants"
                                        :processId="processId"
                                        :info="infoTotal"
                                        :filteredInfo="infoFiltered"
                                        :percentageMode="percentageDisplay.value"
                                        :settings="settings"
                                        :disableElements="disableElements"
                                        @on-center-chart="handleCenterChart"
                                        @on-load-chart="handleSetChart"
                                        @on-color-loops="handleColorChartLoops"
                                        @on-reset="handleResetModel"
                                        @variants-loading="loadingControl"
                                        @on-open-rename-modal="handleOpenRenameModal"
                                        @on-changed-variant="settedChart = false"
                                        @on-changed-variant-tab="handleChangeVariantTab"
                                    />
                                </QTabPanel>
                                <QTabPanel name="LOOPS">
                                    <Loops
                                        ref="LOOPS"
                                        v-model="model"
                                        :processId="processId"
                                        :info="infoTotal"
                                        :filteredInfo="infoFiltered"
                                        :percentageMode="percentageDisplay.value"
                                        :disableElements="disableElements"
                                        @on-center-chart="handleCenterChart"
                                        @on-load-chart="handleSetChart"
                                        @on-reset="handleResetModel"
                                        @variants-loading="loadingControl"
                                        @on-open-rename-loop-modal="handleOpenRenameLoopModal"
                                    />
                                </QTabPanel>
                            </QTabPanels>
                        </div>
                    </div>
                </div>
                <FilterModal
                    v-if="processId"
                    ref="FilterModal"
                    class="FilterModal"
                    :processId="processId"
                    :attributes="attributes"
                    :isOpen="openFilterModal"
                    :info="infoTotal"
                    @close="handleCancelAddFilters"
                    @on-save-filter="handleAddFilter"
                />
                <FilterSetsModal
                    v-if="processId"
                    ref="FilterSetsModal"
                    class="FilterSetsModal"
                    :datasetId="processId"
                    :activeFilters="visualizationFilters"
                    :isOpen="openFilterSetsModal"
                    @addToActive="addToActiveFilters"
                    @replaceFilters="replaceActiveFilters"
                    @removedFilterSets="removedFilterSets"
                    @repeatCalls="reloadTabData"
                    @close="handleCancelAddFilterSets"
                />
                <FilterSetsModal
                    v-if="processId"
                    ref="FilterSetsModal"
                    class="FilterSetsModal"
                    :datasetId="processId"
                    :activeFilters="visualizationFilters"
                    :isOpen="openFilterSetsModal"
                    @addToActive="addToActiveFilters"
                    @replaceFilters="replaceActiveFilters"
                    @removedFilterSets="removedFilterSets"
                    @repeatCalls="reloadTabData"
                    @close="handleCancelAddFilterSets"
                />
                <CreateFilterSetsModal
                    v-if="processId"
                    ref="CreateFilterSetsModal"
                    :datasetId="processId"
                    :activeFilters="visualizationFilters"
                    :isOpen="openCreateFilterSetsModal"
                    @close="handleCloseCreateFilterSetsModal"
                    @repeatCalls="reloadTabData"
                />
            </div>
            <Sidebar
                ref="Sidebar"
                :showAvatar="false"
                :infoTotal="infoTotal"
                :filteredInfo="infoFiltered"
                :processId="processId"
                :readOnly="disableElements"
                @on-manage-filter="handleManageFilters"
                @on-manage-filter-sets="handleManageFilterSets"
                @on-delete-click="handleDeleteFilter"
                @on-apply="handleApplyFilters"
                @on-replace-filters="handleApplyFilters"
                @on-reset="handleResetFilters"
                @on-manage-create-filter-sets="HandleopenCreateFilterSetsModal"
                @repeat-calls="reloadTabData"
            />
            <CreateModel
                ref="modelModal"
                @save="generateModel"
            />
            <RenameModel
                ref="renameVariantModal"
                :isVariant="true"
                @save="renameVariant"
            />
            <RenameModel
                ref="renameLoopModal"
                :isVariant="false"
                @save="renameLoop"
            />
        </div>
        <InfoModal
            v-if="processId"
            :processId="processId"
            @refresh-info="reloadSidebar"
        />
        <ZoomerEditModal
            v-if="settings"
            ref="ZoomerEditModal"
            class="FilterModal"
            :settings="settings"
            @close="handleCancelEditZoomer"
            @onSaveZoomerConfig="handleSaveZoomerConfig"
        />
        <ZoomerCreateModal
            ref="ZoomerCreateModal"
            class="FilterModal"
            :settings="settings"
            @close="handleCancelCreateZoomer"
        />
        <LoadingSpinner
            :visible="isExportingFile"
            :format="format"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import Draggable from '@/components/Draggable/Draggable.vue'
import LoadingSpinner from './components/LoadingSpinner.vue'
import CreateModel from './components/CreateModel.vue'
import RenameModel from './components/RenameModel.vue'
import InfoModal from './components/InfoModal.vue'
import ChartContextualInfo from './components/Contextualnfo/ChartContextualInfo.vue'
import LoopMetricsPanel from './components/Contextualnfo/LoopMetricsPanel.vue'
import ZoomerEditModal from './components/ZoomerEditModal.vue'
import ZoomerCreateModal from './components/ZoomerCreateModal.vue'
import ZoomerIndicator from './components/ZoomerIndicator.vue'

import {
    Sidebar,
    Variants,
    Loops,
    DataNavigator,
    FilterModal,
    FilterSetsModal,
    Conformance,
    Assistant,
    CreateFilterSetsModal,
} from './sections'
import ContextComparison from './sections/ContextComparison/ContextComparison.vue'
import TracesSection from './sections/Traces/TracesSection.vue'
import {
    ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS, NVARIANTS_FILTERS,
} from './sections/FilterModal/filtersEntity'
import CustomPlotPanels from './components/Contextualnfo/CustomPlotPanels.vue'
import SLAInfo from './components/Contextualnfo/SLAInfo.vue'
import GraphCyTo from '../../../../components/Graph/GraphCyTo.vue'

const DEFAULT_PERCENTAGE_MODE = {
    label: 'visualization.percentageTotal',
    value: 'TOTAL',
    icon: 'storage',
}
const DEFAULT_CURRENCY = {
    value: 'euro',
    label: 'Euro (€)',
}
const DEFAULT_COST_PER_HOUR = 0
const INITIAL_MODEL = {
    variant: 1,
    variants: 1,
    heatMapValue: 'none',
    directionValue: 'TB',
    statsValue: 'averages',
    values: 'both',
}
const EMPTY_MODEL_INFO = {
    activities: undefined,
    cases: undefined,
    duration: undefined,
    maxEndDateTime: '',
    minStartDateTime: '',
    variants: undefined,
}
const DIRECTION_ICONS = {
    horizontal: 'east',
    vertical: 'south',
}
const DEFAULT_DIRECTION = {
    value: 'TB',
    icon: DIRECTION_ICONS.vertical,
}
const DEFAULT_TAB = 'MODEL'
const DEFAULT_VARIANT_TAB = 'FREQUENCY'

export default {
    name: 'Visualization',
    components: {
        Loops,
        TracesSection,
        Conformance,
        Variants,
        Sidebar,
        GraphCyTo,
        DataNavigator,
        FilterModal,
        FilterSetsModal,
        CreateModel,
        RenameModel,
        InfoModal,
        LoadingSpinner,
        ZoomerEditModal,
        ZoomerCreateModal,
        ChartContextualInfo,
        LoopMetricsPanel,
        ContextComparison,
        CustomPlotPanels,
        Assistant,
        SLAInfo,
        Draggable,
        CreateFilterSetsModal,
        ZoomerIndicator,
    },
    mixins: [filtersStorageMixin],
    props: {
        process: VueTypes.any,
    },
    emits: ['llmEnabledGet', 'checkForVariantFilter', 'boosterButtonEnabled', 'boosterButtonDisabled', 'boosterButtonHidden'],
    data () {
        return {
            tab: this.$route.params.tab || DEFAULT_TAB,
            panelsContainerObserver: undefined,
            resizeTimeout: null,
            resizeChartTimeout: null,
            processId: this.$route.params.processId || undefined,
            chart: undefined,
            color: undefined,
            settedChart: false,
            disableElements: false,
            variantsLoading: false,
            variantRenamedId: undefined,
            loopRenamedId: undefined,
            variantTab: DEFAULT_VARIANT_TAB,
            model: INITIAL_MODEL,
            openFilterModal: false,
            openFilterSetsModal: false,
            visibleOptions: false,
            imageTemp: undefined,
            attributes: undefined,
            relevantAttributesString: undefined,
            info: undefined,
            infoTotal: undefined,
            infoFiltered: undefined,
            displayedInfo: undefined,
            filteredTraces: 0,
            percentageDisplay: DEFAULT_PERCENTAGE_MODE,
            directionDisplay: DEFAULT_DIRECTION,
            statsDisplay: {
                abbreviation: 'avg',
                value: 'averages',
                label: this.$t('visualization.filters.contextualInfo.avg'),
            },
            toggleLoopMetrics: true,
            currency: DEFAULT_CURRENCY,
            costPerHour: DEFAULT_COST_PER_HOUR,
            settings: undefined,
            loading: true,
            showPredictions: true,
            sectionsContext: [],
            comparationFilters: [],
            currentFilters: [],
            attributePanelOption: { key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), icon: 'query_stats' },
            frequencyPanelOption: { key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), icon: 'timelapse' },
            activeFab: null,
            llmEnabled: false,
            attributesLoading: false,
            attributesNotEmpty: false,
            isDragging: false,
            dragStartX: 0,
            dragStartY: 0,
            initialLeft: 0,
            initialTop: 0,
            showingPanels: false,
            showingZoomer: false,
            modelMetricsPanel: true,
            loopMetricsPanel: true,
            customPlotPanels: true,
            slaInfoPanel: true,
            isCalculating: false,
            isExportingFile: false,
            format: 'PNG',
            openCreateFilterSetsModal: false,
            fabs: ['percentageFab', 'directionFab', 'statsFab', 'exportImageFab'],
            isZoomerVisible: false,
        }
    },
    computed: {
        getTabs () {
            const tabs = [
                {
                    id: 'MODEL',
                    label: this.$t('visualization.tabs.process'),
                    icon: 'account_tree',
                    tooltip: this.$t('visualization.tabs.tooltip.process'),
                },
                {
                    id: 'LOOPS',
                    label: this.$t('visualization.tabs.loops'),
                    icon: '360',
                    tooltip: this.$t('visualization.tabs.tooltip.loops'),
                },
                {
                    id: 'CONFORMANCE',
                    label: this.$t('visualization.tabs.conformance'),
                    icon: 'fact_check',
                    tooltip: this.$t('visualization.tabs.tooltip.conformance'),
                },
                {
                    id: 'DASHBOARD',
                    label: this.$t('visualization.tabs.metrics'),
                    icon: 'bar_chart',
                    tooltip: this.$t('visualization.tabs.tooltip.metrics'),
                },
                {
                    id: 'COMPARISON',
                    label: this.$t('visualization.tabs.contextComparison'),
                    icon: 'compare',
                    tooltip: this.$t('visualization.tabs.tooltip.contextComparison'),
                },
                {
                    id: 'TRACES',
                    label: this.$t('visualization.tabs.traces'),
                    icon: 'list',
                    tooltip: this.$t('visualization.tabs.tooltip.traces'),
                },
            ]
            if (this.llmEnabled) {
                const assistantTab = {
                    id: 'ASSISTANT',
                    label: this.$t('visualization.tabs.assistant'),
                    icon: 'psychology',
                    tooltip: this.$t('visualization.tabs.tooltip.assistant'),
                }
                // to add the assistant tab before the settings tab
                const indexBeforeSettings = tabs.length - 1
                tabs.splice(indexBeforeSettings, 0, assistantTab)
            }
            return tabs
        },
        percOptions () {
            return [
                {
                    label: 'visualization.percentageTotal',
                    value: 'TOTAL',
                    icon: 'storage',
                },
                {
                    label: 'visualization.percentageFiltered',
                    value: 'FILTERED',
                    icon: 'filter_alt',
                },
            ]
        },
        curOptions () {
            return [
                {
                    label: 'Euro (€)',
                    value: 'euro',
                },
                {
                    label: 'Dollar ($)',
                    value: 'dollar',
                },
                {
                    label: 'Pound (£)',
                    value: 'pound',
                },
            ]
        },
        directionOptions () {
            return [
                {
                    value: 'LR',
                    icon: DIRECTION_ICONS.horizontal,
                },
                {
                    value: 'TB',
                    icon: DIRECTION_ICONS.vertical,
                },
            ]
        },
        statsOptions () {
            return [
                {
                    abbreviation: 'sd',
                    value: 'variances',
                    label: this.$t('visualization.filters.contextualInfo.sd'),
                },
                {
                    abbreviation: 'avg',
                    value: 'averages',
                    label: this.$t('visualization.filters.contextualInfo.avg'),
                },
                {
                    abbreviation: 'median',
                    value: 'median',
                    label: this.$t('visualization.filters.contextualInfo.med'),
                },
                {
                    abbreviation: 'min',
                    value: 'min',
                    label: this.$t('visualization.filters.contextualInfo.min'),
                },
                {
                    abbreviation: 'max',
                    value: 'max',
                    label: this.$t('visualization.filters.contextualInfo.max'),
                },
            ]
        },
        exportOptions () {
            return [
                {
                    value: 'PNG',
                    label: 'PNG',
                },
                {
                    value: 'JPG',
                    label: 'JPG',
                },
                {
                    value: 'SVG',
                    label: 'SVG',
                },
            ]
        },
    },
    watch: {
        tab (a, oldTab) {
            if (oldTab === 'MODEL' || oldTab === 'LOOPS') {
                if (this.panelsContainerObserver && this.$refs.panelsContainer) {
                    this.panelsContainerObserver.unobserve(this.$refs.panelsContainer)
                }
            }
            if (a === 'MODEL' || a === 'LOOPS') {
                this.panelsContainerObserver = new ResizeObserver(this.onResize)
                setTimeout(() => {
                    if (this.$refs.panelsContainer) {
                        this.panelsContainerObserver.observe(this.$refs.panelsContainer)
                    }
                }, 50)
            }
        },
        // eslint-disable-next-line func-names
        '$route.params.tab': function (newVal) {
            this.tab = newVal || DEFAULT_TAB
        },
    },
    async mounted () {
        this.$eventBus.on('calculatingFlagChanged', this.handleCalculatingFlagChange)
        const { processId, tab } = this.$route.params
        await this.getSettings()
        this.getModelInfoFiltered()
        this.getTotalInfo()
        this.processId = processId
        this.getConfigurations(true)
        if (tab) {
            this.tab = tab
        }
        if (this.tab === 'MODEL' || this.tab === 'LOOPS') {
            this.panelsContainerObserver = new ResizeObserver(this.onResize)
            setTimeout(() => {
                if (this.$refs.panelsContainer) {
                    this.panelsContainerObserver.observe(this.$refs.panelsContainer)
                }
            }, 50)
        }
        document.addEventListener('click', this.handleClickOutside)
    },
    created () {
        this.changeLLMEnabled()
        this.getLLMEnabled()
    },
    beforeDestroy () {
        document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
        getLLMEnabled () {
            this.$eventBus.emit('llmEnabledGet')
        },
        changeLLMEnabled () {
            this.$eventBus.on('llmEnabledChanged', (llmEnabled) => {
                this.llmEnabled = llmEnabled
            })
        },
        onResize () {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout)
            }
            this.resizeTimeout = setTimeout(() => {
                if (this.$refs.mainContainer) {
                    this.$refs.mainContainer.style.height = `${this.$refs.panelsContainer.offsetHeight + 20}px`
                }
            }, 50)
        },
        handleSetChart (data) {
            this.getModelInfoDisplayed({ variantCount: data?.variants, variantUid: data?.variantId, loopUid: data?.loopId })
            const formattedData = this.formatChartData(data)
            this.chart = data ? { ...formattedData } : undefined
            this.color = undefined
        },
        handleColorChartLoops (data) {
            if (data !== undefined) {
                const formattedData = this.formatChartData(data)
                this.color = data ? { ...formattedData } : undefined
            } else {
                this.color = undefined
            }
        },
        formatChartData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },
        formatAttributes (attributes = {}) {
            return [...Object.keys(attributes)].map(key => ({ label: key, value: key }))
        },
        handleCenterChart () {
            const { chart } = this.$refs
            if (chart) chart.centerGraph()
        },
        handleChangeTab (tab) {
            const { tab: actualTab, selectedCaseId, ...otherParams } = this.$route.params
            if (tab === actualTab) return
            const params = { tab, ...otherParams }
            if (tab !== 'TRACES' && selectedCaseId) {
                delete params.selectedCaseId
            }
            if (tab) {
                this.$router.replace({ ...this.$route, params }).catch(() => {})
            }
            this.handleResetModel()
        },
        handleResetModel () {
            this.model = { ...INITIAL_MODEL }
            this.model.statsValue = this.statsDisplay.value
        },
        handleOpenZoomerModal () {
            this.$refs.ZoomerEditModal.open()
            this.showingZoomer = true
        },
        handleCancelEditZoomer () {
            this.$refs.ZoomerEditModal.close()
            this.showingZoomer = false
        },
        handleOpenCreateZoomerModal () {
            this.$refs.ZoomerCreateModal.open()
            this.showingZoomer = true
        },
        handleCancelCreateZoomer () {
            this.$refs.ZoomerCreateModal.close()
            this.showingZoomer = false
        },
        handleEditZoomer (selected) {
            // TODO work on save zoomer
            this.$refs.ZoomerEditModal.close()
            this.openZoomerEditModal = false
        },
        async handleSaveZoomerConfig () {
            await this.getSettings()
            this.loading = true
            await this.reloadTabData()
            this.showingZoomer = false
            this.loading = false
        },
        handleAddFilter (selected) {
            if (ACTIVITY_FILTERS.includes(selected.id)) {
                const activityFilter = {
                    ...selected,
                    key: selected.type.category,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(activityFilter)
            }
            if (DATE_FILTERS.includes(selected.id)) {
                const dateFilter = {
                    ...selected,
                    key: selected.type.date,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(dateFilter)
            }
            if (DURATION_FILTERS.includes(selected.id)) {
                const durationFilter = {
                    ...selected,
                    key: selected.type.duration,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(durationFilter)
            }
            if (ARC_FILTERS.includes(selected.id)) {
                const arcFilter = {
                    ...selected,
                    key: selected.type.arc,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(arcFilter)
            }
            if (ATTRIBUTES_FILTERS.includes(selected.type)) {
                const attributesFilter = selected.attributes.map((attr, i) => ({
                    ...attr,
                    value: attr.value,
                    label: `${attr.attribute.value} - ${attr.constraints.label}`,
                    id: selected.type,
                    uuid: `${selected.type}_${Math.floor(Math.random() * 10000)}_${i}`,
                }))
                this.addMultipleFilters(attributesFilter)
            }
            if (VARIANT_FILTERS.includes(selected.id)) {
                const variantFilter = {
                    ...selected,
                    key: selected.type.variant,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(variantFilter)
            }
            if (LOOP_FILTERS.includes(selected.id)) {
                const variantFilter = {
                    ...selected,
                    key: selected.type.variant,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(variantFilter)
            }
            if (NVARIANTS_FILTERS.includes(selected.id)) {
                const nVariantFilter = {
                    ...selected,
                    key: selected.type.nvariants,
                    nvariants: selected.type.nvariants,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(nVariantFilter)
            }
            this.handleApplyFilters()
        },
        handleManageFilters () {
            this.openFilterModal = true
        },
        handleManageFilterSets () {
            this.openFilterSetsModal = true
        },
        handleDeleteFilter (filter) {
            this.updateFilters(filter)
            this.handleApplyFilters()
            this.$eventBus.emit('checkForVariantFilter')
        },
        handleCancelAddFilters () {
            this.openFilterModal = false
        },
        handleCancelAddFilterSets () {
            this.openFilterSetsModal = false
        },
        handleResetFilters () {
            this.setVisualizationFilters([])
            this.handleApplyFilters()
        },
        async handleApplyFilters () {
            this.openFilterModal = false
            const sanitizedFilters = (this.visualizationFilters || []).map(f => ({ ...f, pending: false }))
            const filteredFilters = sanitizedFilters.filter(f => !f.toDelete)
            this.setVisualizationFilters(filteredFilters)
            const tempTab = this.tab
            this.tab = undefined
            await this.$refs.FilterModal.getAttributeValues()
            this.tab = tempTab
            this.reloadSidebar()
        },

        async getModelInfoDisplayed ({ loopUid, variantCount, variantUid }) {
            this.settedChart = false
            this.disableElements = true
            if (loopUid === undefined && !variantCount && !variantUid) {
                this.displayedInfo = EMPTY_MODEL_INFO
                return
            }
            const { processId } = this.$route.params
            const { visualizationFilters } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(visualizationFilters)
            this.currentFilters = filters
            const params = {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(loopUid !== undefined ? { loopUid } : {}),
                ...(variantCount ? { variantCount } : {}),
                ...(variantUid ? { variantUid } : {}),
            }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.displayedInfo = data
                })
                .catch(notifyError)
                .finally(() => {
                    this.settedChart = true
                    if (!this.variantsLoading) {
                        this.disableElements = false
                    }
                })
            if (this.isCalculating) {
                this.reloadSidebar()
            }
        },
        async getModelInfoFiltered () {
            this.settedChart = false
            this.disableElements = true
            const { processId } = this.$route.params
            const { visualizationFilters } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(visualizationFilters)
            const params = { filters, filterSets: filterSetsUUIDs, operator: generalOperator }

            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.filteredTraces = data?.cases || 0
                    this.infoFiltered = data
                })
                .catch(notifyError)
                .finally(() => {
                    this.settedChart = true
                    if (!this.variantsLoading) {
                        this.disableElements = false
                    }
                })
            if (this.checkIfEnterTest) {
                this.$router.push({ name: 'WizardChooseApproach' })
            }
        },
        getTotalInfo () {
            const { processId } = this.$route.params
            const params = { filters: [] }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.infoTotal = data
                })
                .catch(notifyError)
        },
        getConfigurations (firstLoad) {
            if (typeof firstLoad === 'boolean' && firstLoad) { this.attributesLoading = true }
            const { processId } = this
            apiRequest(Api().datasets.getConfigurations({ datasetId: processId }))
                .then(({ data }) => {
                    const [datasetConfig] = data
                    this.attributes = datasetConfig.columns
                    if (this.attributes.length > 0) {
                        this.attributesNotEmpty = true
                    }
                    this.relevantAttributesString = this.attributes
                        .filter(c => c.relevant === true)
                        .map(c => c.name)
                        .join(', ')
                    this.checkBoosterButtonDisabled()
                    this.checkBoosterButtonHidden()
                })
                .catch(notifyError)
                .finally(() => { if (typeof firstLoad === 'boolean' && firstLoad) { this.attributesLoading = false } })
        },
        saveBpmnModel () {
            this.$refs.modelModal.open()
        },
        async generateModel ({ modelName }) {
            this.$refs.modelModal.close()
            const arcs = (this.chart.arcs || []).map(({
                source, target, sourceId, targetId, ...rest
            }) => ({
                ...rest,
                source: sourceId,
                target: targetId,
                sourceId: source,
                targetId: target,
            }))
            const modelData = {
                name: modelName,
                projectUid: this.$route.params.projectId,
                datasetUid: this.processId,
                activities: this.chart.activities,
                arcs,
            }
            apiRequest(Api().models.create({
                params: modelData,
            }))
                .then(() => {
                    notifySuccess(this.$t('visualization.bpmnOK'))
                })
                .catch(notifyError)
                .finally()
        },
        handleExportImage (format) {
            this.format = format.value
            this.isExportingFile = true
            const { chart } = this.$refs
            if (chart) {
                chart.downloadGraph(format)
                this.isExportingFile = false
            }
        },
        setPercentageMode (mode) {
            this.percentageDisplay = mode
        },
        setDirectionMode (mode) {
            this.directionDisplay = mode
            this.model.directionValue = this.directionDisplay.value
        },
        setStatsMode (mode) {
            this.statsDisplay = mode
            this.model.statsValue = this.statsDisplay.value
        },
        updateCostPerHour (value) {
            this.costPerHour = Number(value)
        },
        updateCurrency (value) {
            this.currency = value
        },
        async reloadSidebar (source) {
            await this.getModelInfoFiltered()
            this.$refs.Sidebar.updateInfoTotal(this.infoTotal)
        },
        async getSettings () {
            try {
                const params = {
                    datasetId: this.$route.params.processId,
                }
                const { data } = await apiRequest(Api().datasets.get(params));
                [this.settings] = data
                this.checkBoosterButtonDisabled()
                if (data[0].cost) {
                    this.currency = { value: data[0].cost.unit }
                    this.costPerHour = data[0].cost.value
                }
                this.loading = false
            } catch (error) {
                notifyError(error)
            }
        },
        updateSectionsList (sectionsList) {
            this.sectionsContext = sectionsList
        },
        changeOptionPanel1 (newOption) {
            this.attributePanelOption = newOption
        },
        changeOptionPanel2 (newOption) {
            this.frequencyPanelOption = newOption
        },
        handleOpenRenameModal (data) {
            this.$refs.renameVariantModal.open()
            this.variantRenamedId = data.variantId
        },
        handleOpenRenameLoopModal (data) {
            this.$refs.renameLoopModal.open()
            this.loopRenamedId = data.loopId
        },
        async renameVariant ({ newName }) {
            if (this.$refs.MODEL && this.$refs.MODEL.renameVariant) {
                this.$refs.renameVariantModal.close()
                this.$refs.MODEL.renameVariant({ newName, variantRenamedId: this.variantRenamedId })
            }
        },
        async renameLoop ({ newName }) {
            if (this.$refs.LOOPS && this.$refs.LOOPS.renameLoop) {
                this.$refs.renameLoopModal.close()
                this.$refs.LOOPS.renameLoop({ newName, loopRenamedId: this.loopRenamedId })
            }
        },
        loadingControl (load) {
            this.variantsLoading = load
            if (this.settedChart && !this.variantsLoading) {
                this.disableElements = false
            }
            if (this.variantsLoading) {
                this.disableElements = true
            }
        },
        handleChangeVariantTab (newVariantTab) {
            this.variantTab = newVariantTab
        },
        toggleFab (fab) {
            if (this.activeFab === fab) {
                this.$refs[fab].hide()
                this.activeFab = null
            } else {
                this.$refs[fab].show()
                if (this.activeFab) {
                    this.$refs[this.activeFab].hide()
                }
                this.activeFab = fab
            }
        },
        viewTrace (a) {
            const currentParams = { ...this.$route.params }
            currentParams.selectedCaseId = a.selectedCaseId
            currentParams.tab = a.tab
            this.tab = a.tab
            this.$router.push({ name: this.$route.name, params: currentParams })
        },
        async reloadTabData () {
            await this.reloadSidebar()
            if (this.$refs.MODEL) {
                await this.$refs.MODEL.initializeVariants()
            }
            if (this.$refs.LOOPS) {
                await this.$refs.LOOPS.initializeLoops()
            }
        },
        checkBoosterButtonDisabled () {
            if (this.settings !== undefined && this.relevantAttributesString !== undefined) {
                if (this.settings.sla !== null && this.relevantAttributesString !== '' && this.checkCaseCompletion()) {
                    this.$emit('boosterButtonEnabled')
                } else {
                    this.$emit('boosterButtonDisabled')
                }
            }
        },
        checkCaseCompletion () {
            if (this.settings.caseCompletion === null) {
                return false
            }
            if (this.settings.caseCompletion.startingActivities.length === 0 &&
                this.settings.caseCompletion.occurringActivities.length === 0 &&
                this.settings.caseCompletion.endingActivities.length === 0) {
                return false
            }
            return true
        },
        checkBoosterButtonHidden () {
            if (this.attributes.filter(c => c.type === 'STRING').length > 0) {
                this.$emit('boosterButtonHidden')
            }
        },
        resetDraggablePanels () {
            if (this.$refs.DraggableChartContextualInfo) {
                this.$refs.DraggableChartContextualInfo.resetPosition()
            }
            if (this.$refs.DraggableLoopMetricsPanel) {
                this.$refs.DraggableLoopMetricsPanel.resetPosition()
            }
            if (this.$refs.DraggableCustomPlotPanels) {
                this.$refs.DraggableCustomPlotPanels.resetPosition()
            }
            if (this.$refs.DraggableSlaInfoPanel) {
                this.$refs.DraggableSlaInfoPanel.resetPosition()
            }
        },
        handleCalculatingFlagChange (calculatingFlag) {
            this.isCalculating = calculatingFlag
        },
        togglePanels (event) {
            this.fabs.forEach((fab) => {
                const fabElement = this.$refs[fab]?.$el
                if (fabElement && !fabElement.contains(event.target)) {
                    this.$refs[fab].hide()
                    this.activeFab = null
                }
            })
            event.stopPropagation()
            if (this.showingZoomer) {
                this.showingZoomer = false
            }
            this.showingPanels = !this.showingPanels
        },
        toggleZoomer (event) {
            this.fabs.forEach((fab) => {
                const fabElement = this.$refs[fab]?.$el
                if (fabElement && !fabElement.contains(event.target)) {
                    this.$refs[fab].hide()
                    this.activeFab = null
                }
            })
            event.stopPropagation()
            if (this.showingPanels) {
                this.showingPanels = false
            }
            this.showingZoomer = !this.showingZoomer
        },
        handleClickOutside (event) {
            if (this.activeFab) {
                const fabElement = this.$refs[this.activeFab]?.$el
                if (fabElement && !fabElement.contains(event.target)) {
                    this.$refs[this.activeFab].hide()
                    this.activeFab = null
                }
            }

            this.fabs.forEach((fab) => {
                if (fab !== this.activeFab) {
                    const fabElement = this.$refs[fab]?.$el
                    if (fabElement && !fabElement.contains(event.target)) {
                        this.$refs[fab].hide()
                    }
                }
            })

            if (this.$refs.modal && !this.$refs.modal.contains(event.target) && this.showingPanels) {
                this.showingPanels = false
            }
            if (this.$refs.zoomerModal && !this.$refs.zoomerModal.contains(event.target) && this.showingZoomer) {
                this.showingZoomer = false
            }
        },
        addToActiveFilters (filtersSets) {
            this.handleSetFilters(filtersSets, 'add')
        },
        replaceActiveFilters (filterSets) {
            this.handleSetFilters(filterSets, 'replace')
        },
        removedFilterSets (filterSets) {
            const updatedFilters = this.visualizationFilters.filter((existingFilter) => {
                if (existingFilter.id !== 'FILTER_SET') {
                    return true
                }
                return !filterSets.filters.includes(existingFilter.uuid)
            })

            this.setVisualizationFilters(updatedFilters)
            this.handleApplyFilters()
        },
        handleSetFilters (selectedSets, mode) {
            if (mode === 'replace') {
                selectedSets.forEach((filterSet) => {
                    const formattedFilters = this.formatFiltersToSave(filterSet.filters, filterSet.uuid)
                    formattedFilters.forEach((filter) => {
                        const newFilter = filter
                        const exists = this.checkIfExistFilter(newFilter)
                        if (!exists) {
                            this.visualizationFilters.push(newFilter)
                        }
                    })
                })
            } else {
                selectedSets.forEach((filterSet) => {
                    const newFilterSet = filterSet
                    newFilterSet.id = 'FILTER_SET'
                    const exists = this.checkIfExistFilterSet(newFilterSet)
                    if (!exists) {
                        this.visualizationFilters.push(newFilterSet)
                    }
                })
            }
            this.setVisualizationFilters(this.visualizationFilters)
            this.handleApplyFilters()
            this.reloadSidebar()
        },
        formatFiltersToSave (filters, filterSetUuid) {
            return filters.map((f, index) => ({
                label: f.label || f.id,
                pending: false,
                uuid: `${f.id}_${index}`,
                value: this.formatValue(f.values),
                filterset_uuid: `${filterSetUuid}`,
                ...f,
            }))
        },
        checkIfExistFilterSet (filterSet) {
            return this.visualizationFilters.some(existingFilter => existingFilter.name === filterSet.name &&
                    JSON.stringify(existingFilter.filters) === JSON.stringify(filterSet.filters))
        },
        checkIfExistFilter (filter) {
            return this.visualizationFilters.some(existingFilter => existingFilter.id === filter.id &&
                JSON.stringify(existingFilter.values) === JSON.stringify(filter.values))
        },
        formatValue (value) {
            if (!value) return ''
            return typeof value === 'string' ? value : (value || []).join(', ')
        },
        handleCloseCreateFilterSetsModal () {
            this.openCreateFilterSetsModal = false
        },
        HandleopenCreateFilterSetsModal () {
            this.openCreateFilterSetsModal = true
        },
        async restoreZoomerConfig () {
            this.$refs.ZoomerEditModal.restoreZoomerConfig(async () => {
                this.showingZoomer = !this.showingZoomer
                await this.getSettings()
                await this.reloadTabData()
            })
        },

        handleZoomerIsVisible (state) {
            this.isZoomerVisible = state
        },

        handleResetZoomerIndicator () {
            this.$refs.zoomerIndicator.resetZoomerStatus()
        },

        handlertoggleZoomerView (status) {
            this.$refs.chart.toggleZoomerView(status)
        },

    },
}

</script>
<style scoped lang="scss">
$options-width: 340px;
$visualization-sidebar-width: 310px;

.Visualization {
    flex-wrap: nowrap;
    overflow: hidden;
}
.Screen {
    height: calc(100vh - $header-height - $sidebar-height);
    overflow: auto;
    .FilterModal {
        position: fixed;
        top: $header-height;
        bottom: $sidebar-height;
        left: 0;
        right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        width: 100%;
        .FilterSection {
            height: 100%;
            width: 100%;
            .DataContainer {
                width: 300px;
            }
            .CardFooter {
                text-align: right;
            }
        }
        .BackArrow {
            top: 30px;
            color: $black;
            font-size: 24px;
            opacity: .65;
            cursor: pointer;
        }
        .BackArrow {
            left: 50px;
        }
        &:deep(.Dialog) {
            min-width: 70%;
            display: flex;
            flex-direction: column;
            max-width: 60%;
            overflow: hidden;
            height: 100%;
            .Body {
                overflow:auto;
            }
        }
    }
    .FilterSetsModal {
        position: fixed;
        top: $header-height;
        bottom: $sidebar-height;
        left: 0;
        right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        width: 100%;
        &:deep(.Dialog) {
            min-width: 70%;
            display: flex;
            flex-direction: column;
            max-width: 60%;
            overflow: hidden;
            height: 100%;
            .Body {
                overflow:auto;
            }
        }
    }
    &:deep(.q-tab-panel) {
        padding: 0;
    }
    & > .Wrapper {
        /* position: relative; */
        .FixedBar {
            background: $primary;
            padding: 5px;
            border-radius: 5px;
        }
        .FixedPanels {
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: absolute;
            margin-top: 10px;
            margin-left: 10px;
            z-index: 1050;
        }
    }
}
.Sidebar {
    width: 100%;
    position:fixed;
    bottom: 0;
    max-height: 77px;
    height: 77px;
    display:flex;
    z-index:5000;
    background: #F5F5F5;
    transition: $transition;
}
.Report {
    margin-left: 200px;
    margin-top: $header-height;
}
.DownloadGraph {
    position: absolute;
    right: 20px;
    top: 100px;
}
.ChargingHeight {
    min-height:  calc(100vh - $header-height - $sidebar-height - $tabs-height);
    flex-wrap: nowrap;
}
.Graph {
    height: 100%;
}
.ChartContainer {
    height: 100%;
    width: 70%;
    display: flex;
    align-items: stretch;
    position: relative;
    .Actions {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        &:deep(.WButton) {
            padding: 7px 10px;
        }
    }
    .EmptyModel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 10;
        text-align: center;
        font-size: 1.2em;
        color: #555;
    }
}
.Options {
    position: absolute;
    height:100%;
    bottom: 0;
    right: 0;
    width: $options-width;
    margin-right: -#{$options-width};
    border-left: 1px solid $border-color;
    background: $options-bg;
    transition: $transition;
    z-index: 1100;
    @media screen and (min-width: $md) {
        position: relative;
        margin-right: 0;
    }
    &.visible {
        margin-right: 0px;
    }
    &Close {
        position: absolute;
        margin-top: 20px;
        margin-right: 20px;
        z-index: 1;
    }
    &Btn, &Close {
        @media screen and (min-width: $md) {
            display: none;
        }
    }
}
.q-tab-panels {
    height: 100%;
}
.Variants {
    min-height: calc(100vh - $sidebar-height - $header-height - $tabs-height);
    height: calc(100vh - $sidebar-height - $header-height - $tabs-height);
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}
.Assistant {
    height: calc(100vh - $sidebar-height - $header-height);
}
.Tabs {
    margin: 0;
    border-bottom: 1px solid $gray-05;
    background-color: $white;
    z-index:1100;
    &:deep(.Container) {
        padding: 0;
        .Tabs {
            display: flex;
            .Tab {
                width: fit-content;
                min-height: inherit;
                padding: 15px;
            }
        }
    }
}
.ShowingPanelsContainer {
    left: 0px;
    top: 60px;
    position: absolute;
    z-index: 2000;
    background-color: white;
    border: 1px solid $primary;
    border-radius: 6px;
    max-width: fit-content;
}
.Panel {
    padding: 3px;
}
.BtnCreateZoomer {
    padding: 0;
    :deep(> span:nth-child(2)) {
        padding-left: 0.285em;
        min-height: 2.5em;
        padding-right: 0.4em;
    }
}
.BtnZoomer {
    :deep(> span:nth-child(2)) {
        gap: 10px;
    }
}
</style>
