<template>
    <div
        id="app"
        class="App"
    >
        <RouterView />
    </div>
</template>
<style lang="scss">
@import '@/styles/_globals.scss';
</style>
