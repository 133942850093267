import axios from 'axios'
import { get, set, merge } from 'lodash'
import Config from '@/config'
import { TokenSession } from '@/services/session'
import Mocks from './mock'
import Middleware from './middleware'
import Endpoints from './endpoints'

export * from './utils/promiseHandling'
export * from './utils/notify'

let api = null
let apiWithEndPoints = null
let apiLLm = null
let apiWithEndPointsLLm = null

/**
 * Headers manager
 */
export const Headers = {
    get: key => get(api.defaults.headers, key),
    set: (headers) => {
        api.defaults.headers = merge(api.defaults.headers, headers)
        apiLLm.defaults.headers = merge(apiLLm.defaults.headers, headers)
    },
    clean: () => {
        api.defaults.headers = {}
        apiLLm.defaults.headers = {}
    },
    delete: (key) => {
        set(api.defaults.headers, key, undefined)
        set(apiLLm.defaults.headers, key, undefined)
    },
}

/**
 * Set Auth Headers
 * @param {Object} token
 * @param {string} token.type - Token type `Bearer`
 * @param {string} token.token - Token value
 */
const setAuthorizationHeader = ({ type = 'Bearer', token = null }) => {
    if (api && token && type) Headers.set({ common: { Authorization: `${type} ${token}` } })
}

/**
 * Set Auth session
 * @param {Object} token - Token
 * @param {string} token.type - Token type `Bearer`
 * @param {string} token.token - Token value
 */
export const setAuthorization = (token) => {
    TokenSession.set(token)
    setAuthorizationHeader(token)
}

/**
 * Get Auth Headers
 */
export const getAuthorization = () => {
    if (api) return Headers.get('common.Authorization')
    return undefined
}

/**
 * Remove Auth Headers
 */
export const removeAuthorization = () => {
    TokenSession.unset()
    if (api) return Headers.delete('common.Authorization')
    return undefined
}

/**
 * Create api
 */
export const create = (config) => {
    // Create API
    api = axios.create(Config.api)
    // Instance endpoints
    apiWithEndPoints = Endpoints.create(api)
    // Middleware request
    api.interceptors.request.use(
        Middleware.request.success,
        Middleware.request.error,
    )
    // Middleware response
    api.interceptors.response.use(
        response => Middleware.response.success({ response, ...config }),
        error => Middleware.response.error({
            error,
            setAuthorization,
            ...config,
        }),
    )

    // Manage mocks
    if (Config.isTest || Config.useMock) Mocks(api)

    createLLM(config)

    return apiWithEndPoints
}

export const createLLM = (config) => {
    // Create API
    apiLLm = axios.create(Config.apiLLm)
    apiLLm.defaults.timeout = Config.apiLLm.timeout
    // Instance endpoints
    apiWithEndPointsLLm = Endpoints.create(apiLLm)
    // Middleware request
    apiLLm.interceptors.request.use(
        Middleware.request.success,
        Middleware.request.error,
    )
    // Middleware response
    apiLLm.interceptors.response.use(
        response => Middleware.response.success({ response, ...config }),
        error => Middleware.response.error({
            error,
            setAuthorization,
            ...config,
        }),
    )

    // Manage mocks
    if (Config.isTest || Config.useMock) Mocks(apiLLm)

    return apiWithEndPointsLLm
}

/**
 * Instance
 */
export const Api = () => apiWithEndPoints
export const ApiLLM = () => apiWithEndPointsLLm

export default {
    create,
    createLLM,
    Api,
    ApiLLM,
    Headers,
    getAuthorization,
    setAuthorization,
    removeAuthorization,
}
