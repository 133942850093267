<template>
    <div
        v-if="zoomerIsVisible"
        class="indicatorContainer"
    >
        <q-icon
            :name="iconName"
            style="cursor: pointer;"
            size="40px"
            :class="['q-mr-md', zoomerStatus ? '' : 'disabledBtn']"
            color="primary"
            @click="handleToggleZoomerView"
        >
            <q-tooltip
                anchor="top middle"
                self="bottom middle"
                :offset="[0, 10]"
                contentStyle="contentQToolTip"
            >
                {{ $t(`visualization.zoomer.indicator.${toolTipMessage}`) }}
            </q-tooltip>
        </q-icon>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'ZoomerIndicator',
    props: {
        zoomerIsVisible: VueTypes.bool,
    },
    emits: ['toggleZoomerView'],
    data () {
        return {
            zoomerStatus: true,
            iconName: 'search',
            toolTipMessage: 'activated',
        }
    },
    methods: {

        resetZoomerStatus () {
            this.zoomerStatus = true;
            [this.toolTipMessage, this.iconName] = this.changeTooltipStatusMessage(this.zoomerStatus)
        },

        handleToggleZoomerView () {
            this.zoomerStatus = !this.zoomerStatus;
            [this.toolTipMessage, this.iconName] = this.changeTooltipStatusMessage(this.zoomerStatus)
            this.$emit('toggleZoomerView', this.zoomerStatus)
        },

        changeTooltipStatusMessage (status) {
            const statusChanged = {
                true: ['activated', 'search'],
                false: ['disabled', 'search_off'],
            }
            return statusChanged[status]
        },
    },
}
</script>
<style lang="scss">

.indicatorContainer {
    position: fixed;
    bottom: 165px;
    right: 350px;
}
.contentQToolTip {
    white-space: normal;
    max-width: 150px;
    word-wrap: break-word;
}
.disabledBtn {
    opacity: 0.5;
}
</style>
