<template>
    <div class="Variants">
        <QExpansionItem
            defaultOpened
            dense
            denseToggle
            icon="alt_route"
            headerStyle="padding-bottom: 10px; padding-top: 8px; font-weight: 600; color: var(--q-primary) !important;"
            :label="$t('visualization.variants')"
        >
            <div class="flex flex-center q-my-sm">
                <QBtnToggle
                    v-model="variantTab"
                    toggleColor="primary"
                    textColor="primary"
                    toggleTextColor="white"
                    noCaps
                    unelevated
                    :options="variantTabs"
                    class="q-mt-sm border-toggle"
                />
            </div>
            <QTabPanels
                v-model="variantTab"
                animated
                transitionNext="fade"
                transitionPrev="fade"
            >
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <QTabPanel name="FREQUENCY">
                    <div class="mt-1 q-mx-sm">
                        <div class="flex-row justify-between mb-1">
                            <div style="padding-top: 6px">
                                <WText>
                                    {{ $t('visualization.availableVariants') }}
                                </WText>
                            </div>
                            <div>
                                <QBtn
                                    class="w-full"
                                    style="justify-content: center;"
                                    flat
                                    color="primary"
                                    noCaps
                                    dense
                                    padding="xs"
                                    :disabled="isVariantsLoading || isVariantFilterActive || disableElements"
                                    :class="{ 'active-filter': isVariantFilterActive }"
                                    @click="toggleFrequencyVariantFilter"
                                >
                                    <QIcon
                                        name="filter_alt"
                                        color="primary"
                                        left
                                        size="25px"
                                    />
                                    <QTooltip
                                        maxWidth="500px"
                                        anchor="center right"
                                        self="center left"
                                    >
                                        {{ isVariantFilterActive ? $t('visualization.filterVariantsApplied') : $t('visualization.filterVariants') }}
                                    </QTooltip>
                                </Qbtn>
                            </div>
                        </div>
                        <div class="Labels flex row-stretch-top">
                            <QChip
                                square
                                textColor="white"
                                color="primary"
                                outline
                            >
                                {{ App.numberLocationFormat(minValue, false, true) }}
                            </QChip>
                            <QChip
                                square
                                textColor="white"
                                :color="percentageColor"
                                class="text-weight-bold cursor-pointer"
                            >
                                {{ `${App.numberLocationFormat(formatNumber(sliderValue), false, true)} (${percentageValue}%)` }}
                            </QChip>
                            <QChip
                                square
                                textColor="white"
                                color="secondary"
                                outline
                            >
                                {{ App.numberLocationFormat(formatNumber(maxValue), false, true) }}
                            </QChip>
                            <QPopupEdit :modelValue="undefined">
                                <QInput
                                    ref="textinput"
                                    :modelValue="sliderValue"
                                    type="number"
                                    :label="$t('visualization.modelLabel')"
                                    :max="maxValue"
                                    :min="minValue"
                                    :disable="isVariantsLoading || !minValue || disableElements"
                                    :debounce="600"
                                    :rules="[
                                        value => validators.minValue(value, minValue)|| $t('validation.minValue', { attribute: $t('visualization.variants'), min: minValue }),
                                        value => validators.maxValue(value, maxValue)|| $t('validation.maxValue', { attribute: $t('visualization.variants'), max: maxValue }),
                                    ]"
                                    @update:modelValue="handleChangeTextInput"
                                />
                            </QPopupEdit>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 q-pr-md">
                                <QSlider
                                    :modelValue="sliderValue"
                                    class="q-ma-sm"
                                    :color="percentageColor"
                                    :labelValue="variantLabel"
                                    :min="minValue"
                                    :max="maxValue"
                                    :disable="isVariantsLoading || !minValue || disableElements"
                                    @change="handleChangedModel"
                                    @update:modelValue="handleChangeSliderValue"
                                />
                            </div>
                        </div>
                    </div>
                </QTabPanel>
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <!-- min-height set to avoid the order dropdown to be partially covered by other elements
                     in case there is not enough variants  -->
                <QTabPanel
                    name="INDIVIDUAL"
                    style=" min-height: 270px;margin-bottom: 10px;"
                >
                    <div class="flex row-stretch-center flex-nowrap mt-1">
                        <WText class="pr-0-5">
                            {{ $t('visualization.variants') }}
                        </WText>
                        <QInput
                            v-model="variantSearch"
                            dense
                            outlined
                            type="search"
                            :disable="disableElements"
                            :label="$t('actions.search')"
                            class="Search search-input float-left"
                            @keyup.enter="handleSearchVariant(variantSearch)"
                        >
                            <template #append>
                                <q-icon
                                    v-if="variantSearch"
                                    id="clean"
                                    name="close"
                                    size="20px"
                                    clickable
                                    style="margin-right: 0.5rem;cursor: pointer;"
                                    @click="handleResetVariantSearch"
                                />
                                <q-icon
                                    id="search"
                                    name="search1"
                                    size="20px"
                                    clickable
                                    style="cursor: pointer;"
                                    @click="handleSearchVariant(variantSearch)"
                                />
                            </template>
                        </QInput>
                    </div>
                    <div style="display: flex; justify-content: space-between; padding-top:10px;">
                        <div style="display: flex; justify-content: space-between;">
                            <div>
                                <QFab
                                    v-model="displayDirectionOptions"
                                    :label="orderByBigger.direction === 'ASCENDING' ?
                                        $t('projects.variants.orderOptionsAbbreviations.ascending') :
                                        $t('projects.variants.orderOptionsAbbreviations.descending')"
                                    square
                                    flat
                                    labelPosition="left"
                                    color="grey-2"
                                    textColor="primary"
                                    :icon="orderByBigger.direction === 'ASCENDING' ? 'arrow_upward' : 'arrow_downward'"
                                    direction="down"
                                    padding="sm"
                                    @show="showOrder"
                                >
                                    <QFabAction
                                        v-for="option in order"
                                        :key="option.direction"
                                        textColor="primary"
                                        color="grey-2"
                                        :label="option.label"
                                        :style="{ transform: 'translateX(30px)' }"
                                        padding="xs"
                                        @click="changeOrder(option)"
                                    />
                                </QFab>
                                <QTooltip
                                    v-if="!displayDirectionOptions"
                                >
                                    {{ $t('projects.variants.changeOrder') }}
                                </QTooltip>
                            </div>
                            <div>
                                <QFab
                                    v-model="displayDurationOptions"
                                    :label="selectedOption.label"
                                    square
                                    flat
                                    labelPosition="left"
                                    color="grey-2"
                                    textColor="primary"
                                    icon="keyboard_arrow_down"
                                    direction="down"
                                    padding="sm"
                                    @show="handleFocus"
                                    @hide="handleBlur"
                                >
                                    <QFabAction
                                        v-for="option in combinedOptions"
                                        :key="option.value"
                                        textColor="primary"
                                        color="grey-2"
                                        :label="option.label"
                                        padding="xs"
                                        @click="setDurationOption(option)"
                                    />
                                </QFab>
                                <QTooltip
                                    v-if="!displayDurationOptions"
                                >
                                    {{ $t('projects.variants.changeOrderCriteria') }}
                                </QTooltip>
                            </div>
                        </div>
                        <div style="display: flex; align-self:center;">
                            <div
                                style="margin-right: 0px;"
                            >
                                <QBtn
                                    class="w-full"
                                    style="justify-content: center;"
                                    size="md"
                                    color="primary"
                                    flat
                                    icon="filter_alt"
                                    :disable="isVariantFilterActive || disableElements"
                                    :class="{ 'active-filter': isVariantFilterActive }"
                                    @click="toggleIndividualVariantFilter"
                                />
                                <QTooltip>
                                    {{ isVariantFilterActive ? $t('visualization.filterVariantsApplied') : $t('visualization.filterVariants') }}
                                </QTooltip>
                            </div>
                            <div
                                style="margin-left: 0px;"
                            >
                                <QBtn
                                    class="w-full"
                                    style="justify-content: center;"
                                    size="sm"
                                    flat
                                    color="primary"
                                    @click="handleResetMultipleVariants"
                                >
                                    <QIcon
                                        name="history"
                                        size="24px"
                                    />
                                </QBtn>
                                <QTooltip
                                    maxWidth="100px"
                                    class="text-center"
                                >
                                    {{ $t('visualization.resetMultipleVariants') }}
                                </QTooltip>
                            </div>
                        </div>
                    </div>
                    <div class="scroll">
                        <q-inner-loading :showing="isVariantsLoading || isVariantsLoadingPagination" />
                        <div v-if="!(isVariantsLoading || isVariantsLoadingPagination)">
                            <template
                                v-for="(item) in variants"
                                :key="item.id"
                            >
                                <div class="flex flex-row flex-nowrap justify-between">
                                    <a
                                        href="#"
                                        class="Bar mt-0-5 w-full"
                                        style="max-width: 65%!important;"
                                        :aria-disabled="disableElements ? 'true' : 'false'"
                                        :class="{ selected: item.id === activeVariant || selectedMultipleVariants.some(v => v.id === item.id), disabled: disableElements }"
                                        @click.prevent="handleChangeVariant(item)"
                                    >
                                        <div
                                            class="flex row w-full"
                                            style="padding-bottom: 5px"
                                        >
                                            <div class="flex col-sm-8">
                                                <WText
                                                    weight="semi"
                                                    :size="12"
                                                >
                                                    {{ item.name !== undefined ? noVariantPrefix(item.name) : item.name }}
                                                </WText>
                                                <QBtn
                                                    v-if="selectedMultipleVariants.some(v => v.id === item.id)"
                                                    flat
                                                    square
                                                    icon="edit"
                                                    size="10px"
                                                    color="white"
                                                    textColor="primary"
                                                    class="q-mr-sm"
                                                    padding="0px"
                                                    :style="{ marginLeft: '5px'}"
                                                    @click.stop="handleRenameVariant(item.id)"
                                                />
                                            </div>
                                            <div
                                                class="flex col-sm-4 items-end"
                                                :style="{ justifyContent: 'end' }"
                                            >
                                                <WText
                                                    weight="semi"
                                                    :size="12"
                                                >
                                                    {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                                </WText>
                                            </div>
                                        </div>
                                        <QLinearProgress
                                            size="10px"
                                            :value="getAbsFrequency(item.absFrequency)"
                                            class="LinearProgress"
                                        />
                                        <QTooltip
                                            class="text-primary"
                                            maxWidth="500px"
                                            style="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                            anchor="center right"
                                            self="center left"
                                        >
                                            <WText
                                                color="primary"
                                            >
                                                <strong>
                                                    {{ item.name !== undefined ? noVariantPrefix(item.name) : item.name }}
                                                </strong>: {{ item.absFrequency }} {{ $t('visualization.cases') }} ({{ percentage(getAbsFrequency(item.absFrequency)) }}%)
                                            </WText>
                                        </QTooltip>
                                    </a>
                                    <WText
                                        class="ml-1 self-end fit-content"
                                        style="width: 18%;"
                                    >
                                        {{ getVariantQuantity(item) }}
                                    </Wtext>
                                    <input
                                        style="align-self:end; margin-bottom:3px;"
                                        type="checkbox"
                                        :checked="isSelected(item)"
                                        @change="handleAddMultipleVariant(item)"
                                    >
                                </div>
                            </template>
                        </div>
                        <div
                            v-if="!isVariantsLoading && !showVariants"
                            class="flex col-center mt-2"
                        >
                            <Badge
                                icon="warning_amber"
                                :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.variants') })"
                                color="warning"
                                textColor="black"
                                class="row-center pt-0-5 pb-0-5 mb-0-5"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showVariants"
                        class="flex flex-col row-center pt-1 pb-1"
                    >
                        <QPagination
                            v-model="variantPagination.start"
                            :max="variantPagination.max"
                            :directionLinks="true"
                            :disable="disableElements || isVariantsLoadingPagination"
                            :maxPages="4"
                            size="10px"
                            :boundaryNumbers="true"
                            @update:modelValue="handleChangeVariantsWithPagination"
                        />
                    </div>
                </QTabPanel>
            </QTabPanels>
        </QExpansionItem>
        <Divider />
        <QExpansionItem
            ref="loopExpansionItem"
            dense
            denseToggle
            icon="360"
            headerStyle="padding-bottom: 10px; padding-top: 10px; font-weight: 600; color: var(--q-primary) !important;"
            :label="$t('visualization.nLoops')"
        >
            <div
                v-if="settings && settings.zoomerConfig &&
                    !settings.zoomerConfig.excludedActivities.length &&
                    !settings.zoomerConfig.groupedActivities.length"
                class="flex row-stretch-center flex-nowrap mt-1"
            >
                <WText class="pr-0-5">
                    {{ $t('visualization.nLoops') }}
                </WText>
                <QInput
                    v-model="loopSearch"
                    dense
                    outlined
                    type="search"
                    :label="$t('actions.search')"
                    :disable="disableElements"
                    class="Search search-input float-left"
                    @keyup.enter="handleSearchLoops(loopSearch)"
                >
                    <template #append>
                        <q-icon
                            v-if="loopSearch"
                            id="clean"
                            name="close"
                            size="20px"
                            clickable
                            style="margin-right: 0.5rem;cursor: pointer;"
                            @click="handleResetLoopSearch"
                        />
                        <q-icon
                            id="search"
                            name="search1"
                            size="20px"
                            clickable
                            style="cursor: pointer;"
                            @click="handleSearchLoops(loopSearch)"
                        />
                    </template>
                </QInput>
            </div>
            <Tabs
                v-if="settings && settings.zoomerConfig &&
                    !settings.zoomerConfig.excludedActivities.length &&
                    !settings.zoomerConfig.groupedActivities.length"
                :value="loopTab"
                class="Tabs"
                :tabs="loopTabs"
                horizontal
                @onClick="handleChangeLoopTab"
            />
            <QTabPanels v-model="loopTab">
                <QTabPanel
                    class="model-tab-panel"
                    :name="loopTab"
                >
                    <div class="scroll">
                        <q-inner-loading :showing="isLoopsLoading" />
                        <div v-show="showLoops">
                            <a
                                v-for="(item) in loops"
                                :key="item.id"
                                href="#"
                                class="Bar mt-0-5"
                                :aria-disabled="disableElements ? 'true' : 'false'"
                                :class="{ selected: item.id === activeLoopId, disabled: disableElements }"
                                @click.prevent="handleChangeLoop(item.id)"
                            >
                                <div
                                    class="flex row"
                                    style="padding-bottom: 5px"
                                >
                                    <div class="flex col-sm-9">
                                        <WText
                                            weight="semi"
                                            :size="12"
                                        >
                                            {{ item.name !== undefined ? item.name.replace('Loop', $t('visualization.loop')) : item.name }}
                                        </WText>
                                        <QBtn
                                            v-if="loopVisible && item.id === activeLoopId"
                                            flat
                                            square
                                            icon="visibility_off"
                                            size="10px"
                                            color="white"
                                            textColor="primary"
                                            class="q-mr-sm"
                                            padding="0px"
                                            :style="{ marginLeft: '8px'}"
                                            :disable="item.id !== activeLoopId"
                                            @click.stop="handleHideLoop()"
                                        />
                                        <QBtn
                                            v-if="!loopVisible && item.id === activeLoopId"
                                            flat
                                            square
                                            icon="visibility"
                                            size="10px"
                                            color="white"
                                            textColor="primary"
                                            class="q-mr-sm"
                                            padding="0px"
                                            :style="{ marginLeft: '8px'}"
                                            :disable="item.id !== activeLoopId"
                                            @click.stop="handleShowLoop()"
                                        />
                                    </div>
                                    <div
                                        class="flex col-sm-3 items-end"
                                        :style="{ justifyContent: 'end' }"
                                    >
                                        <WText
                                            weight="semi"
                                            :size="12"
                                        >
                                            {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                        </WText>
                                    </div>
                                </div>
                                <QLinearProgress
                                    size="10px"
                                    :value="getAbsFrequency(item.absFrequency)"
                                    class="LinearProgress"
                                />
                                <QTooltip
                                    class="text-primary"
                                    maxWidth="500px"
                                    style="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                    anchor="center right"
                                    self="center left"
                                >
                                    <WText
                                        color="primary"
                                    >
                                        <strong>
                                            {{ item.name !== undefined ? item.name.replace('Loop', $t('visualization.loop')) : item.name }}
                                        </strong>: {{ $t('visualization.loopsFound') }} {{ item.absFrequency }} {{ $t('visualization.cases') }}
                                        ({{ percentage(getAbsFrequency(item.absFrequency)) }}%)
                                    </WText>
                                </QTooltip>
                            </a>
                        </div>
                        <div
                            v-if="settings && settings.zoomerConfig &&
                                (settings.zoomerConfig.excludedActivities.length > 0 ||
                                    settings.zoomerConfig.groupedActivities.length > 0)"
                            class="flex col-center mt-2 mb-1"
                        >
                            <Badge
                                icon="warning_amber"
                                :label="$t('visualization.zoomer.loopsNotAvailable')"
                                color="warning"
                                textColor="black"
                                class="row-center pt-0-5 pb-0-5 mb-0-5"
                            />
                        </div>
                        <div
                            v-if="settings && !(settings.zoomerConfig &&
                                (settings.zoomerConfig.excludedActivities.length > 0 ||
                                    settings.zoomerConfig.groupedActivities.length > 0)) &&
                                !showLoops"
                            class="flex col-center mt-2 mb-1"
                        >
                            <Badge
                                icon="warning_amber"
                                :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.loops') })"
                                color="warning"
                                textColor="black"
                                class="row-center pt-0-5 pb-0-5 mb-0-5"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showLoops"
                        class="flex row-center pt-1 mb-1"
                    >
                        <QPagination
                            v-model="loopPagination.start"
                            :max="loopPagination.max"
                            :directionLinks="true"
                            :maxPages="4"
                            :disable="disableElements"
                            size="11.5px"
                            :boundaryNumbers="true"
                            @update:modelValue="handleChangeLoopPagination"
                        />
                    </div>
                </QTabPanel>
            </QTabPanels>
        </QExpansionItem>
        <Divider />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { Badge, Tabs, Divider } from '@/components'
import { filtersStorageMixin, filtersMixin, validatorsMixin } from '@/mixins'

const MIN_VALUE = 1
const INITIAL_VARIANTS_PAGINATION = { start: 0, limit: 8 }
const INITIAL_LOOPS_PAGINATION = { start: 0, limit: 8 }
const DEFAULT_VARIANT_TAB = 'FREQUENCY'
const TABS = {
    LOOPS: 'loops',
    SELF_LOOPS: 'self-loops',
}

export default {
    name: 'Variants',
    components: {
        Badge,
        Tabs,
        Divider,
    },
    mixins: [filtersStorageMixin, filtersMixin, validatorsMixin],
    inject: ['App'],
    props: {
        modelValue: VueTypes.shape({
            heatMapValue: VueTypes.string,
            values: VueTypes.string,
            variant: VueTypes.number,
        }).loose,
        info: VueTypes.object,
        filteredInfo: VueTypes.object,
        percentageMode: VueTypes.string,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        settings: VueTypes.objects,
        disableElements: VueTypes.bool.def(false),
    },
    emits: ['onChangedVariantTab', 'checkForVariantFilter', 'filterByVariant',
        'variantsLoading', 'onLoadChart', 'onOpenRenameModal', 'refreshVariantsNamesAtSideBar',
        'onColorLoops', 'filterByNVariants', 'onChangedVariant'],
    data () {
        return {
            variantSearch: '',
            loopSearch: '',
            activeVariantId: undefined,
            activeVariant: undefined,
            selectedMultipleVariants: [],
            activeLoopId: undefined,
            activeLoop: undefined,
            selectedKpi: undefined,
            variants: undefined,
            allVariants: undefined,
            loops: undefined,
            loopPagination: {
                start: 1,
                max: INITIAL_LOOPS_PAGINATION.limit,
            },
            variantPagination: {
                start: 1,
                max: INITIAL_VARIANTS_PAGINATION.limit,
            },
            isVariantsLoading: undefined,
            isLoopsLoading: undefined,
            isFetching: undefined,
            isVariantFilterActive: false,
            loopTab: TABS.LOOPS,
            variantTab: DEFAULT_VARIANT_TAB,
            loopVisible: true,
            sliderValue: (this.modelValue || {}).variant || 1,
            isChecked: {},
            percentageValue: Math.round((this.modelValue || {}).variant / this.maxValue * 100) || 1,
            isDropdownOpen: false,
            options: [
                {
                    label: this.$t('projects.variants.order.frequency'),
                    value: 'FREQUENCY',
                },
                {
                    label: this.$t('projects.variants.order.mean'),
                    value: 'AVERAGE',
                },
                {
                    label: this.$t('projects.variants.order.median'),
                    value: 'MEDIAN',
                },
                {
                    label: this.$t('projects.variants.order.deviation'),
                    value: 'SD',
                },
            ],

            abbreviations: [
                {
                    label: this.$t('projects.variants.orderAbbreviations.frequency'),
                    value: 'FREQUENCY',
                },
                {
                    label: this.$t('projects.variants.orderAbbreviations.mean'),
                    value: 'AVERAGE',
                },
                {
                    label: this.$t('projects.variants.orderAbbreviations.median'),
                    value: 'MEDIAN',
                },
                {
                    label: this.$t('projects.variants.orderAbbreviations.deviation'),
                    value: 'SD',
                },
            ],
            selectedOption:
                {
                    label: this.$t('projects.variants.orderAbbreviations.frequency'),
                    value: 'FREQUENCY',
                },
            orderByBigger: { direction: 'DESCENDING', label: this.$t('projects.variants.orderOptions.descending') },
            displayDurationOptions: false,
            displayDirectionOptions: false,
            isVariantsLoadingPagination: false,
            isVariantsLoadingMap: {
                getMultipleVariants: false,
                getModel: false,
                getVariants: false,
                renameVariant: false,
                getFrequencyVariants: false,
                initializeVariants: false,
            },
            variantsLoading: false,
        }
    },
    computed: {
        order () {
            return [
                { direction: 'ASCENDING', label: this.$t('projects.variants.orderOptions.ascending') },
                { direction: 'DESCENDING', label: this.$t('projects.variants.orderOptions.descending') },
            ]
        },
        combinedOptions () {
            if (this.isDropdownOpen) {
                return this.options
            }
            return this.abbreviations
        },
        showVariants () {
            return !this.isVariantsLoading && (this.variants || []).length
        },
        showLoops () {
            return !this.isLoopsLoading && (this.loops || []).length
        },
        loopTabs () {
            return [
                { id: TABS.LOOPS, label: this.$t('visualization.nLoops') },
                { id: TABS.SELF_LOOPS, label: this.$t('visualization.selfLoops') },
            ]
        },
        variantTabs () {
            return [{
                value: 'FREQUENCY',
                label: this.$t('visualization.aggregate'),
            },
            {
                value: 'INDIVIDUAL',
                label: this.$t('visualization.individual'),
            }]
        },
        maxValue () {
            const { variants } = this.filteredInfo
            return variants || 1
        },
        variantLabel () {
            return `${this.sliderValue} (${this.percentageValue}%)`
        },
        arrayMarkerLabel () {
            return [
                { value: this.minValue, label: this.minValue },
                { value: this.maxValue, label: this.maxValue },
            ]
        },
        percentageColor () {
            if (this.percentageValue >= 75) {
                return 'secondary'
            }
            return 'primary'
        },
    },
    watch: {
        isVariantsLoadingMap: {
            handler: 'emitVariantsLoading',
            deep: true,
        },
        orderByBigger () {
            this.variantPagination.start = 1
            this.getVariants({ search: this.variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        modelValue (val) {
            this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
        },
        maxValue: {
            handler () {
                this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
                this.handleChangeSliderValue((this.modelValue || {}).variant || 1)
            },
            immediate: true,
        },
        variantTab (selectedVariantTab) {
            this.activeVariant = undefined
            this.selectedMultipleVariants = []
            const { loopExpansionItem } = this.$refs
            if (loopExpansionItem && loopExpansionItem.showing) {
                loopExpansionItem.toggle()
            }
            if (selectedVariantTab === 'FREQUENCY') {
                this.handleChangedModel(this.minValue)
                this.$emit('onChangedVariantTab', 'FREQUENCY')
            } else if (selectedVariantTab === 'INDIVIDUAL') {
                this.initVariants()
                this.$emit('onChangedVariantTab', 'INDIVIDUAL')
            }
        },
    },
    beforeMount () {
        this.minValue = MIN_VALUE
        this.selectedOption = this.abbreviations.find(el => el.value === 'FREQUENCY')
        this.$watch('selectedOption', this.selectedOptionWatcher)
    },
    mounted () {
        this.initializeVariants()
    },
    methods: {
        async initializeVariants () {
            this.setVariantsLoading('initializeVariants', true)
            this.checkForVariantFilter()
            const { variant } = this.modelValue
            if (this.processId) {
                if (this.variantTab === 'FREQUENCY') {
                    this.sliderValue = variant
                    await this.fetchModel(variant)
                } else if (this.variantTab === 'INDIVIDUAL') {
                    await this.initVariants()
                }
            }
            this.setVariantsLoading('initializeVariants', false)
        },
        selectedOptionWatcher () {
            this.variantPagination.start = 1
            this.getVariants({ search: this.variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        noVariantPrefix (name) {
            const regex = /^Variant\s+/
            if (regex.test(name)) {
                return name.replace(regex, '')
            }
            return name
        },
        setDurationOption (option) {
            this.selectedOption = option
        },
        getDurationFormattedData (value) {
            return moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
        },
        processNumber (value, isQuantity) {
            if (isQuantity && value > 1000) {
                return this.formatNumber(value)
            } if (!isQuantity) {
                return this.getDurationFormattedData(value)
            }
            return value
        },
        formatNumber (num) {
            switch (true) {
                case num >= 1000000:
                    return `${(num / 1000000).toFixed(1)}M`
                case num >= 100000:
                    return `${(num / 100000).toFixed(0)}k`
                case num >= 1000:
                    return `${(num / 1000).toFixed(1)}k`
                default:
                    return num.toString()
            }
        },
        getVariantQuantity (item) {
            let result
            if (this.selectedOption.value === 'AVERAGE') {
                result = item.avg
            } if (this.selectedOption.value === 'MEDIAN') {
                result = item.med
            } if (this.selectedOption.value === 'SD') {
                result = item.sd
            } if (this.selectedOption.value === 'FREQUENCY') {
                result = item.absFrequency
                return this.App.numberLocationFormat(this.processNumber(result, true), false, true)
            }
            return this.App.numberLocationFormat(this.processNumber(result, false), false, true)
        },
        changeOrder (opt) {
            this.orderByBigger = opt
        },
        showOrder () {
            this.displayDurationOptions = false
        },
        handleFocus () {
            this.isDropdownOpen = true
            this.displayDirectionOptions = false
        },
        handleBlur () {
            this.isDropdownOpen = false
            this.selectedOption = this.abbreviations.find(abb => abb.value === this.selectedOption.value)
        },
        async initVariants () {
            await this.handleResetVariantSearch()
            this.handleResetMultipleVariants()
        },
        checkForVariantFilter () {
            this.$eventBus.emit('checkForVariantFilter')
            this.$eventBus.on('hasVariantFilter', (variantFilter) => {
                this.isVariantFilterActive = variantFilter
            })
        },
        toggleIndividualVariantFilter () {
            if (!this.isVariantFilterActive) {
                this.filterByIndividualVariant()
                this.selectedMultipleVariants = []
                this.getVariants()
            }
        },
        filterByIndividualVariant () {
            this.$eventBus.emit('filterByVariant', this.selectedMultipleVariants.map(v => v.id))
        },
        handleChangeVariantsWithPagination (start) {
            this.isVariantsLoadingPagination = true
            this.refreshVariantsList({ start: start - 1, search: this.variantSearch })
        },
        async handleResetVariantSearch () {
            this.variantSearch = ''
            this.variantPagination.start = 1
            await this.getVariants({ search: this.variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        handleSearchVariant (variantSearch) {
            this.variantPagination.start = 1
            this.getVariants({ search: variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        handleChangeVariantPagination (variant, oldVariants) {
            if (this.selectedMultipleVariants.length === 0) {
                this.activeVariant = variant
                this.activeVariantId = variant.id
                this.selectedMultipleVariants.push(variant)
            }
        },
        handleChangeVariant (variant) {
            this.activeVariantId = variant.id
            this.selectedMultipleVariants = [variant]
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        handleAddMultipleVariant (variant) {
            this.activeVariantId = variant.id
            const index = this.selectedMultipleVariants.findIndex(v => v.id === variant.id)
            if (index !== -1) {
                this.selectedMultipleVariants.splice(index, 1)
            } else {
                this.selectedMultipleVariants.push(variant)
            }
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        handleDeleteMultipleVariant (variant) {
            if (this.selectedMultipleVariants.length > 1) {
                this.selectedMultipleVariants = this.selectedMultipleVariants.filter(v => v.id !== variant.id)
                this.activeVariantId = this.selectedMultipleVariants.find(v => true).id
                this.getMultipleVariants(this.selectedMultipleVariants)
            }
        },
        getAbsFrequency (absFrequency) {
            const { cases } = this.info
            return cases ? absFrequency / cases : 0
        },
        setVariantsLoading (key, value) {
            if (Object.prototype.hasOwnProperty.call(this.isVariantsLoadingMap, key)) {
                this.isVariantsLoadingMap[key] = value
            }
        },
        emitVariantsLoading () {
            const allLoadingFalse = Object.values(this.isVariantsLoadingMap).every(isLoading => !isLoading)
            if (allLoadingFalse && this.variantsLoading) {
                this.variantsLoading = false
                this.$emit('variantsLoading', false)
            } else if (!allLoadingFalse && !this.variantsLoading) {
                this.variantsLoading = true
                this.$emit('variantsLoading', true)
            }
        },
        normalizeVariantPaginationMax (value) {
            return Math.ceil(value / INITIAL_VARIANTS_PAGINATION.limit)
        },
        normalizeVariantParams (param) {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const order = { orderDurationBy: this.selectedOption.value }
            const sortDirection = { sortDirection: this.orderByBigger.direction }
            return {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...INITIAL_VARIANTS_PAGINATION,
                ...order,
                ...sortDirection,
                ...(param || {}),
            }
        },
        normalizeParamsMultipleVariants (param) {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            let filtersFinal = { filters: [] }
            if (filters.length !== 0) {
                filtersFinal = { filters }
            }
            return {
                filters: filtersFinal,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...INITIAL_VARIANTS_PAGINATION,
                ...(param || {}),
            }
        },
        async getVariants (param) {
            this.isVariantsLoading = true
            const params = this.normalizeVariantParams(param)
            await apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    const oldVariants = this.variants ? this.variants : []
                    const [first] = data
                    this.variants = data
                    this.variantPagination.max = this.normalizeVariantPaginationMax(total)
                    if (first) this.handleChangeVariantPagination(first, oldVariants)
                })
                .catch(notifyError)
                .finally(() => {
                    this.isVariantsLoading = false
                })
        },
        async getMultipleVariants (variants) {
            const variantsIds = variants.map(v => v.id)
            const { processId } = this
            this.setVariantsLoading('getMultipleVariants', true)
            const params = this.normalizeParamsMultipleVariants({
                variantsUids: variantsIds,
                excludedActivities: this.settings?.zoomerConfig?.excludedActivities || [],
                groupedActivities: this.settings?.zoomerConfig?.groupedActivities || [],
            })
            apiRequest(Api().visualizations.multipleVariants({ processId, params }))
                .then(({ data }) => {
                    const [model] = data || []
                    this.$emit('onLoadChart', { ...model, variantId: this.selectedMultipleVariants.map(v => v.id) })
                    this.getLoops()
                    this.activeVariant = model
                    this.activeLoopId = undefined
                })
                .catch(notifyError)
                .finally(() => {
                    this.setVariantsLoading('getMultipleVariants', false)
                })
        },
        handleRenameVariant (variantId) {
            this.$emit('onOpenRenameModal', { variantId })
        },
        checkVariantIsSelected (variantId) {
            return this.selectedMultipleVariants.some(v => v.id === variantId)
        },
        handleResetMultipleVariants () {
            this.selectedMultipleVariants = [this.variants[0]]
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        async renameVariant ({ newName, variantRenamedId }) {
            const { processId } = this
            const variantId = this.selectedMultipleVariants.find(v => v.id === variantRenamedId).id
            const params = { name: newName }
            apiRequest(Api().visualizations.renameVariant({ processId, variantId, params }))
                .then(() => {
                    this.refreshVariantsList({ start: this.variantPagination.start - 1, search: this.variantSearch }).then(() => {
                        notifySuccess(this.$t('visualization.variantRenameOK'))
                    })
                    this.$eventBus.emit('refreshVariantsNamesAtSideBar')
                })
                .catch(notifyError)
                .finally()
        },
        async refreshVariantsList (param) {
            this.isVariantsLoadingPagination = true
            const params = this.normalizeVariantParams(param)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(params.filters)
            params.filters = filters
            params.filterSets = filterSetsUUIDs
            params.operator = generalOperator
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data
                    this.selectedMultipleVariants = this.selectedMultipleVariants.map((variant) => {
                        const name = this.variants.find(v => variant.id === v.id)?.name
                        return { ...variant, name: name || variant.name }
                    })
                    this.variantPagination.max = this.normalizeVariantPaginationMax(total)
                })
                .catch(notifyError)
                .finally(() => {
                    this.isVariantsLoadingPagination = false
                })
        },
        /* ***************************  */
        /*            LOOPS             */
        /* ***************************  */
        handleSearchLoops (loopSearch) {
            this.loopPagination.start = 1
            this.getLoops({ search: loopSearch, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleResetLoopSearch () {
            this.loopSearch = ''
            this.loopPagination.start = 1
            this.getLoops({ search: this.loopSearch, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleChangeLoopTab (loopTab) {
            this.loopPagination.start = 1
            this.loopTab = loopTab
            this.activeLoopId = undefined
            this.activeLoop = undefined
            this.getLoops()
            this.$emit('onColorLoops', { ...this.activeVariant, variantId: this.activeVariantId })
        },
        handleChangeLoop (id) {
            this.activeLoopId = id
            this.loopVisible = true
            this.getLoop(id)
        },
        handleChangeLoopPagination (start) {
            this.getLoops({ start: start - 1, search: this.loopSearch || '' })
        },
        normalizeLoopParams (param, self) {
            const filters = this.visualizationFilters
            return {
                filters,
                ...INITIAL_LOOPS_PAGINATION,
                start: this.loopPagination.start - 1,
                ...(self ? { self } : {}),
                ...(typeof param === 'string' ? {} : param),
                variantsId: this.selectedMultipleVariants.map(v => v.id),
            }
        },
        handleHideLoop () {
            this.loopVisible = false
            this.$emit('onColorLoops', { ...this.activeVariant })
        },
        handleShowLoop () {
            this.loopVisible = true
            this.$emit('onColorLoops', { ...this.activeLoop })
        },
        normalizeLoops (loops) {
            return (loops || []).map(loop => ({ ...loop, value: loop.relativeFrequency }))
        },
        normalizeLoopPaginationMax (value) {
            const max = Math.round(value / INITIAL_LOOPS_PAGINATION.limit)
            return max || 1
        },
        async getLoops (param) {
            this.isLoopsLoading = true
            this.loopVisible = true
            const self = this.loopTab === TABS.SELF_LOOPS
            const params = this.normalizeLoopParams(param, self)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(params.filters)
            params.filters = filters
            params.filterSets = filterSetsUUIDs
            params.operator = generalOperator
            apiRequest(Api().visualizations.loops({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.loops = this.normalizeLoops(data)
                    this.loopPagination.max = this.normalizeLoopPaginationMax(total)
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoopsLoading = false
                })
        },
        async getLoop (loopId) {
            const params = this.normalizeLoopParams()
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(params.filters)
            params.filters = filters
            params.filterSets = filterSetsUUIDs
            params.operator = generalOperator
            apiRequest(Api().visualizations.loop({ processId: this.processId, loopId, params }))
                .then(({ data }) => {
                    const [loop] = data || []
                    this.activeLoop = loop
                    if (this.activeVariant) {
                        this.activeLoop.activities = this.activeVariant.activities
                        this.activeLoop.frequency = this.activeVariant.frequency
                        this.activeLoop.arcs = this.activeVariant.arcs
                        this.activeLoop.duration = this.activeVariant.duration
                    }
                    this.$emit('onColorLoops', { ...this.activeLoop })
                })
                .catch(notifyError)
                .finally(() => {
                })
        },
        /* ***************************  */
        /*    VARIANTS BY FREQUENCY     */
        /* ***************************  */
        toggleFrequencyVariantFilter () {
            if (!this.isVariantFilterActive) {
                this.getFrequencyVariants()
            }
        },
        getFrequencyVariants () {
            this.isVariantsLoading = true
            this.setVariantsLoading('getFrequencyVariants', true)
            const params = { start: 0, limit: this.sliderValue }
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data.map(item => item.id)
                    this.filterByFrequencyVariant()
                })
                .catch(notifyError)
                .finally(() => {
                    this.isVariantsLoading = false
                    this.setVariantsLoading('getFrequencyVariants', false)
                })
        },
        filterByFrequencyVariant () {
            // const variantIds = this.variants.slice(0, this.sliderValue)
            this.$eventBus.emit('filterByNVariants', this.sliderValue)
        },
        handleChangeSliderValue (value) {
            this.sliderValue = value
            this.percentageValue = Math.round(value / this.maxValue * 100)
        },
        handleChangeTextInput (value) {
            this.$refs.textinput.validate(value)
            if (!this.$refs.textinput.hasError) {
                this.handleChangedModel(value)
            }
        },
        handleChangedModel (value) {
            this.handleChangeSliderValue(Number(value))
            const { variants = 1 } = this.info
            if (value > variants || value < 1) return
            const newValue = Number(value)

            this.$emit('onChangedVariant', newValue)
            // DOC: Fix animation on change big input values, added delay to move slider component
            setTimeout(() => this.getModel(newValue), 500)
        },
        async fetchModel (variant) {
            await this.getVariants()
            this.getModel(variant)
        },
        async getModel (variants) {
            const { processId } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            this.setVariantsLoading('getModel', true)
            const params = {
                variants,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                groupedActivities: this.settings?.zoomerConfig?.groupedActivities || [],
                excludedActivities: this.settings?.zoomerConfig?.excludedActivities || [],
            }
            apiRequest(Api().visualizations.model({ processId, params }))
                .then(({ data }) => {
                    const [model] = data || []
                    this.$emit('onLoadChart', { ...model, variants })
                    this.activeVariant = model
                    if (variants === 1) {
                        this.selectedMultipleVariants = this.variants.slice(0, variants)
                        this.getLoops()
                        this.activeLoopId = undefined
                    } else {
                        this.getFrequencyVariantsIds(variants)
                    }
                })
                .catch(notifyError)
                .finally(() => this.setVariantsLoading('getModel', false))
        },
        async getFrequencyVariantsIds (variantNumber) {
            const params = this.normalizeVariantParams({ limit: variantNumber })
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data }) => {
                    const variants = data
                    this.selectedMultipleVariants = variants.slice(0, variantNumber)
                    this.getLoops()
                    this.activeLoopId = undefined
                })
                .catch(notifyError)
                .finally(() => {
                })
        },
        isSelected (item) {
            return this.selectedMultipleVariants.some(v => v.id === item.id)
        },
    },
}
</script>
<style scoped lang="scss">

.scroll {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.Search {
    width: 200px;
}

.Bar {
    display: block;

    &:hover, &.selected {
        .WText { color: $black; }
        .LinearProgress { color: $accent; }
    }

    .WText {
        color: $gray-02;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 70%;
        margin-bottom: 0px;
    }

    .LinearProgress {
        color: $secondary;
        border-radius: $border-radius;
        transition: $transition;
    }
}
.QBtn {
    padding: 7px;
}

.QBtnChangeOrder {
    margin-left: 0px;
    width: 80px;
    color: var(--q-primary) !important;
}

.Stats{
    flex-wrap: nowrap;
}

.Tabs {
    margin-top: 10px;
    margin-bottom: 15px;
    &:deep() {
        .Container {
            padding: 0;
        }
        .Tab {
            min-height: auto;
        }
    }
}

.border-toggle {
    border: 1px solid $primary;
}

.transparent-select {
    background-color: $white !important;
    border: none !important;
    box-shadow: none !important;
    border-bottom: none !important;
}

.q-icon {
  transition: transform 0.5s;
}

.normal-icon {
  transform: rotate(0deg);
}

.rotate-icon {
  transform: rotate(180deg);
}
</style>
