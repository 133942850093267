export default {
    new: 'Convidar novo utilizador',
    available: 'Convites disponíveis',
    revoke: 'Revogar convite',
    cancel: 'Cancelar convite',
    hint: 'Endereço de e-mail do novo utilizador',
    valid: 'Introduzir um e-mail válido',
    modal: {
        title: 'Revogar convite',
        text: 'Tem a certeza de que pretende revogar o convite?',
    },
    notifications: {
        noResult: {
            title: 'Nenhum resultado encontrado',
            text: 'Não foram encontrados convites para a sua pesquisa.',
        },
        empty: {
            title: 'Não foram encontrados convites.',
            text: 'Pode criar o seu primeiro convite.',
        },
        canceled: {
            text: 'Convite cancelado com sucesso.',
        },
        revoked: {
            text: 'Convite revogado com sucesso.',
        },
        registered: {
            text: 'Convite registado com sucesso.',
        },
    },

    INVITED: 'CONVIDADO',
    ACCEPTED: 'ACEITE',
    CANCELLED: 'CANCELADO',
    REVOKED: 'REVOGADO',
}
