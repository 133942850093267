<template>
    <div>
        <div class="SelectContainer">
            <label class="Label">{{ $t('booster.state.overallPerformance') }} </label>
            <QSelect
                v-model="selectedAttribute"
                dense
                outlined
                class="Select"
                :options="attributes.map(i=> i.name)"
                @update:model-value="getValue"
            />
            <QSelect
                v-if="visible"
                v-model="selectedValue"
                outlined
                dense
                class="Select"
                :options="options"
                @update:model-value="getData({ attribute: selectedAttribute, value: selectedValue})"
            />
        </div>
        <div class="GraphSelectContainer">
            <div class="ChartContainer">
                <v-chart
                    ref="overallChart"
                    :option="rendimientoGlobal"
                    class="Chart"
                />
            </div>
        </div>
        <div class="PercentagesContainer">
            <QCard class="slaCompliancePercentage">
                <div
                    class="Percentage"
                    style="color: #00BF7B;"
                >
                    <strong> {{ App.numberLocationFormat(slaCompliance, true) }} % </strong>
                </div>
                <div class="Subtitle">
                    <strong>{{ slaComplianceCases }} {{ $t('booster.state.cases') }}</strong>
                </div>
            </QCard>
            <QCard class="slaNonCompliancePercentage">
                <div
                    class="Percentage"
                    style="color: #F94751;"
                >
                    <strong>{{ App.numberLocationFormat(slaNonCompliance, true) }} %</strong>
                </div>
                <div class="Subtitle">
                    <strong>{{ slaNonComplianceCases }} {{ $t('booster.state.cases') }}</strong>
                </div>
            </QCard>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
// import debounce from 'lodash'
import {
    Api, apiRequest, notifyError,
} from '@/api'

export default {
    name: 'OverallPerformance',
    components: {
    },
    inject: ['App'],
    inject: ['App'],
    props: {
        attributes: VueTypes.array,
    },
    data () {
        return {
            selectedAttribute: undefined,
            options: [],
            selectedValue: undefined,
            visible: false,
            slaCompliance: undefined,
            slaComplianceCases: undefined,
            slaNonCompliance: undefined,
            slaNonComplianceCases: undefined,
            rendimientoGlobal: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                },
                title: {
                    left: 'left',
                    top: '5%',
                    textStyle: {
                        color: '#517EA8',
                        fontSize: '1em',
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                grid: {
                    left: 45,
                    right: 15,
                    bottom: '15%',
                    top: 50,
                },
                series: {
                    name: this.$t('visualization.customPlotPanels.frequency'),
                    type: 'bar',
                    data: [],
                    itemStyle: {
                        borderRadius: [3, 3, 3, 3],
                    },
                },
            },
        }
    },
    mounted () {
        this.sel()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        sel () {
            // eslint-disable-next-line prefer-destructuring
            this.selectedAttribute = this.attributes[0].name
            this.getValue()
        },
        handleResize () {
            if (this.$refs.overallChart) {
                setTimeout(() => {
                    this.$refs.overallChart.resize()
                }, 500)
            }
        },
        getValue () {
            if (this.selectedAttribute === this.$t('booster.state.all')) {
                this.options = []
                this.visible = false
                this.getData()
            } else {
                // eslint-disable-next-line max-len
                this.options = this.attributes.find(item => item.name === this.selectedAttribute).values ? this.attributes.find(item => item.name === this.selectedAttribute).values : []
                this.selectedValue = undefined
                if (this.options.length > 0) {
                    this.visible = true
                    this.rendimientoGlobal.series.data = []
                    this.rendimientoGlobal.xAxis.data = []
                    // eslint-disable-next-line prefer-destructuring
                    this.selectedValue = this.options[0]
                } else {
                    this.visible = false
                }
                const params = {
                    attribute: this.selectedAttribute,
                    value: this.selectedValue,
                }
                this.getData(params)
            }
        },
        getData (data) {
            const { processId } = this.$route.params
            let a
            data ? a = data : a = { attribute: null, value: null }
            apiRequest(Api().booster.overall({ processId, params: a }))
                .then((res) => {
                    this.slaCompliance = res.kpis.slaCompliancePercentage
                    this.slaComplianceCases = res.kpis.slaComplianceAbsolute
                    this.slaNonCompliance = res.kpis.slaNonCompliancePercentage
                    this.slaNonComplianceCases = res.kpis.slaNonComplianceAbsolute
                    const dataRows = res.rows.map(item => ({
                        value: item.frequency,
                        itemStyle: {
                            color: item.slaCompliance ? '#40E196' : '#FF8084',
                            borderColor: item.slaCompliance ? '#00C383' : '#F40019',
                        },
                    }))
                    this.rendimientoGlobal.series.data = dataRows
                    const rangosConvertidos = res.rows.map((elemento) => {
                        const startConvertido = moment.duration(elemento.start, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
                        const finishConvertido = moment.duration(elemento.finish, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
                        return `${startConvertido} - ${finishConvertido}`
                    })

                    this.rendimientoGlobal.xAxis.data = rangosConvertidos
                })
                .catch((e) => {
                    this.slaCompliance = undefined
                    this.slaComplianceCases = undefined
                    this.slaNonCompliance = undefined
                    this.slaNonComplianceCases = undefined
                    notifyError(e)
                })
                .finally(() => {
                })
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.currentUnit = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.currentUnit = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.currentUnit = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.currentUnit = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
    },
}
</script>
<style scoped lang="scss">
.GraphSelectContainer {
    width:70%;
    height: 80%;
}
.Select {
    overflow: none;
    height: fit-content;
    margin-right: 5px;
}
.PercentagesContainer {
    width:30%;
    display: flex;
    margin:0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #fbfbfb;
    justify-content: center;
    align-items: center;
    height: 80%;
    padding: 1%;
}
.slaCompliancePercentage {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #40E196;
}
.Percentage {
    font-size: 2.8vh;
}
.Subtitle {
    font-size: 2.4vh;
}
.Label {
    margin-right: 2em;
    font-size: 15px;
    @media screen and (min-height: $md) {
        font-size: large;
    }
}
.slaNonCompliancePercentage {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #ee394a;
}
.SelectContainer {
    height: fit-content;
    display: flex;
    margin:0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

  .ChartContainer {
    width: 95%;
    height: 100%;
    background-color: #fbfbfb;
    .Chart {
        width: 95%;
        height: 95%;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }

}
</style>
