<template>
    <div
        class="ContextualInfo"
    >
        <QCard
            v-if="noFinished && (noRisk === undefined) && (risk === undefined)"
            flat
            class="Card"
        >
            <QCardSection class="text-weight-bold text-center MetricTitle">
                {{ $t(`visualization.predictions.unifinishedCases`) }}
            </QCardSection>
            <QCardSection class="text-center text-weight-bold q-pt-none text-grey-8 MetricNumber">
                {{ App.numberLocationFormat(noFinished) }}
            </QCardSection>
        </QCard>
        <QCard
            v-if="noRisk != undefined"
            flat
            class="Card"
        >
            <QCardSection class="text-weight-bold text-center MetricTitle">
                {{ $t(`visualization.predictions.noRisk`) }}
            </QCardSection>
            <QCardSection class="text-center text-weight-bold q-pt-none text-grey-8 MetricNumber">
                {{ `${App.numberLocationFormat(noRisk)} (${(100*noRiskPercentage).toFixed(0)}%)` }}
            </QCardSection>
            <QCardSection class="q-pa-none">
                <QLinearProgress
                    size="5px"
                    :value="noRiskPercentage"
                    color="primary"
                    trackColor="transparent"
                />
            </QCardSection>
        </QCard>
        <QCard
            v-if="risk != undefined"
            flat
            class="Card"
        >
            <QCardSection class="text-weight-bold text-center MetricTitle">
                {{ $t(`visualization.predictions.risk`) }}
            </QCardSection>
            <QCardSection class="text-center text-weight-bold q-pt-none text-negative MetricNumber">
                {{ `${App.numberLocationFormat(risk)} (${(100*riskPercentage).toFixed(0)}%)` }}
            </QCardSection>
            <QCardSection class="q-pa-none">
                <QLinearProgress
                    size="5px"
                    :value="riskPercentage"
                    color="negative"
                    trackColor="transparent"
                />
            </QCardSection>
        </QCard>

        <!-- <QExpansionItem
            v-if="caseId"
            defaultOpened
            dark
            dense
            denseToggle
            expandSeparator
            class="metricsTitle"
            :label="$t('visualization.predictions.caseMetrics')"
        >
            <div class="CaseMetrics">
                <div class="metricsContainer">
                    <span class="ContextualElementName">{{ $t(`visualization.predictions.caseId`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ caseId }}
                        </div>
                    </QCard>
                </div>
                <div class="metricsContainer">
                    <span class="ContextualElementName">{{ $t(`visualization.predictions.estimatedDuration`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ estimatedDuration }}
                        </div>
                    </QCard>
                </div>
                <div class="metricsContainer">
                    <span class="ContextualElementName">{{ $t(`visualization.predictions.riskLevel`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ riskLevel }}
                        </div>
                    </QCard>
                </div>
                <div class="metricsContainer">
                    <span class="ContextualElementName">{{ $t(`visualization.predictions.nextActivity`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ nextActivity }}
                        </div>
                    </QCard>
                </div>
            </div>
        </QExpansionItem> !-->
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'PredictionMetricsPanel',
    components: {

    },
    inject: ['App'],
    props: {
        caseId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        noFinished: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        noRisk: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        risk: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        estimatedDuration: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        riskLevel: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        nextActivity: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    computed: {
        noRiskPercentage () {
            return this.noRisk / this.noFinished
        },
        riskPercentage () {
            return this.risk / this.noFinished
        },
    },
    mounted () {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>

.ContextualInfo {
    border: 1px solid #e6e6f2;
    display: flex;
    justify-content: space-around;
    border-radius: 5px;
}
    .q-card__section--vert {
        padding-top:0.5vh;
        padding-bottom:0.5vh;
    }
.MetricTitle {
    font-size: 2.6vh;
    @media screen and (max-height: $md) {
        font-size: 16px;
    }
}
.MetricNumber {
    font-size: 2.7vh;
    @media screen and (max-height: $md) {
        font-size: 17px;
    }
}

</style>
