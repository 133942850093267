<template>
    <div
        class="EmptyList"
        :class="[ dynamicClass, staticClass ]"
        :style="dynamicStyle"
    >
        <slot name="header" />
        <q-icon
            v-if="icon"
            :name="icon"
            :color="iconColor"
            :size="iconSize"
        />
        <WText
            v-if="title"
            :color="color"
            size="20"
            align="center"
        >
            {{ title }}
        </WText>
        <WText
            v-if="text"
            :color="color"
            size="14"
            align="center"
            class="Text"
        >
            {{ text }}
        </WText>
        <slot name="footer" />
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'EmptyList',
    props: {
        title: VueTypes.string,
        text: VueTypes.string,
        color: VueTypes.string,
        icon: VueTypes.string,
        iconColor: VueTypes.string,
        iconSize: VueTypes.number.def(40),
        dynamicClass: VueTypes.string.def(''),
        staticClass: VueTypes.string.def(''),
        dynamicStyle: VueTypes.object.def({}),
    },
}
</script>
<style scoped lang="scss">
.EmptyList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 15px;

	.WText {
		&.Text {
			margin-bottom: 0px;
		}
	}
}
</style>
