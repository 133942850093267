<template>
    <div>
        <WText
            class="q-mb-sm"
            :size="18"
        >
            {{ $t('booster.alerts.definition.createScreen.newAlert') }}
        </WText>
        <q-card
            flat
            bordered
            class="q-mb-sm q-pa-md"
        >
            <div
                class="q-pa-md"
            >
                <div
                    class="row q-gutter-md items-center"
                >
                    <div class="col-sm-12">
                        <QInput
                            ref="name"
                            v-model="nameFld"
                            lazyRules
                            outlined
                            stackLabel
                            maxlength="100"
                            :label="$t('booster.alerts.definition.name')"
                            :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.name') })]"
                        />
                    </div>
                    <div class="col-sm-12">
                        <QField
                            :label="$t('booster.alerts.definition.type')"
                            stackLabel
                            borderless
                        >
                            <template #control>
                                <QBtnToggle
                                    v-model="typeTgl"
                                    toggleColor="primary"
                                    unelevated
                                    noCaps
                                    :options="typeOptions"
                                    class="q-mt-sm"
                                    @update:model-value="setDefaultSeverity"
                                />
                            </template>
                        </QField>
                    </div>
                    <div class="col-sm-12">
                        <QField
                            :label="$t('booster.alerts.definition.severity')"
                            stackLabel
                            borderless
                        >
                            <template #control>
                                <QBtnToggle
                                    v-model="severityTgl"
                                    :toggleColor="getSeverityColor(severityTgl)"
                                    toggleTextColor="black"
                                    push
                                    unelevated
                                    noCaps
                                    :options="typeTgl === 'PREDICTION' ? riskOptions: severityOptions"
                                    class="q-mt-sm"
                                />
                            </template>
                        </QField>
                    </div>
                    <div
                        v-if="typeTgl === 'STANDARD'"
                        class="col-sm-12"
                    >
                        <QSelect
                            ref="filters"
                            v-model="fSet"
                            :options="formattedFilterSets"
                            :label="$t('booster.alerts.definition.filterSet')"
                            stackLabel
                            :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.filterSet') })]"
                        />
                    </div>
                    <div
                        v-if="typeTgl === 'TIME'"
                        class="col-sm-12"
                    >
                        <div
                            class="row"
                            style="gap: 30px;"
                        >
                            <QSelect
                                ref="activity"
                                v-model="activity"
                                :options="formatedActivities"
                                stackLabel
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.filterSet') })]"
                            />
                            <QSelect
                                ref="filters"
                                v-model="period"
                                :options="activityPeriod(activity)"
                                stackLabel
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.filterSet') })]"
                            />
                        </div>
                        <div
                            class="row"
                            style="gap: 30px;"
                        >
                            <QInput
                                ref="time"
                                v-model="time"
                                lazyRules
                                outlined
                                stackLabel
                                maxlength="100"
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.name') })]"
                                @update:modelValue="handleInputTime"
                            />
                            <QSelect
                                ref="unit"
                                v-model="unit"
                                :options="timeUnits"
                                stackLabel
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.filterSet') })]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </q-card>
        <q-card
            flat
            bordered
            class="q-mb-sm q-pa-md"
        >
            <QField
                :label="$t('booster.alerts.definition.createScreen.sendEmail')"
                stackLabel
                borderless
            >
                <template #control>
                    <QBtnToggle
                        v-model="sendTgl"
                        toggleColor="primary"
                        unelevated
                        noCaps
                        :options="sendOptions"
                        class="q-mt-sm"
                    />
                </template>
            </QField>
            <Transition
                mode="out-in"
                appear
            >
                <div v-if="sendTgl === 'other'">
                    <QInput
                        ref="mail"
                        v-model="emailAdrs"
                        lazyRules
                        outlined
                        stackLabel
                        maxlength="100"
                        :label="$t('booster.alerts.definition.createScreen.notifyTo')"
                        :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.createScreen.notifyTo') })]"
                    />
                </div>
            </Transition>
        </q-card>
        <div class="flex q-gutter-md justify-end">
            <QBtn
                :label="$t('booster.alerts.definition.createScreen.cancel')"
                color="primary"
                noCaps
                outline
                @click="cancel"
            />
            <QBtn
                :label="$t('booster.alerts.definition.createScreen.addAlert')"
                color="primary"
                noCaps
                :disable="!saveBtnStatus"
                @click="create"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError,
} from '@/api'

const DEFAULT_SEVERITY = 'LOW'
const DEFAULT_SEND = 'user'
const DEFAULT_TYPE = 'STANDARD'

export default {
    name: 'NewAlert',
    mixins: [validatorsMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filterSets: VueTypes.array,
    },
    emits: ['create', 'cancel'],
    data () {
        return {
            DEFAULT_TYPE,
            nameFld: undefined,
            fSet: undefined,
            emailAdrs: null,
            severityTgl: DEFAULT_SEVERITY,
            typeTgl: DEFAULT_TYPE,
            sendTgl: DEFAULT_SEND,
            saveBtnStatus: true,
            activity: this.$t('booster.alerts.definition.activity'),
            period: this.$t('booster.alerts.definition.period'),
            time: 0,
            unit: {
                value: 'minutes',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            activities: [],
            formatedActivities: [],
        }
    },
    computed: {
        severityOptions () {
            return [{
                value: 'LOW',
                label: this.$t('booster.alerts.detected.severityRiskLabels.LOW'),
            }, {
                value: 'MEDIUM',
                label: this.$t('booster.alerts.detected.severityRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('booster.alerts.detected.severityRiskLabels.HIGH'),
            }]
        },
        riskOptions () {
            return [{
                value: 'MEDIUM',
                label: this.$t('booster.alerts.detected.predictionRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('booster.alerts.detected.predictionRiskLabels.HIGH'),
            }]
        },
        typeOptions () {
            return [{
                value: 'STANDARD',
                label: this.$t('booster.alerts.definition.types.STANDARD'),
            },
            {
                value: 'PREDICTION',
                label: this.$t('booster.alerts.definition.types.PREDICTION'),
            },
            {
                value: 'TIME',
                label: this.$t('booster.alerts.definition.types.TIME'),
            }]
        },
        sendOptions () {
            return [{
                value: 'user',
                label: this.$t('booster.alerts.definition.createScreen.user'),
            },
            {
                value: 'other',
                label: this.$t('booster.alerts.definition.createScreen.other'),
            },
            {
                value: 'notSend',
                label: this.$t('booster.alerts.definition.createScreen.notSend'),
            }]
        },
        formattedFilterSets () {
            return this.filterSets.map(fSet => ({ label: fSet.name, value: fSet.uuid }))
        },
        timeUnits () {
            return [{
                value: 'seconds',
                label: this.$t('booster.alerts.definition.timeUnit.seconds'),
            },
            {
                value: 'minutes',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            {
                value: 'hours',
                label: this.$t('booster.alerts.definition.timeUnit.hours'),
            },
            {
                value: 'days',
                label: this.$t('booster.alerts.definition.timeUnit.days'),
            },
            {
                value: 'weeks',
                label: this.$t('booster.alerts.definition.timeUnit.weeks'),
            },
            {
                value: 'months',
                label: this.$t('booster.alerts.definition.timeUnit.months'),
            }]
        },
    },
    mounted () {
        this.isLoading = true
        const datasetId = this.processId
        apiRequest(Api().alerts.activities({ datasetId }))
            .then((data) => {
                this.activities = data
                this.formatedActivities = data.map(el => ({
                    value: el.activity,
                    label: el.activity,
                }))
            })
            .catch(notifyError)
            .finally(() => (this.isLoading = false))
    },
    methods: {
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        setDefaultSeverity () {
            this.severityTgl = this.typeTgl === DEFAULT_TYPE ? 'LOW' : 'MEDIUM'
        },
        cancel () {
            this.$emit('cancel')
        },
        hasValidationErrors () {
            this.$refs.name.validate()
            let errorCheck
            if (this.typeTgl === 'STANDARD') {
                this.$refs.filters.validate()
                errorCheck = this.$refs.filters.hasError
            }
            errorCheck = this.$refs.name.hasError
            if (this.sendTgl === 'other') {
                if (this.$refs.mail) {
                    this.$refs.mail.validate()
                }
                errorCheck = errorCheck || this.$refs.mail.hasError
            }
            return errorCheck
        },
        create () {
            if (!this.hasValidationErrors()) {
                this.saveBtnStatus = false
                const data = {
                    name: this.nameFld,
                    filterSets: this.typeTgl === 'STANDARD' ? [this.fSet.label] : [],
                    severity: this.severityTgl,
                    riskSLA: this.typeTgl === 'PREDICTION' ? this.severityTgl : null,
                    type: this.typeTgl,
                    mailToCreatingUser: this.sendTgl === 'user',
                    mailToOtherUser: this.sendTgl === 'other',
                    mailToNotify: this.emailAdrs ? this.emailAdrs : '',
                    options: {
                        activity: this.activity.value,
                        hasEnded: this.period === 'hasEnded',
                        number: Number(this.time),
                        unit: this.unit.value,
                    },
                }
                this.$emit('create', data)
            } else {
                notifyError({ message: this.$t('booster.alerts.notifications.validateError') })
            }
        },
        activityPeriod (activity) {
            if (activity !== '') {
                const act = this.activities.filter(el => el.activity === activity.value)
                if (act.length > 0 && act[0].hasEnded) {
                    return [
                        {
                            value: 'hasStarted',
                            label: this.$t('booster.alerts.definition.activityPeriod.hasStarted'),
                        },
                        {
                            value: 'hasEnded',
                            label: this.$t('booster.alerts.definition.activityPeriod.hasEnded'),
                        },
                    ]
                }
            }
            return [
                {
                    value: 'hasStarted',
                    label: this.$t('booster.alerts.definition.activityPeriod.hasStarted'),
                },
            ]
        },
        handleInputTime (event) {
            this.time = event.replace(/[^\d]/g, '')
        },
    },
}
</script>
<style scoped lang="scss">
.toggle {
  border: 1px solid $primary
}
.AlertTypes {
        padding: 0;
}
</style>
