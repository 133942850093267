<template>
    <Modal
        :isOpen="opened"
        @close="opened = false"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('settings.token.modal.title') }}
            </WText>
        </template>
        <template #body>
            <div
                class="flex col-top-stretch"
            >
                <div class="Form mb-1">
                    <QInput
                        ref="name"
                        v-model="userInfo.name"
                        outlined
                        class="Field mb-1"
                        label="Email"
                        type="email"
                        :rules="[value => validators.validEmail(value) || $t('invitations.valid')]"
                    />
                    <QInput
                        v-model="userInfo.password"
                        outlined
                        :type="isPwd ? 'text' : 'password'"
                        :label="$t('settings.token.modal.password')"
                    >
                        <template #append>
                            <q-icon
                                :name="isPwd ? 'visibility': 'visibility_off'"
                                size="16px"
                                clickable
                                style="cursor: pointer;"
                                @click="isPwd = !isPwd"
                            />
                        </template>
                    </QInput>
                </div>
                <QBtn
                    id="submit"
                    class="ml-1"
                    color="primary"
                    noCaps
                    :label="$t('settings.token.modal.btn')"
                    @click="handleSubmit"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue'
import { validatorsMixin } from '@/mixins'

const INITAL_STATE = {
    name: '',
    password: '',
}

export default {
    name: 'DocModal',
    components: {
        Modal,
    },
    mixins: [validatorsMixin],
    emits: ['onSubmit'],
    data () {
        return {
            isPwd: false,
            opened: false,
            userInfo: { ...INITAL_STATE },
        }
    },
    methods: {
        open () {
            this.reset(this.userInfo)
            this.opened = true
        },
        reset (userInfo) {
            const { name, password } = userInfo
            if (!!name && !!password) {
                this.userInfo = { ...INITAL_STATE }
            }
        },
        close () {
            this.opened = false
        },
        handleSubmit (form) {
            this.opened = false
            this.$emit('onSubmit', this.userInfo)
        },
    },
}
</script>

<style lang="scss" scoped>
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 500px;
    }
    &:deep(.Body) {
        margin: 30px 0;
    }
}
</style>
