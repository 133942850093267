<template>
    <div class="row q-ma-md">
        <div v-if="!predictionNotAvailable">
            <div>
                <div
                    v-if="!isFullResult && all.length < 1"
                    class="LackData"
                >
                    <div
                        class="Warning rounded-borders q-pa-md"
                    >
                        <QIcon
                            class="Icon"
                            name="warning"
                            color="white"
                            size="40px"
                        />
                        <WText class="CenteredText">
                            {{ $t('visualization.predictions.notAvailable') }}
                        </WText>
                    </div>
                </div>
                <div v-if="interval">
                    {{ $t('visualization.predictions.loadingMessage') }}
                </div>
            </div>
            <div
                v-if="all.length > 0"
                class="PredictionList col-sm-9"
            >
                <div
                    v-if="ready"
                    class="row q-gutter-sm"
                >
                    <PredictionMetricsPanel
                        class="col"
                        :noFinished="total"
                    />
                    <PredictionMetricsPanel
                        class="col"
                        :noRisk="noRisk"
                        :noFinished="total"
                    />
                    <PredictionMetricsPanel
                        class="col"
                        :risk="risk"
                        :noFinished="total"
                    />
                    <QIcon
                        v-if="!isFullResult"
                        class="WarningIcon"
                        name="warning"
                        :style="{ color: '#ffbc04' }"
                        size="40px"
                    >
                        <QTooltip
                            class="bg-amber text-black shadow-4"
                            anchor="center left"
                            self="center right"
                            :offset="[10, 10]"
                            :style="{ fontSize: '12px' }"
                        >
                            {{ $t('visualization.predictions.someNotAvailable') }}
                        </QTooltip>
                    </QIcon>
                </div>
            </div>
            <div
                v-if="all.length > 0"
                class="PredictionList col-12 row"
            >
                <PredictionTable
                    v-if="ready"
                    ref="table"
                    class="col-sm-9 PredictionTable"
                    :columns="columns"
                    :rows="rows"
                    :loading="isLoading"
                    :total="total"
                    @download="handleDownload"
                    @selected-id="caseMetrics"
                    @view-trace="viewTrace"
                    @update="update"
                    @ready="selectFirstRow"
                />
                <div class="col-sm-3 GraphColumn">
                    <div
                        ref="view-wrapper"
                        class="GraphHolder flex items-center q-ml-md"
                    >
                        <Graph
                            v-if="!newVariant && chart && (chart.activities || []).length && (Object.keys(chart) || []).length"
                            ref="chart"
                            :data="chart"
                            :model="model"
                            simple
                            parent="view-wrapper"
                            class="Graph"
                        />
                        <div
                            v-if="newVariant"
                            class="col text-center text-h6"
                        >
                            {{ $t('visualization.predictions.newVariant') }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="ready && all.length < 1 && isFullResult">
                <!-- When no data from back, introduce message-->
                {{ $t('visualization.predictions.noData') }}
            </div>
        </div>
        <div v-else>
            <div class="flex flex-col justify-center items-center q-ma-md">
                <div>
                    <WText
                        :size="18"
                    >
                        {{ predictionNotAvailable }}
                    </WText>
                </div>
                <div>
                    <WText
                        :size="15"
                    >
                        {{ $t('visualization.predictions.noPredictionsText') }}
                    </WText>
                </div>
                <div class="mt-2">
                    <QBtn
                        color="primary"
                        noCaps
                        icon="settings"
                        :label="$t('visualization.tabs.settings')"
                        @click="handleGoToSettings"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
    returnError,
} from '@/api'
import { filtersStorageMixin } from '@/mixins'
import { download } from '@/services/download'
import Graph from '@/components/Graph/Graph.vue'
import PredictionTable from './PredictionTable.vue'
import { PredictionMetricsPanel } from '../components'

const DEFAULT_TAB = 'TABLE'
const INITIAL_MODEL = {
    variant: 1,
    variants: 1,
    heatMapValue: 'none',
    directionValue: 'TB',
    statsValue: 'averages',
    values: 'freq',
}
export default {
    name: 'Predictions',
    components: {
        PredictionMetricsPanel,
        PredictionTable,
        Graph,
    },
    mixins: [filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        settings: VueTypes.any,
    },
    emits: ['goToSettingsTab', 'viewTrace'],
    data () {
        return {
            attributes: [],
            columns: [],
            rows: [],
            total: 0,
            isLoading: false,
            tab: DEFAULT_TAB,
            activities: null,
            colors: null,
            ready: false,
            risk: 0,
            noRisk: 0,
            allTraces: [],
            estimatedDuration: '-',
            estimatedVariant: '-',
            riskLevel: '-',
            nextActivity: '-',
            caseId: '-',
            all: '',
            flag: false,
            chart: undefined,
            model: INITIAL_MODEL,
            newVariant: false,
            interval: null,
            predictionNotAvailable: false,
            isFullResult: true,
            data: {
                sortBy: 'caseId',
                sortDirection: 'DESCENDING',
                limit: '30',
                start: 0,
            },
            fail: true,
        }
    },
    watch: {
        settings (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.checkSettingsAndFetchData()
            }
        },
    },
    mounted () {
        this.checkSettingsAndFetchData()
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        checkSettingsAndFetchData () {
            if (this.processId && this.settings) {
                const errorMessage = this.getErrorMessage()
                if (errorMessage) {
                    this.predictionNotAvailable = errorMessage
                } else {
                    this.getPrediction()
                }
            }
        },
        update (date) {
            this.data = {
                sortBy: date.sortBy,
                sortDirection: date.descending ? 'DESCENDING' : 'ASCENDING',
                limit: date.rowsPerPage,
                start: date.page - 1,
            }
            this.isLoading = true
            this.getPrediction()
        },
        caseMetrics (id) {
            this.estimatedDuration = id.estimatedDuration
            this.estimatedVariant = id.estimatedVariant
            this.riskLevel = id.riskLevel
            this.caseId = id.caseId
            const nextAc = id.nextActivities[0]
            nextAc === undefined ? this.nextActivity = '-' : this.nextActivity = nextAc
            if (this.riskLevel >= 0) {
                this.newVariant = false
                this.getTraceIdPrediction()
            } else {
                this.handleNewVariant()
            }
            if (this.estimatedVariant === 'New variant') {
                this.handleNewVariant()
            }
        },
        selectFirstRow () {
            this.newVariant = false
            if (this.rows.length !== 0) {
                if (this.rows.table) this.$refs.table.selectRow(this.rows[0])
                this.caseMetrics(this.rows[0])
            }
        },
        handleNewVariant () {
            this.newVariant = true
        },
        handleGoToSettings () {
            this.$emit('goToSettingsTab')
        },
        getErrorMessage () {
            let errorMessage = ''
            if (!this.checkCaseCompletion() && this.settings.sla === null) {
                errorMessage = this.$t('visualization.predictions.slaAndCaseNotDefined')
            } else if (this.checkCaseCompletion() && this.settings.sla === null) {
                errorMessage = this.$t('visualization.predictions.slaNotDefined')
            } else if (!this.checkCaseCompletion() && this.settings.sla !== null) {
                errorMessage = this.$t('visualization.predictions.caseCompletionNotDefined')
            }
            return errorMessage
        },
        checkCaseCompletion () {
            if (this.settings.caseCompletion === null) {
                return false
            }
            if (this.settings.caseCompletion.startingActivities.length === 0 &&
                this.settings.caseCompletion.occurringActivities.length === 0 &&
                this.settings.caseCompletion.endingActivities.length === 0) {
                return false
            }
            return true
        },
        getPrediction () {
            apiRequest(Api().visualizations.predictions({ processId: this.$route.params.processId, params: this.data }))
                .then(({
                    data, total, noRisk, risk, isFullResult,
                }) => {
                    this.noRisk = noRisk
                    this.risk = risk
                    this.all = data
                    if (data.length) {
                        const keys = Object.keys(data[0]).filter(key => key !== 'nextActivities')
                        this.attributes = keys.map(value => ({ name: value }))
                    }
                    this.rows = this.formatRows(data)
                    this.columns = this.formatColumns(data)
                    this.isFullResult = isFullResult
                    this.total = total
                })
                .catch((error) => {
                    const errorCode = returnError(error)
                    if (errorCode.status === 404) {
                        const errorMessage = this.getErrorMessage()
                        this.predictionNotAvailable = errorMessage !== '' ? errorMessage : errorCode.message
                    } else if (errorCode.status === 503 && !this.interval) {
                        this.interval = setInterval(this.getPrediction, 5000)
                    }
                })
                .finally(() => {
                    if (this.all) {
                        clearInterval(this.interval)
                        this.ready = true
                    }
                    this.isLoading = false
                })
        },
        getTraceIdPrediction () {
            this.isLoading = true
            apiRequest(Api().visualizations.predictionTrace({ processId: this.$route.params.processId, traceId: this.caseId, params: this.filter }))
                .then(({
                    data,
                }) => {
                    const [destrData] = data
                    const formattedData = this.formatChartData(destrData)
                    this.chart = data ? { ...formattedData } : undefined
                })
                .catch(notifyError)
                .finally(() => {
                    this.ready = true
                    this.isLoading = false
                })
        },
        formatChartData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },
        formatRows (data) {
            return data.map((obj) => {
                const formattedObj = {}

                Object.entries(obj).forEach(([key, value]) => {
                    if ((key === 'estimatedDuration') || (key === 'currentDuration')) {
                        formattedObj[key] = this.formatDate(value)
                    } else {
                        formattedObj[key] = value
                    }
                })

                return formattedObj
            })
        },
        formatDate (date) {
            return moment.duration(date, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
        },
        formatColumns (data) {
            if (data.length === 0) {
                return []
            }
            const firstItem = data[0]
            const valueToRemove = 'nextActivities'
            const backgroundValues = ['riskLevel', 'currentDuration', 'estimatedDuration', 'estimatedVariant', 'lastActivity']
            const keys = Object.keys(firstItem)
                .filter(item => item !== valueToRemove)
                .map(item => ({
                    value: item,
                    type: backgroundValues.includes(item) ? 'primary' : 'other',
                }))
            return this.formatField(keys)
        },
        formatField (field) {
            const finalArray = field.map(values => ({
                name: values.value,
                required: true,
                label: this.$t(`visualization.predictions.${values.value}`),
                field: values.value,
                align: 'center',
                sortable: true,
                headerClasses: `${values.type === 'primary' ? 'bg-grey-4' : 'bg-grey-3'}`,
            }))
            return finalArray
        },
        openExport () {
            this.$refs.attrSelector.open()
        },
        convertToCSV (data, selectedColumns) {
            const columnNames = Object.keys(data[0])
                .filter(key => selectedColumns.includes(key))

            const csvRows = [columnNames.map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(',')]
            data.forEach((obj) => {
                const rowValues = columnNames.map(key => obj[key])
                csvRows.push(rowValues.join(','))
            })

            const csvContent = csvRows.join('\n')
            return csvContent
        },
        handleDownload () {
            this.isLoading = true
            const csvData = {
                sortBy: this.data.sortBy,
                sortDirection: this.data.sortDirection,
                limit: this.total,
                start: 0,
            }
            const attributes = this.columns.map(name => name.field)
            apiRequest(Api().visualizations.predictions({ processId: this.$route.params.processId, params: csvData }))
                .then(({
                    data,
                }) => {
                    this.allTraces = data
                })
                .catch(notifyError)
                .finally(() => {
                    download({ name: this.$route.params.processId, data: this.convertToCSV(this.allTraces, attributes) })
                    notifySuccess(this.$t('visualization.traces.downloadOK'), (this.isLoading = false))
                })
        },
        viewTrace (e) {
            this.$emit('viewTrace', e)
        },
    },
}
</script>

<style lang="scss" scoped>
    .q-ma-md {
        margin-bottom: 0px !important;
    }
    .PredictionTable{
        margin-top: 15px !important;
    }
    .GraphColumn{
        padding-top: 15px;
    }
    .GraphHolder{
        border: 1px solid #e6e6f2;
        height: 100%;
    }
    .PredictionList{
        max-width: 100%;
    }
    .Graph {
        &:deep() {
            .Graph {
                max-height: calc(64vh - $sidebar-height);
                @media screen and (min-height: 834px) {
                    max-height:calc(68vh - $sidebar-height);
                }
            }
        }
    }
    .Warning {
        width: 80%;
        background-color: #ffbc04;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .Icon {
            margin-left: 1vw;
            margin-right: 1vw;
        }
        .CenteredText {
            text-align: center;
            flex: 1;
            font-weight: bold;
        }
    }
    .LackData {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    // .WarningIcon {
    //     color: #ffbc04;
    // }
</style>
