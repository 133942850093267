<template>
    <div class="Step">
        <QForm ref="form">
            <div class="row justify-center items-start">
                <QInput
                    v-model="localValue.report.name"
                    autogrow
                    class="col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md"
                    clearable
                    outlined
                    :label="$t('wizard.report.name')"
                    lazyRules
                    :rules="[
                        value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.report.name') }),
                        value => validators.betweenLength(value, 5, 100) || $t('validation.rangeLength', { attribute: $t('wizard.report.name'), min: 5, max: 100 }),
                        value => validators.allowedChars(value) || $t('validation.allowedChars', { attribute: $t('wizard.report.name') }),
                    ]"
                />
            </div>
            <div class="row justify-center items-start">
                <QSelect
                    v-model="localValue.report.type"
                    class="col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md"
                    lazyRules
                    outlined
                    behavior="menu"
                    :label="$t('wizard.report.type')"
                    :options="normalizeReportTypes"
                />
            </div>
            <div class="row justify-center items-start">
                <QInput
                    v-model.trim="localValue.report.description"
                    type="textarea"
                    outlined
                    class="col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md"
                    :hint="$t('general.optional')"
                    :label="$t('general.description')"
                    lazyRules
                    maxlength="500"
                    :rules="[value => (
                        validators.optional(value) ||
                        validators.maxLength(value, 500)) ||
                        $t('validation.maxLength', { attribute: $t('general.description'), max: 500 })
                    ]"
                />
            </div>
        </QForm>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { validatorsMixin } from '@/mixins'

const REPORT_TYPES = [{
    name: 'Standard discovery',
    identifier: 'STANDARD_DISCOVERY',
}]

export default {
    name: 'StepReport',
    mixins: [validatorsMixin],
    props: {
        datasetId: VueTypes.string,
        value: VueTypes.shape({
            definition: VueTypes.shape({
                mode: VueTypes.string.isRequired,
            }).loose,
            report: VueTypes.any.isRequired,
        }).loose,
    },
    data () {
        return {
            reportType: undefined,
            reportTypes: undefined,
            isLoading: false,
            localValue: this.value,
        }
    },
    computed: {
        normalizeReportTypes () {
            return (this.reportTypes || []).map(({ name, identifier }) => ({ label: name, value: identifier }))
        },
    },
    watch: {
        value (value) {
            this.localValue = value
        },
    },
    mounted () {
        const { definition } = this.value || {}
        if (definition?.mode) this.getModeReportTypes(definition.mode)
        else this.reportTypes = REPORT_TYPES
    },
    methods: {
        isValid () {
            return this.$refs.form.validate().then(result => Promise.resolve(result))
        },
        async getModeReportTypes (modeId) {
            this.isLoading = true
            apiRequest(Api().queries.modeReportTypes({ modeId }))
                .then(data => (this.reportTypes = data))
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
