import Transforms from './transforms'

const endpoints = {
    list: {
        path: '/datasets',
        transform: Transforms.list,
    },
    create: {
        method: 'POST',
        path: '/datasets',
    },
    get: {
        path: '/datasets/:projectId',
        transform: Transforms.get,
    },
    workflows: {
        method: 'POST',
        path: '/datasets/importers/:importer/workflows',
    },
    validateJdbc: {
        method: 'POST',
        path: '/datasets/importers/jdbc/validate',
    },
    activities: {
        method: 'POST',
        path: '/datasets/:datasetId/activities',
        transform: Transforms.activities,
    },
    finishUpload: {
        method: 'POST',
        path: '/datasets/:datasetId/finishUpload',
    },
    getConfigurations: {
        method: 'GET',
        path: '/datasets/:datasetId/configurations',
    },
}

export default {
    name: 'datasets',
    endpoints,
}
