<template>
    <div>
        <div class="Container">
            <div class="Title">
                {{ $t('booster.prediction.predictionHighlights.name') }}
            </div>
            <PredictionBoosterMetricsPanel
                class="MetricsPanel"
                :predictionHighlights="predictionHighlights"
                :highlightsLoading="highlightsLoading"
            />
        </div>
        <div>
            <QCard />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import PredictionBoosterMetricsPanel from './PredictionBoosterMetricsPanel.vue'

export default {
    name: 'PredictionHighlights',
    components: { PredictionBoosterMetricsPanel },
    props: {
        predictionHighlights: VueTypes.object,
        highlightsLoading: VueTypes.bool,
    },
    methods: { },
}
</script>
<style scoped lang="scss">
    .Container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .Title {
        margin: 1vh 0px;
        margin-right: 2em;
        font-size: 15px;
        @media screen and (min-height: $md) {
            font-size: large;
        }
        height: fit-content;
    }
    .MetricsPanel {
        flex-grow: 1;
    }
</style>
