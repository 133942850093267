<template>
    <div style="display: flex; min-width: fit-content;">
        <div>
            <div class="row q-col-gutter-sm">
                <div
                    v-for="(type, index) in ['cases', 'variants']"
                    :key="index"
                    :class="type === 'cases' ? 'Cases' : 'VariantsSidebar'"
                >
                    <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.${type}`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <QLinearProgress
                            size="35px"
                            :value="parseFloat(dictFormattedData[type][`${displayMode}DataPercentage`])"
                            color="secondary"
                            trackColor="grey"
                        >
                            <div class="absolute-full flex flex-center">
                                <QBadge
                                    color="white"
                                    textColor="secondary"
                                    class="flex flex-center"
                                >
                                    <span class="q-px-sm text-center text-weight-bold text-caption text-black">
                                        {{ dictFormattedData[type][`${displayMode}Data`] }}
                                    </span>
                                    {{ (dictFormattedData[type][`${displayMode}DataPercentage`] * 100).toFixed(0) }}%
                                </QBadge>
                            </div>
                        </QLinearProgress>
                    </QCard>
                </div>
                <div class="Duration">
                    <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.duration`) }} ({{ durMode.value }}) </span>
                    <QCard
                        flat
                    >
                        <div class="text-center text-weight-bold">
                            <QFab
                                v-model="displayDurationOptions"
                                :label="dictFormattedData[`${durMode.value}`][`${displayMode}Data`]"
                                square
                                dense
                                flat
                                class="text-caption"
                                labelPosition="left"
                                textColor="black"
                                icon="keyboard_arrow_down"
                                direction="up"
                                padding="sm"
                            >
                                <QTooltip v-if="!displayDurationOptions">
                                    {{ $t('visualization.filters.sidebarTooltips.durationMetric') }}
                                </QTooltip>
                                <QFabAction
                                    v-for="option in durOptions"
                                    :key="option.value"
                                    textColor="primary"
                                    color="grey-2"
                                    :label="option.label"
                                    padding="xs"
                                    @click="setDurationOption(option)"
                                />
                            </QFab>
                        </div>
                    </QCard>
                </div>
            </div>
        </div>
        <div
            class=""
        >
            <div
                class="DataContainer"
            >
                <div
                    v-for="(type, index) in ['minStartDateTime', 'maxEndDateTime']"
                    :key="index"
                    class="Data"
                >
                    <QInput
                        v-model="dictFormattedData[type][`${displayMode}Data`]"
                        :disable="readOnly"
                        outlined
                        standout
                        :class="type === 'minStartDateTime' ? 'q-pa-none custom-cursor' : 'custom-cursor'"
                        bgColor="white"
                        dense
                        @click="type === 'minStartDateTime' ? openStartDateModal=true : openEndDateModal=true"
                    >
                        <QIcon
                            name="event"
                            class="cursor-pointer self-center"
                        >
                            <QPopupProxy
                                transitionShow="scale"
                                transitionHide="scale"
                                :value="type === 'minStartDateTime' ? openStartDateModal : openEndDateModal"
                                @update:modelValue="val => { type === 'minStartDateTime' ? openStartDateModal = val : openEndDateModal = val; }"
                            >
                                <QDate
                                    v-if="datetimeFilters"
                                    v-model="filterDate[type === 'minStartDateTime' ? 'start' : 'end']"
                                    mask="YYYY/MM/DD"
                                    :options="date => type === 'minStartDateTime' ? date < filterDate.end && date > formatDateString(dictFormattedData.minStartDateTime.totalData) :
                                        date > filterDate.start && date < formatDateString(dictFormattedData.maxEndDateTime.totalData)"
                                    @update:modelValue="((date)=> inputDate(date, type === 'minStartDateTime' ? 'TRACE_STARTS_AFTER' : 'TRACE_ENDS_BEFORE'))"
                                >
                                    <div class="row items-center justify-end">
                                        <QBtn
                                            :label="$t('visualization.filters.reset')"
                                            size="sm"
                                            color="primary"
                                            noCaps
                                            :disable="readOnly"
                                            @click="resetFilters"
                                        >
                                            <QIcon
                                                name="restart_alt"
                                                size="15px"
                                                right
                                            />
                                        </QBtn>
                                    </div>
                                </QDate>
                            </QPopupProxy>
                        </QIcon>
                    </QInput>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersMixin, filtersStorageMixin } from '@/mixins'
import { Api, apiRequest, notifyError } from '@/api'
import { DATE_FILTERS } from '../../sections/FilterModal/filtersEntity'

export default {
    name: 'ContextualInfo',
    components: {
    },
    mixins: [filtersMixin, filtersStorageMixin],
    inject: ['App'],
    props: {
        totalInfo: VueTypes.object,
        filteredInfo: VueTypes.object,
        readOnly: VueTypes.bool.def(false),
    },
    emits: ['onApplyFilter'],
    data () {
        return {
            displayMode: 'filtered',
            openStartDateModal: false,
            openEndDateModal: false,
            datetimeFilters: undefined,
            selectedFilter: null,
            filterDate: {
                start: null,
                end: null,
            },
            durMode: {
                label: this.$t('visualization.filters.contextualInfo.avg'),
                value: 'avg',
            },
            displayDurationOptions: false,
        }
    },
    computed: {
        dictFormattedData () {
            const {
                totalInfo, filteredInfo, getDurationFormattedData, getFormattedData,
            } = this
            return {
                cases: getFormattedData('cases'),
                variants: getFormattedData('variants'),
                med: getDurationFormattedData('med'),
                avg: getDurationFormattedData('avg'),
                sd: getDurationFormattedData('sd'),
                min: getDurationFormattedData('min'),
                max: getDurationFormattedData('max'),
                minStartDateTime: {
                    totalData: this.App.dateLocationFormat(totalInfo.minStartDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.minStartDateTime, 'short'),
                },
                maxEndDateTime: {
                    totalData: this.App.dateLocationFormat(totalInfo.maxEndDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.maxEndDateTime, 'short'),
                },
            }
        },
        durOptions () {
            return [
                {
                    label: this.$t('visualization.filters.contextualInfo.med'),
                    value: 'med',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.avg'),
                    value: 'avg',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.sd'),
                    value: 'sd',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.min'),
                    value: 'min',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.max'),
                    value: 'max',
                },
            ]
        },
    },
    watch: {
        'dictFormattedData.minStartDateTime.totalData': {
            handler (newValue, oldValue) {
                if (newValue !== 'No data') {
                    this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
                }
            },
        },
        'dictFormattedData.maxEndDateTime.totalData': {
            handler (newValue, oldValue) {
                if (newValue !== 'No data') {
                    this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
                }
            },
        },
    },
    mounted () {
        this.getDateFilters()
        this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
        this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
    },
    methods: {
        setDurationOption (option) {
            this.durMode = option
        },
        getDurationFormattedData (key) {
            const { totalInfo, filteredInfo } = this
            return {
                totalData: totalInfo.duration ? moment.duration(totalInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
                filteredData: filteredInfo.duration ? moment.duration(filteredInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
            }
        },
        getFormattedData (key) {
            const {
                totalInfo, filteredInfo, numFormatter, formatPercentage,
            } = this
            return {
                totalData: this.App.numberLocationFormat(numFormatter(totalInfo[key]), false, true),
                totalDataPercentage: `${formatPercentage(totalInfo[key], totalInfo[key])}`,
                totalDataTooltip: totalInfo[key] !== numFormatter(totalInfo[key]) ? totalInfo[key] : '',
                filteredData: this.App.numberLocationFormat(numFormatter(filteredInfo[key]), false, true),
                filteredDataPercentage: `${formatPercentage(filteredInfo[key], totalInfo[key])}`,
                filteredDataTooltip: filteredInfo[key] !== numFormatter(filteredInfo[key]) ? filteredInfo[key] : '',
            }
        },
        formatPercentage (value, total) {
            return value ? ((value / total)).toFixed(2) : 0
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
        addDateFilter (selectedDate, filterType) {
            if (this.filterDate.start >= this.filterDate.end) { return }

            this.selectedFilter = filterType
            const { visualizationFilters } = this

            if (DATE_FILTERS.includes(this.selectedFilter.id) && selectedDate) {
                const dateFilter = {
                    ...this.selectedFilter,
                    key: selectedDate,
                    uuid: `${this.selectedFilter.id}_${Math.floor(Math.random() * 10000)}`,
                    hidden: true,
                }
                const oldDateFilter = visualizationFilters.find(filter => filter.id === this.selectedFilter.id)
                if (oldDateFilter) {
                    this.updateFilters(oldDateFilter)
                }
                this.addVisualizationFilters(dateFilter)
                this.$emit('onApplyFilter')
                this.openDateModal = false
            }
        },
        resetFilters () {
            const { visualizationFilters } = this
            const afterStartDateFilter = visualizationFilters.find(filter => filter.id === 'TRACE_STARTS_AFTER')
            const beforeEndDateFilter = visualizationFilters.find(filter => filter.id === 'TRACE_ENDS_BEFORE')
            afterStartDateFilter && this.updateFilters(afterStartDateFilter)
            beforeEndDateFilter && this.updateFilters(beforeEndDateFilter)
            this.openStartDateModal = false
            this.openEndDateModal = false
            this.$emit('onApplyFilter')
            this.$nextTick(() => {
                this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
                this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
            })
        },
        getDateFilters () {
            apiRequest(Api().visualizations.filters())
                .then((res) => {
                    const filtersIds = (this.visualizationFilters || []).map(({ id }) => id)
                    const sanitizedFilters = this.formatFilters(res)
                    const formattedFilters = sanitizedFilters.map(filter => (filtersIds.includes(filter.id) && !filter.repeatable ? { ...filter, readOnly: true } : filter))
                    const datetimeFilters = formattedFilters.filter(filter => filter.kind === 'DATETIME')
                    this.datetimeFilters = datetimeFilters
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        formatFilters (filters = []) {
            return filters.map(({
                id, label, kind, repeatable,
            }) => ({
                kind, id, label, repeatable,
            }))
        },
        inputDate (dateString, filterType) {
            this.addDateFilter(dateString, this.datetimeFilters.find(filter => filter.id === filterType))
            filterType === 'TRACE_STARTS_AFTER'
                ? this.openStartDateModal = false
                : this.openEndDateModal = false
        },
        formatDateString (dateString) {
            const parts = dateString.split('/')
            const day = parts[0]
            const month = parts[1]
            const year = parts[2]
            return `${year}/${month}/${day}`
        },
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        updateInfoTotal (infoTotal) {
            this.infoTotal = infoTotal
        },
    },
}
</script>
<style lang="scss">
    .custom-cursor.q-field--readonly.q-field--float .q-field__native,
    .custom-cursor.q-field--readonly.q-field--float .q-field__input {
        cursor: pointer;
    }

    .Data {
        margin: 2px;

        .q-field--dense .q-field__control,
        .q-field--outlined .q-field__control:after {
            max-height: 30px;
            max-width: 120px;
        }

        .q-field__control-container {
            max-height: 30px;
            max-width: 120px;
        }
    }

    .DataContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
    }

    .Duration,
    .Cases,
    .VariantsSidebar {
        width: 110px;
        margin: 10px;

        &.Cases {
            margin-left: 20px;
        }

        &.Duration .q-fab--form-square {
            height: 35px;
        }
    }
</style>
