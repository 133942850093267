export default api => ({
    list: params => api.get('/datasets', { params }),
    create: params => api.post('/datasets', params),
    createChild: ({ datasetId, params }) => api.post(`/datasets/${datasetId}`, params),
    get: ({ datasetId }) => api.get(`/datasets/${datasetId}`),
    getInfo: ({ datasetId }) => api.get(`/datasets/${datasetId}/info`),
    delete: ({ datasetId }) => api.delete(`/datasets/${datasetId}`),
    deleteSla: ({ datasetId }) => api.delete(`/datasets/${datasetId}/deleteSla`),
    update: ({ datasetId, params }) => api.put(`/datasets/${datasetId}`, params),
    setOpened: ({ datasetId }) => api.post(`/datasets/${datasetId}/open`),

    preview: ({ datasetId, params }) => api.get(`/datasets/${datasetId}/uploads/preview`, { params }),
    configure: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/configurations`, params),
    updateRelevant: ({ datasetId, context, params }) => api.put(`/datasets/${datasetId}/configurations/relevant/${context}`, params),
    getConfigurations: ({ datasetId }) => api.get(`/datasets/${datasetId}/configurations`),

    importers: () => api.get('/datasets/importers'),
    jdbc: () => api.get('/datasets/importers/jdbc'),

    denodoIntegratedImporter: () => api.get('/datasets/importers/DENODO_INT/workflows/list'),
    jdbcImporter: ({ params, importer }) => api.post(`/datasets/importers/jdbc/${importer}/workflows/list`, params),
    httpImporter: ({ params, importer }) => api.post(`/datasets/importers/http/${importer}/workflows/list`, params),

    importDenodoIntegratedworkflows: ({ importer, params }) => api.post(`/datasets/importers/${importer}/workflows/import`, params),
    importJdbcWorkflows: ({ importer, params, config }) => api.post(`/datasets/importers/jdbc/${importer}/workflows/import`, params, config),
    importHttpworkflows: ({ importer, params }) => api.post(`/datasets/importers/http/${importer}/workflows/import`, params),

    validateJdbc: (params, config) => api.post('/datasets/importers/jdbc/validate', params, config),
    activities: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/activities`, params),
    finishUpload: ({ datasetId }) => api.post(`/datasets/${datasetId}/uploads/finish`),
    download: ({ datasetId }) => api.get(`/datasets/${datasetId}/models/download`, { responseType: 'blob' }),

    generateUploadToken: ({ datasetId }) => api.post(`/datasets/${datasetId}/uploads/initiate`),
    infer: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/schema/infer`, params),

    getDatasetFilters: ({ datasetId }) => api.get(`/datasets/${datasetId}/filters`),
    registerDatasetFilters: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/filters`, params),
    deleteMultipleFiltersSets: ({ datasetId, filtersSets }) => api.post(`/datasets/${datasetId}/filters/delete`, filtersSets),
    updateFilterSetOperator: ({ datasetId, filterSetId, params }) => api.post(`/datasets/${datasetId}/filters/${filterSetId}`, params),

    overall: ({ processId, params }) => api.post(`/datasets/${processId}/performance/overall`, params),

    move: params => api.post(`/datasets/move`, params),

    createZoomer: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/createZoomer`, params),
})
